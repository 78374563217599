import React, { Component } from 'react';

import { FormValidator, FieldCreator, generateFieldRules } from 'components/Forms/components';

import { withNavigator } from 'hoc';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';
import ConfirmationButton from 'components/ConfirmationButton';

import { scheduleFormFields } from '../booking-schedules.fields';

import {
  findBookingByDateRange, 
  updateBookingSchedules, 
  createBookingSchedules
} from 'services/booking-schedules';

import { fetchAllCubicles } from 'services/cubicles';
import { fetchAllSpecies } from 'services/species-details';

// Material components/
import { Paper, Button, Grid, TextField, MenuItem, Tabs, Tab} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MultipleDateSelector from './MultipleDateSelector';

class BookingScheduleForm extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: false,
      validation: { isValid: false },
      statusMessage: null,
      scheduleMonth : props.currentValues &&  props.currentValues.schedules &&
              props.currentValues.schedules[0].availableDate 
                ? props.currentValues.schedules[0].availableDate 
                : new Date().toISOString().split('T')[0],
      speciesId : props.currentValues &&  props.currentValues.schedules &&
            props.currentValues.schedules[0].speciesId
              ? props.currentValues.schedules[0].speciesId.id : '',
      status : props.currentValues ?  props.currentValues.status : 'NotAvailable',
      activeTab : '',
      cubicleInfo : [],
      speciesInfo : []
    }
    
    this.currentSchedule = null;
    this.scheduleCheckError = false;
    
    if (props.currentValues && props.currentValues.schedules) {
      let availableDates = {};
      this.currentSchedule = {};
      props.currentValues.schedules.map( schedule => {
        let dateIdx = parseInt(schedule.availableDate.split('-')[2]);
        if (!availableDates[schedule.cubicleId.id]) {
          availableDates[schedule.cubicleId.id] = [];
          this.currentSchedule[schedule.cubicleId.id] = [];
          availableDates[schedule.cubicleId.id].push(dateIdx);
          this.currentSchedule[schedule.cubicleId.id].push(dateIdx);
        } else {
          availableDates[schedule.cubicleId.id].push(dateIdx);
          this.currentSchedule[schedule.cubicleId.id].push(dateIdx);
        }
      });
      this.state['availableDates'] = availableDates;
    } else {
      this.state['availableDates'] = null;
    }
    
    // field meta info
    this.meta = {
      scheduleMonth : { touched : false },
      speciesId : { touched : false },
      status : { touched : false },
      availableDates : { touched : false }
    };
    
    // start with empty validationRules
    this.validator = {};
    this.validationRules = [];

    // various form action states
    this.submitted = false;
    
    scheduleFormFields.forEach( f => {
      if (f.actions.includes('create') || 
        (props.currentValues && f.actions.includes('update'))) {
        generateFieldRules(f, this.validationRules);
      }
    });
    
    this.validator = new FormValidator(this.validationRules, scheduleFormFields);
    
    this.submitted = false;
  }
  
  isFieldError(field, validation) {
    return (this.meta[field].touched && validation[field] &&
      validation[field].message !== '')
  }
  
  async componentDidMount() {
    let cubicleInfo = [], speciesInfo = [];
    try {
      let response = await fetchAllCubicles({page : 1, per_page : -1, sort : 'id:ASC'});
      cubicleInfo = response.data.map ( x => { return ({'id' : x.id, 'name' : x.name})});
      
      response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      speciesInfo = response.data.map ( s => { return ({'id' : s.id, 'type' : s.name})});
      
      this.setState({cubicleInfo : cubicleInfo, speciesInfo : speciesInfo, activeTab : response.data[0].id});
    } catch (error) {
      console.log(error);
      this.setState({statusMessage : { status: "error", message: "Failed to get available cubicles data ...." }});
    }
  }
  
  scheduleExistsCheck = async (date, species) => {
    
    // In case of new schedule, check if schedule already exists 
    // for the selected month
    //
    this.scheduleCheckError = false;
    
    if (!date || !species || this.props.currentValues) return;
    
    let scheduleDate = new Date(date);
    let year = scheduleDate.getFullYear();
    let month =  scheduleDate.getMonth();
    
    let firstDay = new Date(year, month, 1);
    let lastDay = new Date(year, month + 1, 0);
    
    try {
      let response = await findBookingByDateRange({
        start : firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
        end : lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        speciesType : species,
        all : false
      });
      if (response.data && response.data.status === "NA") {
        return;
      }
    } catch (error) {
      console.log(error);
    }
    
    this.scheduleCheckError = true;
  }
  
  handleMonthChange = async (value) => {
    let fieldState = this.state;
    
    this.meta['scheduleMonth'].touched = true;
    fieldState['scheduleMonth'] = value.toISOString().split('T')[0];
    fieldState['validation'] = this.validator.validate(fieldState);
    
    // In case of new schedule, check if schedule already exists 
    // for the selected species and month
    //
    await this.scheduleExistsCheck(value.toISOString().split('T')[0], this.state.speciesId);
    if (this.scheduleCheckError) {
      // update validator
      fieldState['validation']['scheduleMonth'] = { isInvalid : true, message : "Schedule already exists for selected month and species"};
      fieldState['validation']['isValid'] = false;
    }
    
    this.setState(fieldState);
  }
  
  handleInputChange  = async (event) => {
    let fieldState = this.state;
    
    this.meta[event.target.name].touched = true;
    fieldState[event.target.name] = event.target.value;
    fieldState['validation'] = this.validator.validate(fieldState);
    
    // In case of new schedule, check if schedule already exists 
    // for the selected species and month
    //
    if (event.target.name === "speciesId") {
      await this.scheduleExistsCheck(this.state.scheduleMonth, event.target.value);
    }
    
    if (this.scheduleCheckError) {
      // update validator
      fieldState['validation']['speciesId'] = { isInvalid : true, message : "Schedule already exists for selected month and species"};
      fieldState['validation']['scheduleMonth'] = { isInvalid : true, message : "Schedule already exists for selected month and species"};
      fieldState['validation']['isValid'] = false;
    } 
    
    this.setState(fieldState);
  }
  
  handleAvailableDateChange = async (cubicle, dates) => {
    let fieldState = this.state;
    this.meta['availableDates'].touched = true;
    if (!fieldState['availableDates']) {
      fieldState['availableDates'] = {};
    }
    fieldState['availableDates'][cubicle] = dates;
    fieldState['validation'] = this.validator.validate(fieldState);
    
    if (this.scheduleCheckError) {
      fieldState['validation']['scheduleMonth'] = { isInvalid : true, message : "Schedule already exists for selected month and species"};
      fieldState['validation']['speciesId'] = { isInvalid : true, message : "Schedule already exists for selected month and species"};
      fieldState['validation']['isValid'] = false;
    }
    
    this.setState(fieldState);
  }
  
  handleFormSubmit = async () => {
    
    let statusMessage = this.scheduleCheckError 
      ? { status : "error", message : "schedule for the selected month and species might already exist. Cannot create new one !..."} 
      : { status : "success", message : "successfully updated cubicle booking schedules...!"} 
      
    if (!this.props.currentValues && this.scheduleCheckError) {
      this.setState({statusMessage});
      return;
    }
    
    // todo API call to save data
    try {
      let formData = { bulkData : [], status : this.state.status };
      let schedules = this.state.availableDates;
      let splitDate = this.state.scheduleMonth.split('-');
      
      // fill all changed and deleted dates, edit case
      if (this.currentSchedule) {
        Object.keys(this.currentSchedule).map (cubicle => {
          if (this.currentSchedule[cubicle] && this.currentSchedule[cubicle].length) {
            for (let i = 0; i < this.currentSchedule[cubicle].length ; ++i) {
              let operation = null;
              let exists = schedules[cubicle].includes(this.currentSchedule[cubicle][i]);
              let day = this.currentSchedule[cubicle][i];
              if (!exists) {
                operation = "delete";
              } else if (exists && this.props.currentValues.status !== this.state.status) {
                operation = "modify";
              }
              if (operation != null) {
                formData.bulkData.push({
                  'operation' : operation,
                  'status' : this.state.status,
                  'availableDate' : `${splitDate[0]}-${splitDate[1]}-${day < 10 ? '0'+day : day}`,
                  'speciesId': this.state.speciesId,
                  'cubicleId' : cubicle
                });
              }
            }
          }
        });
      }
      
      // fill in new selected dates
      Object.keys(schedules).map (cubicle => {
        if (schedules[cubicle] && schedules[cubicle].length) {
          for (let i = 0; i < schedules[cubicle].length ; ++i) {
            if (!this.currentSchedule || !this.currentSchedule[cubicle] || 
              !this.currentSchedule[cubicle].includes(schedules[cubicle][i])) {
              let day = schedules[cubicle][i];
              formData.bulkData.push({
                'operation' : 'add',
                'status' : this.state.status,
                'availableDate' : `${splitDate[0]}-${splitDate[1]}-${day < 10 ? '0'+day : day}`,
                'speciesId': this.state.speciesId,
                'cubicleId' : cubicle
              });
            }
          }
        }
      });
      let response;
      if (this.currentSchedule) {
        response = await updateBookingSchedules(formData);
      } else {
        response = await createBookingSchedules(formData);
      }
      
      // navigate to index page if successful
      this.props.navigate('/aqf/booking-schedules/list');
    
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message };
    }
      
    this.setState({statusMessage});  
  }
  
  render() {

    // if the form has been submitted at least once then check validity every time we render otherwise just use what's in state
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    let dateName = this.state.scheduleMonth ? 
      new Date(this.state.scheduleMonth).toLocaleString('en-us', { month: 'long', year : 'numeric' }) : '';
    
    return (
      <>
        <form>
          <div>
            {
              this.state.statusMessage && this.state.statusMessage.message && 
              <CustomSnackbar variant={this.state.statusMessage.status}
                message={this.state.statusMessage.message}
                open={this.state.statusMessage.status}
                onClose={() => this.setState({ statusMessage: null })}
              />
            }
            <Grid container spacing={3}>
              <Grid key={0} item xs={12} sm={4} md={4}>
              {
                this.props.currentValues ?
                <TextField
                  id={'scheduleMonth'}
                  label="Schedule Month"
                  name={'scheduleMonth'}
                  fullWidth
                  type='string'
                  size="small"
                  value={dateName}
                  //onChange={this.handleInputChange}
                  margin="normal"
                  variant="standard"
                  InputProps = {{ readOnly : true }} 
                /> : 
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Schedule Month"
                    value={this.state.scheduleMonth}
                    onChange={this.handleMonthChange}
                    renderInput={(params) => <TextField {...params}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    error={this.isFieldError('scheduleMonth', validation)}
                    helperText={this.isFieldError('scheduleMonth', validation) ? validation['scheduleMonth'].message : ''} 
                    />}
                  />
                </LocalizationProvider>
              }
              </Grid>
              <Grid key={1} item xs={12} sm={4} md={4}>
                <TextField
                  id={'speciesId'}
                  label={'Species Type'}
                  name={'speciesId'}
                  fullWidth
                  select
                  type='text'
                  size="small"
                  value={this.state.speciesId}
                  onChange={this.handleInputChange}
                  margin="normal"
                  variant="standard"
                  InputProps = {{ readOnly : this.props.currentValues ? true : false }} 
                  error={this.isFieldError('speciesId', validation)}
                  helperText={this.isFieldError('speciesId', validation) ? validation['speciesId'].message : ''}
                  SelectProps = {{ multiple : false }}
                >
                {
                  this.state.speciesInfo.map ( (s, idx) => {
                    return (<MenuItem key={idx} value={s.id}>{s.type}</MenuItem>)
                  })
                }
                </TextField>
              </Grid>
              <Grid key={2} item xs={12} sm={4} md={4}>
                <TextField
                  id={'status'}
                  label={'Schedule Status'}
                  name={'status'}
                  fullWidth
                  select
                  type='text'
                  size="small"
                  value={this.state.status}
                  onChange={this.handleInputChange}
                  margin="normal"
                  variant="standard"
                  error={this.isFieldError('status', validation)}
                  helperText={this.isFieldError('status', validation) ? validation['status'].message : ''}
                  SelectProps = {{ multiple : false }}
                >
                  <MenuItem key={0} value={'Available'}>{ "Available"}</MenuItem>
                  <MenuItem key={3} value={'NotAvailable'}>{"Not Available"}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </div><br/>
          
          {
            this.state.cubicleInfo.length > 0 &&
             <Grid container spacing={3}>
              <Grid key={1} item xs={12} sm={12} md={12}>
                <Paper elevation={4} sx={{textAlign:"center"}}>
                  <Tabs
                    value={this.state.activeTab}
                    variant="fullWidth"
                    onChange={async (event, newValue) => await this.setState({activeTab : newValue}) }
                    textColor="primary"
                    indicatorColor="secondary"
                    aria-label="Cubicle Schedules"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                  {
                    this.state.cubicleInfo.map ( c => {
                      return (<Tab key={c.id} value={c.id} label={c.name} />)
                    })
                  }
                  </Tabs>
                </Paper>
              </Grid>
              <Grid key={2} item xs={12} sm={12} md={12} sx={{m:2}}>
              {
                this.state.cubicleInfo.map ( c => {
                  if (this.state.activeTab === c.id) {
                    return (
                      <div role="tabpanel" 
                        hidden={this.state.activeTab !== c.id} 
                        id={c.id} 
                        aria-labelledby="hatchery-details"
                        key = {c.id}
                      >
                      <MultipleDateSelector
                        cubicle = {c}
                        currentMonth = {this.state.scheduleMonth}
                        selectedDates={this.state.availableDates ? this.state.availableDates[c.id] : []}
                        onDateSelect={dates => this.handleAvailableDateChange(c.id, dates)}
                      />
                    </div>);
                  }
                })
              }
              </Grid>
            </Grid>
          }
          <ConfirmationButton
            fullWidth={true}
            color = "primary"
            disabled={!validation.isValid || this.state.isLoading }
            onClick={this.handleFormSubmit}
            confirmation="Are you sure you want to save the booking schedules ?"
            buttonText = {this.state.isLoading ? "Please Wait..." : "Save Schedule Details"}
          />
        </form>
      </>
    );
  }
}

export default withNavigator(BookingScheduleForm);
