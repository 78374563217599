import { useState,useEffect  } from 'react';

const LiveDateTime = (props) => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000); // Update every second
    
        return () => clearInterval(interval);
      }, []);

      const formattedDate = currentDateTime.toLocaleDateString();
      const formattedTime = currentDateTime.toLocaleTimeString();

      return (
        <div>
          <p>{formattedDate} {formattedTime}</p>
        </div>
      );
};

export default LiveDateTime;