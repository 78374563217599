export default [
  {
    name: 'species_id',
    label : 'Species Type',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create','index', 'view', 'update']
  },
  {  
    name: 'startTime',
    label: 'Bookings Start Time',
    type: 'datetime',
    properties: [{required:true}],
    actions: ['create', 'view', 'index','update']
  },
  {
    name: 'unlimitedStartTime',
    label: 'Unlimited Bookings Start Time',
    type: 'datetime',
    properties: [{required:true},{datetime : {DateGreaterThan : "startTime"}}],
    actions: ['create',  'view', 'index','update']
  },
  {  
    name: 'status',
    label: 'Status',
    type: 'select',
    properties: [{required:true}],
    selectOptions : {
      true : "Active",
      false : "In Active"
    },
    actions: ['view', 'index','update']
  }
];