import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';
import { Navigate } from 'react-router-dom';

import { Paper, Grid, Typography, Dialog, DialogTitle, DialogContent, Box, Button, IconButton } from '@mui/material';

import Close from '@mui/icons-material/Close';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import NavigateBack from 'components/NavigateBack';

// import TableView Component
import DTable from "components/DataTables/DTable";

// import single entry view component
import EntryGridView from 'components/EntryGridView';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import AddSampleCollection from './add';
import EditSampleCollection from './edit';

import { sampleCollectionFields } from  './sample-collection.fields';
import { bookingFields } from  'pages/bookings/bookings.fields';


import { fetchSampleCollection } from 'services/sample-collection';

import { permissionCheck } from 'permissions';

class ViewSampleCollection extends Component {

  state = {
    reportData : null,
    modalOpen : false,
    currentValues : null,
    printView : false,
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."},
  };
  
  constructor(props) {
    super(props);
    this.sampleCollectionFields = Array.from(sampleCollectionFields);
    this.sampleCollectionFields.splice(1,2);
    
  }
  
  async componentDidMount() {
    
    let statusMessage = this.state.statusMessage;
    let reportData = {};
    
    try {
      let response = await fetchSampleCollection({
        bookingId : this.props.locationParams.id, 
        page : 1, 
        per_page : 100,
        hatcheryId : 'ALL',
        speciesType : 'ALL'
      });
      
      reportData = response.data[0];
      if (reportData.bulkData && reportData.bulkData.length>0)
      {
        reportData.samplecollectionscount = reportData.bulkData.length;
      }
      else
      {
        reportData.samplecollectionscount = reportData.bulkData.length;
      }

      // initialize display fields used in single entry view
      reportData.booking = reportData.bookingId;
      reportData.bookingId = reportData.booking.id;
      reportData.booking.hatcheryName = reportData.booking.hatcheryId.name;
      reportData.booking.totalBioMass = (reportData.booking.bioMassPerStock * reportData.booking.totalNumberOfStock) / 1000;
      if (reportData.booking.hatcheryId.license) {
        reportData.booking.CAARegistrationNumber = reportData.booking.hatcheryId.license.CAARegistrationNumber;
        reportData.booking.validFrom = reportData.booking.hatcheryId.license.validFrom;
        reportData.booking.validTo = reportData.booking.hatcheryId.license.validTo;
      }
      if (reportData.bulkData ) {
        reportData.bulkData.map ( e => {
          e.bioMassPerStock = reportData.booking.bioMassPerStock;
          e.totalNumberOfStock = reportData.booking.totalNumberOfStock;
        });
      }
      
      // extract supplier info
      if (reportData.booking.supplierId && typeof reportData.booking.supplierId === 'object') {
        reportData.booking.supplierDetails = reportData.booking.supplierId;
        reportData.booking.supplierId = reportData.booking.supplierDetails.name;
      }
      
      // extract species info
      reportData.booking.speciesType = reportData.booking.speciesType.name;
        
      statusMessage = {status: "success", message: "Fetched sample collection data for booking successfully !"};
    } catch (error) {
        console.log (error);
        reportData = null;
        statusMessage = { status : "error", message : error.message, code : error.status };
    }
    await this.setState({reportData, statusMessage});
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  handleCreateSample  = async (reportType) => {
    await this.setState({ modalOpen : true,  reportOp: "create"});
  }
  
  handleEditSample = async (idx, entry) => {
    await this.setState({ modalOpen : true, reportOp : "update", currentValues : entry});
  }

  render() {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
    
    return (<>
      {
        this.props.locationParams && this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      {
        this.state.printView === false &&
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
            <Button
              variant="outlined" 
              color="warning" 
              size="small"
              startIcon={<PrintOutlinedIcon />}
              onClick={this.handlePrint}
            > 
              Print 
            </Button>
          </Box>  
          <NavigateBack />
        </Grid>
      }
      
      <Paper elevation={3}  sx={{textAlign:"center", p:2, m:2}}>
        <Typography variant="h3"> 
          View Sample Collection Details{ ` - Booking ID ${this.props.locationParams.id}`} 
        </Typography>
      </Paper>
      {
        this.state.reportData && 
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item sm={8} xs={12}>
            <EntryGridView values={this.state.reportData.booking} formFields = {bookingFields} />
          </Grid>
        </Grid>
      }
      {
        <Dialog maxWidth="lg" open={this.state.modalOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={() => this.setState({modalOpen : false})}><Close /></IconButton>
          </DialogTitle>
          <DialogContent sx={{textAlign:"center", p:2}}> 
          {
            this.state.reportOp === 'create' &&
            <AddSampleCollection 
              bookingData={this.state.reportData.booking}
              samplecollectionscount={this.state.reportData.samplecollectionscount}
              redirectUrl={`/aqf/bookings/samples/${this.props.locationParams.id}`}
            />
          }
          {
            this.state.reportOp === 'update' &&
            <EditSampleCollection 
              bookingData={this.state.reportData.booking}
              redirectUrl={`/aqf/bookings/samples/${this.props.locationParams.id}`}
              currentValues={this.state.currentValues}
              single={true}
            />
          }
          </DialogContent>
        </Dialog>
      }
      {
        this.state.reportData && 
        this.state.reportData.booking.status === 'Confirmed' &&
        permissionCheck('booking', 'create-sample-collection-storage') &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p:2 }}>
          <Button color="primary" size="small" variant="contained" 
            // disabled={this.state.reportData.bulkData && this.state.reportData.bulkData.length >= 5}
            onClick ={this.handleCreateSample}
          >
            Add Samples
          </Button>
        </Box>
      }
      {
        this.state.reportData && this.state.reportData.bulkData &&
        <DTable
            columns = {this.sampleCollectionFields}
            rowsPerPage={this.state.reportData.bulkData ? this.state.reportData.bulkData.length : 0}
            dataTotal={this.state.reportData.bulkData ? this.state.reportData.bulkData.length : 0}
            data={this.state.reportData.bulkData ? this.state.reportData.bulkData : []}
            page={1}
            editUrlPath={this.handleEditSample}
        />
      }
    </>);
  }
}

export default withPrint(withLocationParams(ViewSampleCollection));
