import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import speciesFields from './species.fields';

import { fetchSpeciesById, updateSpecies } from 'services/species-details';

class EditSpecies extends Component {
  
  state = { 
    speciesData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
    speciesFields[1].callback = this.calculateCubicleFee;
    speciesFields[2].callback = this.calculateCubicleFee;
  }
  
  calculateCubicleFee = async (name, value, formData, asyncCb) => {
    let labFee = formData.lab_fee ? parseInt(formData.lab_fee) : 0;
    let quarantineFee = formData.quarantine_fee ? parseInt(formData.quarantine_fee) : 0;
    
    formData.cubicle_fee = labFee + quarantineFee;
   
    return { fields : null, values : formData};
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated species details..." };
    
    // todo API calls
    try {
      params.details = params.name;
      let response = await updateSpecies(this.state.speciesData.id, params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let speciesData = null;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchSpeciesById(this.props.locationParams.id);
      speciesData = response.data;
      speciesData.speciesIds = (speciesData.speciesIds || {}).id;
      statusMessage = {status: "success", message: "Fetched hatchery Details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({speciesData, statusMessage});
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Species Details </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.speciesData &&
            <DynamicForm
              formFields={speciesFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              buttonText="Update Species Details"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.speciesData}
              redirect={'/aqf/admin/species-details/list'}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditSpecies);