import React, { Component } from 'react';
import Box from '@mui/material/Box';
import PDFViewer from 'components/PDFViewer';

export default function Policy(props) {
  return (
    <Box sx={{mt:2}}>
      {
        props.type === 'terms' &&
        <PDFViewer url = {process.env.PUBLIC_URL + "/static/docs/terms.pdf"} scale={1.6}/>
      }
      {
        props.type === 'privacy' &&
        <PDFViewer url = {process.env.PUBLIC_URL + "/static/docs/privacy.pdf"} scale={1.6} />
      }
      {
        props.type === 'refunds' &&
        <PDFViewer url = {process.env.PUBLIC_URL + "/static/docs/cancellation-refunds.pdf"} scale={1.6} />
      }
    </Box>
  );
}