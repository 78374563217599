import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';
import { Navigate } from 'react-router-dom';

import { 
  Paper, Grid, Typography, Button, Box, Dialog, DialogTitle, IconButton, DialogContent,
  TableContainer, Table, TableRow, TableCell
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import BorderColorOutlined from '@mui/icons-material/BorderColorOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

// import TableView Component
import DTable from "components/DataTables/DTable";

import NavigateBack from 'components/NavigateBack';

// import single entry view component
import EntryGridView from 'components/EntryGridView';

// import add and edit report components
import AddNewBookingReport from 'pages/booking-reports/add';
import EditBookingReport from 'pages/booking-reports/edit';
import ReportApprovalForm  from 'pages/booking-reports/form-components/ReportApprovalForm';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import { AuthStore } from 'store';

import {
  receivedSampleFields,
  labAnalysisFieldsLvannamei,
  labAnalysisFieldsMonodon,
  labAnalysisReportFieldsLvannamei,
  labAnalysisReportFieldsMonodon,
  quarantineFields,
  clearanceFields,
  shipmentPackingFields,
  feedingFields,
  mortalityFields,
  waterQualityFields,
  sampleWeightFields,
  labAnalysisReportFields
} from './booking-reports.fields';

import { bookingFields } from 'pages/bookings/bookings.fields';

import { 
  fetchBookingReportById, generateBookingPdfReport, 
  filterFormFieldsBySpeciesType,filterDataBySpeciesType
} from 'services/booking-reports';

import { filterBookingDataBySpeciesType } from 'services/bookings';

import { permissionCheck } from 'permissions';

import { calculateAPISignature } from 'services/utils';

class ViewBookingReport extends Component {

  state = {
    modalOpen : false,
    reportOp : null,
    reportData : null,
    currentValues : null,
    pdfReport : null,
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."},
    printView : false,
  };
  
  constructor(props) {
    super(props);
    
    this.reportTypeString = '';
    this.formFields = [];
    this.speciesType = 'Broodstock';

  }
  
  initializeFormFields = (reportData) => {
        
    switch (this.props.locationParams.reportType) {
      case 'received-sample-report':
        this.reportTypeString = "Arrival Report";
        this.formFields = Array.from(receivedSampleFields);
      break;
      case 'feeding-report':
        this.reportTypeString = "Feeding Report";
        this.formFields = Array.from(feedingFields);
      break;
      case 'mortality-report':
        this.reportTypeString = "Mortality Report";
        this.formFields = Array.from(mortalityFields);
      break;
      case 'lab-analysis':
        this.reportTypeString = "Sample Lab Analysis";
        if (reportData && reportData.booking.speciesType.name.toLowerCase().includes('vannamei')) {
          this.formFields = Array.from(labAnalysisFieldsLvannamei);
        } else {
          this.formFields = Array.from(labAnalysisFieldsMonodon);
        }
      break;
      case 'lab-analysis-report':
        this.reportTypeString = "Lab Analysis Report";
        if (reportData && reportData.booking.speciesType.name.toLowerCase().includes('vannamei')) {
          if (reportData && reportData.booking.speciesType.type=="PPL")
          {
            labAnalysisReportFieldsLvannamei[24].label="PPL Dispatch"
          }
          this.formFields = Array.from(labAnalysisReportFieldsLvannamei);
        } else {
          if (reportData && reportData.booking.speciesType.type=="PPL")
          {
            labAnalysisReportFieldsMonodon[27].label="PPL Dispatch"
          }
          this.formFields = Array.from(labAnalysisReportFieldsMonodon);
        }
      break;
      case 'water-quality-analysis':
        this.reportTypeString = "Water Quality Analysis Report";
        this.formFields = Array.from(waterQualityFields);
      break;
      case 'sample-weight-of-brood-stock':
        if (reportData && this.speciesType === 'PPL') {
          this.reportTypeString = "Sample PPL Weight Report";
        } else {
          this.reportTypeString = "Sample BS Weight Report";
        }
        this.formFields = Array.from(sampleWeightFields); 
      break;
      case 'quarantine-report':
        this.reportTypeString = "Quarantine Report";
        this.formFields = Array.from(quarantineFields);
      break;
      case 'clearance-certificate':
        this.reportTypeString = "Clearance Certificate";
        this.formFields = Array.from(clearanceFields);
      break;
      case 'shipment-packing':
        this.reportTypeString = "Shipment Packing Report";
        this.formFields = Array.from(shipmentPackingFields);
      break;
      default:
      break;
    }
    
    // remove booking ID field as we are showing this data in context of a booking
    this.formFields = this.formFields.filter(x => x.name !== 'bookingId');
    
    // remove hatchery ID field as we are showing this data in context of a booking
    this.formFields = this.formFields.filter(x => x.name !== 'hatcheryId');
    
    // remove updatedBy field for hatchery owners
    if (AuthStore.state.currentUser &&  
      AuthStore.state.currentUser.user.role.name === 'Hatchery Owner') {
      this.formFields = this.formFields.filter(x => x.name !== 'createUser' && x.name !== 'updateUser');
    }
    
    // populate cubicle info from booking data
    let cubicleFieldIdx = this.formFields.findIndex( f => f.name === 'cubicleId' );
    if (cubicleFieldIdx >= 0 && reportData) {
      if (reportData.booking.cubicleDetails) {
        reportData.booking.cubicleDetails.map( c => {
          if (c.cubicleId) this.formFields[cubicleFieldIdx].selectOptions[c.cubicleId] = c.cubicleName;
          else if (c.cubicleNumber) this.formFields[cubicleFieldIdx].selectOptions[c.cubicleNumber] = `CUB-${c.cubicleNumber}`;
        });
      } else if (reportData.booking.cubicleBookingScheduleIds) {
        reportData.booking.cubicleBookingScheduleIds.map(c => {
          this.formFields[cubicleFieldIdx].selectOptions[c.cubicleId] = `CUB-${c.cubicleId}`;
        });
      }
    }
    
    // filter fields based on species type
    this.formFields = filterFormFieldsBySpeciesType(this.props.locationParams.reportType, 
      this.speciesType, this.formFields);
  }
  
  initializeBookingReportData = (reportData) => {
    
    // initialize booking info fields used in single entry view (hatchery, license)
    reportData.booking = reportData.bookingId;
    reportData.bookingId = reportData.booking.id;
    reportData.booking.hatcheryName = reportData.booking.hatcheryId.name;
    reportData.booking.hatcheryAddress = reportData.booking.hatcheryId.address;
    reportData.consignmentId = reportData.booking.consignmentId;
    if (reportData.booking.hatcheryId.license) {
      reportData.booking.CAARegistrationNumber = reportData.booking.hatcheryId.license.CAARegistrationNumber;
      reportData.booking.validFrom = reportData.booking.hatcheryId.license.validFrom;
      reportData.booking.validTo = reportData.booking.hatcheryId.license.validTo;
    }

    reportData.booking.numCubicles =
      reportData.booking.cubicleBookingScheduleIds && reportData.booking.cubicleBookingScheduleIds.length
        ? reportData.booking.cubicleBookingScheduleIds.length 
        : reportData.booking.cubicleDetails ? reportData.booking.cubicleDetails.length : 0;

    reportData.booking.cubicleNumber = 
      reportData.booking.cubicleDetails ? reportData.booking.cubicleDetails.map( x => x.cubicleName).join(',') : '';
   
    // extract species info
    this.speciesType = reportData.booking.speciesType.type;
    
    if (reportData.bulkData) {
      
      // extract cubicle info from bulk data
      reportData.bulkData.forEach ( entry => {
        entry.selection = false;
        if (entry.cubicleId && typeof entry.cubicleId === 'object') {
          entry.cubicleId = entry.cubicleId.id;
        }
      });
    } else { 
      // extract cubicle info
      if (reportData.cubicleId && typeof reportData.cubicleId === 'object') {
        reportData.cubicleId = reportData.cubicleId.name;
      }
    }
      
    // extract supplier info
    if (reportData.booking.supplierId && typeof reportData.booking.supplierId === 'object') {
      reportData.booking.supplierDetails = reportData.booking.supplierId;
      reportData.supplierId = reportData.booking.supplierId.name;
      reportData.booking.supplierId = reportData.booking.supplierDetails.name;
    } 
    
    if (typeof reportData.supplierId === 'object') {
      reportData.supplierId = reportData.supplierId.name;
    }
    
    // extract created by and updated by info
    if (reportData.createUser && typeof reportData.createUser === 'object') {
      reportData.createUser = reportData.createUser.name;
    }
    if (reportData.updateUser && typeof reportData.updateUser === 'object') {
      reportData.updateUser = reportData.updateUser.name;
    }
    
    return reportData;
  }
  
  
  async componentDidMount() {
    
    let statusMessage = this.state.statusMessage;
    let reportData = {};
    let pdfReport = null;
    
    try {
      let response = await fetchBookingReportById(
        this.props.locationParams.id, 
        this.props.locationParams.reportType
      );
      
      reportData = this.initializeBookingReportData(response.data);
     
      if (reportData.booking.documents) {
        switch (this.props.locationParams.reportType) {
          case 'received-sample-report':
            pdfReport = reportData.booking.documents['arrivalReport'];
            break;
          case 'quarantine-report': break;
          case 'lab-analysis-report':
            pdfReport = reportData.booking.documents['labAnalysisReport'];
            break;
          case 'clearance-certificate':
            pdfReport = reportData.booking.documents['clearanceCertificate'];
            break;
          case 'shipment-packing':
            pdfReport = reportData.booking.documents['shipmentPacking'];
            break;
          default:
            break;
        }
      }
      
      // filter data and fields by species type
      this.initializeFormFields(reportData);
      if (reportData.bulkData) {
        reportData.bulkData = reportData.bulkData.map (data => {
          data.cubicleId = data.number;
          return filterDataBySpeciesType(this.props.locationParams.reportType, 
            this.speciesType, data);
        });
      } else {
        reportData = filterDataBySpeciesType(this.props.locationParams.reportType, 
          this.speciesType, reportData);
      }
      
      statusMessage = {status: "success", message: "Fetched test report data successfully !"};
    } catch (error) {
        console.log (error);
        reportData = null;
        statusMessage = { status : "error", message : error.message, code : error.status };
    }
    
    await this.setState({reportData, statusMessage, pdfReport});
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  handleCreateNewReport  = async (reportType) => {
    await this.setState({ modalOpen : true,  reportOp: "create"});
  }
  
  handleViewFullReport  = async (idx, entry) => {
    await this.setState({ modalOpen : true, reportOp : "view", currentValues : entry});
  }
  
  handleEditReport = async (idx, entry) => {
    await this.setState({ modalOpen : true, reportOp : "update", currentValues : entry});
  }
  
  handleReportApproval = async () => {
    await this.setState({ modalOpen : true, reportOp : "approve"});
  }
  
  handleGenerateReport = async (event) => {
    let response;
    let statusMessage = { status : "warning", message : "Please wait, while the report is being generated"};
    this.setState({statusMessage});
    try {
      response = await generateBookingPdfReport(this.props.locationParams.reportType, 
        this.props.locationParams.id);
      statusMessage = { status : "success", message : "successfully generated PDF report"};
      this.setState({pdfReport : response.data.report, statusMessage : statusMessage});
    } catch(error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message};
      this.setState({statusMessage});
    }
  }
  
  canGeneratePdfReport = () => {
    let allowed = false;
    switch (this.props.locationParams.reportType) {
      case 'received-sample-report':
        allowed = permissionCheck('booking-reports', 'update-received-sample-report');
        break;
      case 'lab-analysis-report':
        allowed = permissionCheck('booking-reports', 'update-lab-analysis-report');
        break;
      case 'quarantine-report':
      case 'clearance-certificate':
        allowed = permissionCheck('booking-reports', 'update-clearance-certificate');
        break;
      case 'shipment-packing':
        allowed = permissionCheck('booking-reports', 'update-shipment-packing');
        break;
    }
    return allowed;
  }
  
  isSingleReportType = () => {
    switch (this.props.locationParams.reportType) {
      case 'received-sample-report':
      case 'lab-analysis-report':
      case 'quarantine-report':
      case 'clearance-certificate':
      case 'shipment-packing':
        return true;
      default:
        return false;
    }
    
    return false;
  }

  canPerformOperation = (operation) => {

    // check booking status
    if (!this.state.reportData || 
        (this.state.reportData && this.state.reportData.booking.status !== 'Confirmed')) {
        return false;
    }

    // check permissions
    if (permissionCheck('booking-reports', `${operation}-${this.props.locationParams.reportType}`) === false) {
        return false;
    }

    // check if lab report is already prepared
    const labAnalysis = this.state.reportData.booking.documents && 
        this.state.reportData.booking.documents.labAnalysisReport ? true : false;

    // check if clearance report is prepared already
    const clearance = this.state.reportData.booking.documents &&
        this.state.reportData.booking.documents.clearanceCertificate ? true : false;

    switch (this.props.locationParams.reportType) {
      case 'received-sample-report':
        return ((operation != 'create') && (labAnalysis === false)); 
      case 'feeding-report':
      case 'mortality-report':
      case 'lab-analysis':
      case 'water-quality-analysis':
      case 'sample-weight-of-brood-stock':
        return (labAnalysis === false);
      case 'lab-analysis-report':
        return (clearance === false);
      case 'quarantine-report':
      case 'clearance-certificate':
        return (this.state.reportData.booking.status === 'Confirmed');
      case 'shipment-packing':
        return true;
      break;
      default:
      break;
    }
  }
  
  render() {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
   
    const signature = this.state.pdfReport 
      ? calculateAPISignature(encodeURIComponent(this.state.pdfReport.replace(/ /g, '%20')))
      : null;
      
    let bookingSummary = this.state.reportData ? {...this.state.reportData.booking} : null;
    if (bookingSummary) {
      if (bookingSummary.oldId) bookingSummary.id =  `${bookingSummary.oldId} / ${bookingSummary.id}`;
      bookingSummary = filterBookingDataBySpeciesType(this.speciesType, bookingSummary);
      bookingSummary.speciesType = bookingSummary.speciesType.name;
    }
    
    return (<>
      {
        this.props.locationParams && this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      
      {
        this.state.printView === false &&
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
            <Button
              variant="outlined" 
              color="warning" 
              size="small"
              startIcon={<PrintOutlinedIcon />}
              onClick={this.handlePrint}
            > 
              Print 
            </Button>
          </Box>  
          <NavigateBack />
        </Grid>
      }
      
      {
        <Dialog maxWidth="xl" open={this.state.modalOpen} aria-labelledby="form-dialog-title">
          <DialogTitle sx= {{ display : 'flex', justifyContent : 'flex-end'}}>
            <IconButton onClick={()=> this.setState({modalOpen : false})}><CloseIcon /></IconButton>
          </DialogTitle>
          <DialogContent sx={{textAlign:"center", p:1}}> 
          {
            this.state.reportOp === 'create' &&
            <AddNewBookingReport 
              reportType={this.props.locationParams.reportType} 
              bookingData={this.state.reportData.booking}
              redirectUrl={`/aqf/bookings/test-reports/${this.props.locationParams.reportType}`}
            />
          }
          {
            this.state.reportOp === 'update' &&
            <EditBookingReport 
              reportType={this.props.locationParams.reportType} 
              bookingData={this.state.reportData.booking}
              currentValues={this.state.currentValues}
              redirectUrl={`/aqf/bookings/test-reports/${this.props.locationParams.reportType}`}
            />
          } 
          {
            this.state.reportOp === 'approve' &&
            <ReportApprovalForm 
              reportType={this.props.locationParams.reportType} 
              id={this.props.locationParams.id}
              redirectUrl={`/aqf/bookings/test-reports/${this.props.locationParams.reportType}`}
            />
          }
          {
            this.state.reportOp === 'view' &&
            <>
              <Typography variant="h3"> 
                {` View ${this.reportTypeString} - Booking ID : ${this.state.reportData.bookingId}`} 
              </Typography>
              <EntryGridView values={this.state.currentValues} formFields = {this.formFields} noPaper={true} />
            </>
          }           
          </DialogContent>
        </Dialog>
      }
      
      <Paper elevation={3}  sx={{textAlign:"center", p:2, m:1}}>
        <Typography variant="h3"> 
          View Report Details{ ` - ${this.reportTypeString}`} 
        </Typography>
      </Paper>
      {
        this.state.reportData && 
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item sm={8} xs={12}>
            <EntryGridView values={bookingSummary} formFields = {bookingFields} title="Booking Summary"/>
          </Grid>
          {
            this.state.printView === false &&
            <Grid item sm={4} xs={12}>
            {
              this.isSingleReportType() &&
              <Paper elevation={3} sx={{textAlign:"center", p:1, m:2}}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item sm={3} xs={2}>
                    <img 
                      src={
                        process.env.PUBLIC_URL + 
                        (this.state.pdfReport ? "/static/images/misc/success.png" : "/static/images/misc/pending.png")
                      }
                      height="64"
                    />
                  </Grid>
                
                  <Grid item sm={9} xs={12}>
                  {
                    this.state.pdfReport ?
                    <Button color="secondary" variant="outlined" 
                      startIcon={<OpenInNewOutlined /> }
                      fullWidth
                      href={process.env.REACT_APP_STRAPI_FILE_URL + this.state.pdfReport + '?s=' + signature}
                      component="a" target="_blank"
                    >
                      View PDF Report
                    </Button> :
                    <>
                    {
                      this.canGeneratePdfReport() ?
                      <Button color="secondary" variant="outlined"
                        fullWidth
                        startIcon={<BorderColorOutlined /> }
                        onClick={this.handleGenerateReport}
                      >
                        Generate PDF Report
                      </Button> :
                      <Typography variant="subtitle1" noWrap> PDF Report Not Available </Typography> 
                    }
                    </>
                  }
                  </Grid>
                </Grid>
              </Paper>
            }
            </Grid>
          }
        </Grid>
      }
      {
        this.state.reportData && this.canPerformOperation("create") &&
        this.isSingleReportType() == false &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p:2 }}>
          <Button color="primary" size="small" variant="contained" 
            onClick ={() => this.handleCreateNewReport(this.state.reportData)}
          >
          { 'Add ' + this.reportTypeString }
          </Button>
        </Box>
      }  
      {
        this.state.reportData && this.isSingleReportType() && this.canPerformOperation("update") &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p:2 }}>
          <Button color="primary" size="small" variant="contained" 
            onClick ={() => this.handleEditReport(0, this.state.reportData)}
          >
          { 'Edit ' + this.reportTypeString }
          </Button>
        </Box>
      }
       
      {
        this.state.reportData &&  
        <>
        {  
          this.state.reportData.bulkData ?
          <DTable
            columns = {this.formFields}
            rowsPerPage={this.state.reportData.bulkData ? this.state.reportData.bulkData.length : 0}
            dataTotal={this.state.reportData.bulkData ? this.state.reportData.bulkData.length : 0}
            data={this.state.reportData.bulkData ? this.state.reportData.bulkData : []}
            page={1}
            itemUrlPath={this.handleViewFullReport}
            editUrlPath={this.canPerformOperation("update") ? this.handleEditReport : null}
            showColumnTotals={this.props.locationParams.reportType === 'mortality-report'}
          /> : 
          <EntryGridView values={this.state.reportData} formFields = {this.formFields} title={this.reportTypeString + " Data"}/>
        }
        </>
      }
    </>);
  }
}

export default withPrint(withLocationParams(ViewBookingReport));
