export const commonReportFields = [
  {  
    name: 'id',
    label: 'Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {  
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  
  {  
    name: 'arrivalDate',
    label: 'Stock Arrival Date',
    type: 'datetime',
    properties: [{readOnly:true}, {datetime : {noFutureDates : true}}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'supplierName',
    label: 'Supplier Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
];

export const receivedSampleFields = [
  {  
    name: 'id',
    label: 'Arrival Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {  
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view','index']
  },
  {  
    name: 'reservedDate',
    label: 'Reserved Date',
    type: 'date',
    properties: [{readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'consignmentId',
    label: 'Consignment No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'arrivingDateTime',
    label: 'Arriving Date Time (at AQF)',
    type: 'datetime',
    properties: [{required:true}, { datetime : {noFutureDates : true}}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalFemaleBs',
    label: 'Total Female BS',
    type: 'number',
    default  : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalMaleBs',
    label: 'Total Male BS',
    type: 'number',
    default  : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalArrivedBs',
    label: 'Total Arrived BS',
    type: 'number',
    default : 0,
    properties: [{required:true}, {readOnly : true}],
    actions: ['view', 'create', 'index', 'update']
  },
  {  
    name: 'totalMaleMortalities',
    label: 'Total Male Mortalities',
    type: 'number',
    default  : 0,
    properties: [{required:true},{LessThan:"totalMaleBs"},{numeric:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalFemaleMortalities',
    label: 'Total Female Mortalities',
    type: 'number',
    default  : 0,
    properties: [{required:true},{LessThan:"totalFemaleBs"},{numeric:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalArrivMortalities',
    label: 'Total Arrived Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:true},{LessThan:"totalArrivedBs"}, {readOnly:true}],
    actions: ['view', 'create', 'update', 'index']
  },
  {  
    name: 'totalArrivMolts',
    label: 'Total Arrived Molts',
    type: 'number',
    default : 0,
    properties: [{required:true},{LessThan:"totalArrivedBs"},{numeric:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'avgMaleWt',
    label: 'Avg. Male Weight(gms)',
    type: 'float',
    default : 0,
    properties: [{required:true},{float:{min:0}}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'avgFemaleWt',
    label: 'Avg. Female Weight(gms)',
    type: 'float',
    default : 0,
    properties: [{required:true},{float:{min:0}}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'avgWt',
    label: 'Avg. Weight(gms)',
    type: 'float',
    default : 0,
    properties: [{required:true},{float:{min:0}}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'maleBsPerBag',
    label: 'Male Per Bag(No.s)',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'femaleBsPerBag',
    label: 'Female Per Bag(No.s)',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'totalBags',
    label: 'Total Bags(No.)',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'totalBoxes',
    label: 'Total Boxes',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['view', 'create', 'update']
  },
   {  
    name: 'totalLiveBiomass',
    label: 'Total Live Biomass (Kgs.)',
    type: 'number',
    default : 0,
    properties: [{required:false}],
    actions: ['view']
  },
  {
    name: 'totalBsReserved',
    label: 'Total Reserved BS',
    type: 'number',
    properties: [{required:true}, {readOnly : true}],
    actions: ['index']
  },
  {  
    name: 'weekNStress',
    label: 'Weak N Stress',
    type: 'number',
    default : 0,
    properties: [{required:true}],
    actions: ['create','update']
  },
  {  
    name: 'timeDelay',
    label: 'Delay Days',
    type: 'string',
    properties: [{required:true}, { readOnly : true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'transitTime',
    label: 'Transit Time (HH:MM)',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'images',
    label: 'Arrival Images',
    type: 'file',
    properties : [
      {required : true}, 
      {multiple : true},
      {filesize: { min: 0, max: 10 } },
      {filetype: ["pdf", "docx", "doc", "png", "jpeg", "jpg","PDF", "PNG", "JPEG", "JPG"]},
    ],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'remarks',
    label: 'Remarks',
    type: 'text',
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  },
  {  
    name: 'supplierName',
    label: 'Supplier Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['index']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const feedingFields = [
  {  
    name: 'id',
    label: 'Feeding Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'monitorFeedDate',
    label: 'Feeding Date',
    type: 'date',
    properties:  [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'feed1',
    label: 'Feed No.1',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'feed2',
    label: 'Feed No.2',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'feed3',
    label: 'Feed No.3',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'feed4',
    label: 'Feed No.4',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'feed5',
    label: 'Feed No.5',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const mortalityFields = [
  {  
    name: 'id',
    label: 'Mortality Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'date',
    label: 'Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'maleMortality',
    label: 'Male Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
  {  
    name: 'maleMolts',
    label: 'Male Molts',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
  {  
    name: 'femaleMortality',
    label: 'Female Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
  {  
    name: 'femaleMolts',
    label: 'Female Molts',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
   {  
    name: 'totalMortality',
    label: 'Total Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
  {  
    name: 'totalMolts',
    label: 'Total Molts',
    type: 'number',
    default : 0,
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view'],
    columnTotal : true
  },
  {  
    name: 'siphon',
    label: 'Siphon (%)',
    type: 'float',
    default : 0,
    properties: [{required:true}, {float : { min : 0, max : 100}}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const labAnalysisFieldsLvannamei = [
  {  
    name: 'id',
    label: 'Lab Analysis Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'sampleId',
    label: 'Storage Sample',
    type: 'select',
    selectOptions : [],
    properties: [{required:true}],
    actions: ['create', 'update']
  },
  {  
    name: 'sampleStorageNo',
    label: 'Sample No.',
    type: 'string',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'date',
    label: 'Analysis Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'WSSV',
    label: 'WSSV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'IHHNV',
    label: 'IHHNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'TSV',
    label: 'TSV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'YHV',
    label: 'YHV/GAV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'IMNV',
    label: 'IMNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'NHPB',
    label: 'NHPB',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'AHPND',
    label: 'AHPND/EMS',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index','create', 'update']
  },
  {
    name: 'EHP',
    label: 'EHP',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {
    name: 'DIV1SHIV',
    label: 'DIV/SHIV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'remark',
    label: 'Remarks',
    type: 'text',
    default:'Negative',
    properties: [{required:true}],
    actions: ['index','view', 'create', 'update']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const labAnalysisFieldsMonodon = [
  {  
    name: 'id',
    label: 'Lab Analysis Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'sampleId',
    label: 'Storage Sample',
    type: 'select',
    selectOptions : [],
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'sampleStorageNo',
    label: 'Sample No.',
    type: 'string',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'date',
    label: 'Analysis Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'WSSV',
    label: 'WSSV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'IHHNV',
    label: 'IHHNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'TSV',
    label: 'TSV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'YHV',
    label: 'YHV/GAV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'IMNV',
    label: 'IMNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'NHPB',
    label: 'NHPB',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'AHPND',
    label: 'AHPND/EMS',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index','create', 'update']
  },
  {  
    name: 'EHP',
    label: 'EHP',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'MBV',
    label: 'MBV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'MrNV',
    label: 'MrNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'LSNV',
    label: 'LSNV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {
    name: 'DIV1SHIV',
    label: 'DIV/SHIV',
    type: 'select',
    selectOptions : { '+ve' : 'Positive', '-ve' : 'Negative' },
    default :'-ve',
    properties: [{required:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'remark',
    label: 'Remarks',
    type: 'text',
    default:'Negative',
    properties: [{required:true}],
    actions: ['index','view', 'create', 'update']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];
  
export const labAnalysisReportFieldsLvannamei = [
  {  
    name: 'id',
    label: 'Lab Analysis Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'view']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'refDate',
    label: 'Clearance Date',
    type: 'date',
    properties:  [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalPackages',
    label: 'Total Packages',
    type: 'number',
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'sipNoDate',
    label: 'Reference No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'importedNumber',
    label: 'Imported No.',
    type: 'number',
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'importMortalities',
    label: 'Transit Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'supplier',
    label: 'Supplier Name',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'qrtnDays',
    label: 'Quarantine Days',
    type: 'number',
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnFromDate',
    label: 'Quarantine From Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnToDate',
    label: 'Quarantine To Date',
    type: 'date',
    properties:  [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnMortaLities',
    label: 'Quarantine Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'animalAge',
    label: 'Animal Age',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'certNo',
    label: 'AQCS Cert No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'tsvReport',
    label: 'TSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'yhvReport',
    label: 'YHV/GAV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'imnvReport',
    label: 'IMNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ihhnvReport',
    label: 'IHHNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  // {  
  //   name: 'bpReport',
  //   label: 'BP Report',
  //   type: 'select',
  //   selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
  //   default: "NA",
  //   properties: [{required:false}],
  //   actions: ['view', 'create', 'update']
  // },
  {  
    name: 'wssvReport',
    label: 'WSSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'nhpbReport',
    label: 'NHPB Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ahpndReport',
    label: 'AHPND/EMS Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ehpReport',
    label: 'EHP Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'div1ShivReport',
    label: 'DIV/SHIV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'bsDespatch',
    label: 'BS Dispatch',
    type: 'boolean',
    default : true,
    valueStrings : ['No Dispatch', 'Dispatch'],
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'approvalFlag',
    label: 'Approval',
    type: 'select',
    selectOptions : {
      'ApprovalPending' : 'Approval Pending',
      'Approved' : 'Approved',
      'Rejected' : 'Rejected'
    },
    properties: [{required:false}],
    actions: ['view', 'index']
  },
  {  
    name: 'attachment',
    label: 'PDF Report',
    type: 'file',
    properties: [{required:false}],
    actions: ['index']
  },
  {  
    name: 'qrtnResult',
    label: 'Test Result',
    type: 'select',
    selectOptions : {
      'NEGATIVE' : 'NEGATIVE',
      'POSITIVE' : 'POSITIVE'
    },
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'remark',
    label: 'Remarks',
    type: 'text',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const labAnalysisReportFieldsMonodon = [
  {  
    name: 'id',
    label: 'Lab Analysis Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'view']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'refDate',
    label: 'Clearance Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'totalPackages',
    label: 'Total Packages',
    type: 'number',
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'sipNoDate',
    label: 'Reference No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'importedNumber',
    label: 'Imported No.',
    type: 'number',
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'importMortalities',
    label: 'Transit Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'supplier',
    label: 'Supplier Name',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'qrtnDays',
    label: 'Quarantine Days',
    type: 'number',
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnFromDate',
    label: 'Quarantine From Date',
    type: 'date',
    properties:  [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnToDate',
    label: 'Quarantine To Date',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'qrtnMortaLities',
    label: 'Quarantine Mortalities',
    type: 'number',
    default : 0,
    properties: [{required:false,readOnly:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'animalAge',
    label: 'Animal Age',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'certNo',
    label: 'AQCS Cert No.',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'tsvReport',
    label: 'TSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'yhvReport',
    label: 'YHV/GAV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'imnvReport',
    label: 'IMNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ihhnvReport',
    label: 'IHHNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  // {  
  //   name: 'bpReport',
  //   label: 'BP Report',
  //   type: 'select',
  //   selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
  //   default: "NA",
  //   properties: [{required:false}],
  //   actions: ['view', 'create', 'update']
  // },
  {  
    name: 'wssvReport',
    label: 'WSSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'nhpbReport',
    label: 'NHPB Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ahpndReport',
    label: 'AHPND/EMS Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'ehpReport',
    label: 'EHP Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'div1ShivReport',
    label: 'DIV/SHIV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'MBVReport',
    label: 'MBV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'MrNVReport',
    label: 'MrNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'LSNVReport',
    label: 'LSNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'bsDespatch',
    label: 'BS Dispatch',
    type: 'boolean',
    default : true,
    valueStrings : ['No Dispatch', 'Dispatch'],
    properties: [{required:false}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'approvalFlag',
    label: 'Approval',
    type: 'select',
    selectOptions : {
      'ApprovalPending' : 'Approval Pending',
      'Approved' : 'Approved',
      'Rejected' : 'Rejected'
    },
    properties: [{required:false}],
    actions: ['view', 'index']
  },
  {  
    name: 'attachment',
    label: 'PDF Report',
    type: 'file',
    properties: [{required:false}],
    actions: ['index']
  },
  {  
    name: 'qrtnResult',
    label: 'Test Result',
    type: 'select',
    selectOptions : {
      'NEGATIVE' : 'NEGATIVE',
      'POSITIVE' : 'POSITIVE'
    },
    properties: [{required:true}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'remark',
    label: 'Remarks',
    type: 'text',
    properties: [{required:false}],
    actions: ['view', 'create', 'update']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const waterQualityFields = [
  {  
    name: 'id',
    label: 'Water Quality Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'monitorDateTime',
    label: 'Monitor Date',
    type: 'datetime',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'type',
    label: 'Type of Report',
    type: 'select',
    selectOptions : {
      'water_quality_analysis' : 'WATER QUALITY', 
      'receiving_water_quality' : 'RECEIVING WATER QUALITY'
    },
    default : 'water_quality_analysis',
    properties: [{required:true}],
    actions: ['index', 'update', 'view']
  },
  {  
    name: 'waterTemperature',
    label: 'Water Temperature (o C)',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'nh3Level',
    label: 'NH3 Level (ppm)',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'No2Level',
    label: 'NO2 Level (ppm)',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'phLevel',
    label: 'pH Level',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'doLevel',
    label: 'DO Level (mg/l)',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'salinityLevel',
    label: 'Salinity Level (ppt)',
    type: 'float',
    default : 0,
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'remarks',
    label: 'Remarks',
    type: 'text',
    properties: [{required:false}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const sampleWeightFields = [
  {  
    name: 'id',
    label: 'Sample Weight Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'view']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle No.',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'averageMaleWeight',
    label: 'Avg. Male Weight (gms)',
    type: 'float',
    properties: [{required:true},{float:{min:0}}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'averageFemaleWeight',
    label: 'Avg. Female Weight (gms)',
    type: 'float',
    properties: [{required:true},{float:{min:0}}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'averageWeight',
    label: 'Avg. Weight (gms)',
    type: 'float',
    properties: [{required:true},{float:{min:0}}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const quarantineFields = [
  {  
    name: 'id',
    label: 'Quarantine Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'AQCSclearanceNumber',
    label: 'AQCS Clearance No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'certificationDate',
    label: 'Certification Date',
    type: 'date',
    properties: [{required:true}, {date : {noFutureDates : true}}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'registrationNumber',
    label: 'Registration No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'certificateNumber',
    label: 'Certificate No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'shrimp',
    label: 'Shrimp',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  }
];

export const clearanceFields = [
  {  
    name: 'id',
    label: 'Clearance Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'dateOfImport',
    label: 'Date of Import',
    type: 'datetime',
    properties: [{required:true}, {readOnly:true}],
    actions: ['create', 'update', 'index', 'view']
  },
  {  
    name: 'dateOfClearance',
    label: 'Date of Clearance',
    type: 'date',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'fileNo',
    label: 'File No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'regdNo',
    label: 'Regd No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalNoOfAnimalsImported',
    label: 'Total Imported (No.)',
    type: 'number',
    properties: [{required:true}, {readOnly:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalNoOfBroodstockMortalityDuringTransit',
    label: 'Mortality During Transit (No.)',
    type: 'number',
    properties: [{required:true}, {readOnly:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalNoOfBroodstockMortalityDuringQuarantine',
    label: 'Mortality During Quarantine (No.)',
    type: 'number',
    properties: [{required:true}, {readOnly:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalDispatched',
    label: 'Total Dispatched (No.)',
    type: 'number',
    properties: [{required:true}, {readOnly:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'supplierName',
    label: 'Supplier Name',
    type: 'string',
    properties: [{required:true}, {readOnly:true}],
    actions: ['index','create', 'update']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const shipmentPackingFields = [
  {  
    name: 'id',
    label: 'Shipment Packing Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{ readOnly: true }],
    actions: ['index']
  },
  {  
    name: 'totalMaleBs',
    label: 'Total Male BS Packed',
    type: 'number',
    properties: [{required:true}, {readOnly : true},{numeric:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'totalFemaleBs',
    label: 'Total Female BS Packed',
    type: 'number',
    properties: [{required:true},{readOnly : true},{numeric:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'totalBsPacked',
    label: 'Total BS Packed',
    type: 'number',
    properties: [{required:true}, {readOnly : true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'daysInAQF',
    label: 'Days in AQF',
    type: 'number',
    properties: [{required:true}, { readOnly : true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'packingDateTime',
    label: 'Packing Date',
    type: 'datetime',
    properties: [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'waterTemp',
    label: 'Water Temperature (o C)',
    type: 'float',
    properties: [{required:true}],
    actions: ['create', 'update']
  },
  {  
    name: 'salinity',
    label: 'Salinity Level (ppt)',
    type: 'float',
    properties: [{required:true}],
    actions: ['create','update']
  },
  {  
    name: 'maleBsPerBag',
    label: 'Male BS Per Bag',
    type: 'number',
    properties: [{required:true}, {numeric:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'femaleBsPerBag',
    label: 'Female BS Per Bag',
    type: 'number',
    properties: [{required:true},{numeric:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'bsPerBag',
    label: 'Total BS per Bag',
    type: 'string',
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalBags',
    label: 'Total Bags',
    type: 'number',
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'totalBoxes',
    label: 'Total Boxes',
    type: 'number',
    properties: [{required:true},{numeric:true}],
    actions: ['create', 'index', 'update', 'view']
  },
  {  
    name: 'animalsCondition',
    label: 'Animal Condition',
    type: 'string',
    properties: [{required:true}],
    actions: ['create','update']
  },
  {  
    name: 'ph',
    label: 'pH Level',
    type: 'float',
    properties: [{required:true}],
    actions: ['create','update']
  },
  {  
    name: 'name',
    label: 'Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create','update']
  },
  {  
    name: 'phoneNumber',
    label: 'Phone Number',
    type: 'string',
    properties: [{required:true}, {mobile:true}, {string : { min : 10, max : 10}}],
    actions: ['create','update']
  },
  {  
    name: 'remarks',
    label: 'Remarks',
    type: 'text',
    properties: [{required:true}],
    actions: ['create','update', 'view']
  },
  {  
    name: 'attachment',
    label: 'PDF Report',
    type: 'file',
    properties: [{required:true}],
    actions: ['index']
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];

export const clearanceBookingFields = [
  {  
    name: 'id',
    label: 'Report Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'bookingId',
    label: 'Booking Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index', 'create', 'update']
  },
  {  
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'speciesType',
    label: 'Species Type',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index']
  },
  {  
    name: 'cubicleId',
    label: 'Cubicle',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  
  {  
    name: 'arrivalDate',
    label: 'Stock Arrival Date',
    type: 'datetime',
    properties: [{readOnly:true}, {datetime : {noFutureDates : true}}],
    actions: ['view', 'index', 'create', 'update']
  },
  {  
    name: 'supplierName',
    label: 'Supplier Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index', 'create', 'update']
  },
  {
    name : 'clearanceStatus',
    label : 'Clearance Status',
    type : 'select',
    selectOptions : {
      'Issued' : 'Issued',
      'Pending' : 'Pending'
    },
    properties: [{readOnly:true}],
    actions: ['view', 'index']
  }
];
