import React, { Component } from 'react';

import { 
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography, 
  TextField,
  MenuItem
} from '@mui/material';

// import TableView Component
import DTable from "components/DataTables/DTable";

// services
import { fetchBookingsByHatcheries, fetchBookingsByHatcheriesCount } from 'services/bookings';
import { fetchAllSpecies } from 'services/species-details';

var fields = [{
  name : 'name', 
  label : 'Hatchery Name',
  type : 'string',
  properties : [{required : true}],
  actions : ['index']
}, {
  name : 'CAARegistrationNumber', 
  label : 'CAA Number',
  type : 'string',
  properties : [{required : true}],
  actions : ['index']
},  {
  name : 'state', 
  label : 'State',
  type : 'string',
  properties : [{required : true}],
  actions : ['index']
}, {
  name : 'district', 
  label : 'District',
  type : 'string',
  properties : [{required : true}],
  actions : ['index']
}, {
  name : 'permittedSpeciesId',
  label : 'Species Type',
  type: 'select',
  selectOptions : {},
  properties: [{required:true}],
  actions: ['index']
}, {
  name : 'totalBookings', 
  label : 'Total Bookings',
  type : 'number',
  properties : [{required : true}],
  actions : ['index']
}, {
  name : 'totalNumberOfStock', 
  label : 'Total Imported Stock',
  type : 'number',
  properties : [{required : true}],
  actions : ['index']
}, {
  name : 'totalBioMass', 
  label : 'Total BioMass (Kgs)',
  type : 'number',
  properties : [{required : true}],
  actions : ['index']
}];

export default class TopHatcheriesCard extends Component {
  
  constructor (props) {
    super(props);
    
    let fyear = new Date();
    this.state = {
      fyear : fyear.getMonth() < 3 ? (fyear.getFullYear() - 1) : fyear.getFullYear(),
      hatcheries : [], count : 0
    };
  }
  
  async componentDidMount () {
    
    this.firstDay = new Date(this.state.fyear + '-04-01').toISOString();
    this.lastDay = new Date((parseInt(this.state.fyear) + 1) +  '-03-31').toISOString();
    
    try {
      let species = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      fields[4].selectOptions = {};
      species.data.map ( s => fields[4].selectOptions[s.id] = s.name);
      
      await this.fetchData();
      
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchData = async () => {
    try {
      let count = await fetchBookingsByHatcheriesCount({
        fromDate : this.firstDay,
        toDate : this.lastDay,
        state : 'ALL',
        district : 'ALL',
        speciesType : 'ALL'
      });
      let hatcheries = await fetchBookingsByHatcheries({
        fromDate : this.firstDay, 
        toDate : this.lastDay,
        state : 'ALL',
        district : 'ALL',
        speciesType : 'ALL'
      });
      
      hatcheries.data.map ( d => {
        if (d.license) {
          d.CAARegistrationNumber = d.license.CAARegistrationNumber;
        }
        return d;
      });
        
      this.setState({hatcheries : hatcheries.data, count: count.data});
    } catch(error) {
      console.log(error);
    }
  }
  
  handleYearSelect = async (value) => {

    if (value == this.state.fyear) return;

    this.firstDay = new Date(value + '-04-01').toISOString();
    this.lastDay = new Date((value + 1) +  '-03-31').toISOString();
    
    await this.fetchData();
    this.setState({fyear : value});
  }

  render() {
    
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    const filterValues = range(currentYear - 10, currentYear + 5, 1).map( year => {return {'id' : year, 'value' : `FY ${year} - ${year+1}`}});

    return (<Card sx={{ height: '400', p:2 }} {...this.props} >
      <CardHeader 
        title="Top Hatcheries By Bookings"
        action={(
            <TextField
              id="year"
              label="Select FY"
              name="year"
              fullWidth
              select
              type='text'
              value={this.state.fyear}
              onChange={e => {this.handleYearSelect(e.target.value)}}
              margin="normal"
              variant="standard"
            >
            {
              filterValues.map ((opt, i) => {
                return (<MenuItem key={i} value={opt.id}>{opt.value}</MenuItem>);
              })
            }
            </TextField>
        )}
      />
      <Divider />
      <CardContent>
      {
        this.state.hatcheries.length > 0 &&  
        <DTable
          columns = {fields}
          rowsPerPage={this.state.count}
          dataTotal={this.state.count}
          data={this.state.hatcheries}
          page={1}
          multiDeleteEnabled={false}
        />
      }
      </CardContent>
    </Card>)
  }
}
