import React, { Component } from 'react';
import cxor from 'xor-crypto-js';

import { Paper, Button, Grid, Box } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicTableForm } from 'components/Forms';

import WebcamImgCapture from 'components/WebcamImgCapture';

import { authorizedPersonFields } from '../owner.fields';

import { faceRecognitionEnrol, faceRecognitionDelete, checkUniqueAttributes } from "services/common";

import { calculateAPISignature } from 'services/utils';

export default class AuthorizedPersonForm extends Component {

  state = { 
    valid: false, 
    isSubmitting: false, 
    authPersons: this.props.currentValues ? [] : null, 
    capturedImages: { 
      "authPerson1" : null,
      "authPerson2": null,
    },
  };

  constructor(props) {
    super(props);
    
    let signature = null;
    
    if (this.props.currentValues) {
      this.props.currentValues.map ( x => {
        this.state.authPersons.push({...x});
      });
    }
    
    if (this.props.currentValues && this.props.currentValues[0] && this.props.currentValues[0].picture) {
      signature = calculateAPISignature(encodeURIComponent(this.props.currentValues[0].picture));
      this.state.capturedImages.authPerson1 =  
        process.env.REACT_APP_STRAPI_FILE_URL + this.props.currentValues[0].picture + '?s=' + signature;
    }
    if (this.props.currentValues && this.props.currentValues[1] && this.props.currentValues[1].picture) {
      signature = calculateAPISignature(encodeURIComponent(this.props.currentValues[1].picture));
      this.state.capturedImages.authPerson2 =  
        process.env.REACT_APP_STRAPI_FILE_URL + this.props.currentValues[1].picture + '?s=' + signature;
    }
    
    // decrypt aadhaar data
    if (this.state.authPersons && this.state.authPersons[0] && this.state.authPersons[0].aadhaarNumber && 
      this.state.authPersons[0].aadhaarNumber.length > 12) {
      this.state.authPersons[0].aadhaarNumber = 
        cxor.decrypt(this.state.authPersons[0].aadhaarNumber, process.env.REACT_APP_BODY_ENCRYPT_KEY);
    }
    
    if (this.state.authPersons && this.state.authPersons[1] && this.state.authPersons[1].aadhaarNumber && 
      this.state.authPersons[0].aadhaarNumber.length > 12) {
      this.state.authPersons[1].aadhaarNumber = 
        cxor.decrypt(this.state.authPersons[1].aadhaarNumber, process.env.REACT_APP_BODY_ENCRYPT_KEY);
    }
  }
  
  
  async componentDidMount() {
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData, tableData) => {
    
    await this.setState({ isSubmitting: true });
    let statusMessage = { status: "success" };
    
    // enrol captured images for face recognition
    // enrol only if image has been captured
    //
    let response;
    if (this.state.capturedImages.authPerson1 && 
      this.state.capturedImages.authPerson1.includes("data:image") &&
      tableData && tableData[0]) {
      response = await faceRecognitionEnrol(
        this.props.hatcheryData.id,
        this.state.capturedImages.authPerson1,
        "auth1"
      );
      
      if (response.status === "success") {
        tableData[0].picture = response.fileUpload && response.fileUpload.status === "success" 
          ?  response.fileUpload.url
          : null;
      } else {
        statusMessage = { status: "error", message : "Person #1 : "  + response.message};
        return statusMessage;
      }
      
      // clean up old enrollment if exists
      if (this.props.currentValues && this.props.currentValues[0]) {
        await faceRecognitionDelete(this.props.currentValues[0].picture);
      }
    }
    
    if (this.state.capturedImages.authPerson2 && 
      this.state.capturedImages.authPerson2.includes("data:image") &&
      tableData && tableData[1]) {
      response = await faceRecognitionEnrol(
        this.props.hatcheryData.id,
        this.state.capturedImages.authPerson2,
        "auth2"
      );
      
      if (response.status === "success") {
        tableData[1].picture = response.fileUpload && response.fileUpload.status === "success" 
          ?  response.fileUpload.url
          : null;
      } else {
        statusMessage = { status: "error", message : "Person #2 : "  + response.message};
        return statusMessage;
      }
      
      // clean up old enrollment if exists
      if (this.props.currentValues && this.props.currentValues[1]) {
        await faceRecognitionDelete(this.props.currentValues[1].picture);
      } 
    }
    
    // cleanup enrollment if table row was deleted, but image exists
    // update case
    //
    if (this.props.currentValues && 
      this.props.currentValues.length > tableData.length) {
      
      try {
        if (this.props.currentValues && this.props.currentValues[1] && !tableData[1]) {
          // cleanup auth person 2
          await faceRecognitionDelete(this.props.currentValues[1].picture);
        }
      } catch(error) {
        statusMessage = { status: "error", message : "Person #2 : "  + response.message};
        return statusMessage;
      }
      
      try {
        if (this.props.currentValues && this.props.currentValues[0] && !tableData[0]) {
          // cleanup auth person 2
          await faceRecognitionDelete(this.props.currentValues[0].picture);
        }
      } catch (error) {
        statusMessage = { status: "error", message : "Person #1 : "  + response.message};
        return statusMessage;
      }
    }
    
    if (this.props.handleFormSubmit) {
      await this.props.handleFormSubmit(tableData);  
    }
    
    return statusMessage;
  }

  handleAuthPersonChange = async (table, validation, operation) => {
    if (validation && validation.length) {
      // check if all table rows are valid
      await this.setState({
        authPersons: table,
        validAuthPersons: !Boolean(validation.find(v => v.isValid === false)),
      });
    } else {
      await this.setState({ 
        authPersons: null, 
        validAuthPersons: false, 
        capturedImages: { "authPerson1": null, "authPerson2": null } 
      });
    }
  }

  handlePreFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "" };

    await this.setState({ isSubmitting: true });

    // some more validations here before save 
    if (this.state.authPersons) {
      if (this.state.authPersons[0] && !this.state.capturedImages["authPerson1"]) {
        statusMessage = { status: "error", message: "Please capture authorized person#1 image" };
        return statusMessage;
      } else if (this.state.authPersons[1] && !this.state.capturedImages["authPerson2"]) {
        statusMessage = { status: "error", message: "Please capture authorized person#2 image" };
        return statusMessage;
      }
      
      // check if the entered values are unique between the two auth persons
      if (this.state.authPersons.length > 1) {
        if (this.state.authPersons[0].phoneNumber === this.state.authPersons[1].phoneNumber) {
          statusMessage = { status: "error", message: "Cannot allow same phone numbers for both authorized persons" };
          return statusMessage;
        }
        if (this.state.authPersons[0].aadhaarNumber === this.state.authPersons[1].aadhaarNumber) {
          statusMessage = { status: "error", message: "Cannot allow same Govt Issued ID numbers for both authorized persons" };
          return statusMessage;
        }
        if (this.state.authPersons[0].email === this.state.authPersons[1].email) {
          statusMessage = { status: "error", message: "Cannot allow same email address for both authorized persons" };
          return statusMessage;
        }
        if (this.state.authPersons[0].name.toLowerCase() === this.state.authPersons[1].name.toLowerCase()) {
          statusMessage = { status: "error", message: "Cannot allow same names for both authorized persons" };
          return statusMessage;
        }
      }
      
      // check if phone number and email does not conflict with owner details
      if (this.props.ownerData) {
        if (this.state.authPersons[0].email === this.props.ownerData.email) {
          statusMessage = { status: "error", message: "Cannot allow same phone numbers for hatchery owner and authorized persons" };
          return statusMessage;
        } else if (this.state.authPersons[0].phoneNumber === this.props.ownerData.phoneNumber) {
          statusMessage = { status: "error", message: "Cannot allow same phone numbers for hatchery owner and authorized persons" };
          return statusMessage;
        }
        
        if (this.state.authPersons.length > 1) {
          if (this.state.authPersons[1].email === this.props.ownerData.email) {
            statusMessage = { status: "error", message: "Cannot allow same phone numbers for hatchery owner and authorized persons" };
            return statusMessage;
          } else if (this.state.authPersons[1].phoneNumber === this.props.ownerData.phoneNumber) {
            statusMessage = { status: "error", message: "Cannot allow same phone numbers for hatchery owner and authorized persons" };
            return statusMessage;
          }
        }
      }
      
      let validation = [];
      
      // check uniqueness of the attributes
      for (let i = 0; i < this.state.authPersons.length; ++i) {
        let attr = (i === 0)
          ? ['auth1Email', 'auth1Phone', 'auth1Aadhaar'] 
          : ['auth2Email', 'auth2Phone', 'auth2Aadhaar'];
        
        let rowValidation = { isValid : true };
        
        try {
          let uniqueParams = [{
            'attribute' : attr[0],
            'value' : this.state.authPersons[i].email,
            'currentId' : null
          }, {
            'attribute' : attr[1],
            'value' : this.state.authPersons[i].phoneNumber,
            'currentId' : null
          }, {
            'attribute' : attr[2],
            'value' : this.state.authPersons[i].aadhaarNumber,
            'currentId' : null
          }];
          
          let result = await checkUniqueAttributes(uniqueParams);
          
          if (result.data[attr[0]] && result.data[attr[0]].unique === false) {
            rowValidation.isValid = false;
            rowValidation['email'] = { isInvalid : true, message : result.data[attr[0]].message };
          }          

          if (result.data[attr[1]] && result.data[attr[1]].unique === false) {
            rowValidation.isValid = false;
            rowValidation['phoneNumber'] = { isInvalid : true, message : result.data[attr[1]].message };
          }
          
          if (result.data[attr[2]] && result.data[attr[2]].unique === false) {
            rowValidation.isValid = false;
            rowValidation['aadhaarNumber'] = { isInvalid : true, message : result.data[attr[2]].message };
          } 
        } catch(error) {
          statusMessage =  { status: "error", message: error.message };
        }
        validation.push(rowValidation);
      }
      
      if (Boolean(validation.find(v => v.isValid === false))) {
        statusMessage =  { 
          status: "error", 
          message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
          tableData : validation 
        };
      }
    }
    return statusMessage;
  }
  
  canCapturePhoto = (name) => {
    switch (name) {
      case 'authPerson1':
        return (this.state.authPersons && this.state.authPersons[0] &&
          this.state.capturePhotoName === 'authPerson1');
      case 'authPerson2':
        return (this.state.authPersons && this.state.authPersons[1] &&
          this.state.capturePhotoName === 'authPerson2');
      default:
        return false;
    }
  }

  handleCapturePhoto = async (name, value) => {
    let capturedImages = this.state.capturedImages;
    capturedImages[name] = value;
    this.setState({ capturePhotoName: null, capturedImages: capturedImages });
  }

  render() {
    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="row">
        <Grid item xs={12} sm={9}>
          <DynamicTableForm
            tableFormProps={{
              fields: authorizedPersonFields,
              handleTableDataChange: this.handleAuthPersonChange,
              serialNoHeader: 'S.No',
              deleteRows: this.state.authPersons ? "none" : "last",
              currentValues : this.state.authPersons,
              uploadFiles : true,
              maxRows: 2,
              minRows: 0,
              validation : this.props.errorsOnSubmit
            }}
            handleSubmit={this.handleFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            buttonText={'Save Authorized Person Details'}
            buttonWidth="full"
            columns={2}
            action={ this.state.authPersons ? "update" : "create" }
            uploadFiles={true}
            redirect={null}
            showSubmitButton={ this.state.authPersons ? true : false }
          />
        </Grid>
        <Grid item xs={12} sm={3} sx={{ borderLeft: '1px 0 solid', p: 2, textAlign: "center" }}>
          <>
            {
              this.state.authPersons && this.state.authPersons.map((item, index) => {
                let newIndex = index + 1;
                return <Box sx={{ mt: 2 }}>
                  {
                    this.canCapturePhoto("authPerson" + newIndex) ?
                      (<WebcamImgCapture name={"authPerson" + newIndex} capturePhoto={this.handleCapturePhoto} />) :
                      (<Grid container justify="center" direction="column" alignItems="center">
                        <Grid item key={1}>
                          <img src={this.state.capturedImages["authPerson" + newIndex] ? this.state.capturedImages["authPerson" + newIndex] :
                            process.env.PUBLIC_URL + '/static/images/misc/dummy.png'} width="120" height="120"/>
                        </Grid>
                        <Grid item key={2}>
                          <Button size="small" variant="outlined" color="primary"
                            onClick={async () => await this.setState({ capturePhotoName: "authPerson" + newIndex })}
                          > {`Capture Person#${newIndex} Photo`} </Button>
                        </Grid>
                      </Grid>)
                  }
                </Box>
              }
              )
            }
          </>
        </Grid>
      </Grid>
    );
  }
}
