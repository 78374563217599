import React, { Component } from 'react';

import { 
  Paper, 
  Grid, 
  TextField, 
  Box, 
  Typography, 
  Button,  
  Table, 
  TableHead, 
  TableBody,
  TableRow, 
  TableCell,
  Card,
  Fade,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import { HatcheryStore } from 'store';

import { findBookingByDateRange } from 'services/booking-schedules';
import { fetchAllCubicles } from 'services/cubicles';
import { fetchBookingLimits } from 'services/bookings';
import Calendar from 'components/Calendar';

export default class CubicleSelectionForm extends Component {
  
  state = {
    selectedDate : this.props.scheduleData ? this.props.scheduleData.selectedDate : null,
    monthSchedule : null,
    selectedSchedules : this.props.scheduleData ? this.props.scheduleData.cubicles : [],
    cubicleData : [],
    statusCount : {availableDate : '--', availableCount:0, blockedCount : 0, confirmedCount : 0},
    fadeEffect : true,
    statusMessage : null,
    fyLimitData : this.props.fyLimitData,
    selectedSpecies : HatcheryStore.state && HatcheryStore.state.currentHatchery ? 
                        HatcheryStore.state.currentHatchery.permittedSpeciesId.id : null,
    
  };
  
  constructor(props) {
    super(props); 
    let fyear = props.fyLimitData.fyear.split('-');
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let startDate = (parseInt(month) < 4 ? (parseInt(year) - 1) : year) + '-04-01';
    let finishDate = (parseInt(month) < 4 ? year : (parseInt(year) + 1)) + '-03-31';
    let currentFyear = `${startDate.split('-')[0]}-${finishDate.split('-')[0]}`;
    
    this.fyStartDate = new Date(`${fyear[0]}-04-01`);
    this.fyEndDate = new Date(`${fyear[1]}-03-31`);
    
    if (currentFyear === props.fyLimitData.fyear) {
      date.setDate(date.getDate() + 4);
      this.minDate = date.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
      this.maxDate = `${fyear[1]}-03-31`;
    } else {
      this.minDate = `${fyear[0]}-04-01`;
      this.maxDate = `${fyear[1]}-03-31`;
    }
  }
  
  async componentDidMount() {
    try {
      let cubicles = await fetchAllCubicles({page : 1, per_page: 100});
      this.setState({cubicleData : cubicles.data});
      this.handleMonthChange(0, this.firstDay, this.lastDay);
    } catch (error) {
      console.log(error);
    }
  }
  
  
  handleDateSelect = async (value) => {
    let date = new Date(value);
    let fyLimitData = this.props.fyLimitData;
    let monthSchedule = null;
    const currentHatchery = HatcheryStore.state.currentHatchery;
    const duration = (currentHatchery.permittedSpeciesId.duration) * 1000 * 24 * 60 * 60;
    
    // dont allow past dates or dates with in next 5 days
    if (Math.ceil((date.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) < 5) {
      this.setState({
        selectedDate : null,  
        selectedSchedules : [], 
        statusMessage : { status : "error", message : "Please select dates atleast 5 days from now" },
      });
      return;
    }
    
    if (value === this.state.selectedDate) {
      this.setState({selectedDate : null,  selectedSchedules : []});
      return;
    }
    
    // check if the selected date can be booked
    if (fyLimitData.license &&
      (new Date(value).getTime() + duration) > new Date(fyLimitData.license.validTo).getTime()) {
      this.setState({
        selectedDate : null,  
        selectedSchedules : [], 
        statusMessage : { 
          status : "error", 
          message : "CAA license will expire before the stock quarantine period is completed, please renew"
        }
      });
      return;
    }        
    
    // fetch monthly schedule if month changed in the selected date
    if (!this.state.monthSchedule || 
      (date.toLocaleString('default', { month: 'long' }) !== this.state.monthSchedule.month)) {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      try {
        let response = await findBookingByDateRange({
          start : firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
          end : lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
          speciesType : HatcheryStore.state && HatcheryStore.state.currentHatchery ?
            HatcheryStore.state.currentHatchery.permittedSpeciesId.id : null,
          all : true
        });
        this.setState({ monthSchedule : response.data, statusCount : null });
        monthSchedule = response.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      monthSchedule = this.state.monthSchedule;
    }
    
    if (monthSchedule && monthSchedule.statusCount && 
      monthSchedule.statusCount[value]) {
      let statusCount = monthSchedule.statusCount[value];
      let available = statusCount ? (statusCount.availableCount >= this.props.bookingData.numCubicles) : false;
      let statusMessage = { status : "success" };
  
      this.setState({
        monthSchedule : monthSchedule,
        statusCount : {
          availableCount : statusCount.availableCount,
          blockedCount : statusCount.blockedCount,
          confirmedCount : statusCount.confirmedCount,
          availableDate : value
        }, 
        selectedDate : available ? value : null, 
        statusMessage : statusMessage.status === "error" ? statusMessage : null, 
        selectedSchedules : [],
        fadeEffect : false,
      });
    } else {
      this.setState({
        monthSchedule : monthSchedule,
        statusCount : {availableDate : value, availableCount:0, blockedCount : 0, confirmedCount : 0},
        selectedDate : null, 
        selectedSchedules : [],
        fadeEffect : false
      });
    }
    
    setTimeout( () => this.setState({ fadeEffect : true}), 500);
  }
  
  handleCubicleSelect = (schedule) => {
    if (!schedule) return;
    
    let currentSchedules = this.state.selectedSchedules;
    let idx = currentSchedules.findIndex( s => s.id === schedule.id );
    if (idx >= 0) {
      currentSchedules.splice(idx, 1);
    } else {
      currentSchedules.push(schedule);
    }
    
    this.setState({selectedSchedules  : currentSchedules });

  }
  
  handleFormSubmit = async () => {
    let params = {
      'selectedDate' : this.state.selectedDate,
      'cubicles' : this.state.selectedSchedules
    };
    
    if (this.props.handleFormSubmit) {
      this.props.handleFormSubmit(params);
    }
  }

  handleMonthChange = async (monthNo, firstDay, lastDay) => {
    
    this.firstDay = firstDay;
    this.lastDay = lastDay;

    if (!this.state.selectedSpecies) return;
    try {
      let response = await findBookingByDateRange({
        start : firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
        end : lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        speciesType : this.state.selectedSpecies,
        all : true
      });
      this.setState({
        monthSchedule : response.data, 
        statusCount : null,
      });
    } catch (error) {
      console.log(error);
    }
  }
  disableDate = (date) =>{

    
    let current_date = new Date();
    let check_date = new Date(date);
    current_date.setDate(current_date.getDate() + 4);

    if (check_date < current_date) {
      return true;
    }
    return false;
  }
  
  dayColor = (date) => {
   // let date = new Date(this.year + '-' + (this.month+1) + '-' + day).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
	  if (
      this.state.monthSchedule && 
      this.state.monthSchedule.status === 'Available' &&
      this.state.monthSchedule.statusCount &&
      this.state.monthSchedule.statusCount[date]
    ) {
      let statusCount = this.state.monthSchedule.statusCount[date];
      if (statusCount.availableCount > 0) return 'success.main';
      else if (statusCount.blockedCount > 0) return 'yellow';
      else if (statusCount.confirmedCount > 0) return 'error.main';
      else return null;
    }
    return null;
  }
  
  render() {
    return (
    <>
      {
        this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      <Grid container spacing={3} justifyContent="space-between" justify="center" sx={{p:1, mt:1}}>
        <Grid key="0-firstgrid" item xs={12} sm={7}>
          <Card key="x-card" sx={{p: 1, mb:2}}>

          <Calendar
              title = "Select Booking Date"
              currentMonth = {this.currentMonth}
              handleMonthChange = {this.handleMonthChange}
              handleDateSelect = {this.handleDateSelect}
              dayColor = {this.dayColor}
              disableDate= {this.disableDate}
            />
            <Divider />
            <CardContent>
              <Typography variant="h4" align="left"> Hatchery FY Import Limit Data </Typography> 
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="limits-2x"> Financial Year </TableCell>
                    <TableCell key="limits-3x"> Import Limit </TableCell>
                    <TableCell key="limits-4x"> Total Imported </TableCell>
                    <TableCell key="limits-5x"> FY Balance </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell key="limits-2y">{this.state.fyLimitData ? this.state.fyLimitData.fyear : '--'}</TableCell>
                    <TableCell key="limits-3y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockLimit : '--'}</TableCell>
                    <TableCell key="limits-4y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockImported : '--'}</TableCell>
                    <TableCell key="limits-5y">{this.state.fyLimitData ? this.state.fyLimitData.fyStockBalance : '--'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid key={0} item xs={12} sm={5}>
          <Card key="0-card">
            <CardHeader title="Select Available Cubicles"></CardHeader>
            <Divider />
            <CardContent>
              <Grid container key="1-grid">
              {
                this.state.cubicleData.length > 0 &&
                this.state.cubicleData.map((cubicle, idx) => {
                  let bgcolor = "white";
                  let color = "black";
                  let schedule = this.state.selectedSchedules.find (s => s.cubicleId.id === cubicle.id);
                  if (this.state.monthSchedule) {
                    let s = this.state.monthSchedule.schedules.find( x => 
                        x.availableDate === this.state.selectedDate && x.cubicleId.id === cubicle.id);
                    if (!s) {
                        return null
                    } else {
                      if (schedule) {
                        bgcolor = "primary.main"; color = "white";
                      } else if (s.status === "Available") {
                        bgcolor = "success.main"; color = "white"; schedule = s;
                      } else if (s.status === "Blocked") {
                        bgcolor = "yellow"; color = "black";
                      } else if (s.status === "Confirmed") {
                        bgcolor = "error.main"; color = "white";
                      }
                      return (
                        <Grid key={idx} item xs={2} sm={2} sx={{m:1}}>
                          <Box
                            key={idx}
                            sx={{ 
                              width: 80, 
                              height: 40,
                              p:1,                          
                              borderColor : 'primary', 
                              border: '1px dashed',
                              borderRadius : '5px',
                              cursor:'pointer',
                              bgcolor: bgcolor,
                              color : color,
                              textAlign: "center"
                            }}
                            onClick={ () => this.handleCubicleSelect(schedule) }
                          >  
                            <Typography variant="caption">{cubicle.name}</Typography>
                          </Box>
                        </Grid>
                      )
                    }
                  }
                })  
              }
              {
                this.state.statusCount &&
                <Fade in = {this.state.fadeEffect}>
                  <Grid container key="0-grid" sx={{ backgroundColor : '#c9c9c9', p: 1}}>
                    <Box key={'1x'} component="span" sx={{ p: 1}}>
                      <Typography variant="overline" color="primary.main"> Date : {this.state.statusCount.availableDate}</Typography>
                    </Box>
                    <Box key={'2x'} component="span" sx={{ p: 1}}>
                      <Typography variant="overline" color="success.main"> Available : {this.state.statusCount.availableCount}</Typography>
                    </Box>
                    <Box key={'3x'} component="span" sx={{ p: 1}}>
                      <Typography variant="overline" color="yellow"> Blocked : {this.state.statusCount.blockedCount}</Typography>
                    </Box>
                    <Box key={'4x'} component="span" sx={{ p: 1}}>
                      <Typography variant="overline" color="error.main"> Confirmed : {this.state.statusCount.confirmedCount}</Typography>
                    </Box>
                  </Grid>
                </Fade>
              }
              </Grid>
            </CardContent>
          </Card>
          <Button
              variant="contained"
              sx={{ mt : 2}}
              fullWidth={true}
              color = "primary"
              disabled={ !(this.state.selectedDate && this.state.selectedSchedules.length === this.props.bookingData.numCubicles)}
              onClick={this.handleFormSubmit}
            >
              Save Cubicle Selection
            </Button>
        </Grid>
      </Grid>
    </>);
  }
};
