import React, { Component,useState } from 'react';
import { NavLink } from 'react-router-dom';
 
import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';
import NavigateBack from 'components/NavigateBack';

import bookingTimeFields from './booking-times.fields';

import { fetchBookingTimeById, updateBookingTime } from 'services/booking-times';
import { fetchAllSpecies } from 'services/species-details';

class EditBookingTimes extends Component {
  
  state = { 
    bookingTimeData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };

  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated booking time details..." };
    
    // todo API calls
    try {
      let response = await updateBookingTime(this.props.locationParams.id,params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let bookingTimeData = null;
    let statusMessage = this.state.statusMessage;
    bookingTimeFields[0].selectOptions = {};
    try {

      let response = await fetchBookingTimeById(this.props.locationParams.id);
      bookingTimeData = response.data;

      bookingTimeData.species_id =(bookingTimeData.species_id || {}).id;
      bookingTimeData.status = bookingTimeData.status.toString();

       // fetch all species
       let species_response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
       species_response.data.map ( species => {
        bookingTimeFields[0].selectOptions[species.id] = species.name;
       });
      
      statusMessage = {status: "success", message: "Fetched booking times details successfully !"}  

    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }

    await this.setState({bookingTimeData, statusMessage});
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Booking Times Details </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.bookingTimeData &&
            <DynamicForm
              formFields={bookingTimeFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              buttonText="Update Booking Time Details"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.bookingTimeData}
              redirect={'/aqf/admin/booking-times/list'}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditBookingTimes);