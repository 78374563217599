import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';

import {
  ListAltOutlined,
  BarChartOutlined,
  ReceiptOutlined,
  LocalMallOutlined,
  AccountCircleOutlined,
  LocalShippingOutlined,
  InsertInvitationOutlined,
  MoneyOutlined,
  DeveloperBoardOutlined,
  MobiledataOffOutlined,
  BloodtypeOutlined,
  BusinessOutlined,
  FlagCircleOutlined,
  TrackChangesOutlined
} from '@mui/icons-material';

import NavItem from './sidebar-components/NavItem';

import { AuthStore, HatcheryStore } from 'store';

import {
  HatcheryOwner,
  Hatcheries,
  Approvals,
  AppSettings
} from './sidebar-components/NestedMenus';

import { permissionCheck } from 'permissions';

import { calculateAPISignature } from 'services/utils';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
  if (openMobile && onMobileClose) {
    onMobileClose();
  }
  }, [location.pathname]);
  
  let profilePicture = '/static/images/misc/dummy.png';
  let currentHatchery = HatcheryStore.state.currentHatchery;
  let signature = null;
  
  if (currentHatchery && currentHatchery.userProfile && currentHatchery.userProfile.picture) {
    profilePicture = `${process.env.REACT_APP_STRAPI_FILE_URL}${currentHatchery.userProfile.picture}`
    signature = calculateAPISignature(encodeURIComponent(currentHatchery.userProfile.picture.replace(/ /g, '%20')));
  } else if(AuthStore.state.currentUser.user.userProfile.picture) {
    signature = calculateAPISignature(encodeURIComponent(AuthStore.state.currentUser.user.userProfile.picture.replace(/ /g, '%20')));
    profilePicture = `${process.env.REACT_APP_STRAPI_FILE_URL}${AuthStore.state.currentUser.user.userProfile.picture}`;
  }
  
  const content = (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}} >
    <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2 }}>
    <Avatar
      component={RouterLink}
      src={profilePicture +'?s=' + signature}
      sx={{
      cursor: 'pointer',
      width: 96,
      height: 96
      }}
      variant="circular"
      to="/aqf/dashboard/account"
      state={{id : AuthStore.state.currentUser.user.userProfile.id}}
    />
    <Typography
      color="textPrimary"
      variant="h5"
    >
    {
      HatcheryStore.state.currentHatchery 
        ? HatcheryStore.state.currentHatchery.name 
        : AuthStore.state.currentUser.user.userProfile.name
    }
    </Typography>
    <Typography
      color="textSecondary"
      variant="body2"
    >
      {
        AuthStore.state.currentUser.user.userProfile.isHatcheryIncharge 
          ? 'Hatchery Incharge' 
          : AuthStore.state.currentUser.user.role.name
      }
    </Typography>
    </Box>
    <Divider />
    <Box sx={{ p: 1 }}>
    <List>
    {
        AuthStore.state.currentUser.user.role.name === 'Hatchery Owner' ?
        <HatcheryOwner /> :
        (<>
          <NavItem href="/aqf/dashboard" title="Dashboard" icon={BarChartOutlined} />
          <AppSettings/>
          {
            permissionCheck('hatchery', 'view-any') &&
            <NavItem href="/aqf/hatcheries/list" title="Manage Hatcheries" icon={BusinessOutlined} />
          }
          {
            (permissionCheck('booking', 'view-any') || permissionCheck('booking', 'view-self')) &&
            <NavItem href="/aqf/bookings/list" title="Manage Bookings" icon={DeveloperBoardOutlined} />
          }
          {
            permissionCheck('booking-reports', 'create-received-sample-report') &&
            <NavItem href="/aqf/bookings/arrivals" title="Manage Quarantine Cubicles" icon={FlagCircleOutlined} />
          }
          {
            permissionCheck('booking-reports', 'create-clearance-certificate') &&
            <NavItem href="/aqf/bookings/clearances" title="Manage Clearances" icon={FlagCircleOutlined} />
          }
          <Approvals />
          {
            permissionCheck('transaction', 'view-additional-charges') &&
            <NavItem href="/aqf/bookings/additional-charges/list" title="Additional Charges" icon={MoneyOutlined} />
          }
          {
           (permissionCheck('transaction', 'view-any') ||  permissionCheck('transaction', 'view-self')) &&
            <NavItem href="/aqf/bookings/transactions" title="Transactions" icon={ReceiptOutlined} />
          }
          {
            permissionCheck('booking-reports', 'view') &&
            <NavItem href="/aqf/bookings/test-reports" title="Booking Reports" icon={MobiledataOffOutlined} />
          }
          <NavItem href="/aqf/reports/list" title="AQMS Reports" icon={ListAltOutlined} />
          {
            permissionCheck('common', 'view-event-log-any') &&
            <NavItem href="/aqf/admin/event-logs/list" title="Event Logs" icon={TrackChangesOutlined} />
          }
        </>)
          
        }
     </List>
    </Box>
  </Box>
  );

  return (
  <>
    <Hidden lgUp>
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
      PaperProps={{
      sx: {
        width: 240
      }
      }}
    >
      {content}
    </Drawer>
    </Hidden>
    <Hidden lgDown>
    <Drawer
      anchor="left"
      open
      variant="persistent"
      PaperProps={{
      sx: {
        width: 240,
        top: 64,
        height: 'calc(100% - 64px)'
      }
      }}
    >
      {content}
    </Drawer>
    </Hidden>
  </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
