import { fetchWithTimeout, addPagerParams } from './utils';

const BOOKING_TIMES_EP_URL = "/booking-times";

  
export const fetchBookingTimesTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : BOOKING_TIMES_EP_URL + '/count?_start=0'
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'common:view-booking-times'
  });
};

export const fetchAllBookingTimes = async (params) => {
 
    let response = await fetchWithTimeout({
      path : BOOKING_TIMES_EP_URL + addPagerParams(params, 'status:desc,species_id:DESC') 
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
      method : 'GET',
      operation : 'common:view-booking-times'
    });
    
    if (response.status == 200 && params.per_page === -1) {
      CubicleDataStore.setState({cubicles : response.data, timestamp : new Date().getTime()});
    }
    return response;
 
};

export const fetchBookingTimeById = async (bookingTimeId) => {
  return await fetchWithTimeout({
    path : BOOKING_TIMES_EP_URL + '/' + bookingTimeId,
    method : 'GET',
    operation : 'common:view-booking-times'
  });
};

export const createNewBookingTime = async (params) => {
  return await fetchWithTimeout({
    path: BOOKING_TIMES_EP_URL,
    method: 'POST',
    body : params,
    operation : 'common:booking-times-create'
  });
};

export const updateBookingTime = async (bookingTimeId,params) => {
  return await fetchWithTimeout({
    path: BOOKING_TIMES_EP_URL + '/' +bookingTimeId,
    method: 'PUT',
    body : params,
    operation : 'common:booking-times-update'
  });
};