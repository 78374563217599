import React, { Component } from 'react';

import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchBookingReportsCount} from 'services/booking-reports';

export default class TotalShipmentPackingsCard extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchBookingReportsCount('shipment-packing', {hatcheryId : 'ALL', speciesType : 'ALL'});
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total Shipment Packing Reports'} 
        count = {this.state.count} 
        icon = {<LocalShippingOutlined />}
        url = { '/aqf/bookings/test-reports/shipment-packing' }
      />
    );
  }
}