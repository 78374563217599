import React, { Component } from 'react';

import { Container, Paper, Grid, Typography, Button, Box} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// import booking components
import BookingForm from './form-components/BookingForm';
import CubicleSelectionForm from './form-components/CubicleSelectionForm';
import BookingTandC from './form-components/BookingTandC';
import BookingSummary from './view-components/BookingSummary';

import ConfirmationPage from 'pages/common/ConfirmationPage';

import { HatcheryStore } from 'store';

import { createNewBooking } from 'services/bookings';
import { fetchHatcheryById } from 'services/hatcheries';
import { fetchTransactionsData } from 'services/transactions';
import { fetchBookingTimesOfSpeciesId } from 'services/common';

export default class AddNewBooking extends Component {
  
  constructor ( props ) {
    super (props);
    this.steps = ['Enter Booking Details', 'Select Cubicles', 'Terms and Conditions', 'Booking Confirmation'];
    this.state = {
      activeStep  : 0,
      completed : {},
      statusMessage : null,
      bookingData : null,
      scheduleData : null,
      isHatcheryData : false,
      timer : 300,
      fyLimitData : null
    };
    
    // check booking eligibility 
    this.bookingErrMessage = null;
    this.disabledMessage = null;
    
    // booking completion timer for 10 mins
    this.bookingTimer = null;
  }
  
  handleTimerInterval = () => {
    if (this.state.timer > 0) {
      this.setState({ timer : this.state.timer -1 });
    } else {
      this.setState({
        activeStep : 0,
        completed : {},
        statusMessage : { status : "error", message : "Booking timed out, please retry"}
      });
      
      // refresh booking page
      window.location.reload();
    }
  }
  
  async componentDidMount() {
    let currentHatchery = HatcheryStore.state.currentHatchery;
    if (currentHatchery) {
      try {
        let response = await fetchHatcheryById(currentHatchery.id);
        currentHatchery = response.data;
        await this.checkBookingEligible(currentHatchery);
      } catch(error) {
        console.log(error);
        this.bookingErrMessage = error.message;
      }
    } else {
      this.bookingErrMessage = 'Current Hatchery Details are not available. Cannot proceed with booking. Please re-login and try';
    }
    
    this.setState({isHatcheryData : true});
    
    // run timer for 5 mins to complete the booking, otherwise reload
    this.bookingTimer = setInterval(this.handleTimerInterval, 1000);

  }
  
  componentWillUnmount() {
    if(this.bookingTimer) {
      clearInterval(this.bookingTimer);
      this.bookingTimer = null;
    }
  }
  
  checkBookingEligible = async (currentHatchery) => {

    
    if (!currentHatchery) {
      this.bookingErrMessage = 'Current Hatchery Details are not available. Cannot proceed with booking. Please re-login and try';
      return;
    }
    
    let currentTime = new Date();
    let allowedTime = null;
    let unlimitedBooking = false;

    // check booking start time
    try {
        let btresponse = await fetchBookingTimesOfSpeciesId(currentHatchery.permittedSpeciesId.id);
        if (btresponse.status==200 && btresponse.data) {
            allowedTime = new Date(btresponse.data.startTime);
            unlimitedBooking = new Date(btresponse.data.unlimitedStartTime).getTime() < currentTime.getTime();
        }
        
        // do not allow bookings if already there is a pending payment
        let transactions = await fetchTransactionsData({
           paymentId : currentHatchery.rzrpayVirtualAccId, status : 'ApprovalPending',
           page : 1, per_page : -1, additionalId : 'ALL', hatcheryId : 'ALL'
        });
        if (transactions.data && transactions.data.length) {
          this.bookingErrMessage = 'There is a pending payment. Cannot initiate new booking until all previous payments are cleared';
        }
    } catch(error) {
        console.log(error);
    }
    
    
    if (allowedTime==null){
      this.disabledMessage = "Bookings are not opened yet"; ;
    } else if (allowedTime && currentTime.getTime() < allowedTime.getTime()) {
      this.disabledMessage = "Bookings will open from " + allowedTime.toLocaleString('en-GB', {timezone : 'Asia/Kolkata'});
    } 
    // else if (currentTime.toLocaleString("en-US", {timezone : 'Asia/Kolkota', weekday: 'long'}) === 'Sunday') 
    // {
    //   this.bookingErrMessage = 'Cubicle bookings are not allowed on Sunday';
    // } 
    else if (!currentHatchery.license) {
      this.bookingErrMessage = 'License Expired for this hatchery. Please renew license and retry booking';
    } else if (!currentHatchery.rzrpayVirtualAccount) {
      this.bookingErrMessage = 'Hatchery virtual account number for making payments via NEFT/RTGS is not setup. ' 
        + 'Please add your bank account details from which you wish to make payments. Once the bank details are approved ' 
        + 'your hatchery will be provided with a unique RGCA virtual account number to which, the payments can be made ' + 
        'via NEFT / RTGS from your registered bank account.';
    } else if (currentHatchery.monthlyBookings && currentHatchery.monthlyBookings.adminCancelled > 0) {
      this.bookingErrMessage = 'Previous booking was cancelled by AQF, cannot make any new bookings this month'; 
    } else if (!unlimitedBooking && currentHatchery.permittedSpeciesId.monthlyBookingLimit > 0 && 
      currentHatchery.monthlyBookings && currentHatchery.monthlyBookings.bookings &&
      currentHatchery.monthlyBookings.bookings >= currentHatchery.permittedSpeciesId.monthlyBookingLimit) {
      this.bookingErrMessage = 'Exceeded monthly bookings limit, cannot proceed with cubicle bookings';
    } else if (!unlimitedBooking && currentHatchery.permittedSpeciesId.quarterlyBookingLimit > 0 && 
      currentHatchery.quarterlyBookings && currentHatchery.quarterlyBookings.bookings && 
      currentHatchery.quarterlyBookings.bookings >= currentHatchery.permittedSpeciesId.quarterlyBookingLimit) {
      this.bookingErrMessage = 'Exceeded quarterly bookings limit, cannot proceed with cubicle bookings';
    } else if (!unlimitedBooking && currentHatchery.permittedSpeciesId.monthlyCubicleLimit > 0 && 
      currentHatchery.monthlyBookings && currentHatchery.monthlyBookings.cubicles && 
      currentHatchery.monthlyBookings.cubicles >= currentHatchery.permittedSpeciesId.monthlyCubicleLimit) {
      this.bookingErrMessage = 'Exceeded monthly cubicles limit, cannot proceed with cubicle bookings';
    } else if (!unlimitedBooking && currentHatchery.permittedSpeciesId.quarterlyCubicleLimit > 0 &&
      currentHatchery.quarterlyBookings && currentHatchery.quarterlyBookings.cubicles && 
      currentHatchery.quarterlyBookings.cubicles >= currentHatchery.permittedSpeciesId.quarterlyCubicleLimit) {
      this.bookingErrMessage = 'Exceeded quarterly cubicles limit, cannot proceed with cubicle bookings';
    }
  }
  
  totalSteps = () => {
    return this.steps.length;
  };

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleNext = async () => {
    const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
      ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      this.steps.findIndex((step, i) => !(i in this.state.completed))
      : this.state.activeStep + 1;
      
    await this.setState({activeStep : newActiveStep});
  };

  handleBack = () => {
    //this.setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  handleStep = step => async () => {
    // await this.setState({activeStep : step});
  };

  handleComplete = async (statusMessage) => {
    const newCompleted = this.state.completed;
    newCompleted[this.state.activeStep] = true;
    await this.setState({completed : newCompleted, statusMessage : statusMessage});
    await this.handleNext();
  };

  handleReset = async () => {
    await this.setState({activeStep : 0, completed: {}});
  };
  
  handleBookingDetails = async (fyLimitData, bookingData) => {
    await this.setState({ bookingData, fyLimitData });
    await this.handleComplete({status: 'success', message : 'Proceeding to cubicle selection....'});
  }
  
  handleCubicleSelection = async (scheduleData) => {
    await this.setState({ scheduleData });
    await this.handleComplete({status: 'success', message : 'Proceeding to Terms and conditions..'});
  }
  
  handleTermsAndConditions = async (agree) => {
    // to do make booking
    if (agree) {
      let formData = {
        'hatcheryId' : this.state.bookingData.hatcheryId,
        'bioMassPerStock' : this.state.bookingData.bioMassPerStock,
        'totalNumberOfStock' : this.state.bookingData.totalNumberOfStock,
        'totalBioMass' : this.state.bookingData.totalBioMass,
        'supplierId' : this.state.bookingData.supplierId,
        'speciesType' : this.state.bookingData.speciesType,
        'cubicleBookingScheduleIds' : this.state.scheduleData.cubicles.map( s => s.id )
      };
      let statusMessage = {status: 'success', message : 'Cubicle booking completed successfully !...'}
      try {
        let response = await createNewBooking(formData);
        const {hatcheryName, CAARegistrationNumber} = this.state.bookingData;
        this.state.bookingData = response.data;
        this.state.bookingData.hatcheryName = hatcheryName;
        this.state.bookingData.hatcheryAddress = response.data.hatcheryId.address;
        this.state.bookingData.CAARegistrationNumber = CAARegistrationNumber;
        this.state.bookingData.validFrom = this.state.fyLimitData.license ? this.state.fyLimitData.license.validFrom : null;
        this.state.bookingData.validTo = this.state.fyLimitData.license ? this.state.fyLimitData.license.validTo : null;
        if (response.data.cubicleDetails && response.data.cubicleDetails.length) {
          this.state.bookingData.numCubicles = response.data.cubicleDetails.length;
          this.state.bookingData.cubicleNumber = response.data.cubicleDetails.map( x => x.cubicleName).join(',');
        } else {
          this.state.bookingData.numCubicles = 0;
          this.state.bookingData.cubicleNumber = '';
        }
        
        if (response.data.supplierId) {
          this.state.bookingData.supplierId = response.data.supplierId.name;
        }
        
        if(this.bookingTimer) {
          clearInterval(this.bookingTimer);
          this.bookingTimer = null;
        }
      } catch (error) {
        console.log(error);
        statusMessage = {status: 'error', message : error.message };
      }
      await this.handleComplete(statusMessage);
    }
  }
  
  render () {
    
    const minutes = Math.floor(this.state.timer / 60);
    const seconds = this.state.timer - (minutes * 60);
    
    if (this.bookingErrMessage || this.disabledMessage) {
      return (
       <Paper elevation={4} sx={{m :1}}>
         <ConfirmationPage
            type = "error"
            title = {this.disabledMessage ? this.disabledMessage : "Bookings are not allowed for this hatchery !"}
            message = {this.disabledMessage ? null : this.bookingErrMessage}
            redirect = {'/aqf/dashboard'}
            redirectText = 'Back to Home'
         />
       </Paper>
      );
    } else if (!this.state.isHatcheryData) {
      return (<Paper elevation={4} sx={{p:1}}><Typography variant="h3" align="center" p={2} > New Booking Form </Typography></Paper>);
    }
    
    return(<Container maxWidth={false}  sx={{mt:2}}>
      <Paper elevation={4} sx={{p:1}}>
        {
          this.state.statusMessage && this.state.activeStep < 3 &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        <Typography variant="h3" align="center" p={2} > New Booking Form </Typography>
        {
          this.state.activeStep <= 2 &&  
          <Typography noWrap variant="h5" color="error.light" align="center" > 
            Time left to complete your booking : {`0${minutes}:${seconds >= 10 ? seconds : '0' + seconds} minutes`} 
          </Typography>
        }
        <br/>
        <Stepper nonLinear activeStep={this.state.activeStep}>
          {this.steps.map((label, index) => (
            <Step key={label}>
              <StepButton disabled = {this.state.completed[index] ? true : false}
                onClick={this.handleStep(index)} 
                completed={this.state.completed[index]}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Paper>
      {
        this.state.activeStep === 0 &&
        <BookingForm
          fyLimitData = { this.state.fyLimitData }
          currentValues = {this.state.bookingData} 
          handleFormSubmit={this.handleBookingDetails}
        />
      }
      {
        this.state.activeStep === 1 &&
        <CubicleSelectionForm
          fyLimitData = { this.state.fyLimitData }
          bookingData = {this.state.bookingData} 
          scheduleData = {this.state.scheduleData} 
          handleFormSubmit={this.handleCubicleSelection} 
        />
      }
      {
        this.state.activeStep === 2 &&
        <BookingTandC handleFormSubmit={this.handleTermsAndConditions} />
      }
      {
        this.state.activeStep === 3 &&
        <>
        {
          this.state.statusMessage && this.state.statusMessage.status == 'error' ?
          <Paper elevation={4} sx={{m :1}}>
            <ConfirmationPage
              type = "error"
              title = {"Cubicles booking failed !"}
              message = {this.state.statusMessage.message}
              redirect = {() => {
                this.setState({ activeStep  : 0,
                  completed : {},
                  statusMessage : null,
                  bookingData : null,
                  scheduleData : null,
                  timer : 300,
                  fyLimitData : null
                })
              }}
              redirectText = 'Try Again !'
            />
          </Paper> :
          <BookingSummary bookingData={this.state.bookingData}/>
        }
        </>
      }      
    </Container>);
  }
}