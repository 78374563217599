import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchEventLogsTotalCount, 
  fetchEventLogsData, 
} from 'services/common';

import { eventLogFields } from './eventlog.fields'

class EventLogsList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Events',
      addButtonText : 'New Additional Charges',
      addUrl : null,
      searchEnable : true,
      searchPlaceholder : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : eventLogFields,
      itemUrlPath : '/aqf/admin/event-logs/view',
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchEventLogsDataWrapper,
      fetchCountApi : this.fetchEventLogsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],

      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchEventLogsCountWrapper = async(filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchEventLogsTotalCount({
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
    });
    return response;
  }    

  fetchEventLogsDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    let response = await fetchEventLogsData({
      page: page,
      per_page: perPage,
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
    });
    
    
    response.data.forEach(entry => {
      entry.selection = false; //this.currentUser.user.role.name === 'Front Desk';
      entry.url = `/aqf/admin/event-logs/view/${entry.id}`;
      entry.hatcheryId = entry.hatcheryId ? entry.hatcheryId.name : null;
      entry.owner = entry.owner ? entry.owner.name : null;
      entry.eventType = entry.eventType.replace(/_/g, ' ');
      if (entry.bookingId) {
        entry.bookingId = entry.bookingId.id;
      }
    });
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default EventLogsList;
