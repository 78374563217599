import { Paper, Grid, TableContainer, Table, TableBody, TableRow, TableCell, Button, Typography, Chip  } from '@mui/material';

import ImageOrDocument from 'components/ImageOrDocument';

const TextFormatter = (props) => {
  if (props.formatter) {
    switch(props.formatter.el) {
      case "chip":
        return (<Chip label={props.text} color={props.formatter.color} size={props.formatter.size} sx={{fontWeight:'bold'}}/>);
      case "imgafter":
        return (<>{props.text}<img src={props.formatter.path} height="24" width="48"/></>);
      case "imgbefore":
        return (<><img src={props.formatter.path} height="24" width="48"/>{props.text}</>);
      default:
        return props.text;
    }
  } else {
    return props.text;
  }
};

const EntryTabularView = (props) => {
  
  if (!props.values || !props.formFields || props.formFields.length === 0) {
    console.log("invalid tabular entry");
    return (<></>);
  }
  return (
  
    <Paper elevation={props.noPaper ? 0 : 3} sx={{textAlign:"center", mt : 1, ml : 1, p : 2}}>
    {
      props.title &&
      <><Typography variant="h4"> {props.title} </Typography><hr style={{borderColor:"#eef1f6", marginTop:1}} /></>
    }
    <TableContainer sx={{ px: 1}}>
      <Table size="small">
        <TableBody>
        {
          props.formFields.map ( (field, idx) => {
            if (['file', 'image', 'date', 'datetime'].includes(field.type) === false &&  props.values[field.name] && 
              typeof props.values[field.name] === 'object') {
              return;
            }
            if (field.actions && field.actions.find(a => a === 'view')) {
              if (props.values.hasOwnProperty(field.name) == false && ['file', 'image'].includes(field.type) === false) {
                return (
                  <TableRow key={`row-${idx}`}>
                    <TableCell key={`row-${idx}-col-0`} align="left">
                      <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                    </TableCell>
                    <TableCell key={`row-${idx}-col-1`} align="left"> -- </TableCell>
                  </TableRow>
                );
              }
            
              if (['image', 'file'].includes(field.type)) {
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                  {
                     Array.isArray(props.values[field.name]) ?
                     <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{m:2}}>
                     {
                      props.values[field.name].map ( (v,idx) => {
                        return (<Grid item key={'inneridx-' + idx}><ImageOrDocument document={v} minView align="flex-start" /></Grid>)
                      })
                     }
                     </Grid> : 
                    <ImageOrDocument document={props.values[field.name]} minView align="flex-start" />
                  }
                  </TableCell>
                </TableRow>)
              } else if (field.type === 'select') {
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                    <TextFormatter 
                      text={ 
                        props.values[field.name] ? 
                          field.selectOptions[props.values[field.name]] ?
                          field.selectOptions[props.values[field.name]] : props.values[field.name] : '--'
                      }
                      formatter={field.formatter}
                     />
                  </TableCell>
                </TableRow>)
              } else if (field.type === 'boolean') {
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                    <TextFormatter 
                      text={
                        field.valueStrings ? field.valueStrings[props.values[field.name] ? 1 : 0] : props.values[field.name].toString()
                      }
                      formatter={field.formatter}
                    />
                    
                  </TableCell>
                </TableRow>)
              } else if (field.type === 'date') {
                let value = props.values[field.name] ? new Date(props.values[field.name]) : null;
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                   <TextFormatter 
                      text={value ? value.toLocaleDateString('en-GB', {'timezone' : 'Asia/Kolkata'}) : '--'}
                      formatter={field.formatter}
                   />
                  </TableCell>
                </TableRow>)
              } else if ('datetime' === field.type) {
                let value = props.values[field.name] ? new Date(props.values[field.name]) : null;
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                    <TextFormatter 
                      text={value ? value.toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'}) : '--'}
                      formatter={field.formatter}
                    />
                  </TableCell>
                </TableRow>)
              } else if ('currency' === field.type) { 
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                    <p dangerouslySetInnerHTML={{__html : (props.values[field.name] ? "&#x20B9; " + props.values[field.name] : '')}} />
                  </TableCell>
                </TableRow>)
              } else {
                return (<TableRow key={`row-${idx}`}>
                  <TableCell key={`row-${idx}-col-0`} align="left">
                    <p style={{"fontWeight" : "bold"}}>{field.label}</p>
                  </TableCell>
                  <TableCell key={`row-${idx}-col-1`} align="left">
                    <TextFormatter 
                      text={[undefined, null].includes(props.values[field.name]) ? '--' : props.values[field.name]}
                      formatter={field.formatter}
                    />
                  </TableCell>
                </TableRow>)
              }
            }
          })
        }
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>);
};

export default EntryTabularView;
