import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { addNoCDocument } from 'services/bookings';

export default class NoCForm extends React.Component {
 
  constructor(props) {
    super(props);
    this.formFields = [
      {
        name : 'nocCertificate',
        label : 'Upload NoC Document',
        type : 'file',
        properties: [
          {required:true }, 
          {filesize : {min : 0, max : 10} }, 
          {filetype : ['pdf', 'docx','doc','PDF']}
        ],
        actions : ['index', 'view', 'create', 'update']
      }
    ];
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "Successfully saved NoC document...." };
    try {
      let response = await addNoCDocument(this.props.bookingData.id, formData);
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render() {
    return(<>
      <Typography variant="h5" align="center" p={1} > 
      { 
        (this.props.bookingData.documents && this.props.bookingData.documents.nocCertificate ? 'Update NoC Details ' : 'Add NoC Details') + 
        ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={this.formFields}
        action={this.props.bookingData.documents && this.props.bookingData.documents.nocCertificate ? "update" : "create"}
        handleSubmit={this.handleFormSubmit}
        buttonText="Save NoC Document"
        buttonWidth= "full"
        columns = {1}
        uploadFiles={true}
        currentValues={this.props.bookingData.documents}
        redirect={null}
      />
    </>);
  }
}