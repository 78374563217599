import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import supplierFields from './supplier.fields';

import { createNewSupplier } from 'services/suppliers';

import { fetchAllSpecies } from 'services/species-details';

export default class AddNewSupplier extends Component {
  
  state = { speciesData : false };
  
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new supplier..." };
    
    // todo API calls
    try {
      let response = await createNewSupplier(params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
      try {
        let response = await fetchAllSpecies({ page : 1, per_page : -1, sort: 'id:ASC'});
        response.data.map ( species => {
           supplierFields[2].selectOptions[species.id] = species.name;
        });
        this.setState({speciesData : true});
      } catch(error) {
        console.log(error);
      }
    }
    

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Add New Supplier </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            formFields={supplierFields}
            action="create"
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Supplier Details"
            buttonWidth= "full"
            columns = {3}
            redirect={'/aqf/admin/suppliers/list'}
            confirmation={"Are you sure you want to save the details ? "}
          />
        </Paper>
      </>
    );
  }
}