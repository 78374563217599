import React from "react";
import {
  BarChartOutlined,
  DeveloperBoardOutlined,
  DeveloperBoardOffOutlined,
  ReceiptOutlined,
  PendingActionsOutlined,
  ChromeReaderModeOutlined,
  MobiledataOffOutlined,
  SettingsApplicationsOutlined,
  LabelImportant,
  MoneyOutlined
} from '@mui/icons-material';

import Box from '@mui/material/Box';

import NavItem from './NavItem';
import NavParent from './NavParent';

import { permissionCheck } from 'permissions';

import { HatcheryStore } from 'store';

export function Approvals () {
  return (<NavParent title="Manage Approvals" icon={PendingActionsOutlined}
    children = {
      <Box sx={{ml:2}}>
      {
        permissionCheck('hatchery', 'approve') &&
        <NavItem href="/aqf/hatcheries/approvals/hatcheries" title="Hatcheries" icon={LabelImportant}  />
      }
      {
        permissionCheck('hatchery', 'approve-changes') &&
        <NavItem href="/aqf/hatcheries/approvals/hatcherychanges" title="Hatchery Changes" icon={LabelImportant} />
      }
      {
        permissionCheck('booking', 'approve-shipment') &&
        <NavItem href="/aqf/bookings/list-shipment-approval" title="Shipment Documents" icon={LabelImportant} />
      }
      </Box>
      
    }
  />);
};

export function AppSettings() {
  return (<NavParent title="Application Setup" icon={SettingsApplicationsOutlined}
    children = {
      <Box sx={{ml:2}}>
      {
        permissionCheck('common', 'cubicle-create') &&
        <NavItem href="/aqf/admin/cubicles/list" title="Manage Cubicles" icon={LabelImportant} />
      }
      {
        permissionCheck('supplier', 'create') &&
        <NavItem href="/aqf/admin/suppliers/list" title="Manage Suppliers" icon={LabelImportant} />
      }
      {
        permissionCheck('common', 'species-create') &&
        <NavItem href="/aqf/admin/species-details/list" title="Manage Species" icon={LabelImportant} />
      }
      {
        permissionCheck('user-accounts', 'view-permissions-any') &&
        <NavItem href="/aqf/admin/role-permissions/list" title="Manage Roles" icon={LabelImportant} />
      }
      {
        permissionCheck('user-accounts', 'create') &&
        <NavItem href="/aqf/admin/user-accounts/list" title="Manage Users" icon={LabelImportant} />
      }
      {
        permissionCheck('booking-schedules', 'view-any') &&
        <NavItem href="/aqf/booking-schedules/list" title="Cubicle Schedules" icon={LabelImportant} />
      }
      {
        permissionCheck('common', 'booking-times-create') &&
        <NavItem href="/aqf/admin/booking-times/list" title="Manage Booking Times" icon={LabelImportant} />
      }
      </Box>
      
    }
  />);
}

export function HatcheryOwner() {
  return (<>
    <NavItem href="/aqf/dashboard" title="Dashboard" icon={BarChartOutlined} />
    <NavItem href="/aqf/bookings/list" title="Manage Bookings" icon={DeveloperBoardOutlined} />
    <NavItem href="/aqf/bookings/transactions" title="Transactions" icon={ReceiptOutlined} />
    <NavItem href="/aqf/bookings/additional-charges/list" title="Additional Charges" icon={MoneyOutlined} />
    {
      HatcheryStore.state && HatcheryStore.state.currentHatchery &&  
      <NavItem href={"/aqf/hatcheries/view/" + HatcheryStore.state.currentHatchery.id}  
        title="Hatchery Details" icon={ChromeReaderModeOutlined} 
      />
    }
    <NavItem href="/aqf/bookings/test-reports" title="Reports" icon={MobiledataOffOutlined} />
  </>);
}