import { fetchWithTimeout, addPagerParams } from './utils';

const TRANSACTIONS_EP_URL = "/transactions";

import { permissionCheck } from 'permissions';
  
export const fetchTransactionsTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : TRANSACTIONS_EP_URL + '/count?_start=0'
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.additionalId !== 'ALL' ? '&additionalBookingChargesId_null=' + params.additionalId == 'booking' : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.oldId && params.oldId.length ? '&bookingId.oldId=' + params.oldId : ''),
    method : 'GET',
    operation : permissionCheck('transaction', 'view-self') ? 'transaction:view-self' : 'transaction:view-any'
  });
};

export const fetchTransactionsData = async (params) => {
  return await fetchWithTimeout({
    path : TRANSACTIONS_EP_URL + addPagerParams(params) 
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryId !== 'ALL' ? '&bookingId.hatcheryId=' + params.hatcheryId : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.additionalId !== 'ALL' ? '&additionalBookingChargesId_null=' + (params.additionalId == 'booking') : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.oldId && params.oldId.length ? '&bookingId.oldId=' + params.oldId : '')
        + (params.paymentId && params.paymentId.length ? '&paymentId=' + params.paymentId : ''),
    method : 'GET',
    operation : permissionCheck('transaction','view-self') ? 'transaction:view-self' : 'transaction:view-any'
  });  
};

export const fetchTransactionById = async (transactionId, download=false) => {
  return await fetchWithTimeout({
    path : TRANSACTIONS_EP_URL + (download ? '/download/' : '/') + transactionId,
    method : 'GET',
    operation : permissionCheck('transaction','view-self') ? 'transaction:view-self' : 'transaction:view-any'
  });
};

export const fetchRevenuesByHatcheries = async (params) => {
  return await fetchWithTimeout({
    path : TRANSACTIONS_EP_URL + '/revenuebyhatchery?fromDate=' + params.fromDate + '&toDate=' + params.toDate
      + (params.state !== 'ALL' ? '&state=' + params.state : '')
      + (params.district !== 'ALL' ? '&district=' + params.district : ''),
    method : 'GET',
    operation : 'app-reports:top-hatchery-revenues'
  });
}

export const fetchRevenuesByHatcheriesCount = async (params) => {
  return await fetchWithTimeout({
    path : TRANSACTIONS_EP_URL + '/revenuebyhatchery/count?fromDate=' + params.fromDate + '&toDate=' + params.toDate
      + (params.state !== 'ALL' ? '&state=' + params.state : '')
      + (params.district !== 'ALL' ? '&district=' + params.district : ''),
    method : 'GET',
    operation : 'app-reports:top-hatchery-revenues'
  });
};
