import { useNavigate, useLocation } from 'react-router-dom';
import {Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NavigateBack = (props) => {
    const location = useLocation();
    const navigator = useNavigate();
    
    const handleGoBack = () => {
      if (location.state && location.state.from) {
        navigator(location.state.from , { state : location.state});
      } else {
        navigator(-1, { state : location.state});
      }
    }
    
    return (
       <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:2, py : 1}} component="span">
        <Button  
          color="info" 
          size="small" 
          variant="outlined"
          startIcon = {<ArrowBackIcon />}
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Box>
    );
};

export default NavigateBack;