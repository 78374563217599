import React from "react";
import Webcam from "react-webcam";

import { Box, Button } from '@mui/material';

const WebcamImgCapture = (props) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
  const imageSrc = webcamRef.current.getScreenshot();
  setImgSrc(imageSrc);
  if (props.capturePhoto) {
    return props.capturePhoto(props.name, imageSrc);
  }
  }, [webcamRef, setImgSrc]);

  return (
  <Box elevation={3} sx={{textAlign : 'center'}}>
    <Webcam
    audio={false}
    ref={webcamRef}
    screenshotFormat="image/jpeg"
    width={256}
    />
    <Button size="small" color="primary" variant="outlined" onClick={capture}>Capture Photo</Button>
  </Box>
  );
};

export default WebcamImgCapture;