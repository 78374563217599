import { fetchWithTimeout, addPagerParams } from './utils';

import { PermissionsDataStore } from 'store';

const ROLE_PERMISSIONS_EP_URL = "/role-permissions";
const APP_PERMISSIONS_EP_URL = "/app-permissions";
  
export const fetchRolesTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : ROLE_PERMISSIONS_EP_URL + '/count?_start=0'
        + (params.name && params.name.length ? '&role.name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'user-profile:view-permissions-any'
  });
};

export const fetchAllRoles = async (params) => {
  return await fetchWithTimeout({
    path : ROLE_PERMISSIONS_EP_URL + addPagerParams(params)
        + (params.name && params.name.length ? '&role.name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'user-profile:view-permissions-any'
  });  
};

export const fetchAllAppPermissions = async (params) => {
  return await fetchWithTimeout({
    path : APP_PERMISSIONS_EP_URL + addPagerParams(params, 'module:ASC')
        + (params.name && params.name.length ? '&role.name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'user-profile:view-permissions-any'
  });  
};

export const fetchRoleById = async (roleId) => {
  return await fetchWithTimeout({
    path : ROLE_PERMISSIONS_EP_URL + '/' + roleId,
    method : 'GET',
    operation : 'user-profile:view-permissions-any'
  });
};

export const fetchRolePermissionsById = async (id) => {
  return await fetchWithTimeout({
    path: ROLE_PERMISSIONS_EP_URL + '/' + id,
    method : 'GET',
    useToken : true,
    operation: 'user-profile:view-permissions-all'
  });
};

export const fetchRolePermissions = async () => {
  
  let currentState = PermissionsDataStore.state;
  let moduleName = '';
  
  let response = await fetchWithTimeout({
    path: ROLE_PERMISSIONS_EP_URL + '/me',
    method : 'GET',
    useToken : true,
    operation: 'user-profile:view-permissions-self'
  });
  
  if (response.status == 200) {
    let reportPermissions = [];
    response.data.map ((entry, idx) => {
      switch (entry.module) {
        case 'user-profile':
          moduleName = 'userProfile';
          break;
        case 'booking-schedule':
          moduleName = 'schedule';
          break;
        case 'booking-reports':
          moduleName = 'bookingReports';
          break;
        case 'app-reports':
          moduleName = 'appReports';
          break;
        default:
          moduleName = entry.module;
          break;
      }
      currentState[moduleName][entry.operation] = {
        enable : entry.enabled || entry.public || entry.authenticated,
        fields : entry.fieldPermissions
      }
    });
    
    const postArrivalOps =
      currentState['transaction']['create-additional-charges'] ||
      currentState['booking']['create-cubicle-stocking'] ||
      currentState['bookingReports']['create-water-quality-analysis'] ||
      currentState['bookingReports']['create-feeding-report'] ||
      currentState['bookingReports']['create-mortality-report'] ||
      currentState['bookingReports']['create-sample-weight-of-brood-stock'] ||
      currentState['booking']['create-sample-collection-storage'] ||
      currentState['bookingReports']['create-lab-analysis'] ||
      currentState['bookingReports']['create-lab-analysis-report'] ||
      currentState['bookingReports']['create-clearance-certificate'] ||
      currentState['bookingReports']['create-shipment-packing'];
    
    const viewBookingReports = 
      currentState['bookingReports']['view-received-sample-report'] ||
      currentState['bookingReports']['view-water-quality-analysis'] ||
      currentState['bookingReports']['view-feeding-report'] ||
      currentState['bookingReports']['view-mortality-report'] ||
      currentState['bookingReports']['view-sample-weight-of-brood-stock'] ||
      currentState['bookingReports']['view-lab-analysis'] ||
      currentState['bookingReports']['view-lab-analysis-report'] ||
      currentState['bookingReports']['view-clearance-certificate'] ||
      currentState['bookingReports']['view-shipment-packing'];
  
    // add permission info for atleast one booking operations
    currentState['booking']['postArrivalOps'] = { enable : postArrivalOps } ;
    
    // add permission info for atleast one booking reports
    currentState['bookingReports']['view'] = { enable : viewBookingReports };
    
    PermissionsDataStore.setState(currentState);
  }
  
  return { status : 200, data : module !== 'ALL' ? currentState[moduleName] : currentState };

};

export const createNewRole = async (params) => {
  return await fetchWithTimeout({
    path: ROLE_PERMISSIONS_EP_URL,
    method: 'POST',
    body : params,
    operation : 'user-profile:create-role-permissions'
  });
};

export const updateRole = async (roleId, params) => {
  return await fetchWithTimeout({
    path: ROLE_PERMISSIONS_EP_URL + '/' + roleId,
    method: 'PUT',
    body : params,
    operation : 'user-profile:update-role-permissions'
  });
};
