// import { Store } from 'react-stores';
import CryptoJS from "crypto-js";
import SecureStorage from 'secure-web-storage';

const SECRET_KEY =')H@McQfTjWnZr4u7x!A%C*F-JaNdRgUk';

const secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }
});

class Store {
  
  currentState; persist = false; identifier;
  
  constructor(initialState, identifier, persist=false) {
    this.identifier = identifier;
    this.currentState = {...initialState};
    this.persist = persist;
    if (persist) {
      // check if some data exists in persistant store
      try {
        let data = secureStorage.getItem(this.identifier);
        if (data) this.currentState = data;
        else secureStorage.setItem(this.identifier, this.currentState);
      } catch(error) {
        // no data in secure storage
        console.log(error);
      }
    }
  };
  
  setState = (newState) => {
    
    Object.keys(newState).map(key => {
      this.currentState[key] = newState[key];
    }); 
    if (this.persist) {
      secureStorage.setItem(this.identifier, this.currentState);
    }
  }
  
  get state() {
    if (this.persist) {
      try {
        let data = secureStorage.getItem(this.identifier);
        if (!data) return this.currentState;
        else return data;
      } catch(error) {
        // no data in secure storage
        console.log(error);
        return this.currentState;
      }
    } else {
      return this.currentState;
    }
  }
  
  resetState = (initialState) => {
    console.log("resetting store : " + this.identifier);
    // console.log(initialState);
    this.currentState = initialState;
    if(this.persist) {
      secureStorage.removeItem(this.identifier);
    }
  }
};

// auth store
const initAuthState = { currentUser: null, permissions : null };
export const AuthStore = new Store(initAuthState, 'currentuser', true);

// hatchery store
const initHatcheryState = { currentHatchery : null };
export const HatcheryStore = new Store(initHatcheryState, 'currenthatchery', true);

// cubicle data store
const initCubicleData = { cubicles : [], timestamp : 0};
export const CubicleDataStore = new Store(initCubicleData, 'cubicledata', false);

// species details data store
const initSpeciesData = { species : [], timestamp : 0 };
export const SpeciesDataStore = new Store(initSpeciesData, 'speciesdata', false);

// permissions data store
const initPermissionsData = {
  common : {},
  hatchery : {},
  booking : {},
  userProfile : {},
  schedule : {},
  supplier : {},
  transaction : {},
  bookingReports : {},
  appReports : {}
};

export const PermissionsDataStore = new Store(initPermissionsData, 'permissions-data', true);

export const resetAllStores = () => {
  
  // clear secureStorage data
  secureStorage.clear();
  
  // reset in memory store data
  AuthStore.resetState(initAuthState);
  HatcheryStore.resetState(initHatcheryState);
  CubicleDataStore.resetState(initCubicleData);
  SpeciesDataStore.resetState(initSpeciesData);
  PermissionsDataStore.resetState(initPermissionsData);

}