import React, {Component} from 'react';

// Material helpers
import { Typography, Card, CardContent } from '@mui/material';

import { AuthStore } from  'store';

// import dashboard components
import HatcheryOwner from './components/HatcheryOwner';
import AQFEmployee from './components/AQFEmployee';


const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
} 

export default class Dashboard extends Component {
  
  state = { isLoading : true };
  
  render () {
    let classes = this.props.classes;
    
    const currentUser = AuthStore.state.currentUser;
    if (currentUser.user.role.name === 'Hatchery Owner') {
      return (<HatcheryOwner />);
    } else {
      return (<AQFEmployee />);
    }
  }
}