import cxor from 'xor-crypto-js';
import { 
  Avatar, TableContainer, Table, TableHead, TableBody, TableRow, TableCell  
} from '@mui/material';

import theme from 'theme';

import { calculateAPISignature } from 'services/utils';

const currentValue = (field, authPerson) => {
  return authPerson && authPerson[field] ? authPerson[field] : '--';
}

const updatedValue = (field, authPerson, updateAuthPerson) => {
  if (updateAuthPerson[field]) return updateAuthPerson[field];
  else if (authPerson) return authPerson[field];
  else null;
};

const calculateSignature = (value) => {
  return calculateAPISignature(encodeURIComponent(value));
}

const AuthPersonDetails = (props) => {
  return (<TableContainer sx={{ p : 2 }}>
    <Table size="small">
      <TableHead>
      <TableRow tabIndex={-1} key={0} sx={{backgroundColor: theme.palette.secondary.main, color:"#fff"}}>
        <TableCell key={'header-col-0'}  align="left" sx={{color: "#FFF"}}> Field Name </TableCell>
        <TableCell key={'header-col-1'}  align="left" sx={{color: "#FFF"}}> Current Value </TableCell>
        <TableCell key={'header-col-2'}  align="left" sx={{color: "#FFF"}}> Updated Value </TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
       <TableRow hover role="checkbox" tabIndex={-1} key={1}>
        <TableCell key={'row-1-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Person Name</p></TableCell>
        <TableCell key={'row-1-col-1'} align="left">{currentValue('name', props.authPerson)}</TableCell>
        <TableCell key={'row-1-col-2'} align="left">{updatedValue('name', props.authPerson, props.updateAuthPerson)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={2}>
        <TableCell key={'row-2-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>E-Mail Address</p></TableCell>
        <TableCell key={'row-2-col-1'} align="left">{currentValue('email', props.authPerson)}</TableCell>
        <TableCell key={'row-2-col-2'} align="left">{updatedValue('email', props.authPerson, props.updateAuthPerson)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={3}>
        <TableCell key={'row-3-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Mobile Number</p></TableCell>
        <TableCell key={'row-3-col-1'} align="left">{currentValue('phoneNumber', props.authPerson)}</TableCell>
        <TableCell key={'row-3-col-2'} align="left">{updatedValue('phoneNumber', props.authPerson, props.updateAuthPerson)}</TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={4}>
        <TableCell key={'row-4-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Govt Issued ID</p></TableCell>
        <TableCell key={'row-4-col-1'} align="left">
          {
            cxor.decrypt(
              currentValue('aadhaarNumber', props.authPerson),
              process.env.REACT_APP_BODY_ENCRYPT_KEY
            )
          }
        </TableCell>
        <TableCell key={'row-4-col-2'} align="left">
          {
            cxor.decrypt(
              updatedValue('aadhaarNumber', props.authPerson, props.updateAuthPerson), 
              process.env.REACT_APP_BODY_ENCRYPT_KEY
            )
          }
        </TableCell>
      </TableRow>
      <TableRow hover role="checkbox" tabIndex={-1} key={5}>
        <TableCell key={'row-5-col-0'} align="left"><p style={{"fontWeight" : "bold"}}>Picture</p></TableCell>
        <TableCell key={'row-5-col-1'} align="left">
          <Avatar variant="rounded" alt="Authorized Person Picture" src= {
            props.authPerson && props.authPerson.picture && props.authPerson.picture !== 'null'
              ? process.env.REACT_APP_STRAPI_FILE_URL + props.authPerson.picture
                + '?s=' + calculateSignature(props.authPerson.picture)
              : process.env.PUBLIC_URL + "/static/images/misc/noimage.png"
            }              
            sx={{ width: 128, height: 128, border : "1px solid #ececec" }} 
          />
        </TableCell>
        <TableCell key={'row-5-col-2'} align="left">
          <Avatar variant="rounded" alt="Authorized Person Picture" src= {
            updatedValue('picture', props.authPerson, props.updateAuthPerson)
              ? process.env.REACT_APP_STRAPI_FILE_URL 
                + updatedValue('picture', props.authPerson, props.updateAuthPerson)
                + '?s=' + calculateSignature(updatedValue('picture', props.authPerson, props.updateAuthPerson))
              : process.env.PUBLIC_URL + "/static/images/misc/noimage.png"
            }              
            sx={{ width: 128, height: 128, border : "1px solid #ececec" }} 
          />
        </TableCell>
      </TableRow>
      </TableBody>
    </Table>
  </TableContainer>);
};

export default AuthPersonDetails;
