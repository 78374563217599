export const eventLogFields = [
  {  
    name: 'id',
    label: 'Event No.',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'eventType',
    label: 'Event Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'eventObject',
    label: 'Entry ID',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'owner',
    label: 'User Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'bookingId',
    label: 'Booking ID',
    type: 'string',
    properties: [{required:false}],
    actions: ['view', 'index']
  },
  {  
    name: 'operation',
    label: 'Operation Type',
    type: 'string',
    properties: [{required:true}],
    actions: ['view']
  },
  {  
    name: 'created_at',
    label: 'Event Time',
    type: 'datetime',
    properties: [{required:true}],
    actions: ['view', 'index']
  }
];


export const changeLogFields = [
  {  
    name: 'field',
    label: 'Attribute',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'index',
    label: 'Row Index',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'oldValue',
    label: 'Old Value',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'newValue',
    label: 'New Value',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  }
];
