import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import supplierFields from './supplier.fields';

import { fetchSupplierById, updateSupplier } from 'services/suppliers';
import { fetchAllSpecies } from 'services/species-details';

class EditSupplier extends Component {
  
  state = { 
    supplierData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated supplier details..." };
    
    // todo API calls
    try {
      let response = await updateSupplier(this.state.supplierData.id, params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let supplierData = null;
    let statusMessage = this.state.statusMessage;
    
    supplierFields[2].selectOptions = {};
    
    try {
      let response = await fetchSupplierById(this.props.locationParams.id);
      supplierData = response.data;
      supplierData.speciesIds = (supplierData.speciesIds || {}).id;
      
      response = await fetchAllSpecies({ page : 1, per_page : -1, sort: 'id:ASC'});
      response.data.map ( species => {
         supplierFields[2].selectOptions[species.id] = species.name;
      });
        
      statusMessage = {status: "success", message: "Fetched suppliers details successfully !"}  
      
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({supplierData, statusMessage});
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Supplier Details </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.supplierData &&
            <DynamicForm
              formFields={supplierFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              buttonText="Update Supplier Details"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.supplierData}
              redirect={'/aqf/admin/suppliers/list'}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditSupplier);