import React, { Component } from "react";
import { saveAs } from 'file-saver';

import { withLocation } from 'hoc';

import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// import TableView Component
import RTableView from 'pages/common/RTableView';

// import single entry view component
import EntryGridView from 'components/EntryGridView';

import { AuthStore } from "store";

import { permittedFields } from 'permissions';

import { 
  fetchImportAndDispatchCount, 
  fetchImportAndDispatch, 
} from 'services/booking-reports';

import { fetchSettingsByType } from 'services/common';
import { fetchAllSuppliers } from 'services/suppliers';
import { fetchAllSpecies } from 'services/species-details';

const reportFields =  [
  {
    label: 'Booking Id',
    name: 'bookingId',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Booking Date',
    name: 'fromDate',
    type : 'date',
    properties : [], actions : ['index']
  },
  {
    label: 'AQF Cons.No',
    name: 'AQFConsNo',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'Hatchery',
    name: 'hatchery',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'Supplier',
    name: 'broodstockSupplier',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'Permitted (No.s)',
    name: 'numberOfBsPermitted',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Arrival At AQF',
    name: 'lotAtAQF',
    type : 'date',
    properties : [], actions : ['index']
  },
  {
    label: 'Male Imported (No.s)',
    name: 'maleBsImported',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Female Imported (No.s)',
    name: 'femaleBsImported',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Total Imported (Nos)',
    name: 'totalImported',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Avg Size(gm)',
    name: 'averageSize',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Transit Mortality (No.s)',
    name: 'transitMortality',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Live Received(No.s)',
    name: 'liveReceived',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Quarantine Mortality (No.s)',
    name: 'quarantineMortality',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Dispatch Date',
    name: 'dateOfDispatch',
    type : 'date',
    properties : [], actions : ['index']
  },
  {
    label: 'Male Dispatched (No.s)',
    name: 'numberOfMaleBsDispatched',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Female Dispatched (No.s)',
    name: 'numberOfFemaleBsDispatched',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Total Dispatched (Nos)',
    name: 'totalDispatched',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Quarantine Survival (%)',
    name: 'quarantineSurvival',
    type : 'float',
    properties : [], actions : ['index']
  } 
 
];

const pcrFields = [
  {  
    name: 'tsvReport',
    label: 'TSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'yhvReport',
    label: 'YHV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'imnvReport',
    label: 'IMNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'ihhnvReport',
    label: 'IHHNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'bpReport',
    label: 'BP Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'wssvReport',
    label: 'WSSV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'nhpbReport',
    label: 'NHPB Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'ahpndReport',
    label: 'AHPND Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'ehpReport',
    label: 'EHP Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'div1ShivReport',
    label: 'DIV1/SHIV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'MBVReport',
    label: 'MBV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'MrNVReport',
    label: 'MrNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
  {  
    name: 'LSNVReport',
    label: 'LSNV Report',
    type: 'select',
    selectOptions : {"+ve" : "Postive", "-ve" : "Negative", "NA" : "Not Applicable"},
    default: "NA",
    properties: [{required:false}],
    actions : ['index', 'view']
  },
];

class ImportAndDispatchReport extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false, modalOpen : false, currentValues : null };
    
    let location = props.location.state;
    let fyear = new Date();
    
    const displayFields = permittedFields('app-reports', 'import-dispatch');
    this.pcrFields = Array.from(pcrFields);
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'Import And Dispatch Report',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : displayFields.length ? reportFields.filter ( x => displayFields.includes(x.name)) : reportFields,
      itemUrlPath : displayFields.includes('PCRresult') ? this.handlePCRView : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchImportAndDispatchWrapper,
      fetchCountApi : this.fetchImportAndDispatchCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'supplier',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  Suppliers --"},
        ]
      },{
        name : 'speciesType',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  Species --"},
        ]
      }, {
        name : 'startDate',
        type : 'date',
        label : 'Start Date',
        value : fyear.getMonth() < 3 ? ((parseInt(fyear.getFullYear()) - 1) + '-04-01') : (fyear.getFullYear() + '-04-01'),
      }, {
        name : 'endDate',
        type : 'date',
        label : 'End Date',
        value : fyear.getMonth() < 3 ? (fyear.getFullYear() + '-03-31') : ((parseInt(fyear.getFullYear()) + 1) + '-03-31')
      },{
        name : 'fetchReport',
        type : 'button',
        label : 'Show',
      }],
      
      // export to xl, pdf props
      exportEnable : true,
      exportEvent : this.handleExportEvent
    };
  }
  
  handlePCRView  = async (idx, entry) => {
    var pcrCount = Object.keys(entry.PCRresult).length;
    //if (entry.speciesName.toLowerCase().includes('vannamei')) {
    if (pcrCount<=10) {      
      this.pcrFields = Array.from(pcrFields);
      this.pcrFields.splice(10,3);
    } else {
      this.pcrFields = Array.from(pcrFields);
    }
    
    await this.setState({ modalOpen : true, currentValues : entry.PCRresult});
  }

  handleExportEvent = async (filters, searchValue) => {
    let response = await fetchImportAndDispatch({
      supplierId : filters['supplier'] ? filters['supplier'] : 'ALL',
      speciesType : filters['speciesType'] ? filters['speciesType'] : 'ALL',
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      download : true
    });
    
    let filename = 'Import_Dispatch_Report_' + new Date().getTime() + '.xlsx';
    
    // Download the file
    saveAs(response.data, filename);
  };
  
  fetchImportAndDispatchCountWrapper = async(filters, searchValue) => {
    let response = await fetchImportAndDispatchCount({
      supplierId : filters['supplier'],
      speciesType : filters['speciesType'],
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
    });

    return response;
  }    

  fetchImportAndDispatchWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchImportAndDispatch({
      page: page,
      per_page: perPage,
      supplierId : filters['supplier'],
      speciesType : filters['speciesType'],
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    try {
      let response = await fetchAllSuppliers({
        speciesType : 'ALL',
        status : true,
        per_page : -1,
        page : 1
      });
      
      this.tableProps.selectFilters[0].options = [{id : "ALL", value : "-- All Suppliers --"}];
      this.tableProps.selectFilters[1].options = [{id : "ALL", value : "-- All Species --"}];
      
      response.data.map( supplier => {
        this.tableProps.selectFilters[0].options.push({id : supplier.id, value : supplier.name});
      });
      
      response = await fetchAllSpecies({ page : 1, per_page : -1});
      response.data.map( species => {
        this.tableProps.selectFilters[1].options.push({id : species.id, value : species.name});
      });
      
      this.tableProps.updateContent = true;
      await this.setState({updateContent : true});
      
    } catch(error) {
      console.log(error);
    }
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <>
        {
          this.state.currentValues &&
          <Dialog maxWidth="xl" open={this.state.modalOpen} aria-labelledby="form-dialog-title">
            <DialogTitle sx= {{ display : 'flex', justifyContent : 'flex-end'}}>
              <IconButton onClick={()=> this.setState({modalOpen : false})}><CloseIcon /></IconButton>
            </DialogTitle>
            <DialogContent sx={{textAlign:"center", p:1}}> 
              <EntryGridView values={this.state.currentValues} formFields = {this.pcrFields} title="PCR Results" noPaper/>
            </DialogContent>
          </Dialog>
        }
        <RTableView {...this.props} {...this.tableProps}/>
      </>
    );
  }
}

export default withLocation(ImportAndDispatchReport);