import React, { Component } from 'react';

import { Paper, Grid, Typography, Button, Box  } from '@mui/material';

import WebcamImgCapture from 'components/WebcamImgCapture';

// Form Validation and Dynamic Forms
import { DynamicForm  } from 'components/Forms';

import { ownerFields } from '../owner.fields';

import { faceRecognitionEnrol, checkUniqueAttributes } from "services/common";

import { calculateAPISignature } from 'services/utils';

export default class HatcheryOwnerForm extends Component {

  state = { 
    capturedImages : {
      "owner" : null
    },
    capturePhotoName : null,
  };
  
  constructor(props) {
    super(props);
    
    let signature = null;
    if (this.props.currentValues && this.props.currentValues.picture) {
      signature = calculateAPISignature(encodeURIComponent(this.props.currentValues.picture));
      this.state.capturedImages.owner =  
        process.env.REACT_APP_STRAPI_FILE_URL + this.props.currentValues.picture + '?s=' + signature;
    }
    
    // if hatchery data is present, and no current values, update
    // default values
    if (!props.currentValues && props.hatcheryData) {
      ownerFields[4].default = props.hatcheryData.name;
      ownerFields[6].default = props.hatcheryData.address;
    }
  }
  
  canCapturePhoto = (name) => {
    switch(name) {
      case 'owner' : 
        return (this.state.capturePhotoName === 'owner');
      break;
      default:
        return false;
    }
  }
  
  handleCapturePhoto = async (name, value) => {
    let capturedImages = this.state.capturedImages;
    capturedImages[name] = value;
    this.setState({capturePhotoName: null, capturedImages : capturedImages});
  }
  
  handlePreFormSubmit = async (formData) => {
    let statusMessage = { status: "success" , message : ""};
    
    // some more validations here before save 
    if (!this.state.capturedImages.owner) {
      statusMessage =  { status: "error", message: "Please capture hatchery owner / incharge image" };
      return statusMessage;
    }
    
    if (formData.password !== formData.confirmPassword) {
      statusMessage =  { status: "error", message: "Confirm Password does not match with the entered password" };
      return statusMessage;
    }
    
    let validation = { isValid : true };
    
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'email',
        'value' : formData.email,
        'currentId' : this.props.hatcheryData && this.props.hatcheryData.userProfile ? this.props.hatcheryData.userProfile.id : null
      }, {
        'attribute' : 'phoneNumber',
        'value' : formData.phoneNumber,
        'currentId' : this.props.hatcheryData && this.props.hatcheryData.userProfile ? this.props.hatcheryData.userProfile.id : null
      }];
      
      let result = await checkUniqueAttributes(uniqueParams);
      
      if (result.data.email && result.data.email.unique === false) {
        validation.isValid = false;
        validation['email'] = { isInvalid : true, message : result.data.email.message };
      }
      
      if (result.data.phoneNumber && result.data.phoneNumber.unique === false) {
        validation.isValid = false;
        validation['phoneNumber'] = { isInvalid : true, message : result.data.phoneNumber.message };
      }
      
      if (!validation.isValid) {
        statusMessage =  { 
          status: "error", 
          message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
          data : validation 
        };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success"};
    
    // enrol captured images for face recognition
    let response;
    let newImageCaptured = this.state.capturedImages.owner && this.state.capturedImages.owner.includes("data:image");
    if (newImageCaptured) {
      response = await faceRecognitionEnrol(
        this.props.hatcheryData.id,
        this.state.capturedImages.owner,
        "owner"
      );
      
      if (response.status === "success") {
        formData.picture = response.fileUpload && response.fileUpload.status === "success" 
          ?  response.fileUpload.url
          : null;
      } else {
        statusMessage = { status: "error", message : response.message};
        return statusMessage;
      }
    } else if(this.props.currentValues) {
      formData.picture = this.props.currentValues.picture;
    }
    
    if (this.props.handleFormSubmit) {
      await this.props.handleFormSubmit(formData);  
    }
    
    return statusMessage;
  }
  
  
  render() {
    return (
      <Paper>
        <Grid container justifyContent="flex-start" alignItems="flex-start" direction="row">
          <Grid item xs={12} sm={9}>
            <DynamicForm
              formFields={ownerFields}
              action={this.props.currentValues ? "update" : "create"}
              handleSubmit={this.handleFormSubmit}
              preFormSubmit={this.handlePreFormSubmit}
              buttonText="Save Owner/Incharge Details"
              buttonWidth= "full"
              columns = {2}
              uploadFiles={true}
              validateOnLoad={this.props.currentValues ? true : false}
              currentValues={this.props.currentValues}
              redirect={null}
              validation = {this.props.errorsOnSubmit}
              showSubmitButton={this.props.currentValues ?true : false}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ borderLeft: '1px 0 solid', p:2, textAlign:"center"}}>
            <Box sx={{mt:2}}>
            {
              this.canCapturePhoto("owner") ?
              (<WebcamImgCapture name="owner" capturePhoto={this.handleCapturePhoto} />) :
              (<Grid container justify="center" direction="column" alignItems="center">
                <Grid item> 
                  <img src={this.state.capturedImages.owner ? this.state.capturedImages.owner : 
                  process.env.PUBLIC_URL + '/static/images/misc/dummy.png'} height="128"/>
                </Grid>
                <Grid item>
                  <Button size="small" variant="outlined" color="primary"
                    onClick={ async () => await this.setState({capturePhotoName : "owner"})}
                  > Capture Owner Photo </Button>
                </Grid>
              </Grid>)
            }
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
