import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchRolesTotalCount,
  fetchAllRoles, 
} from 'services/roles-permissions';

import rolePermissionFields from './roles-permissions.fields';

import { permissionCheck } from 'permissions';

export default class RolesPermissionsList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Roles',
      addButtonText : 'Add New Role',
      addUrl : permissionCheck('user-accounts', 'create-role-permissions') ? '/aqf/admin/role-permissions/add' : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Role Name',
      
      // table content props
      columns : rolePermissionFields,
      editUrlPath : permissionCheck('user-accounts', 'update-role-permissions') ? `/aqf/admin/role-permissions/edit/` : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchAllRolesWrapper,
      fetchCountApi : this.fetchRolesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchRolesCountWrapper = async(filters, searchValue) => {
    
    let response = await fetchRolesTotalCount({ name: searchValue });
    return response;
  }    

  fetchAllRolesWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchAllRoles({
      page: page,
      per_page: perPage,
      name: searchValue
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = `/aqf/admin/role-permissions/edit/${entry.id}`;
        entry.roleName = entry.role ? entry.role.name : '--';
        entry.description = entry.role ? entry.role.description : '--';
        // don't allow modifications to fixed roles.
        entry.editable = entry.dynamic ? true : false;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}