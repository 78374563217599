import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';

// booking schedule pages
import BookingSchedulesList from 'pages/booking-schedules/index';
import AddBookingSchedule from 'pages/booking-schedules/add';
import EditBookingSchedule from 'pages/booking-schedules/edit';

import { permissionCheck } from 'permissions';

const BookingScheduleRoutes = (props) =>  {
  console.log("loading booking-schedule routes");
  return (
    <Routes>
      <Route key="aqf-booking-schedules" path="*"
        element={
          <ProtectedComponent Component={DashboardLayout}  redirectLink='/' authCheck={props.isAuthenticated} /> 
        }
      >
        {
          permissionCheck('booking-schedules', 'view-any') &&
          <Route key="aqf-booking-schedules-list" path = "list" element = {<BookingSchedulesList />} />
        }
        {
          permissionCheck('booking-schedules', 'create') &&
          <Route key="aqf-booking-schedules-add" path = "add" element = {<AddBookingSchedule />} />
        }
        {
          permissionCheck('booking-schedules', 'update') &&
          <Route key="aqf-booking-schedules-edit" path = "edit/:date/:speciesType" element = {<EditBookingSchedule />} />
        }    
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
}

export default BookingScheduleRoutes;