import React, { Component } from 'react';

import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { approveHatcheryDetails, approveBankDetails } from "services/hatcheries";
import { approveUserDetails } from "services/user";

export default class ApprovalForm extends Component {
  constructor(props) {
    super(props);
    
    this.formFields = [
      {
        name: props.updateRequest ? 'updateRequestFlag' : 'approvalStatus',
        label: 'Approval Status',
        type: props.value ? 'string' : 'select',
        selectOptions : {},
        default : props.value,
        callback : props.value ? null : this.handleStatusChange,
        properties: [{required:true, readOnly: props.value ? true : false}],
      }
    ];
    
    if (this.formFields[0].type === 'select') {
      this.formFields[0].selectOptions = {
        "Approved" : "Approved",
        "Rejected" : "Rejected"
      }
    }
    
    if (props.value && props.value === 'Rejected') {
      this.formFields.push({
        name : 'rejectReason',
        label : 'Reject Reason',
        type: 'string',
        properties: [{ required:true}, {string : { min : 10}}], 
      });
    }
  }

  
  handleStatusChange = async (name, value, formData, asyncCb) => {
    if (value === 'Rejected') {
      this.formFields.push({
        name : 'rejectReason',
        label : 'Reject Reason',
        type: 'string',
        properties: [{ required:true}, {string : { min : 10}}], 
      });
    } else {
      let idx = this.formFields.findIndex( f => f.name === 'rejectReason');
      if (idx >= 0) {
        this.formFields.splice(idx, 1);
      }
    }
    
    return { fields : this.formFields, values : null};
  }    

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Approval status submitted successfully !!" };
    let response;
    
    try {
      switch (this.props.type) {
        case 'hatchery':
        case 'license':
          response = await approveHatcheryDetails(this.props.entity, params);
          break;
        case 'authPerson':
          response = await approveUserDetails(this.props.entity, params);
          break;
        case 'bank':
          response = await approveBankDetails(this.props.entity, params);
          break; 
        default: break;
      }
    } catch (error) {
      statusMessage = { status : "error", message : error.message };
    }
    return statusMessage;
  }
  
  render() {
    let title = null;
    switch (this.props.type) {
      case 'hatchery':
        title = this.props.updateRequest ? "Hatchery Changes Approval" : "Hatchery Approval";
        break;
      case 'authPerson':
        title = "Approve Authorized Persons";
        break;
      case 'bank':
        title = "Approve Bank Details";
        break;
      case 'license':
        title = 'License Renewal Approval';
        break;
      default: break;
    }
      
    return (<Paper elevation={4} sx={{textAlign : "center", p:1}}>
      <Typography variant="h4"> {title}</Typography> 
      <br/><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={this.formFields}
        handleSubmit={this.handleFormSubmit}
        buttonText="Save Approval Status"
        buttonWidth= "full"
        columns = {1}
        redirect={this.props.updateRequest ? 
          '/aqf/hatcheries/approvals/hatcherychanges' : 
          '/aqf/hatcheries/approvals/hatcheries'
        }
        confirmation={"Are you sure you want to submit the details ? "}
      />
    </Paper>);
  }
}