import React, { Component } from 'react';

import { withLocation } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import userAccountFields from './user-accounts.fields';

import { fetchUserAccountById, updateUserAccountSelf} from 'services/user';
import { checkUniqueAttributes } from 'services/common';

import { AuthStore } from 'store';

class UpdateAccount extends Component {
  
  state = { 
    userData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
    
    this.formFields = [
      {  
        name: 'name',
        label: 'Display Name',
        type: 'string',
        properties: [{required:false}, {string : true}],
        actions: ['create', 'update', 'view', 'index']
      },{  
        name: 'email',
        label: 'Registered Email Address',
        type: 'email',
        properties: [{required:false, email : true}],
        actions : ['view', 'create', 'update', 'index'],
      },{  
        name: 'password',
        label: 'Password',
        type: 'password',
        properties: [{required:false, strongPassword : true}],
        actions : ['create', 'update',],
      }, {  
        name: 'confirmPassword',
        label: 'Confirm Password',
        type: 'password',
        properties: [{required:false}],
        actions : ['create', 'update',],
      }
    ];
    
    // don't allow hatchery owners to upload pic or update mobile number here
    if (AuthStore.state.currentUser &&  
      AuthStore.state.currentUser.user.role.name !== 'Hatchery Owner') {
      this.formFields.push({  
        name: 'phoneNumber',
        label: 'Mobile Number',
        type: 'string',
        properties: [{required:true, mobile:true}, {string : { min : 10, max : 10}}],
        actions: ['create', 'update', 'view', 'index']
      },{  
        name: 'picture',
        label: 'Picture',
        type: 'image',
        type : 'file',
        properties : [{required : false}, {filesize: { min: 0, max: 5 } }, {filetype: ["jpeg", "png", "jpg", "PNG", "JPEG", "JPG"]}],
        actions : ['view', 'create', 'update', 'index'],
      });
    }
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
  
    // check uniqueness of the attributes
    try {
      let uniqueParams = [];
      
      if (formData.email) {
        uniqueParams.push({
          'attribute' : 'email',
          'value' : formData.email,
          'currentId' : this.props.location.state.id
        });
      }
      
      if (formData.phoneNumber) {
        uniqueParams.push({
          'attribute' : 'phoneNumber',
          'value' : formData.phoneNumber,
          'currentId' : this.props.location.state.id
        });
      }
      
      if (uniqueParams.length) {
        let result = await checkUniqueAttributes(uniqueParams);
        
        if (result.data.email && result.data.email.unique === false) {
          validation.isValid = false;
          validation['email'] = { isInvalid : true, message : result.data.email.message };
        }
        if (result.data.phoneNumber && result.data.phoneNumber.unique === false) {
          validation.isValid = false;
          validation['phoneNumber'] = { isInvalid : true, message : result.data.phoneNumber.message };
        }
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated account details. Changed credentials are applicable from next login.." };
    
    let formData = params;
    
    if (formData.password === "DummyP@123" || formData.password === "") {
      delete formData.password;
      delete formData.confirmPassword;
    }
    
    if (formData.password && formData.password !== formData.confirmPassword) {
      return ({ status : "error", message : "Confirm Password does not match with the entered password", 
        data : {
          isValid: false,
          confirmPassword : { isInvalid : true, message : "Confirm Password does not match with the entered Password"},
        }
      });
    }
    
    // todo API calls
    try {
      let response = await updateUserAccountSelf(this.props.location.state.id, formData);
    } catch(error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let userData = null;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchUserAccountById(this.props.location.state.id);
      userData = response.data;
      // fill required info for form from nested data
      userData.email = userData.user.email;
      userData.password = "DummyP@123";
      userData.confirmPassword = "DummyP@123";
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({userData, statusMessage});
  }

  render() {
    return (
      <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
        <Typography variant="h4"> Update My Profile </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.userData &&
          <DynamicForm
            formFields={this.formFields}
            action="update"
            preFormSubmit={this.handlePreFormSubmit}
            handleSubmit={this.handleFormSubmit}
            buttonText="Update Account Details"
            buttonWidth= "full"
            columns = {2}
            currentValues={this.state.userData}
            redirect={null}
            confirmation={"Are you sure you want to save the details ? "}
          />
        }
      </Paper>
    );
  }
}

export default withLocation(UpdateAccount);