import React, { useState, useEffect } from 'react';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';

import { Paper, Typography, Dialog, DialogContent, Button, Box, Divider } from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

var timerHandle = null;

export default function IdleTimeout(props) {
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [offline, setOffline] = useState(false);
  
  window.addEventListener('online', () => setOffline(false));
  window.addEventListener('offline', () => setOffline(true));
  
  const handleOnIdle = async event => {
    // console.log('last active', getLastActiveTime())
    setTimeoutModal(true);
  }

  const handleOnActive = event => {
    // reset();
    // setTimeoutModal(false);
    // console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    // reset();
    // setTimeoutModal(false)
  }
  
  const handleUserAction = async (logout) => {
    if (logout) {
      await props.handleIdleTimeout();
    } else {
      reset();
    }
    setTimeoutModal(false);
  }
  
  // Time is in milliseconds
  const { getTotalIdleTime, getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
      timeout: props.timeout ? props.timeout : (1000 * 60 * 15),
      onIdle: handleOnIdle,
      onActive: handleOnActive,
      onAction: handleOnAction,
      debounce: 5000
  });
  
  useEffect(() => {
    if (timeoutModal) {
      // start 10 second timer for user to take action
      console.log("starting 10 second timer " + timeoutModal);
      timerHandle = setInterval ( async () => {
        reset();
        console.log("10 second timer expired " + timeoutModal);
        if (timeoutModal) {
            let response = await props.handleIdleTimeout();
            if (response.status === 200) {
                clearInterval(timerHandle);
            }
        } else {
          if (timerHandle) clearInterval(timerHandle);
        }
      }, 10000);
    } else {
      if (timerHandle) clearInterval(timerHandle);
      reset();
    }
  }, [timeoutModal]);

  useEffect(() => {
    if (offline) {
      // reset the 10 second timer, until network is available
      if (timerHandle) clearTimeout(timerHandle);
    } else if (timeoutModal) {
      console.log("starting 10 second timer " + timeoutModal);
      timerHandle = setTimeout ( async () => {
        reset();
        console.log("10 second timer expired " + timeoutModal);
        if (timeoutModal) await props.handleIdleTimeout();
      }, 10000);
    }
  }, [offline]);
  
  return (  
    <Dialog open={timeoutModal || offline} aria-labelledby="form-dialog-title">
      <DialogContent sx={{textAlign:"center", p:2}}> 
        <WarningAmberIcon color="warning" sx={{ mr : 2, fontSize : 64, fontWeight : 300 }} />
 		{
          offline &&
          <Box>
            <Typography variant="subtitle1" sx={{ verticalAlign: 'middle',  display: 'inline-flex'}}> 
              You appear to be in offline mode. Please check your internet connection...
            </Typography>
          </Box>
        }
        {
          offline == false && timeoutModal && 
          <>
            <Box>
              <Typography variant="subtitle1" sx={{ verticalAlign: 'middle',  display: 'inline-flex'}}> 
                Your session is about to expire in 10 seconds due to inactivity. You will be redirected to the login page 
                if inactivity continues
              </Typography>
            </Box>
            <Divider light={true} sx={{mt :2 }}/>
            <Box sx={{mt: 2}} display="flex" justifyContent="flex-end">
              <Button color="info" onClick={(e) => handleUserAction(false) } 
                size="small" variant="outlined"> 
                Continue
              </Button>
              <Button color="error" onClick={ (e) => handleUserAction(true)} 
                size="small" variant="outlined" sx={{ ml: 2}} > 
                Logout
              </Button>
            </Box> 
          </>
        }
      </DialogContent>
    </Dialog>
  );
}
