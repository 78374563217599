import React, { Component } from 'react';

import { Alert } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicTableForm } from 'components/Forms';

import { bankFields } from '../hatchery.fields';

import { checkUniqueAttributes } from 'services/common';

export default class BankDetailsForm extends Component {
  
  state = { 
    bankDetails : this.props.currentValues ? this.props.currentValues : [], 
    valid : false 
  };
  
  constructor(props) {
    super(props);
  }
  
  handleBankDetailsChange = async (table, validation) => {
    if (validation && validation.length) {
      // check if all table rows are valid
      await this.setState({bankDetails : table, valid : !Boolean(validation.find(v => v.isValid === false))});
    } else {
      await this.setState({bankDetails : table, valid : false});
    }
  }
  
  handlePreFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "" };
    let validation = [];
    
    if (this.state.bankDetails.length > 1) {
      if (this.state.bankDetails[0].accountNumber === this.state.bankDetails[1].accountNumber && 
          this.state.bankDetails[0].IFSC === this.state.bankDetails[1].IFSC) {
        statusMessage = { status: "error", message: "Cannot allow same account number and IFSC for the two bank accounts" };
        return statusMessage;
      }
    }
      
    // check uniqueness of the attributes
    for (let i = 0; i < this.state.bankDetails.length; ++i) {
      try {
        let uniqueParams = [{
          'attribute' : 'bankAccount',
          'value' : { 'accountNumber' : this.state.bankDetails[i].accountNumber, 'IFSC' : this.state.bankDetails[i].IFSC },
          'currentId' : null
        }];
        
        let rowValidation = { isValid : true };
        
        let result = await checkUniqueAttributes(uniqueParams);
        
        if (result.data.bankAccount && result.data.bankAccount.unique === false) {
          rowValidation.isValid = false;
          rowValidation['accountNumber'] = { isInvalid : true, message : result.data.bankAccount.message };
        }
        
        validation.push(rowValidation);
      
      } catch(error) {
        statusMessage =  { status: "error", message: error.message };
      }
    }
    
    if (Boolean(validation.find(v => v.isValid === false))) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        tableData : validation 
      };
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData, tableData) => {
    let statusMessage = { status: "success"};
    if (this.props.handleFormSubmit) {
      await this.props.handleFormSubmit(tableData);
    }
    return statusMessage;
  }


  render() {
    return (
    <>
      <Alert severity="info" sx={{ m : 2 }} variant="filled">
        Please enter bank account number along with all the leading zeros
      </Alert>
      <DynamicTableForm 
          tableFormProps = {{
            fields : bankFields,
            handleTableDataChange : this.handleBankDetailsChange,
            serialNoHeader : 'S.No',
            deleteRows: this.props.currentValues ? "none" : "last",
            uploadFiles : true,
            maxRows : 2,
            minRows : 1,
            currentValues : this.props.currentValues,
            validation : this.props.errorsOnSubmit
          }}
          uploadFiles={true}
          action={this.props.currentValues ? "update" : "create"}
          buttonText="Save Bank Details"
          preFormSubmit={this.handlePreFormSubmit}
          handleSubmit={this.handleFormSubmit}
        />
      </>
    );
  }
}
