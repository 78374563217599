import React, { Component } from "react";
import { saveAs } from 'file-saver';

import { withLocation } from 'hoc';

// import TableView Component
import RTableView from 'pages/common/RTableView';

import { AuthStore } from "store";
import { permittedFields } from 'permissions';

import { 
  fetchHatcheriesByFyearCount, 
  fetchHatcheriesByFyear,
} from 'services/hatcheries';

import { fetchSettingsByType } from 'services/common';
import { fetchAllSpecies } from 'services/species-details';

import { hatcheryFields } from 'pages/hatcheries/hatchery.fields';

class FyWiseHatcheryReport extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    let location = props.location.state;
    let currentYear = new Date();
    
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    const filterValues = range(currentYear.getFullYear() - 10, currentYear.getFullYear() + 5, 1).map( year => {
      return {'id' : year, 'value' : "FY " + year + "-" + (parseInt(year) + 1)};
    });
    
    const displayFields = permittedFields('hatchery', 'view-any');
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'Financial Year Wise Hatcheries Report',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Hatchery Name',
      
      // table content props
      columns : displayFields.length ? hatcheryFields.filter( x => displayFields.includes(x.name) ) : hatcheryFields,
      itemUrlPath : '/aqf/hatcheries/view/',
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchHatcheriesDataWrapper,
      fetchCountApi : this.fetchHatcheriesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'fyear',
        type : 'select',
        label : 'Select FY',
        value : currentYear.getMonth() < 3 ? currentYear.getFullYear() - 1 : currentYear.getFullYear(),
        options : filterValues
      }, {
        name : 'state',
        type : 'select',
        label : 'Filter By State',
        value : 'ALL',
        options : [
          {'id' : 'ALL', 'value' : '-- All  States --'}
        ]
      }, {
        name : 'speciesType',
        type : 'select',
        label : 'Filter By Species',
        value : 'ALL',
        options : [
          {'id' : 'ALL', 'value' : '-- All  Species --'}
        ]
      },{
        name : 'fetchReport',
        type : 'button',
        label : 'Show',
      }],
      
      // export to xl, pdf props
      exportEnable : true,
      exportEvent : this.handleExportEvent
    };
  }

  handleExportEvent = async (filters, searchValue) => {
    const validTo = filters['fyear'] + '-04-01';
    const validFrom = `${(parseInt(filters['fyear']) + 1)}-03-31`;
    let response = await fetchHatcheriesByFyear({
      validFrom : validFrom,
      validTo : validTo,
      name: searchValue ? searchValue : null,
      state : filters['state'],
      speciesType : filters['speciesType'],
      download : true
    });
    false
    let filename = 'FY_Wise_Hatcheries_Report_' + new Date().getTime() + '.xlsx';
    
    // Download the file
    saveAs(response.data, filename);
  };
  
  fetchHatcheriesCountWrapper = async(filters, searchValue) => {
    const validTo = filters['fyear'] + '-04-01';
    const validFrom = `${(parseInt(filters['fyear']) + 1)}-03-31`;
    
    let response = await fetchHatcheriesByFyearCount({
      validFrom : validFrom,
      validTo : validTo,
      name: searchValue ? searchValue : null,
      state : filters['state'],
      speciesType : filters['speciesType']
    });
    
    return response;
  }    

  fetchHatcheriesDataWrapper = async (page, perPage, filters, searchValue) => {
    const validTo = filters['fyear'] + '-04-01';
    const validFrom = `${(parseInt(filters['fyear']) + 1)}-03-31`;
    
    let response = await fetchHatcheriesByFyear({
      page: page,
      per_page: perPage,
      validFrom : validFrom,
      validTo : validTo,
      name: searchValue ? searchValue : null,
      state : filters['state'],
      speciesType : filters['speciesType']
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.updateRequestFlag = entry.updateRequestFlag ? entry.updateRequestFlag : 'Approved';
        entry.url= '/aqf/hatcheries/view/' + entry.id;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    
    this.tableProps.selectFilters[1].options = [{id : "ALL", value : "-- All  States --"}];
    this.tableProps.selectFilters[1].value = 'ALL';
    this.tableProps.selectFilters[2].options = [{id : "ALL", value : "-- All  Species --"}];
    this.tableProps.selectFilters[2].value = 'ALL';
    
    try {
      let response = await fetchSettingsByType({type : 'STATE', name : 'STATE'});
      response.data.map ( state => {
         this.tableProps.selectFilters[1].options.push({id : state.id, value : state.value});
      });
      
      response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      response.data.map ( species => {
         this.tableProps.selectFilters[2].options.push({id : species.id, value : species.name});
      });   
    } catch(error) {
      console.log(error);
    }
    
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }

  render() {
    return (
      <RTableView {...this.props} {...this.tableProps}/>
   );
  }
}

export default withLocation(FyWiseHatcheryReport);
