import React, { Component } from 'react';
import { withLocationParams, withPrint } from 'hoc';

import { Paper, Grid, Typography, Tabs, Tab, Button, Box } from '@mui/material';

// import view components 
import { HatcheryBasicDetails } from './view-components';

import {
  AuthPersonDetails,
  BankDetails,
  LicenseDetails
} from './approval-components';

import ApprovalForm from './form-components/ApprovalForm';
import LicenseForm from './form-components/LicenseForm';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

// import services
import { fetchHatcheryById } from 'services/hatcheries';

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import { permissionCheck } from 'permissions';

class ViewHatcheryChanges extends Component {

  state = { 
    hatcheryData : null, 
    activeTab: "License Renewal",
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."},
    printView: false,
    licenseReject : false
  };
  
  isHatcheryChanged = (hatcheryData) => {
    return (hatcheryData.updateRequestFlag === 'ApprovalPending' && hatcheryData.updateRequestDetails);
  }
  
  isAuthPersonChanged = (hatcheryData) => {
    return (hatcheryData.userProfile.updateRequestFlag === 'ApprovalPending' && hatcheryData.userProfile.updateRequestDetails);
  }
  
  isBankDetailsChanged = (hatcheryData, idx) => {
    if (hatcheryData.bankDetails && hatcheryData.bankDetails[idx]) {
      return (hatcheryData.bankDetails[idx].updateRequestFlag === 'ApprovalPending' && 
        hatcheryData.bankDetails[idx].updateRequestDetails);
    }
  }
  
  async componentDidMount() {
    
    let statusMessage = this.state.statusMessage;
    let hatcheryData = {};
    let activeTab = this.state.activeTab;
    try {
      
      let response = await fetchHatcheryById(this.props.locationParams.id);
      hatcheryData = response.data;
      statusMessage = {status: "success", message: "Fetched hatchery details successfully !"}  
      
      // show only changed tabs and set correct active tab
      if (this.isHatcheryChanged(hatcheryData)) {
        activeTab = 'License Renewal';
      } else if (this.isAuthPersonChanged(hatcheryData)) {
        activeTab = 'Authorized Persons';
      } else if (this.isBankDetailsChanged(hatcheryData, 0)) {
        activeTab = 'Bank Accounts 1';
      } else if (this.isBankDetailsChanged(hatcheryData, 1)) {
        activeTab = 'Bank Accounts 2';
      }
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    
    this.setState({hatcheryData, statusMessage, activeTab});
  }

  handlePrint = () => {
    this.setState({printView : true});
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
  }
  
  render() {
    
    return (<>
      {
        this.props.locationParams && this.state.statusMessage &&
        <CustomSnackbar variant={this.state.statusMessage.status}
          message={this.state.statusMessage.message}
          open={this.state.statusMessage.status}
          onClose={async () => await this.setState({ statusMessage: null })}
        />
      }
      
      {
        this.state.printView === false &&
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
            <Button
              variant="outlined" 
              color="warning" 
              size="small"
              startIcon={<PrintOutlinedIcon />}
              onClick={this.handlePrint}
            > 
              Print 
            </Button>
          </Box>  
          <NavigateBack />
        </Grid>
      }

      <Paper elevation={3}  sx={{textAlign:"center", p:2, m:1}}>
        <Typography variant="h3"> 
          View Hatchery Change Request{this.state.hatcheryData ? ` - ${this.state.hatcheryData.name}` : ''} 
        </Typography>
      </Paper>
      {
        this.state.hatcheryData && 
        <Grid container direction="row" justifyContent="space-between" spacing ={2}>
          <Grid item sm={8} xs={12}>
            <HatcheryBasicDetails hatcheryData={this.state.hatcheryData} />
          </Grid>
        </Grid>
      }
      {
        this.state.hatcheryData && this.state.hatcheryData.userProfile &&
        <>
          <Paper elevation={3} sx={{textAlign:"center", m:1}}>  
            <Tabs
              value={this.state.activeTab}
              onChange={async (event, newValue) => await this.setState({activeTab : newValue}) }
              textColor="primary"
              indicatorColor="secondary"
              aria-label="License Renewal"
              variant="fullWidth"
            >
            {
              this.isHatcheryChanged(this.state.hatcheryData) && this.state.printView === false &&
              <Tab value="License Renewal" label="License Renewal" />
            }
            {
              this.isAuthPersonChanged(this.state.hatcheryData) && this.state.printView === false &&
              <Tab value="Authorized Persons" label="Authorized Person Changes" />
            }
            {
              this.isBankDetailsChanged(this.state.hatcheryData, 0) && this.state.printView === false &&
              <Tab value="Bank Accounts 1" label="Bank Account# 1 Changes" />
            }
            {
              this.isBankDetailsChanged(this.state.hatcheryData, 1) && this.state.printView === false &&
              <Tab value="Bank Accounts 2" label="Bank Account# 2 Changes" />
            }
            </Tabs>
          </Paper>
          {
            this.isHatcheryChanged(this.state.hatcheryData) && 
            (this.state.activeTab === "License Renewal" || this.state.printView) &&
            <div role="tabpanel" hidden={this.state.activeTab !== "License Renewal" && this.state.printView === false} 
              id="1" aria-labelledby="hatchery-details">
              <Grid container direction="row" justifyContent="space-between" sx={{p:1}} spacing={1} alignItems="flex-start">
                <Grid item sm={3} xs={12} sx={{p:2, textAlign:"center"}}>
                  {
                    this.state.printView &&
                    <><Typography variant="h5"> License Renewal </Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
                  }
                  <Paper elevation={3} sx={{textAlign:"center", p : 2}}>
                    <LicenseDetails hatcheryData={this.state.hatcheryData} />
                    <hr style={{ my : 2, borderColor:"#eef1f6"}} />
                    {
                      this.state.hatcheryData.updateRequestDetails.updated_at &&
                      <Typography variant="caption"> Document Upload Time :
                      { 
                        new Date(this.state.hatcheryData.updateRequestDetails.updated_at )
                          .toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                      }
                      </Typography>
                    }
                    <br /><br />
                    {
                      permissionCheck('hatchery', 'approve-changes') && !this.state.licenseReject &&
                      <Button 
                        variant="contained" 
                        color="warning"
                        size="small"
                        startIcon={<ThumbDownOffAltIcon />}
                        onClick={() => this.setState({licenseReject:true})}
                      > 
                        Reject Document 
                      </Button>
                    }
                    {
                      permissionCheck('hatchery', 'approve-changes') && this.state.licenseReject &&
                      <Button 
                        variant="contained" 
                        color="primary"
                        size="small"
                        startIcon={<ThumbUpOffAltIcon />}
                        onClick={() => this.setState({licenseReject:false})}
                      > 
                        Accept Document 
                      </Button>
                     
                    }
                  </Paper>
                </Grid>
                {
                  this.state.printView === false &&
                  <Grid item sm={9} xs={12} sx={{p:2, textAlign:"center"}}>
                    <Paper elevation={3} sx={{textAlign:"center"}}>
                    {
                      !this.state.licenseReject &&
                      <LicenseForm 
                        hatcheryData = {this.state.hatcheryData }
                        currentValues = { this.state.hatcheryData.updateRequestDetails}
                      />
                    }
                    {
                      this.state.licenseReject &&
                      <ApprovalForm value="Rejected" updateRequest = {true} type="license" entity={this.state.hatcheryData.id} />
                    }
                    </Paper>
                  </Grid>
                }
              </Grid>
            </div>
          }
          {
            this.isAuthPersonChanged(this.state.hatcheryData) && 
            (this.state.activeTab === "Authorized Persons" || this.state.printView) &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Authorized Persons" && this.state.printView === false} 
              id="2" aria-labelledby="authperson-details">
              <Grid container direction="row" justifyContent="space-between" sx={{p:1}} spacing={1}>
              {
                this.state.hatcheryData.userProfile.updateRequestDetails.authPerson1 &&
                <Grid item sm={8} xs={12} sx={{p:1}}>
                  <Paper elevation={3} sx={{textAlign:"center", p:1}}>
                    <Typography variant="h4"> Authorized Person# 1 Changes</Typography><br/> <hr style={{borderColor:"#eef1f6"}} />
                    {
                      this.state.hatcheryData.userProfile.updateRequestDetails.authPerson1.updated_at &&
                      <Typography variant="subtitle2" align="right"> Updated Time :
                      { 
                        new Date(this.state.hatcheryData.userProfile.updateRequestDetails.authPerson1.updated_at )
                          .toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                      }
                      </Typography>
                    }
                    <AuthPersonDetails authPerson={this.state.hatcheryData.userProfile.authPerson1} 
                      updateAuthPerson = {this.state.hatcheryData.userProfile.updateRequestDetails.authPerson1}  />
                  </Paper>
                </Grid>
              }
                <Grid item sm={4} xs={12} sx={{p:1, textAlign:"center"}}>
                  {
                    this.state.printView === false && 
                    <ApprovalForm updateRequest = {true} type="authPerson" entity={this.state.hatcheryData.userProfile.id}/>
                  }
                </Grid>
              {
                this.state.hatcheryData.userProfile.updateRequestDetails.authPerson2 &&
                <Grid item sm={8} xs={12} sx={{p:1}}>
                  <Paper elevation={3} sx={{textAlign:"center", p:1}}>
                    <Typography variant="h4"> Authorized Person# 2 Changes</Typography><br/> <hr style={{borderColor:"#eef1f6"}} />
                    {
                      this.state.hatcheryData.userProfile.updateRequestDetails.authPerson2.updated_at &&
                      <Typography variant="subtitle2" align="right"> Updated Time :
                      { 
                        new Date(this.state.hatcheryData.userProfile.updateRequestDetails.authPerson2.updated_at )
                          .toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                      }
                      </Typography>
                    }
                    <AuthPersonDetails authPerson={this.state.hatcheryData.userProfile.authPerson2}
                      updateAuthPerson = {this.state.hatcheryData.userProfile.updateRequestDetails.authPerson2} />
                  </Paper>
                </Grid>
              }
              </Grid>
            </div>
          }
          {
            this.isBankDetailsChanged(this.state.hatcheryData, 0) && 
            (this.state.activeTab === "Bank Accounts 1" || this.state.printView) &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Bank Accounts 1" && this.state.printView === false}
              id="3" aria-labelledby="bank-accounts-1">
              <Grid container direction="row" justifyContent="space-between" sx={{p:1}} spacing={1}>
                <Grid item sm={8} xs={12}>
                  <Paper elevation={3} sx={{textAlign:"center", p:1}}>
                    {
                      this.state.printView &&
                      <><Typography variant="h5"> Bank Account #1 Changes</Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
                    }
                    {
                      this.state.hatcheryData.bankDetails[0].updated_at &&
                      <Typography variant="subtitle2" align="right">Updated Time:
                      { 
                        new Date(this.state.hatcheryData.bankDetails[0].updated_at )
                          .toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                      }
                      </Typography>
                    }
                    <BankDetails bankDetails={this.state.hatcheryData.bankDetails[0]}  />
                  </Paper>
                </Grid>
                <Grid item sm={4} xs={12} sx={{textAlign:"center"}}>
                  {
                    this.state.printView === false && 
                    <ApprovalForm updateRequest = {true} type="bank" entity={this.state.hatcheryData.bankDetails[0].id}/>
                  } 
                </Grid>
              </Grid>
            </div>
          }
          {
            this.isBankDetailsChanged(this.state.hatcheryData, 1) &&
            (this.state.activeTab === "Bank Accounts 2" || this.state.printView) &&
            <div role="tabpanel" hidden={this.state.activeTab !== "Bank Accounts 2" && this.state.printView === false}
              id="4" aria-labelledby="bank-accounts-2">
              <Grid container direction="row" justifyContent="space-between" sx={{p:1}} spacing={1}>
                <Grid item sm={8} xs={12}>
                  <Paper elevation={3} sx={{textAlign:"center", p:1}}>
                    {
                      this.state.printView &&
                      <><Typography variant="h5"> Bank Account #2 Changes</Typography><br/><hr style={{borderColor:"#eef1f6"}} /></>
                    }
                    {
                      this.state.hatcheryData.bankDetails[1].updated_at &&
                      <Typography variant="subtitle2" align="right">Updated Time:
                      { 
                        new Date(this.state.hatcheryData.bankDetails[1].updated_at )
                          .toLocaleString('en-GB', {'timezone' : 'Asia/Kolkata'})
                      }
                      </Typography>
                    }
                    <hr style={{borderColor:"#eef1f6"}} />
                    <BankDetails bankDetails={this.state.hatcheryData.bankDetails[1]}  />
                    
                  </Paper>
                </Grid>
                <Grid item sm={4} xs={12} sx={{textAlign:"center"}}>
                  {
                    this.state.printView === false && 
                    <ApprovalForm updateRequest = {true} type="bank" entity={this.state.hatcheryData.bankDetails[1].id} />
                  }
                </Grid>
              </Grid>
            </div>
          }
        </>
      }
    </>);
  }
}

export default withPrint(withLocationParams(ViewHatcheryChanges));
