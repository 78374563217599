import React, { Component } from 'react';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from 'layout/GlobalStyles';
import 'mixins/chartjs';
import theme from 'theme';
import AppRoutes from 'routes';

export default class App extends Component {  
  render() {
    return (
      <ThemeProvider theme={ theme } >
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <GlobalStyles />
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}