import { useState } from 'react';

import { Paper, 
  Grid, 
  Button, 
  Box,  
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  MenuList,
  MenuItem,
  ListItemIcon,
  IconButton
} from '@mui/material';

import {
  ListAltOutlined,
  LocalDrinkOutlined,
  GrainOutlined,
  GradientOutlined,
  TrendingUpOutlined,
  BloodtypeOutlined,
  ExtensionOutlined,
  ApprovalOutlined,
  LocalShippingOutlined,
  CancelOutlined,
  FilePresentOutlined,
  PersonAddAltOutlined,
  MonetizationOnOutlined,
  FlightLandOutlined,
  BiotechOutlined,
  StickyNote2Outlined,
  AssignmentTurnedIn,
  Close
} from '@mui/icons-material';

// import booking form components
import CancellationForm from 'pages/bookings/form-components/CancellationForm';
import EditSupplierForm from 'pages/bookings/form-components/EditSupplierForm';
import ShipmentForm from 'pages/bookings/shipment-components/ShipmentForm';
import NoCForm from 'pages/bookings/shipment-components/NoCForm';
import AuthPersonForm from 'pages/bookings/form-components/AuthPersonForm';
import AddNewAddnCharges  from 'pages/additional-charges/add';
import CubicleStocking from 'pages/bookings/form-components/CubicleStocking';
import AddSampleCollection from 'pages/sample-collection/add';

// report components
import AddNewBookingReport from 'pages/booking-reports/add';

// import permissions for role based ops
import { permissionCheck } from 'permissions';

const PreArrivalOperations = (props) => {

  let species_type="";
  if (props.bookingData && props.bookingData.speciesType)
  {
    species_type = props.bookingData.speciesType.type;
  }
    
  return (<>
    <Typography variant="h5"> {species_type} Pre-Arrival Booking Updates </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
    <MenuList>
    { 
      permissionCheck('booking', 'cancel') &&
      <MenuItem key = {1} 
        onClick={(e) => props.handleBookingOps("cancel")}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <CancelOutlined color="error" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Cancel Booking</Typography>
      </MenuItem>
    }
    { 
      (permissionCheck('booking', 'update-self') || permissionCheck('booking', 'update')) &&
      <MenuItem key = {1} 
        onClick={(e) => props.handleBookingOps("update-supplier")}
        disabled={['ApprovalPending', 'Approved'].includes(props.bookingData.shipmentApproval)}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <LocalShippingOutlined color="secondary" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Update Supplier</Typography>
      </MenuItem>
    }
    {
      (permissionCheck('shipment', 'add-shipment-self') || permissionCheck('shipment', 'add-shipment-any')) &&
      <MenuItem key={2} onClick={(e) => props.handleBookingOps("shipment")} 
        disabled={props.bookingData.status !== 'Confirmed' || props.bookingData.shipmentApproval === 'ApprovalPending' || props.bookingData.shipmentApproval === 'Approved'}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <FilePresentOutlined fontSize="medium" color="primary" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Upload Shipment Documents</Typography>
      </MenuItem>
    }
    {
      permissionCheck('shipment', 'add-authperson-self') &&
      <MenuItem key={3} 
        onClick={(e) => props.handleBookingOps("authPerson")} 
        disabled={props.bookingData.status !== 'Confirmed'}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <PersonAddAltOutlined fontSize="medium" color="secondary" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Authorised Person For Receiving BS</Typography>
      </MenuItem>
    }
    {
      (permissionCheck('shipment', 'add-noc-any') || permissionCheck('shipment', 'add-noc-self')) &&
      <MenuItem key={4} onClick={(e) => props.handleBookingOps("noc")} 
        disabled={props.bookingData.status !== 'Confirmed' || props.bookingData.shipmentApproval === 'ApprovalPending' || props.bookingData.shipmentApproval === 'Approved'}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <AssignmentTurnedIn fontSize="medium" color="success" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Upload NoC Document</Typography>
      </MenuItem>
    }
    {
      permissionCheck('transaction', 'create-additional-charges') &&
      <MenuItem key={5} 
        onClick={(e) => props.handleBookingOps("addn-charges")} disabled={props.bookingData.status !== 'Confirmed'}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <MonetizationOnOutlined fontSize="medium" color="secondary" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Add Additional Charges</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-received-sample-report') &&
      <MenuItem key={6} onClick={(e) => props.handleBookingOps("received-sample-report")} 
        disabled={
          props.bookingData.status !== 'Confirmed' ||
          props.bookingData.shipmentApproval !== 'Approved' || 
          (props.bookingData.documents && props.bookingData.documents.arrivalReport ? true : false)
        }
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <FlightLandOutlined fontSize="medium" color="error" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Add Arrival Report</Typography>
      </MenuItem>
    }
    </MenuList>
  </>);
};

const PostArrivalOperations = (props) => {
  
  let cubicles = new Set();
  if (props.stockingData) {
    props.stockingData.map (x => cubicles.add(x.number));
  }

  let species_type="";
  if (props.bookingData && props.bookingData.speciesType)
  {
    species_type = props.bookingData.speciesType.type;
  }
    
  
  return (<>
    <Typography variant="h5"> {species_type} Monitoring And Reports </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
    <MenuList>
    {
      permissionCheck('transaction', 'create-additional-charges') &&
      <MenuItem key={5} 
        onClick={(e) => props.handleBookingOps("addn-charges")} disabled={props.bookingData.status !== 'Confirmed'}
        divider={true}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <MonetizationOnOutlined fontSize="medium" color="secondary" />
        </ListItemIcon>
        <Typography variant="button" sx={{mx : 2}} >Add Additional Charges</Typography>
      </MenuItem>
    }
    { 
      permissionCheck('booking', 'create-cubicle-stocking') &&
      <MenuItem key = {1} 
        onClick={(e) => props.handleBookingOps("cubicle-stocking")}
        divider={true}
        disabled={props.labAnalysis === true || (cubicles.size >= props.bookingData.cubicleDetails.length)}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <ListAltOutlined color="primary" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Cubicle Stocking Data</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-water-quality-analysis') &&
      <MenuItem key = {2} 
        onClick={(e) => props.handleBookingOps("water-quality-analysis")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <LocalDrinkOutlined color="secondary" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Water Quality Report</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-feeding-report') &&
      <MenuItem key = {3} 
        onClick={(e) => props.handleBookingOps("feeding-report")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <GrainOutlined color="warning" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Feeding Report</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-mortality-report') &&
      <MenuItem key = {4} 
        onClick={(e) => props.handleBookingOps("mortality-report")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <GradientOutlined color="info" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Mortality Report</Typography>
      </MenuItem>     
    }
    {
      permissionCheck('booking-reports', 'create-sample-weight-of-brood-stock') &&
      <MenuItem key = {5} 
        onClick={(e) => props.handleBookingOps("sample-weight-of-brood-stock")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <TrendingUpOutlined color="error" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Sample {species_type} Weight Report</Typography>
      </MenuItem>   
    }
    {
      permissionCheck('booking', 'create-sample-collection-storage') &&
      <MenuItem key = {6} 
        onClick={(e) => props.handleBookingOps("sample-collection-storage")}
        divider={true}
        disabled={props.labAnalysis }
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <BloodtypeOutlined color="success" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >New Sample Collection</Typography>
      </MenuItem>   
    }
    {
      permissionCheck('booking-reports', 'create-lab-analysis') &&
      <MenuItem key = {7} 
        onClick={(e) => props.handleBookingOps("lab-analysis")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <BiotechOutlined color="warning" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Sample Lab Analysis</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-lab-analysis-report') &&
      <MenuItem key = {8} 
        onClick={(e) => props.handleBookingOps("lab-analysis-report")}
        divider={true}
        disabled={props.labAnalysis ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <StickyNote2Outlined color="info" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Lab Analysis Report</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-clearance-certificate') &&
      <MenuItem key = {9} 
        onClick={(e) => props.handleBookingOps("clearance-certificate")}
        divider={true}
        disabled={(props.bookingData.labAnalysisReportApprovalFlag && props.bookingData.labAnalysisReportApprovalFlag=="Rejected") || props.labAnalysis === false || props.bookingData.documents && props.bookingData.documents.clearanceCertificate ? true : false}
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <ApprovalOutlined color="secondary" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Clearance Report</Typography>
      </MenuItem>
    }
    {
      permissionCheck('booking-reports', 'create-shipment-packing') &&
      <MenuItem key = {10} 
        onClick={(e) => props.handleBookingOps("shipment-packing")}
        divider={true}
        disabled={
          props.labAnalysis === false ||
          (props.bookingData.documents && 
           (!props.bookingData.documents.clearanceCertificate || props.bookingData.documents.shipmentPacking))
        }
      >
        <ListItemIcon sx={{p: 1, border: '1px dashed grey', borderRadius : '50%'}}>
          <LocalShippingOutlined color="error" fontSize="medium"/>
        </ListItemIcon>
        <Typography variant="inherit" sx={{mx : 2}} >Add Shipment Packing Report</Typography>
      </MenuItem>
    }
    </MenuList>
  </>);
};

const BookingOperations = (props) => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [currentOp, setCurrentOp] = useState(null);

  const handleBookingOps = (operation) => {
    setCurrentOp(operation);
    setModalOpen(true);
  }
  
  const labAnalysis = props.bookingData.documents && props.bookingData.documents.labAnalysisReport ? true : false;
  return (
    <Paper elevation={3} sx={{textAlign:"center", p:1, mx : 1}}>
      {
        props.bookingData &&
        <>
          {
            <Dialog maxWidth="lg" open={modalOpen} aria-labelledby="form-dialog-title">
              <DialogTitle id="responsive-dialog-title" sx={{display : 'flex', justifyContent : 'flex-end'}}>
                  <IconButton onClick={() => setModalOpen(false)}><Close /></IconButton>
              </DialogTitle>
              <DialogContent id="responsive-dialog-content"> 
                <Typography variant="h4" align="center">{props.modalTitle}</Typography>
                {
                  currentOp === 'cancel' &&
                  <CancellationForm bookingData = {props.bookingData} refresh={() => { setModalOpen(false); props.refresh()}}/>
                }
                {
                  currentOp === 'update-supplier' &&
                  <EditSupplierForm bookingData = {props.bookingData} refresh={() => { setModalOpen(false); props.refresh()}}/>
                }
                {
                  currentOp === 'shipment' &&
                  <ShipmentForm bookingData = {props.bookingData} refresh={() => { setModalOpen(false); props.refresh()}}/>
                }
                {
                  currentOp === 'noc' &&
                  <NoCForm bookingData = {props.bookingData} refresh={() => { setModalOpen(false); props.refresh()}}/>
                }
                {
                  currentOp === 'authPerson' &&
                  <AuthPersonForm 
                    bookingData = {props.bookingData} 
                    refresh={() => { setModalOpen(false); props.refresh()}} />
                }
                {
                  currentOp === 'addn-charges' &&
                  <AddNewAddnCharges 
                    bookingData = {props.bookingData} 
                    refresh={() => { setModalOpen(false); props.refresh()}} />
                }
                {
                  currentOp === 'cubicle-stocking' &&
                  <CubicleStocking bookingData = {props.bookingData} refresh={() => { setModalOpen(false); props.refresh()}} />
                }
                {
                  currentOp === 'sample-collection-storage' &&
                  <AddSampleCollection bookingData = {props.bookingData} samplecollectionscount={props.samplecollectionscount} refresh={() => { setModalOpen(false); props.refresh()}} /> // <AddSampleCollection bookingData = {props.bookingData} samplecollectionscount refresh={() => { setModalOpen(false); props.refresh()}} />
                }
                {
                  ['received-sample-report', 'water-quality-analysis', 'feeding-report',
                    'mortality-report', 'lab-analysis', 'lab-analysis-report',
                    'sample-weight-of-brood-stock', 'quarantine-report' , 'clearance-certificate',
                    'shipment-packing'].includes(currentOp) &&
                   <AddNewBookingReport reportType={currentOp} bookingData={props.bookingData} /> 
                }
              </DialogContent >
            </Dialog>
          }
          {
            ['Cancelled', 'Rejected'].includes(props.bookingData.status) &&
            <>
              <img src={process.env.PUBLIC_URL + "/static/images/misc/error.png"} height="200" width="200" />
              <Typography variant="h6"> This booking has been cancelled </Typography>
            </>
          }
          {
            // operations before arrival and after arrival
            (!props.bookingData.arrivalReport && ['Blocked', 'Confirmed'].includes(props.bookingData.status)) &&
            <PreArrivalOperations bookingData = {props.bookingData} handleBookingOps = {handleBookingOps } labAnalysis={labAnalysis} />
          }
          {
            // operations after arrival for hatchery owner, just show the
            // stock status, no more operations required from hatchery owner
            // side
            props.bookingData.arrivalReport &&
            <PostArrivalOperations 
              bookingData = {props.bookingData}
              stockingData = {props.stockingData}
              handleBookingOps = {handleBookingOps} 
              labAnalysis= {labAnalysis} 
              isSamplesCollected={props.isSamplesCollected}
            />
          }
        </>
      }
    </Paper>
  );
}

export default BookingOperations;
