import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { sampleWeightFields } from '../booking-reports.fields';

import { 
  createNewBookingReport, updateBookingReport, 
  fetchBookingReportByBookingId, filterFormFieldsBySpeciesType
} from 'services/booking-reports';

export default class SampleWeightBsReport extends Component {
  
  state = { avgMaleWt : 0, avgFemaleWt : 0, avgWt : 0, fetched : false };
  
  constructor ( props ) {
    super (props);
    
    this.reportFields = [];
    this.title = props.bookingData.speciesType.type === 'PPL' 
      ? 'Sample PPL Weight Report' 
      : 'Sample Broodstock Weight Report'; 
      
    sampleWeightFields.map ( f => {
      let field = {...f};
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
    
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      // populate cubicle data
      if (f.name === 'cubicleId') {
        if (props.bookingData.cubicleDetails) {
          props.bookingData.cubicleDetails.map( c => {
            if (c.cubicleId) field.selectOptions[c.cubicleId] = c.cubicleName;
            else if (c.cubicleNumber) field.selectOptions[c.cubicleNumber] = `CUB-${c.cubicleNumber}`;
          });
        } else if (this.props.bookingData.cubicleBookingScheduleIds) {
          props.bookingData.cubicleBookingScheduleIds.map(c => {
            field.selectOptions[c.cubicleId] = `CUB-${c.cubicleId}`;
          });
        }
      }
      
      this.reportFields.push(field);
      
      // fix warnings
      return null;
    });
    
    // filter fields based on species type
    this.reportFields = filterFormFieldsBySpeciesType('sample-weight-of-brood-stock', 
      props.bookingData.speciesType.type, this.reportFields);
      
  }
  
  async componentDidMount()
  {
    if (this.props.currentValues) return;
    
    try {
      // fetch arrival data for cross checking
      let arrival = await fetchBookingReportByBookingId(
        this.props.bookingData.id, 'received-sample-report'
      );
      
      if (arrival.data && arrival.data.length) {
        this.reportFields = this.reportFields.map ( f => {
          if (f.name === 'averageMaleWeight') f.default = arrival.data[0].avgMaleWt;
          else if (f.name === 'averageFemaleWeight') f.default = arrival.data[0].avgFemaleWt;
          else if (f.name === 'averageWeight') f.default = arrival.data[0].avgWt;
          
          return f;
        });
        
        this.setState({ 
          avgMaleWt : arrival.data[0].avgMaleWt, 
          avgFemaleWt : arrival.data[0].avgFemaleWt, 
          avgWt :arrival.data[0].avgWt, 
          fetched:true
        }) 
      }
    } catch(error) {
      console.log(error);
    }
  }
  
  handlePreFormSubmit = (formData) => {
    let tableData = formData.tableData;
    let duplicates = new Set();
    
    // validate tableData
    tableData.map ( data => {
      duplicates.add(data.cubicleId)
    });
    
    if (tableData.length !== duplicates.size) {
      return {
        status : "error", 
        message : "Found duplicate data, please select different cubicles"
      };
    }
    
    return  {status : "success"};
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleCreateFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully added new feeding details..." };
    let formData = { 
      bookingId : this.props.bookingData.id,
      bulkData : tableData.map(t => { 
        // add booking id into the data
        t.bookingId = this.props.bookingData.id; 
        return t;
      })
    };
    
    try {
      let response = await createNewBookingReport('sample-weight-of-brood-stock', formData);
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message };
      if (error.data && error.data.isValid === false) {
        let errorsOnSubmit = error.data.bulkData[0];
        delete errorsOnSubmit.arrIdx;
        statusMessage['data'] = errorsOnSubmit;
      }
    }
    
    return statusMessage;
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleEditFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated feeding details..." };
    
    try {
      let response = await updateBookingReport('sample-weight-of-brood-stock', this.props.currentValues.id, params);
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  handleTableDataChange = async (tableData) => {
    // no-op
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update ' + this.title : this.title) + 
          `, Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          !this.props.currentValues ?
          <>
          {
            this.state.fetched &&
            <DynamicTableForm
              formFields = {[]}
              tableFormProps = {{
                fields : this.reportFields,
                handleTableDataChange : this.handleTableDataChange,
                serialNoHeader : 'S.No',
                deleteRows: "none",
                uploadFiles : true,
                minRows : 1,
                maxRows: 15,
                currentValues : null,
              }}
              uploadFiles={true}
              action="create"
              buttonText= {"Save " + this.title}
              preFormSubmit={this.handlePreFormSubmit}
              handleSubmit={this.handleCreateFormSubmit}
              redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/sample-weight-of-brood-stock"}
            />
          }            
          </> :
          <DynamicForm
            formFields={this.reportFields}
            action="update"
            handleSubmit={this.handleEditFormSubmit}
            buttonText={"Update  " + this.title}
            buttonWidth= "full"
            columns = {4}
            currentValues={currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/sample-weight-of-brood-stock"}
          />
        }
      </>
    );
  }
}
