export const ownerFields = [
  {
    name: 'isHatcheryIncharge',
    label: 'Profile Type',
    type: 'radio',
    selectOptions : {
      false : 'Owner',
      true : 'Incharge'
    },
    actions : ['view', 'create', 'update'],
    properties: [{ required: true }], 
  },
  {
    name: 'email',
    label: 'Registered E-mail',
    type: 'email',
    actions : ['index', 'view', 'create', 'update'],
    properties : [{required : true}, {email : true}]
  },
  {
    name: 'password',
    label: 'Account Password',
    type: 'password',
    actions : ['create', 'update'],
    properties: [{ required:true}, {strongPassword : true}], 
  },
  {
    name: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    actions : ['create', 'update'],
    properties: [{ required:true}, {strongPassword : true}], 
  },
  {
    name: 'name',
    label: 'Full Name',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true }, {string : { min : 5, max : 64}}], 
  },
  {
    name: 'phoneNumber',
    label: 'Mobile Number',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true}, {mobile:true}, {string : {min : 10, max : 10}}], 
  },
  {
    name: 'address',
    label: 'Hatchery Address',
    type: 'text',
    actions : ['view', 'create', 'update'],
    properties: [{required:true}, {string : { min : 10, max : 512}}], 
  },
  {
    name: 'document',
    label: 'Upload CAA Document',
    type: 'file',
    actions : ['create'],
    properties: [
      { required:true }, 
      { filesize : {min : 0, max : 10} }, 
      { filetype : ['pdf', 'docx','doc','PDF']}
    ],
  }
];

export const authorizedPersonFields = [
  {
    name: 'name',
    label: 'Person Name',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties : [{required : true}]
  },
  {
    name: 'phoneNumber',
    label: 'Mobile Number',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties : [
      {required : true}, 
      {mobile : true},
      {string : { min : 10, max : 10}},
    ]
  },
  {
    name: 'aadhaarNumber',
    label: 'Govt Issued ID',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties : [{required : true}, {string : {min : 12, max : 12}}]
  },
  {
    name: 'email',
    label: 'E-mail Address',
    type: 'email',
    actions : ['index', 'view', 'create', 'update'],
    properties : [{required : true}, {email : true}]
  },
  {
    name : 'updated_at',
    label : 'Updated At',
    type: 'datetime',
    actions : ['view'],
    properties: [], 
  }
];
