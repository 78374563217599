import React, { Component } from "react";
import { saveAs } from 'file-saver';

import { withLocation } from 'hoc';

// import TableView Component
import RTableView from 'pages/common/RTableView';

import { AuthStore } from "store";
import { permittedFields } from 'permissions';

import { 
  fetchBookingsByHatcheriesCount, 
  fetchBookingsByHatcheries, 
} from 'services/bookings';
import { fetchSettingsByType } from 'services/common';
import { fetchAllSpecies } from 'services/species-details';

import { hatcheryFields } from 'pages/hatcheries/hatchery.fields';

class ZeroBookingsReport extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    let location = props.location.state;
    let fyear = new Date();
    
    this.reportFields = [];
    
    const displayFields = permittedFields('hatchery', 'top-hatchery-bookings');
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'Zero Booking Hatcheries Report',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Hatchery Name',
      
      // table content props
      columns : displayFields.length ? hatcheryFields.filter ( x => displayFields.includes(x.name) ) : hatcheryFields,
      itemUrlPath : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingsDataWrapper,
      fetchCountApi : this.fetchBookingsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'startDate',
        type : 'date',
        label : 'Start Date',
        value : fyear.getMonth() < 3 ? ((parseInt(fyear.getFullYear()) - 1) + '-04-01') : (fyear.getFullYear() + '-04-01'),
      }, {
        name : 'endDate',
        type : 'date',
        label : 'End Date',
        value : fyear.getMonth() < 3 ? (fyear.getFullYear() + '-03-31') : ((parseInt(fyear.getFullYear()) + 1) + '-03-31')
      }, {
        name : 'state',
        type : 'select',
        value : 'ALL',
        options : [
          {'id' : 'ALL', 'value' : '-- All States --'}
        ]
      },{
        name : 'speciesType',
        type : 'select',
        value : 'ALL',
        options : [
          {'id' : 'ALL', 'value' : '-- All Species --'}
        ]
      },{
        name : 'fetchReport',
        type : 'button',
        label : 'Show',
      }],
      
      // export to xl, pdf props
      exportEnable : true,
      exportEvent : this.handleExportEvent
    };
  }

  handleExportEvent = async (filters, searchValue) => {
   let response = await fetchBookingsByHatcheries({
      fromDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      toDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      hatcheryName: searchValue,
      state : filters['state'],
      speciesType : filters['speciesType'],
      district : 'ALL',
      zeroBookings : true,
      download : true
    });
    
    let filename = 'Zero_Booking_Hatcheries_Report_' + new Date().getTime() + '.xlsx';
    
    // Download the file
    saveAs(response.data, filename);
  };
  
  fetchBookingsCountWrapper = async(filters, searchValue) => {
    let response = await fetchBookingsByHatcheriesCount({
      fromDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      toDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      hatcheryName: searchValue,
      state : filters['state'],
      speciesType : filters['speciesType'],
      district : 'ALL',
      zeroBookings : true
    });

    return response;
  }    

  fetchBookingsDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchBookingsByHatcheries({
      page: page,
      per_page: perPage,
      fromDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      toDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      state : filters['state'],
      speciesType : filters['speciesType'],
      district : 'ALL',
      hatcheryName: searchValue,
      zeroBookings : true
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.permittedSpeciesId = (entry.permittedSpeciesId || {}).name;
        entry.updateRequestFlag = entry.updateRequestFlag ? entry.updateRequestFlag : 'Approved';
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.selectFilters[2].options = [{id : "ALL", value : "-- All  States --"}];
    this.tableProps.selectFilters[2].value = 'ALL';
    try {
      let response = await fetchSettingsByType({type : 'STATE', name : 'STATE'});
      response.data.map ( state => {
         this.tableProps.selectFilters[2].options.push({id : state.id, value : state.value});
      });
      response = await fetchAllSpecies({ page : 1, per_page : -1});
      response.data.map( species => {
        this.tableProps.selectFilters[3].options.push({id : species.id, value : species.name});
      });
      
      // filter permitted fields
      this.reportFields.filter ( x => this.permittedFields.includes(x.name) );
      
    } catch(error) {
      console.log(error);
    }
    
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <RTableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocation(ZeroBookingsReport);