import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';
import MainLayout from 'layout/MainLayout';

// hatchery pages
import RegisterHatchery from 'pages/hatcheries/register';
import ViewHatchery from 'pages/hatcheries/view';
import ViewHatcheryChanges from  'pages/hatcheries/viewChanges'; 
import HatcheriesList from 'pages/hatcheries/index';
import AddNewHatchery from 'pages/hatcheries/add';
import EditHatchery from 'pages/hatcheries/edit';
import NotFound from 'pages/common/404Page';

import { permissionCheck } from 'permissions';

import { AuthStore } from 'store';

const HatcheryRoutes = (props) =>  {
  console.log("loading hatchery routes");
  return (
    <Routes>
      <Route key="aqf-hatchery-main" path="main" element={<MainLayout />}>
        <Route key="register-hatchery" path="register" element = {<RegisterHatchery />} />
      </Route>
      <Route key="aqf-hatcheries" path="*"
        element={
          <ProtectedComponent Component={DashboardLayout} redirectLink='/' authCheck={props.isAuthenticated} /> 
        }
      >
        {
          permissionCheck('hatchery', 'view-any') &&
          <Route key="aqf-hatcheries-list" path = "list" element = {<HatcheriesList type="Listing"/>} />
        }
        {
          permissionCheck('hatchery', 'create') &&
          <Route key="aqf-hatcheries-add" path="add" element={<AddNewHatchery />} />
        }
        {
          permissionCheck('hatchery', 'update') &&
          <Route key="aqf-hatcheries-edit" path="edit/:id" element={<EditHatchery />} />
        }
        {
          (permissionCheck('hatchery', 'view-self') || permissionCheck('hatchery', 'view-any')) &&
          <Route key="aqf-hatcheries-view" path = "view/:id" element = {<ViewHatchery />} />
        }
        {
          permissionCheck('hatchery', 'approve') &&
          <Route key="aqf-hatcheries-approvals-hatcheries" path = "approvals/hatcheries" element = {<HatcheriesList type="Pending" />} />
        }
        {
          permissionCheck('hatchery', 'approve-changes') &&
          <Route key="aqf-hatcheries-approvals-hatcherychanges" path = "approvals/hatcherychanges" element = {<HatcheriesList type="Changes" />} />
        }
        {
          permissionCheck('hatchery', 'approve') &&
          <Route key="aqf-hatcheries-approvals-view-hatchery" path = "approvals/hatchery/:id" element = {<ViewHatchery />}/>
        }
        {
          permissionCheck('hatchery', 'approve-changes') &&
          <Route key="aqf-hatcheries-approvals-view-hatcherychanges" path = "approvals/hatcherychanges/:id" element = {<ViewHatcheryChanges />} />
        }
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" />} />      
      </Route>
    </Routes>
  );
}

export default HatcheryRoutes;