import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import {
  fetchUserAccountsTotalCount,
  fetchUserAccounts, 
} from 'services/user';
import { fetchAllRoles } from 'services/roles-permissions';

import userAccountFields from './user-accounts.fields';

import { permissionCheck } from 'permissions';

export default class UserAccountList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All User Accounts',
      addButtonText : 'Add New User Account',
      addUrl : permissionCheck('user-accounts', 'create') ? '/aqf/admin/user-accounts/add' : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Name',
      
      // table content props
      columns : userAccountFields,
      editUrlPath : permissionCheck('user-accounts', 'create') ? `/aqf/admin/user-accounts/edit/` : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchUserAccountsDataWrapper,
      fetchCountApi : this.fetchUserAccountsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'role',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  Roles --"},
        ]
      }, {
        name : 'status',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "true", value : "Active"},
          {id : "false", value : "In Active"},
        ]
      }],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }
  
  fetchUserAccountsCountWrapper = async(filters, searchValue) => {
    let response = await fetchUserAccountsTotalCount({
      role : filters['role'],
      status : filters['status'],
      name : searchValue
    });
    return response;
  }

  fetchUserAccountsDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchUserAccounts({
      page: page,
      per_page: perPage,
      role : filters['role'],
      status : filters['status'],
      name : searchValue
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = permissionCheck('user-accounts', 'update') ? `/aqf/admin/user-accounts/edit/${entry.id}` : null;
        entry.role = entry.user.role;
        entry.email = entry.user.email;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    
    let roleFilter = this.tableProps.selectFilters.find( x => x.name === 'role');
    if (roleFilter) {
      roleFilter.options = [{id : "ALL", value : "-- All  Roles --"}];
      let response = await fetchAllRoles({ page : 1, per_page : 100});
      response.data.map( entry => {
        
        // skip hatchery owner role
        if (entry.role.name === 'Hatchery Owner') return null;
        
        roleFilter.options.push({'id' : entry.role.id, 'value' : entry.role.name});
      });
    }      
        
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}