import React, { Component } from 'react';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchUserAccountsTotalCount} from 'services/user';

export default class TotalEmployees extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchUserAccountsTotalCount({role : 'ALL', status : 'ALL'});
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Total Employees'} 
        count = {this.state.count} 
        icon = {<AssignmentIndIcon />}
        url = { '/aqf/admin/user-accounts/list' }
      />
    );
  }
}