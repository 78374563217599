import React, { Component } from 'react';

import { Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { approveBookingReport } from "services/booking-reports";

export default class ReportApprovalForm extends Component {
  
  state = { bookingData : null };
  constructor(props) {
    super(props);
    this.formFields = [
      {
        name: 'approvalFlag',
        label: 'Approval Status',
        type: 'select',
        actions : ['create'],
        selectOptions : {
          "Approved" : "Approved",
          "Rejected" : "Rejected"
        },
        callback : this.handleStatusChange,
        properties: [{required:true}],
      }
    ];
  }
  
  handleStatusChange = async (name, value, formData, asyncFn) => {
    if (value === 'Rejected') {
      this.formFields.push({
        name : 'rejectReason',
        label : 'Reject Reason',
        type: 'string',
        properties: [{ required:true}, {string : { min : 10}}], 
      });
    } else {
      let idx = this.formFields.findIndex( f => f.name === 'rejectReason');
      if (idx >= 0) {
        this.formFields.splice(idx, 1);
      }
    }
    
    return { fields : this.formFields, values : null};
  }    

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Test report approval status submitted successfully !!" };
    
    try { 
      let response = await approveBookingReport(this.props.reportType, this.props.id, params);
    } catch (error) {
      statusMessage = { status : "error", message : error.message };
    }
    return statusMessage;
  }
  
  render() {
    
    return (
      <>
        <Typography variant="h4"> Approve / Reject Report </Typography><br/><hr style={{borderColor:"#eef1f6"}} />   
        <DynamicForm
          formFields={this.formFields}
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Approval Status"
          buttonWidth= "full"
          columns = {1}
          redirect={this.props.redirectUrl}
          action = "create"
        />
      </>
    );
  }
};