import React,  { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { List, ListItem, Collapse, Button, Icon } from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function NavParent(props) {
  
  const location = useLocation();
  const [parentOpen, setParentOpen] = useState(false);
  
  useEffect(() => {
    let open = false;
    if (props.children) {
      React.Children.forEach(props.children.props.children, element => {
        if (!React.isValidElement(element)) return
        const { href } = element.props;
        if (href === location.pathname) {
          open = true;
        }
      })
    }
    setParentOpen(open);
  }, [location.pathname]);
  
  return (
  <>
  {
    props.children && 
    props.children.props.children &&
    props.children.props.children.filter(x => x !== false).length > 0 &&
    <>
      <ListItem disableGutters sx={{ display: 'flex', py: 0 }} key={props.title} >
        <Button
            onClick = { e => setParentOpen(!parentOpen) }
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          // py: 1.25,
          textTransform: 'none',
          width: '100%',
          '& svg': {
          mr: 1
          }
        }}
        >
          <Icon component={props.icon} fontSize ="medium" />
          <span>{props.title}</span>
          <span style={{marginLeft : '8px', marginTop : '8px'}}>
          {
            parentOpen ? <ExpandLess /> : <ExpandMore />
          }
          </span>
        </Button>
      </ListItem>
      <Collapse in={parentOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.children}
          </List>
      </Collapse>
    </>
  }
  </>);
};