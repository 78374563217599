import React, { Component } from "react";
import { withLocationParams } from 'hoc';

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { 
  receivedSampleFields,
  labAnalysisReportFieldsLvannamei,
  quarantineFields,
  clearanceFields,
  shipmentPackingFields,
  commonReportFields
} from './booking-reports.fields';

import { 
  fetchSampleCollectionTotalCount, 
  fetchSampleCollection
} from 'services/sample-collection';

import { sampleCollectionFields } from 'pages/sample-collection/sample-collection.fields'

// import API services for reports
import {
  fetchBookingReportsCount,
  fetchBookingReports,
  filterDataBySpeciesType
} from 'services/booking-reports';

import { fetchAllSpecies } from 'services/species-details';

// import permissions for role based ops
import { permissionCheck } from 'permissions';

class BookingReportsList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { 
      updateContent : false,
      reportType : this.props.locationParams.reportType ? this.props.locationParams.reportType : 'ALL' 
    };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : '',
      // addButtonText : 'New Booking',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : (this.currentUser.user.role.name === 'Hatchery Owner') 
        ? 'Search By Booking ID ' : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : [],
      itemUrlPath : `/aqf/booking-reports/view/`,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingReportsDataWrapper,
      fetchCountApi : this.fetchBookingReportsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'reportType',
        value : this.props.locationParams.reportType ? this.props.locationParams.reportType : 'ALL',
        label: 'Select Report Type',
        options : [
          {id : 'ALL', value : '-- Select Report Type --'}
        ]
      }, {
        name : 'speciesType',
        value : 'ALL',
        label: 'Filter By Species',
        options : [
          {id : "ALL", value : "-- All  Species --"},
        ]
      }],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchBookingReportsCountWrapper = async(filters, searchValue) => {
    
    let hatcheryId = 'ALL';
    let reportType = filters['reportType'];
    let response = {status : 200, data : 0};
    
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    // if there are no report types supported, just return empty data
    if (this.tableProps.selectFilters[0].options.length === 0) {
      return response;
    }
    
    if (reportType === 'sample-collection') {
      response = await fetchSampleCollectionTotalCount({
        hatcheryId : hatcheryId,
        bookingId : parseInt(searchValue) > 0 ? searchValue : null,
        hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        speciesType : filters['speciesType']
      });
    } else if (reportType !== 'ALL') {
      response = await fetchBookingReportsCount(reportType, {
        hatcheryId : hatcheryId,
        bookingId : parseInt(searchValue) > 0 ? searchValue : null,
        hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        speciesType : filters['speciesType']
      });
    }
    
    return response;
  }    

  fetchBookingReportsDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    let reportType = filters['reportType'];
    let response = {status : 200, data : []};
    
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    // if there are no report types supported, just return empty data
    if (this.tableProps.selectFilters[0].options.length === 0) {
      return response;
    }
    
    if (reportType === 'sample-collection') {
      response = await fetchSampleCollection({
        hatcheryId : hatcheryId,
        bookingId : parseInt(searchValue) > 0 ? searchValue : null,
        hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        speciesType : filters['speciesType'],
        per_page : perPage,
        page : this.state.reportType !== reportType ? 1 : page
      });
    } else if (reportType !== 'ALL') {
      response = await fetchBookingReports(reportType, {
        hatcheryId : hatcheryId,
        bookingId : parseInt(searchValue) > 0 ? searchValue : null,
        hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
        speciesType : filters['speciesType'],
        per_page : perPage,
        page : this.state.reportType !== reportType ? 1 : page
      });
    }
    
    switch (reportType) {
      case 'received-sample-report':
        this.tableProps.title = "List Of Arrival Reports";
        this.tableProps.columns = receivedSampleFields;
      break;
      case 'feeding-report':
        this.tableProps.title = "List Of Feeding Reports";
        this.tableProps.columns = commonReportFields;
      break;
      case 'mortality-report':
        this.tableProps.title = "List Of Mortality Reports";
        this.tableProps.columns = commonReportFields;
      break;
      case 'lab-analysis':
        this.tableProps.title = "List Of Sample Lab Analysis";
        this.tableProps.columns = commonReportFields;
      break;
      case 'lab-analysis-report':
        this.tableProps.title = "List Of Lab Analysis Reports";
        this.tableProps.columns = labAnalysisReportFieldsLvannamei;
      break;
      case 'water-quality-analysis':
        this.tableProps.title = "List Of Water Quality Analysis Reports";
        this.tableProps.columns = commonReportFields;
      break;
      case 'sample-weight-of-brood-stock':
        this.tableProps.title = "List Of Sample Weight Reports";
        this.tableProps.columns = commonReportFields;
      break;
      /* not supported
      case 'quarantine-report':
        this.tableProps.title = "List Of Quarantine Reports";
        this.tableProps.columns = quarantineFields;
      break;
      */
      case 'clearance-certificate':
        this.tableProps.title = "List Of Clearance Certificate Reports";
        this.tableProps.columns = clearanceFields;
      break;
      case 'shipment-packing':
        this.tableProps.title = "List Of Shipment Packing Reports";
        this.tableProps.columns = shipmentPackingFields;
      break;
      case 'sample-collection':
        this.tableProps.title = "List Of Sample Collection Reports";
        this.tableProps.columns = sampleCollectionFields;
      default:
    }
    
    // don't show report id column in the table
    if (this.tableProps.columns) this.tableProps.columns[0].actions = ['view'];
    
    if (response && response.status === 200) {
      if (reportType === 'sample-collection') {
        response.data.forEach(entry => {
          entry.selection = false;
          entry.url = `/aqf/bookings/samples/${entry.bookingId.id}`;
          entry.hatcheryName = entry.bookingId.hatcheryId.name;
          entry.bookingId = entry.bookingId.id;
          entry.createUser = entry.createUser ? entry.createUser.name : null;
          entry.updateUser = entry.updateUser ? entry.updateUser.name : null;
          if (entry.bulkData) entry.sampleStorage = entry.bulkData.map(x => x.sampleStorage).join(',');
        });
      } else {      
        response.data.forEach(entry => {
          entry.selection = false;
          entry.reportType = reportType;
          entry.url = `/aqf/bookings/test-reports/view/${entry.id}/${reportType}`;
          
          // filter data by species type
          entry = filterDataBySpeciesType(reportType, entry.bookingId.speciesType.type, entry);
          
          if (entry.bookingId && typeof entry.bookingId === 'object') {
            
            if (entry.bookingId.documents) {
              // fetch pdf report
              switch (reportType) {
                case 'received-sample-report':
                  entry.attachment = entry.bookingId.documents['arrivalReport'];
                  break;
                case 'lab-analysis-report':
                  entry.attachment = entry.bookingId.documents['labAnalysisReport'];
                  break;
                case 'clearance-certificate':
                  entry.attachment = entry.bookingId.documents['clearanceCertificate'];
                  break;
                case 'shipment-packing':
                  entry.attachment = entry.bookingId.documents['shipmentPacking'];
                  break;
                default:
                  break;
              }
            }
            
            if (reportType === 'received-sample-report') {
              entry.totalBsReserved = entry.bookingId.totalNumberOfStock
            }
                
            if (entry.bookingId.hatcheryId && typeof entry.bookingId.hatcheryId === 'object') {
              entry.hatcheryId = entry.bookingId.hatcheryId.name;
            }
            
            entry.reservedDate = entry.bookingId.fromDate;
            if (entry.bookingId.supplierId && typeof entry.bookingId.supplierId === 'object') {
              entry.supplierName = entry.bookingId.supplierId.name;
            } else if (entry.bookingId.shipmentDetail && entry.bookingId.shipmentDetail.supplierName) {
              entry.supplierName = entry.bookingId.shipmentDetail.supplierName;
            }
            
            entry.arrivalDate = entry.bookingId.stockStatus.arrivalReport;
            entry.cubicleId = entry.bookingId.cubicleDetails ? entry.bookingId.cubicleDetails.map (x => x.cubicleName).toString() : "";
            entry.bookingId = entry.bookingId.oldId ? `${entry.bookingId.oldId} / ${entry.bookingId.id}` : entry.bookingId.id;
          }
          
          if (entry.cubicleId && typeof entry.cubicleId === 'object') {
            entry.cubicleId = entry.cubicleId.name;
          }
          
          if (entry.supplierId && typeof entry.supplierId === 'object') {
            entry.supplierId = entry.supplierId.name;
          }
          
          if (entry.sampleId && typeof entry.sampleId === 'object') {
            entry.sampleId = entry.sampleId.id;
          }
        });
      }
    }
    await this.setState({reportType});
    return response;
  }

  async componentDidMount() {
    
    // allow report filter based on permissions
    this.tableProps.selectFilters[0].options = [];
    
    if (permissionCheck('booking-reports', 'view-received-sample-report')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'received-sample-report', 'value' : 'Arrival Reports'});
    }
    if (permissionCheck('booking-reports', 'view-feeding-report')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'feeding-report', 'value' : 'Feeding Reports'});
    }
    if (permissionCheck('booking-reports', 'view-mortality-report')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'mortality-report', 'value' : 'Mortality Reports'});
    }
    if (permissionCheck('booking-reports', 'view-lab-analysis')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'lab-analysis', 'value' : 'Sample Lab Analysis'});
    }
    if (permissionCheck('booking-reports', 'view-lab-analysis-report')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'lab-analysis-report', 'value' : 'Lab Analysis Report'});
    }
    if (permissionCheck('booking-reports', 'view-sample-weight-of-brood-stock')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'sample-weight-of-brood-stock', 'value' : 'Sample BS Weight Report'});
    }
    if (permissionCheck('booking', 'view-sample-collection-storage')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'sample-collection', 'value' : 'Sample Collection Report'});
    }
    if (permissionCheck('booking-reports', 'view-water-quality-analysis')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'water-quality-analysis', 'value' : 'Water Quality Analysis'});
    }
    /* not supported
    if (permissionCheck('booking-reports', 'view-quarantine-report')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'quarantine-report', 'value' : 'Quarantine Report'});
    }
    */
    if (permissionCheck('booking-reports', 'view-clearance-certificate')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'clearance-certificate', 'value' : 'Clearance Certificate'});
    }
    if (permissionCheck('booking-reports', 'view-shipment-packing')) {
      this.tableProps.selectFilters[0].options.push({'id' : 'shipment-packing', 'value' : 'Shipment Packing Report'});
    }
    
    if (this.tableProps.selectFilters[0].options.length) {
      this.tableProps.selectFilters[0].value = 
        this.props.locationParams.reportType 
          ? this.props.locationParams.reportType
          : this.tableProps.selectFilters[0].options[0].id;
      
      this.tableProps.updateContent = true;
      await this.setState({updateContent : true});
    }
    
    try {
      let response = await fetchAllSpecies({ page : 1, per_page : -1});
      response.data.map ( species => {
        this.tableProps.selectFilters[1].options.push({id : species.id, value : species.name});
      });
    } catch(error) {
      console.log(error);
    }
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }
  
  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocationParams(BookingReportsList);