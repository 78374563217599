import React, { Component } from "react";
import { saveAs } from 'file-saver';

import { withLocation } from 'hoc';

// import RTableView Component
import RTableView from 'pages/common/RTableView';

import { AuthStore } from "store";

import { permittedFields } from 'permissions';

import { 
  fetchBookingsTotalCount, 
  fetchBookingsSupplierWise, 
} from 'services/bookings';

import { fetchAllSuppliers } from 'services/suppliers';
import { fetchAllSpecies } from 'services/species-details';

const reportFields =  [
  {
    label: 'Supplier',
    name: 'supplierName',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'Consignment No.',
    name: 'consignmentNo',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'booking Id',
    name: 'bookingId',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Stakeholder',
    name: 'hatchery',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'CAA Permit No.',
    name: 'CAApermitNumber',
    type : 'string',
    properties : [], actions : ['index']
  },
  {
    label: 'Annual BS Limit (No.)',
    name: 'annualAllocationLimit',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Date Of import',
    name: 'importDate',
    type : 'date',
    properties : [], actions : ['index']
  },
  {
    label: 'Imported Stock (No.s)',
    name: 'importedNumbers',
    type : 'number',
    properties : [], actions : ['index']
  },
  {
    label: 'Booking Status',
    name: 'status',
    type : 'select',
    selectOptions : {
      "Blocked" : "Blocked",
      "Cancelled" : "Cancelled",
      "Confirmed" : "Confirmed",
      "Rejected" : "Rejected",
      "Completed" : "Completed",
      "RetryPayment" : "Retry Payment"
    },
    properties : [], actions : ['index']
  }
];

class SupplierWiseBooking extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    let location = props.location.state;
    let fyear = new Date();
    
    const displayFields = permittedFields('app-reports', 'supplier-wise-booking');
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'Supplier Wise Bookings Report',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : displayFields.length ? reportFields.filter ( x => displayFields.includes(x.name) ) : reportFields,
      itemUrlPath : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingsDataWrapper,
      fetchCountApi : this.fetchBookingsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'supplier',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All Suppliers --"},
        ]
      },{
        name : 'speciesType',
        value : 'ALL',
        options : [
          {id : "ALL", value : "-- All  Species --"},
        ]
      },{
        name : 'startDate',
        type : 'date',
        label : 'Start Date',
        value : fyear.getMonth() < 3 ? ((parseInt(fyear.getFullYear()) - 1) + '-04-01') : (fyear.getFullYear() + '-04-01'),
      },{
        name : 'endDate',
        type : 'date',
        label : 'End Date',
        value : fyear.getMonth() < 3 ? (fyear.getFullYear() + '-03-31') : ((parseInt(fyear.getFullYear()) + 1) + '-03-31')
      }
      ,{
        name : 'fetchReport',
        type : 'button',
        label : 'Show',
      }],
      
      // export to xl, pdf props
      exportEnable : true,
      exportEvent : this.handleExportEvent
    };
  }

  handleExportEvent = async (filters, searchValue) => {
   let response = await fetchBookingsSupplierWise({
      supplierId : filters['supplier'],
      speciesType : filters['speciesType'],
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName: isNaN(parseInt(searchValue)) ? searchValue : null,
      status : 'ALL',
      download : true
    });
    
    let filename = 'Supplier_Wise_Bookings_' + new Date().getTime() + '.xlsx';
    
    // Download the file
    saveAs(response.data, filename);
  };
  
  fetchBookingsCountWrapper = async(filters, searchValue) => {
    let response = await fetchBookingsTotalCount({
      supplierId : filters['supplier'],
      speciesType : filters['speciesType'],
      type : 'date-range',
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      hatcheryId : 'ALL',
      status : 'ALL',
      shipmentStatus : 'ALL'
    });

    return response;
  }    

  fetchBookingsDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchBookingsSupplierWise({
      page: page,
      per_page: perPage,
      supplierId : filters['supplier'],
      speciesType : filters['speciesType'],
      startDate : (filters['startDate'] ? new Date(filters['startDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
      endDate : (filters['endDate'] ? new Date(filters['endDate']) : new Date()).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      status : 'ALL',
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
      });
    }
    
    return response;
  }

  async componentDidMount() {
    try {
      let response = await fetchAllSuppliers({
        speciesType : 'ALL',
        status : true,
        per_page : -1,
        page : 1
      });
      
      this.tableProps.selectFilters[0].options = [{id : "ALL", value : "-- All Suppliers --"}];
      this.tableProps.selectFilters[1].options = [{id : "ALL", value : "-- All Species --"}];
      
      response.data.map( supplier => {
        this.tableProps.selectFilters[0].options.push({id : supplier.id, value : supplier.name});
      });
      
      response = await fetchAllSpecies({ page : 1, per_page : -1});
      response.data.map( species => {
        this.tableProps.selectFilters[1].options.push({id : species.id, value : species.name});
      });
      
      this.tableProps.updateContent = true;
      await this.setState({updateContent : true});
      
    } catch(error) {
      console.log(error);
    }
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <RTableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocation(SupplierWiseBooking);
