import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { 
  receivedSampleFields,
  waterQualityFields,
} from '../booking-reports.fields';

import { createNewBookingReport, updateBookingReport, filterFormFieldsBySpeciesType } from 'services/booking-reports';

export default class ArrivalReport extends Component {
  
  constructor ( props ) {
    super (props);
   
    this.reportFields = [];
    receivedSampleFields.map ( f => {
      let field = {...f};
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
    
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      if (props.bookingData.speciesType.type === 'Broodstock') {
        if (['totalMaleBs', 'totalFemaleBs'].includes(f.name))field.callback = this.calculateTotalBs;
        else if (['totalMaleMortalities', 'totalFemaleMortalities'].includes(f.name)) field.callback = this.calculateTotalArrivedMoltalities;
        
        if (props.currentValues && f.name === 'totalArrivedBs') {
          field.default = 
            (props.currentValues.totalMaleBs ? parseInt(props.currentValues.totalMaleBs) : 0) + 
            (props.currentValues.totalFemaleBs ? parseInt(props.currentValues.totalFemaleBs)  : 0);
        } 
        
        if (props.currentValues && f.name === 'totalArrivMortalities') {
          field.default =
            (props.currentValues.totalMaleMortalities ? parseInt(props.currentValues.totalMaleMortalities) : 0) + 
            (props.currentValues.totalFemaleMortalities ? parseInt(props.currentValues.totalFemaleMortalities) : 0); 
        }
      } else {
        if (f.name === 'totalArrivedBs') {
          field.default = props.currentValues 
            ? props.currentValues.totalArrivedBs : parseInt(props.bookingData.shipmentDetail.totalBs || '0')
        }
      }
      
      if (f.name === 'arrivingDateTime') field.callback = this.calculateTimeDelay;

      this.reportFields.push(field);
      
      // fix warnings
      return null;
    });
    
    if (!props.currentValues) {
    
      this.reportFields.push({
        name : 'receiving_water_quality',
        label : 'Receiving Water Quality Data',
        type : 'header',
        properties : []
      });
      
      waterQualityFields.map ( f => {
        let field = {...f};
        field.properties = [];
        // deep copy properties
        f.properties.map ( p => field.properties.push({...p}));
      
        // deep copy select options
        if (f.selectOptions) {
          field.selectOptions = {...f.selectOptions};
        }
        
        // change field name of remarks as it coincides with remarks
        // field in arrival report
        if (field.name === 'remarks') {
          field.name = 'wqRemarks';
        }
        if (field.name === 'monitorDateTime')
        {
          field.properties =[{readOnly:true},{required:true},  {datetime : {noFutureDates : true}} ];
        }
      
        this.reportFields.push(field);
        
        // fix warnings
        return null;
      });
    }
    
     // remove cubicle Id field
    this.reportFields = this.reportFields.filter(f => f.name !== 'cubicleId');
    
    // remove duplicate bookingId fields
    this.reportFields = this.reportFields.filter((f, idx) => f.name !== 'bookingId' || idx === 1);
    
    if (!props.currentValues) {
      // populate default water quality report type
      let waterQualityType = this.reportFields.findIndex( f => f.default === 'water_quality_analysis');
      this.reportFields[waterQualityType].properties.push({readOnly : true});
      this.reportFields[waterQualityType].default = "receiving_water_quality";    
    }
      
    // populate current booking id
    let bookingFieldIdx = this.reportFields.findIndex( f => f.name === 'bookingId');
    if (bookingFieldIdx >= 0 && props.bookingData) {
      this.reportFields[bookingFieldIdx].default = props.bookingData.id;
    }

    // filter form fields based on species type
    this.reportFields = filterFormFieldsBySpeciesType(
      'received-sample-report', props.bookingData.speciesType.type, this.reportFields);

  }

  handlePreFormSubmit = async (formData) => {
    // check transit time formatting is correct
    let transitTime = formData.transitTime.split(':');
    if (transitTime.length !== 2) {
      return { 
        status : "error", 
        message : "Incorrect transit time format", 
        data : { isValid : false, transitTime : { isInvalid : true, message : "Incorrect transit time format"}}
      }
    } else if (parseInt(transitTime[0]) > 999 || parseInt(transitTime[1]) > 59) {
      // cannot exceed 999:59
      return { 
        status : "error", 
        message : "Incorrect transit time format", 
        data : { isValid : false, transitTime : { isInvalid : true, message : "Incorrect transit time format"}}
      }
    }
    if (formData.arrivingDateTime > formData.monitorDateTime)
    {
      return { 
        status : "error", 
        message : "monitor date time should be greater than arriving date time", 
        data : { isValid : false, monitorDateTime : { isInvalid : true, message : "monitor date time should be greater than arriving date time"}}
      }
    }
    
    return { status : "success" };
  };
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new arrival report for the booking..." };
    
    params.bookingId = this.props.bookingData.id;
    params.images = Array.isArray(params.images) ? params.images : [params.images];
    
    // calculate total live biomass in kg
    
    if (this.props.bookingData.speciesType.type === 'Broodstock') {
      params.totalLiveBiomass = ((
          ((params.totalMaleBs - params.totalMaleMortalities) * params.avgMaleWt) + 
          ((params.totalFemaleBs - params.totalFemaleMortalities) * params.avgFemaleWt)
      )/1000).toFixed(3);
    } else {
      params.avgWt = 0;
      params.totalLiveBiomass = 0;
    }
    
    // todo API calls
    try {
      let response;
      if (this.props.currentValues) {
        response = await updateBookingReport('received-sample-report', this.props.currentValues.id, params);
        statusMessage = { status: "success", message: "Successfully updated arrival report..." };
      } else {
        response = await createNewBookingReport('received-sample-report', params);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data};
    }
    
    if (this.props.handleFormSubmit) {
      this.props.handleFormSubmit(statusMessage);
    }
    
    return statusMessage;
  }
  
  calculateTotalBs = async (name, value, formData, asyncFn) => {
    let totalFemaleBs = formData.totalFemaleBs ? parseInt(formData.totalFemaleBs) : 0;
    let totalMaleBs = formData.totalMaleBs ? parseInt(formData.totalMaleBs) : 0;
   
    formData.totalArrivedBs = totalFemaleBs + totalMaleBs;
    
    return { fields : null, values : formData};
  }
  
  calculateTimeDelay = async (name, value, formData, asyncFn) => {
    
    let bookingDate = new Date(this.props.bookingData.fromDate);
    let arrivalDate = new Date(value);
    let arrivalTime = new Date(value);
    
    bookingDate.setHours(0,0,0,0);
    arrivalDate.setHours(0,0,0,0);
    
    let days = (arrivalDate.getTime() - bookingDate.getTime()) / (1000 * 60 * 60 * 24);
    if (days >= 0) {
      days = (arrivalTime.getHours() >= 20 && arrivalTime.getMinutes() >= 1) ? days + 1 : days;
    }

    formData.timeDelay = days + ' day(s)';
    formData.monitorDateTime = value;
    
    return { fields : null, values : formData};
  }
  
  calculateTotalArrivedMoltalities = async (name, value, formData, asyncFn) => {
    
    let totalMaleMortalities = formData.totalMaleMortalities ? parseInt(formData.totalMaleMortalities) : 0;
    let totalFemaleMortalities = formData.totalFemaleMortalities ? parseInt(formData.totalFemaleMortalities) : 0;
   
    formData.totalArrivMortalities = totalMaleMortalities + totalFemaleMortalities;
    
    return { fields : null, values : formData};
  }
  
  render () {
   
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Arrival Report' : 'Create Arrival Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          formFields={this.reportFields}
          action={this.props.currentValues ? "update" : "create"}
          preFormSubmit={this.handlePreFormSubmit} 
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Report"
          buttonWidth= "full"
          columns = {4}
          uploadFiles = {true}
          currentValues={this.props.currentValues}
          redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/received-sample-report"}
        />
      </>
    );
  }
}
