import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicTableForm } from 'components/Forms';

import { sampleCollectionFields } from './sample-collection.fields';

import { addSampleCollection } from "services/sample-collection";

export default class AddSampleCollection extends React.Component {
 
  state = { statusMessage : null, currentValues : null };
  constructor(props) {
    super(props);

    localStorage.setItem('arrivaldate', JSON.stringify(this.props.bookingData.arrivalReport));
  }
 
  handlePreFormSubmit = (formData) => {
    
    let tableData = formData.tableData;
    let duplicates = new Set();
    
    // validate tableData
    tableData.map ( data => {
      duplicates.add(data.sampleStorage)
    });
    
    if (tableData.length !== duplicates.size) {
      return {
        status : "error", 
        message : "Duplicate sample storage number entered. Please enter unique numbers"
      };
    }
    return  {status : "success"};
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params, tableData) => {
    let statusMessage = { status: "success", message: "Successfully saved sample collection details...." };
    let formData = { 
      bookingId : this.props.bookingData.id,
      sampleStorages : tableData.map(t => {
        // add booking id into the data
        t.bookingId = this.props.bookingData.id;
        return t;
      })
    };

    // todo API calls
    try {
      
      let response = await addSampleCollection(formData);
      if (this.props.refresh) 
       this.props.refresh();
      else
       window.location.reload(this.props.redirectUrl);
      
    } catch(error) {
      console.log(error)
      statusMessage = { status: "error", message : error.message };
    }
    
    return statusMessage;
  }
  
  handleTableDataChange = (tableData,validation) => {
    tableData.forEach((item,idx)=>{

      if (item._isNew )
      {
        if (item.sampleStorage==="")
        {
          let sno = this.props.samplecollectionscount + idx + 1;
          if (sno<10)
            var sampleStorageAuto = this.props.bookingData.id + '00' + sno;
          else 
            var sampleStorageAuto = this.props.bookingData.id + '0' + sno;

          item.sampleStorage=sampleStorageAuto;
        }
        if (item.storageDate==="")
        {
          validation[idx].isValid = true;
          item.storageDate = this.props.bookingData.arrivalReport;
        }
      }
    });
    // no-op
  }
  
  render() {
    return (<>
      <Typography variant="h3" align="center" p={2} > 
        {
          `Add Sample Collection Details - Booking ID : ${this.props.bookingData.id}`
        }
      </Typography><br/><br/>
        <DynamicTableForm
          formFields = {[]}
          tableFormProps = {{
            fields : sampleCollectionFields,
            handleTableDataChange : this.handleTableDataChange,
            serialNoHeader : 'S.No',
            deleteRows: "none",
            uploadFiles : true,
            minRows : 5,
            maxRows: 12,
            currentValues : this.state.currentValues,
          }}
          uploadFiles={true}
          action="create"
          buttonText="Save Sample Collection Details"
          preFormSubmit={this.handlePreFormSubmit}
          handleSubmit={this.handleFormSubmit}
          validation={{ isValid: true }}
      />
    </>);
  }
}
