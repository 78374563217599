import React, { Component } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';

import { Link } from 'react-router-dom';

import { fetchRandomColor } from './random-color';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default class SummaryWidget extends Component {

  render() {
    
    const { label, count, icon, url } = this.props;
    
    // const randomColor = '#' + Math.floor(Math.random()*(16777215- 4096)).toString(16);
    const randomColor = fetchRandomColor();
    
    return (<Card sx={{ height: 120 }} {...this.props} >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">{ label }</Typography>
            <Link underline="hover" color="inherit" to= {url}>  
              <Typography color="textPrimary" variant="h1"> {count} </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Avatar sx={{backgroundColor: randomColor, height: 56, width: 56, color : "white" }}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>);
  }
};