import React, { Component } from "react";

// dynamic forms and Form Validation
import {
  FormValidator,
  FieldCreator,
  generateFieldRules,
  ImageGridList,
  ImageCrop
} from "./components/";

import CaptionForm from "./components/CaptionForm";

// Custom snackbar
import CustomSnackbar from "components/CustomSnackbar";

// Material components/
import { Grid, Button, Typography } from "@mui/material";

// Aws S3 image uploads
import { convertFileToBlob, uploadFilesToBackend, removeFileFromBackend } from "services/upload";

const uuid = require("uuid");

class DynamicFormGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isImgUploading: false,
      featuredImageKey: null,
      validation: { isValid: false },
      submitStatus: null,
      caption: "",
      currentKey: "",
      currentFieldName: "",
      imgDelList : {}
    };

    // list of fields in the order to be rendered.
    this.inputFields = [];

    // field meta info
    this.meta = {};

    // start with empty validationRules
    this.validator = {};
    this.validationRules = [];

    // list of gallery image files.
    this.galleryFileInput = null;

    // reference to featured image, as it does not showup
    // as a field in the form. It has special property, featured:true
    //
    this.featuredImageField = null;

    // maintain reference to the gallery in parent. Gallery state can
    // be abstracted this way to some extent !!!
    this.refGallery = React.createRef();

    // various form action states
    this.submitted = false;
    
    // various form action states
    this.errorsOnSubmit = props.validation  ? true : false;
  }

  async initializeStateFromProps() {
    const formFields = this.props.formFields;

    let fieldState = this.state;
    this.meta = {};
    this.inputFields = [];
    this.validationRules = [];

    // add each field into the state and meta
    formFields.forEach(f => {
      // skip fields not relevant to this action (create/edit)
      if (f.actions) {
        let actions = f.actions;
        if (!Array.isArray(actions)) {
          actions = actions.split(/,| /);
        }

        if (!actions.find(action => action === this.props.action)) {
          return;
        }
      }

      this.meta[f.name] = { touched: false };

      // render all form fields except gallery (multiple images )
      if (
        f.properties.find(p => p.crop !== undefined) &&
        f.properties.find(p => p.multiple === true)
      ) {
        // console.log ("skipping gallery field");
      } else if (f.properties.find(p => p.featured !== undefined)) {
        // console.log ("skipping featured image field in the form");
        this.featuredImageField = f;
      } else {
        this.inputFields.push({
          name: f.name,
          type: f.type,
          label: f.label,
          selectOptions: f.selectOptions === undefined ? null : f.selectOptions
        });
      }

      // image that needs cropping will be handled in a different
      // component along with file validations
      if (
        f.type === "image" &&
        f.properties &&
        f.properties.length > 0 &&
        f.properties.find(p => p.crop !== undefined)
      ) {
        // gallery with cropping feature will be handled in a different
        // component
        if (f.properties.find(p => p.multiple && p.multiple === true)) {
          this.galleryFileInput = f;
          // do not use set state until all fields are read
          try {
            let images =
              this.props.currentValues && this.props.currentValues[f.name]
                ? this.props.currentValues[f.name]
                : [];

            // console.log (images);
            fieldState[f.name] = [];
            // set state management flags for each entry
            for (let i = 0; i < images.length; ++i) {
              fieldState[f.name].push({
                src: images[i],
                addPending: false,
                removePending: false
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          // do not use set state until all fields are read
          fieldState[f.name] =
            this.props.currentValues && this.props.currentValues[f.name] !== undefined
              ? this.props.currentValues[f.name]
              : f.default === undefined
              ? ""
              : f.default;
        }

        // just populate the required property to handle form submission
        // in one place.
        //
        if (f.properties.find(p => p.required !== undefined && (p.required === true || p.required === 'true'))) {
          this.validationRules.push({
            field: f.name,
            method: "isEmpty",
            validWhen: false,
            message: `${f.label} is required`
          });
        }
      } else {
        // populate required validation rules for the field
        if (f.properties && f.properties.length > 0) {
          generateFieldRules(f, this.validationRules);
        }

        // do not use set state until all fields are read
        fieldState[f.name] = this.props.currentValues && this.props.currentValues[f.name] !== undefined ? 
                    this.props.currentValues[f.name] :
                      f.default === undefined ? '' : f.default;
      }
    });

    // set up featured image field here
    if (this.props.currentValues) {
      for (let i = 0; i < fieldState[this.galleryFileInput.name].length; ++i) {
        // console.log ( fieldState[this.galleryFileInput.name][i]);
        if (
          this.featuredImageField &&
          this.props.currentValues[this.featuredImageField.name] &&
          fieldState[this.galleryFileInput.name][i].src.image ===
            this.props.currentValues[this.featuredImageField.name]
        ) {
          fieldState["featuredImageKey"] = i;
        }
      }
    }
  
    this.validator = new FormValidator(this.validationRules, formFields);
    
    this.errorsOnSubmit = this.props.validation ? true : false;

    // set state
    fieldState["isLoading"] = false;
    fieldState["submitStatus"] = null;
    
    await this.setState(fieldState);
    
    if (this.props.validateOnLoad) {
      await this.triggerFormValidation();
    }
  }
  
  async componentDidMount() {
    // initialize state from props
    if (this.inputFields.length === 0) {
      await this.initializeStateFromProps();
    }
  }
  
  async componentDidUpdate( prevProps, prevState ) {
    // initialize state from props if form current values changes.
    if (this.inputFields.length === 0 || 
      (prevProps.currentValues === null && this.props.currentValues !== null)) {
      await this.initializeStateFromProps();
    }
  }
 
  handleImageCropChange = async (fieldname, newImage) => {
    this.meta[fieldname].touched = true;
    // keep track of old image for deletion
    this.state.imgDelList[fieldname] = this.state[fieldname];
    await this.setState({[fieldname]: {blob : newImage}});
    await this.triggerFormValidation();
  };

  handleInputChange = async (event, name) => {
    event.preventDefault();
    this.meta[name].touched = true;
    
    if (event.target.type === "checkbox") {
      await this.setState({ [name]: event.target.checked });
    } else {
      await this.setState({ [name]: event.target.value });
    }
    await this.triggerFormValidation();
  };

  handleEditorChange = async (event, name) => {
    await this.setState({
      [name]: event.editor.getData()
    });

    this.meta[name].touched = true;

    await this.triggerFormValidation();
  };

  // to validate file size and extensions allowed
  validateFileTypeRules(e) {
    let validation = { isValid: true };
    let formField = this.props.formFields.find(f => f.name === e.target.name);

    if (!formField) return validation;

    if (formField.properties.find(p => p.filesize !== undefined)) {
      validation = this.validator.validateSingleRule(
        this.props.formField.name,
        "isFileSizeMb",
        e.target.files[0].size
      );
    }

    if (
      validation.isValid &&
      formField.properties.find(p => p.filetype !== undefined)
    ) {
      validation = this.validator.validateSingleRule(
        this.props.formField.name,
        "isFileType",
        e.target.value
      );
    }
    return validation;
  }

  handleFileInputChange = async event => {
    let fieldname = event.target.name;
    let fieldValidation = this.validateFileTypeRules(event);
    
    if (fieldValidation.isValid) {
      let fileData = event.target.files[0];
      this.state.imgDelList[fieldname] = this.state[fieldname];
      fileData.blob = await convertFileToBlob(event.target.files[0]);
      await this.setState({[fieldname] : fileData});
    } else {
      // update form validation status
      let validation = this.state.validation;
      validation.isValid = false;
      validation[event.target.name] = fieldValidation[event.target.name];
      await this.setState({ validation: validation });
    }
  };

  handleSetFeaturedImage = async (fieldname, key) => {
    await this.setState({ featuredImageKey: key, 
                        [this.featuredImageField.name]: this.state[fieldname][key]['src']['image']});
    await this.triggerFormValidation();
  };

  editCaptionContent = async (key, fieldname, data) => {
    await this.setState({
      currentKey: key,
      currentFieldName: fieldname,
      caption: data.src.caption,
      isEditCaption: true
    });
  };

  saveCaptionContent = async captionData => {
    let newContent = this.state[this.state.currentFieldName];
    newContent.map((file, idx) => {
      if (idx === this.state.currentKey) {
        file.src.caption = captionData;
      }
      return null; //fix warnings
    });

    this.setState({
      [this.state.currentFieldName]: newContent,
      validation: { isValid: true },
      isEditCaption: false,
      caption: captionData
    });
  };

  cancelEditCaption = async () => {
    await this.setState({
      currentKey: "",
      currentFieldName: "",
      caption: "",
      isEditCaption: false
    });
  };

  handleGalleryImageAdd = async (fieldname, file, captionData) => {
    this.meta[fieldname].touched = true;

    let newState = this.state;
    newState[fieldname].push({
      src: { image: file, caption: captionData },
      addPending: true,
      removePending: false,
      featured: false,
      caption: captionData,
      isEditCaption: false
    });

    await this.setState(newState);

    // validate the current form state.
    await this.triggerFormValidation();
  };

  handleGalleryImageRemove = async (fieldname, key) => {
    this.meta[fieldname].touched = true;

    // console.log(fieldname + " " + key);

    // remove the item from the list if it was yet to be saved.
    // or mark it as removePending to be handled during submission

    let newState = this.state;

    this.state[fieldname].map((file, idx) => {
      if (idx === key) {
        if (file.addPending) {
          // no need to save anymore !
          newState[fieldname].splice(idx, 1);
        } else {
          file.removePending = true;
        }
        if (idx === newState["featuredImageKey"]) {
          newState[this.featuredImageField] = "";
          newState["featuredImageKey"] = null;
        }
      }
      return null; //fix warnings
    });

    await this.setState(newState);

    // validate the current form state.
    await this.triggerFormValidation();
  };

  triggerFormValidation = async () => {
    // pass meta to validate only touched input fields.
    const validation = this.validator.validate(this.state);
    await this.setState({ validation });
  }

  processMultipleUploadOnSubmit = async () => {
    // upload or delete images on S3 here
    let fieldData = this.state[this.galleryFileInput.name];
    let imageList = [];
    let idx = 0;
    for (let i = 0; i < fieldData.length; ++i) {
      if (fieldData[i].addPending) {
        let filename = this.props.imageDir
          ? this.props.imageDir + Date.now().toString()
          : Date.now().toString();
        fieldData[i]["filename"] = filename;
        // src should be a blob.
        let response = await uploadFilesToBackend([fieldData[i].src.image], [filename]);
        if (response.status === 'success') {
            let src = { image: response.url[0], caption: fieldData[i].src.caption}
            imageList[idx++] = {
                src: src,
                addPending: false,
                removePending: false
            };
        }
      } else if (fieldData[i].removePending) {
        // remove image from s3
        // console.log(fieldData[i].src);
        await removeFileFromBackend(fieldData[i].src.image);
      } else {
        // retain the same image in the list
        let src = {
          image: fieldData[i].src.image,
          caption: fieldData[i].src.caption
        };
        imageList[idx++] = {
          src: src,
          addPending: false,
          removePending: false
        };
      }

      // updated featured image data
      if (i === this.state.featuredImageKey && this.featuredImageField) {
        // console.log (this.featuredImageField.name + " " + imageList[idx - 1].src);
        this.state[this.featuredImageField.name] = imageList[idx - 1].src.image;
      }
    }

    // update formdata in the state
    this.state[this.galleryFileInput.name] = imageList;
  };

  handleFormSubmit = async event => {
    let formData = {};
    event.preventDefault();
    
    this.errorsOnSubmit = false;

    // handle actual form submission here
    if (this.state.validation.isValid) {
      
      if (this.props.preFormSubmit) {
        let result = await this.props.preFormSubmit(this.state);
        if (result.status !== "success") {
          this.errorsOnSubmit = true;
          return this.setState({
            isLoading: false, 
            submitStatus: result, 
            validation : result.data && result.data.isValid === false ? result.data : this.state.validation
          });
        }
      }
      
      // compose form data and submit
      await this.asyncForEach(this.inputFields, async f => {
        let fieldname = f.name;
        // if field is a image, and needs to be uploaded
        if (f.type === "image") {
          if (this.state[f.name] && this.meta[f.name].touched) {
            await this.setState({isImgUploading:true});
            // upload new image only when there is change
            let filename = this.props.imageDir
              ? `${this.props.imageDir}${uuid.v4()}`
              : uuid.v4();
            // src should be a blob.
            let response = await uploadFilesToBackend([this.state[fieldname]], [filename]);
            if(response.status === 'success') {
                this.state[fieldname] = formData[fieldname] = response.url[0];
            }
            
            // remove old image if present
            if (this.state.imgDelList[f.name]) {
                await removeFileFromBackend(this.state.imgDelList[f.name]);
            }
          }
        } else {
          formData[fieldname] = this.state[f.name];
        }
      });

      // upload gallery images
      if (this.galleryFileInput) {
        await this.setState({isImgUploading:true});
        
        await this.processMultipleUploadOnSubmit(this.galleryFileInput.name);
      
        formData[this.galleryFileInput.name] = this.state[
          this.galleryFileInput.name
        ].map((tile, idx) => {
          return {
            image: tile.src.image,
            caption: tile.src.caption ? tile.src.caption : ""
          };
        });

        console.log(formData);

        if (this.featuredImageField) {
          formData[this.featuredImageField.name] = this.state[
            this.featuredImageField.name
          ];
          console.log(formData[this.featuredImageField.name]);
        }
      }

      this.submitted = true;
      await this.setState({ isLoading: true,  isImgUploading:false,
        submitStatus: { status: "warning",  message: "Please wait while the request is being processed..."}
      });

      // call parent submit action. if parent submit action returns 
      // validation errors in the data object capture them in validation
      //
      let response = await this.props.handleSubmit(formData);
      if (response.data && !response.data.isValid) {
        await this.setState({ 
          isLoading: false, 
          submitStatus:  { status : response.status, message : response.message },
          validation : response.data
        });
      } else {
        await this.setState({ isLoading: false, submitStatus: response});
      }
      
      if (response.status === "success" && this.props.redirect) {
        this.props.navigate(this.props.redirect, {replace:true});
      }
    }
  };

  isFieldError(field, validation) {
    let fieldChanged = this.meta[field].touched || this.errorsOnSubmit ||
      (this.props.validateOnLoad && this.state[field] && this.state[field].toString().length);
    return (fieldChanged && validation[field] && validation[field].message !== '')
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  render() {
    const classes = this.props.classes;

    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    // check if gallery field is readonly

    let readonlyGallery = false;
    if (this.galleryFileInput) {
      let formfield = this.props.formFields.find(
        field => field.name === this.galleryFileInput.name
      );

      // check if a field is readonly from form field properties or
      // readonly field data passed
      //
      readonlyGallery =
        Boolean(formfield.properties.find(p => p.readOnly !== undefined)) ||
        (this.props.readOnlyFields
          ? this.props.readOnlyFields.indexOf(this.galleryFileInput.name) >= 0
          : false);
    }

    return (
      <React.Fragment>
        <form style={{padding : '16px'}}>
          <div>
            {this.submitted && this.state.submitStatus && (
              <CustomSnackbar
                variant={this.state.submitStatus.status}
                className={classes.margin}
                message={this.state.submitStatus.message}
                open={this.state.submitStatus.status}
                onClose={() => this.setState({ submitStatus: null })}
              />
            )}
            <br />

            <div className={classes.root}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} xl={6} xs={12} sm={6}>
                  {/* iterate and render all fields. Use meta data */
                  this.inputFields.map((f, i) => {
                    let formfield = this.props.formFields.find(
                      field => field.name === f.name
                    );

                    // check if a field is readonly from form field properties or
                    // readonly field data passed
                    //
                    let readonly =  Boolean(formfield.properties.find(p => p.readOnly !== undefined)) ||
                                                (this.props.readOnlyFields ? 
                                                 this.props.readOnlyFields.indexOf(f.name) >= 0 :
                                                 false);
                    
                    if (formfield.type === 'header') {
                        return (
                            <Grid key={i} item xs={12} sm={12} md={12}>
                                <Typography variant="h6">{formfield.label}</Typography><hr style={{borderColor:"#eef1f6"}} />
                            </Grid>);
                    }
                                
                    return formfield.type === "image" &&
                      formfield.properties &&
                      formfield.properties.find(p => p.crop !== undefined) ? (
                      <ImageCrop
                        classes={classes}
                        formField={formfield}
                        action={this.props.action}
                        isHtmlInput={true}
                        value={this.state[f.name]}
                        onSave={this.handleImageCropChange}
                        readOnly={readonly}
                      />
                    ) : (
                      <FieldCreator
                        key={"key_" + i}
                        className={classes.textField}
                        name={f.name}
                        type={f.type}
                        label={f.label}
                        selectOptions={f.selectOptions}
                        selectMultiple={formfield.properties.find(p => p.multiple !== undefined)}
                        value={this.state[f.name]}
                        onChange={this.handleInputChange}
                        onEditorChange={this.handleEditorChange}
                        onFileInputChange={this.handleFileInputChange}
                        error={this.isFieldError(f.name, validation)}
                        helperText={
                          this.isFieldError(f.name, validation)
                            ? validation[f.name].message
                            : ""
                        }
                        readOnly={readonly}
                        valueStrings={formfield.valueStrings}
                      />
                    );
                  })}
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12} sm={6}>
                  {this.galleryFileInput != null && (
                    <div className={classes.row}>
                      <ImageGridList
                        ref={this.refGallery}
                        action={this.props.action}
                        formField={this.galleryFileInput}
                        images={
                          this.galleryFileInput
                            ? this.state[this.galleryFileInput.name]
                            : []
                        }
                        addImageHandler={this.handleGalleryImageAdd}
                        removeImageHandler={this.handleGalleryImageRemove}
                        editCaptionContentHandler={this.editCaptionContent}
                        featureImageHandler={this.handleSetFeaturedImage}
                        featuredImageKey={this.state["featuredImageKey"]}
                        isFeaturedImage={this.featuredImageField !== null}
                        readOnly={readonlyGallery}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <Button
              fullWidth = {this.props.buttonWidth === undefined ||  this.props.buttonWidth === 'full' ? true: false}
              type="submit"
              color= {this.props.buttonColor === undefined ? "primary" : this.props.buttonColor }
              disabled={!validation.isValid || this.state.isLoading || this.state.isImgUploading}
              onClick={this.handleFormSubmit}
              onSubmit={this.handleFormSubmit}
              size="large"
              variant="contained"
          >
            {this.state.isImgUploading ? "Uploading Images..." : 
                this.state.isLoading ? "Please Wait..." : this.props.buttonText}
          </Button>
          {this.state.isEditCaption && (
            <CaptionForm
              cancelEditCaptionHandler={this.cancelEditCaption}
              saveEditCaptionHandler={this.saveCaptionContent}
              data={this.state.caption}
            />
          )}
        </form>
      </React.Fragment>
    );
  }
}

DynamicFormGallery.defaultProps = {
  data: [],
  onSelect: () => {},
  onShowDetails: () => {},
  formFields: []
};

export default DynamicFormGallery;
