import { createTheme } from '@mui/material/styles';
import { colors  } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#eef1f6',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#006fb6'
    },
    secondary : {
      main : '#009688',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    error: { 
      main: "#ff604f", 
      dark: "#ff604f"
    },
    colors : {
      yellow : '#fefe33',
    },
    disabled : {
      main : '#dadcdf'
    }
  },
  shadows,
  typography
});

export default theme;
