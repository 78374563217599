import React, { Component } from 'react';

import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import { HatcheryStore } from 'store';

export default class HatcheryExpiryTimeCard extends Component {
  render() {
    const hatcheryData = HatcheryStore.state.currentHatchery;
    let days = 0;
    if (hatcheryData && hatcheryData.license) {
      days = Math.floor((new Date(hatcheryData.license.validTo) - new Date()) / (1000 * 60 * 60 * 24));
    }
    
    return (
      <SummaryWidget 
        label = {'License Expires in (days)'} 
        count = {days} 
        icon = {<AccessTimeOutlined />}
        url = { hatcheryData ? '/aqf/hatcheries/view/' + hatcheryData.id : '/'}
      />
    );
  }
}