import {
  Box,
  Typography,
  Button
} from '@mui/material';

import { NavLink } from 'react-router-dom';

const ConfirmationPage = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p : 1
    }}
  >
    
    <Box sx={{ textAlign: 'center', mt : 2}}>
      <img
        alt="confirmation page"
        src= {
          props.type === 'success' ? 
          process.env.PUBLIC_URL + "/static/images/misc/success.png" : 
          process.env.PUBLIC_URL + "/static/images/misc/error.png"
        }
        style={{
          display: 'inline-block',
          width: "auto",
          height: 200
        }}
      />
      <Typography align="center" color="textPrimary" variant="h2"> {props.title} </Typography><br/>
      <Typography align="center" color="textPrimary" variant="body2"> {props.message} </Typography>
      {
        props.redirect && 
        <>
        {
          typeof props.redirect === 'function' ?
          <Button variant="contained" onClick={props.redirect} sx={{mt:2}}>{props.redirectText}</Button> :
          <Button component={NavLink} variant="contained" to={props.redirect} sx={{mt:2}}>{props.redirectText}</Button>
        }
        </>
      }
    </Box>
  </Box>
);

export default ConfirmationPage;