import React, { Component } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { HomeWrapperComponent, ProtectedComponent } from 'hoc';

// layout views
import DashboardLayout from 'layout/DashboardLayout';
import MainLayout from 'layout/MainLayout';

// app pages
import Home from 'pages/frontpage/index';
import NotFound from 'pages/common/404Page';
import Dashboard from 'pages/dashboard/index';
import UpdateAccount from 'pages/user-accounts/update-account';
import Support from 'pages/frontpage/support';
import Policy from 'pages/frontpage/policy';

// import module wise route components
import HatcheryRoutes from './components/HatcheryRoutes';
import BookingRoutes from './components/BookingRoutes';
import BookingScheduleRoutes from './components/BookingScheduleRoutes';
import AdminRoutes from './components/AdminRoutes';
import AppReportRoutes from './components/AppReportRoutes';

import { AuthStore } from 'store';

const isAuthenticated = () => {
  let currentUser = AuthStore.state.currentUser;
  return currentUser ? true : false;
};

export default class AppRoutes extends Component {
  render() {
    console.log("rendering routes");
    return (<>
      <Routes>
        <Route key="aqf-common-home" path="/" 
          element={
            <HomeWrapperComponent Component={Home} redirectLink='/aqf/dashboard' authCheck={isAuthenticated} />
          }
        />
        <Route key="aqf-common-authenticated" path="aqf/dashboard/*" 
          element={
            <ProtectedComponent Component={DashboardLayout} redirectLink='/' authCheck={isAuthenticated}/>
          }
        >
          <Route key="aqf-common-user-account" path="account" element={<UpdateAccount />} />
          <Route key="aqf-common-dashboard" path="*" element={<Dashboard />} />
        </Route>
        
        <Route key = "all-hatchery-routes" path="aqf/hatcheries/*" element={<HatcheryRoutes isAuthenticated = {isAuthenticated} />} />
        <Route key = "all-booking-routes" path="aqf/bookings/*" element={<BookingRoutes isAuthenticated = {isAuthenticated} />} />
        <Route key = "all-booking-schedule-routes" path="aqf/booking-schedules/*" element={<BookingScheduleRoutes isAuthenticated = {isAuthenticated} />} />
        <Route key = "all-admin-routes" path="aqf/admin/*" element={<AdminRoutes isAuthenticated = {isAuthenticated} />} />
        <Route key = "all-app-report-routes" path="aqf/reports/*" element={<AppReportRoutes isAuthenticated = {isAuthenticated} />} />
        
        <Route key="aqf-main-help" path="main" element={<MainLayout />}>
          <Route key="aqf-help-view" path="support" element = {<Support />} />
          <Route key="aqf-privacy-view" path="privacy" element = {<Policy type="privacy" />} />
          <Route key="aqf-tc-view" path="terms" element = {<Policy type="terms" />} />
          <Route key="aqf-refund-view" path="cancellation-refunds" element = {<Policy type="refunds" />} />
        </Route>
      
        <Route key="not-found" path="not-found/*" element= {<MainLayout /> }>
          <Route key="not-found-page" path= "*" element={<NotFound />} />
        </Route>
        <Route key="no-matching-route" path="*" element= {<Navigate to="/not-found" /> } />
      </Routes>
    </>);
  }
}