import { useState,useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CustomSnackbar from 'components/CustomSnackbar';
import EntryTabularView from 'components/EntryTabularView';
import { fetchBookingsData } from 'services/bookings';

import { HatcheryStore } from 'store';

const remittenceFields = [{
  'name' : 'status',
  'label': 'Status',
  'type' : 'select',
  'selectOptions' : { 'closed' : 'EXPIRED', 'active' : 'ACTIVE', 'new' : 'NEW ACCOUNT' },
  'properties' : [],
  'formatter' : {'el' : 'chip', 'color' : 'success', 'size' : 'small'},
  'actions' : ['view'],
}, {
  'name' : 'name',
  'label' : 'Beneficiary Name',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'account_number',
  'label' : 'Virtual Account Number',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'ifsc',
  'label' : 'IFSC Code',
  'properties' : [],
  'actions' : ['view'],
}, {
  'name' : 'bank_name',
  'label' : 'Bank Name',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'account1',
  'label' : 'Allowed Account #1',
  'properties' : [],
  'actions' : ['view'],
}, {
  'name' : 'account2',
  'label' : 'Allowed Account #2',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'created_at',
  'label' : 'Active Since',
  'type' : 'string',
  'properties' : [],
  'actions' : ['view']
}, {
  'name' : 'closed_at',
  'label' : 'Expired On',
  'type' : 'datetime',
  'properties' : [],
  'actions' : ['view']
},
{
  'name' : 'close_by',
  'label' : 'Valid Till',
  'type' : 'datetime',
  'properties' : [],
  'actions' : ['view']
}];

const VirtualAccountCard = (props) => {

  let formData = {};
  let account_ago_days = 0;
  let virtualAccount = props.hatcheryData ? props.hatcheryData.rzrpayVirtualAccount : null;
  const [modalOpen, setModalOpen] = useState(true);
  const [isBookingPaymentPending, setIsBookingPaymentPending] = useState(false);
  const [bookingid, setBookingId] = useState(null);

  useEffect(() => {

    const checkBookingPayment = async () => {
      if (props.from && props.from=="hatchery")
      {
        let curdate = new Date();
        let startDate = new Date(curdate.getFullYear(), curdate.getMonth(), 1);
        let endDate = new Date(curdate.getFullYear(), curdate.getMonth()+1, 1);
    
        const hatcheryData =  HatcheryStore.state.currentHatchery;
        try {
          let response = await fetchBookingsData({
          type : 'date-range',
          page : 1,
          per_page : 100,
          status : 'Blocked',
          supplierId : 'ALL',
          hatcheryId : hatcheryData.id,
          startDate : startDate.toISOString(),
          endDate : endDate.toISOString(),
          speciesType : hatcheryData.permittedSpeciesId.id,
          shipmentStatus : 'ALL'
          });
        if (response.data && response.data.length>0)
        {
          setIsBookingPaymentPending(true);
          setBookingId(response.data[0].id);
        }

        }
        catch(error)
        {
          console.log(error);
        }
      }};

      checkBookingPayment();
   },[props]);

  
  if (virtualAccount) {
    formData = virtualAccount.receivers && virtualAccount.receivers.length ? virtualAccount.receivers[0] : {};
    if (virtualAccount.allowed_payers && virtualAccount.allowed_payers[0]) {
      formData['account1'] = 
        `${virtualAccount.allowed_payers[0].bank_account.account_number} / ${virtualAccount.allowed_payers[0].bank_account.ifsc}`;
    } 
    if (virtualAccount.allowed_payers && virtualAccount.allowed_payers[1]) {
      formData['account2'] = 
        `${virtualAccount.allowed_payers[1].bank_account.account_number} / ${virtualAccount.allowed_payers[1].bank_account.ifsc}`;
    }
    if (virtualAccount.close_by) {
      formData['close_by'] = new Date(virtualAccount.close_by * 1000);
      delete remittenceFields[8];
    } else if (virtualAccount.closed_at) {
      delete remittenceFields[7];
      delete remittenceFields[9];
      formData['closed_at'] = new Date(virtualAccount.closed_at * 1000);
    } else {
      delete remittenceFields[8];
      delete remittenceFields[9];
    }
    formData['status'] = virtualAccount.status;
    
    if (formData['status'] === "active") {
      account_ago_days =  Math.ceil(((new Date()).getTime() - (virtualAccount.created_at * 1000))/(1000 * 60 * 60 * 24));
      formData['created_at'] = account_ago_days + ' day/s';
      if (account_ago_days < 15) {
        // new account created recently
        remittenceFields[0].formatter.color="warning";
        formData['status'] = "new";
      }
    } else {
      // account in closed state
      // remittenceFields[2]['formatter'] = {'el' : 'imgafter', 'path' : process.env.PUBLIC_URL + "/static/images/misc/expired.png"};
      remittenceFields[0].formatter.color="error";
    } 
  }
  
  return (
    <>
      {
        virtualAccount ?
        <EntryTabularView 
          title={"Payment NEFT / RTGS Details"}
          formFields={remittenceFields}
          noPaper={props.noPaper}
          values = {formData}
        /> : 
        <Paper elevation={props.noPaper ? 0 : 3} sx={{p:1, mt: 1, textAlign:"left"}}>
          <Typography variant="h4" color="error.main"> Action Required !!!! </Typography><br/><hr style={{borderColor:"#eef1f6"}} />
          <Typography variant="subtitle1" color="primary.main" sx={{mt:1}} align="left"> 
            i. Payments are allowed through NEFT / RTGS from approved bank accounts ONLY. This hatchery does not have
            approved bank accounts. Please add bank account ( upto 2 accounts are allowed ). Once they are 
            approved, bookings can be done.
          </Typography>
          <Typography variant="subtitle1" color="primary.main" sx={{mt:1}} align="left"> 
            ii. Once back account details are approved, hatchery will be provided with a unique RGCA virtual account number
            to which, the payments can be made via NEFT / RTGS from the registered bank account. Virtual account
            information will appear here.
          </Typography>
        </Paper>
      }
       {
        // hatchery owner logged in, alert about new virtual account
        isBookingPaymentPending &&
        <CustomSnackbar variant="warning"
          message={
            `Payment for the booking with id ${bookingid} is pending, Please make the payment within 24 hours of booking.`
          }
          open={modalOpen}
          onClose={()=> setModalOpen(!modalOpen)} 
        />
      }
      {
        // hatchery owner logged in, alert about new virtual account
        !isBookingPaymentPending && HatcheryStore.state.currentHatchery && virtualAccount && account_ago_days < 15 &&
        <CustomSnackbar variant="warning"
          message={
            "RGCA virtual account details used for NEFT / RTGS payments have been recently updated. \
             Please add the new payee details to your registered bank account. Please ignore if done already"
          }
          open={modalOpen}
          onClose={()=> setModalOpen(!modalOpen)} 
        />
      }
      {
        // admin login, alert if the virtual account was closed, it should be created automatically
        !isBookingPaymentPending && virtualAccount && virtualAccount.status === "closed" && !HatcheryStore.state.currentHatchery &&
        <CustomSnackbar variant="warning"
          message={
            "RGCA virtual account used for NEFT / RTGS payments has expired for this hatchery. New virtual \
            account will be created automatically when the hatchery owner logs in."
          }
          open={modalOpen}
          onClose={()=> setModalOpen(!modalOpen)} 
        />
      }
    </>
  );
}

export default VirtualAccountCard;
