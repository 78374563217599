import React, { Component } from "react";

import { withLocation, withPrint } from 'hoc';

// Material helpers
import { withStyles, createStyles } from "@mui/styles";
import { Grid, Button, Box } from '@mui/material';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import DTable from "components/DataTables/DTable";
import RToolbar from "components/DataTables/RToolbar";

import NavigateBack from 'components/NavigateBack';

import theme from 'theme';

// Component styles
const styles = createStyles({
  root: {
    padding: theme.spacing(1),
  },
  item: {
    height: "100%"
  }
});

class RTableView extends Component {
  constructor(props) {
    super(props);
    let toolbarState =  props.location && props.location.state && props.location.state.toolbarState 
      ? props.location.state.toolbarState : null;
    
    this.state = {
      contents: [],
      totalCount : 0,
      selectedItems: [],
      selectFilterValues: toolbarState ? toolbarState.selectFilterValues : {},
      page: props.location && props.location.state && props.location.state.page 
        ? props.location.state.page : 1,
      rowsPerPage: props.location && props.location.state && props.location.state.rowsPerPage 
        ? props.location.state.rowsPerPage : 50,
      exportData: [],
      searchValue:  toolbarState ? toolbarState.searchValue : '',
      statusMessage : RToolbar.STATUS_MESSAGE_WAIT,
      printView : false
    };
    
    this.selectFilters = props.selectFilters;
    
    window.addEventListener("onafterprint", () => this.setState({ printView : false }));
  }
  
  setApiFailureState = async (error = null) => {
    await this.setState({
      contents : [], 
      totalCount : 0, 
      page : 1, 
      statusMessage : error && error.message ? { status : "error", message : error.message } : RToolbar.STATUS_MESSAGE_API_FAILURE
    });
  }
  
  setApiSuccessState = async(totalCount, contents, page) => {
    await this.setState({
      contents: contents,
      totalCount : totalCount,
      page : page,
      statusMessage : contents.length === 0 ?  RToolbar.STATUS_MESSAGE_NO_CONTENTS: null
    });
  }

  handleFetchClick = async () => {
    // fetch updated content based on selected filters
    try {
      await this.setState({statusMessage : RToolbar.STATUS_MESSAGE_WAIT});
      let totalCount = await this.props.fetchCountApi(this.state.selectFilterValues, this.state.searchValue);
      let response = await this.props.fetchDataApi(
        this.state.page, this.state.rowsPerPage, this.state.selectFilterValues,
        this.state.searchValue);
      await this.setApiSuccessState(parseInt(totalCount.data), response.data, 1);
    } catch (error) {
      await this.setApiFailureState(error);
      console.log(error);
    }

  }

  handleSelectFilterChange = async event => {
    // fetch content with page no.
    
    let selectFilterValues = this.state.selectFilterValues;
    
    if (event.target.value === selectFilterValues[event.target.name]) return;
    
    selectFilterValues[event.target.name] = event.target.value;
    
    await this.setState({selectFilterValues: selectFilterValues});
    
    // update select filter value to display
    for (let i = 0; i < this.selectFilters.length ; ++i) {
      if (this.selectFilters[i].name === event.target.name) {
        this.selectFilters[i].value = event.target.value;
        break;
      }
    }
    
    
  };

  handleSelectAll = async event => {
    const { contents } = this.state;
      // update selectedItems list
    let selectedItems = [];
    if (event.target.checked) {
      // select items which can be selected.ignore parent feeds
      contents.forEach(item => {
      if (item.selection) selectedItems.push(item.series_id);
      });
    } else {
      selectedItems = [];
    }
    this.setState({ selectedItems });
  };

  handleSelectOne = async (event, series_id, item_id) => {
    const { selectedItems } = this.state;

    const selectedIndex = selectedItems.indexOf(series_id);

    let newSelectedItems = [];

    if (selectedIndex === -1) {
      newSelectedItems = newSelectedItems.concat(selectedItems, series_id);
    } else if (selectedIndex === 0) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
      selectedItems.slice(0, selectedIndex),
      selectedItems.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedItems: newSelectedItems });
  };

  handlePageChange = async (event, newPage) => {
    await this.setState({ page: newPage + 1, statusMessage : RToolbar.STATUS_MESSAGE_WAIT});
    try {
      // fetch content with page no.
      let response = await this.props.fetchDataApi(
        this.state.page, this.state.rowsPerPage, this.state.selectFilterValues,
        this.state.searchValue);
        
      await this.setApiSuccessState(this.state.totalCount, response.data, newPage + 1);
    } catch (error) {
      console.log(error);
      await this.setApiFailureState(error);
    }
  };

  handleRowsPerPageChange = async event => {
    await this.setState({rowsPerPage: event.target.value, page:1, statusMessage : RToolbar.STATUS_MESSAGE_WAIT});
    try {
      // fetch content with page no.
      let response = await this.props.fetchDataApi(
        this.state.page, this.state.rowsPerPage, this.state.selectFilterValues,
        this.state.searchValue);
      await this.setApiSuccessState(this.state.totalCount, response.data, this.state.page);
    } catch (error) {
      console.log(error);
      await this.setApiFailureState(error);
    }
  };

  handleSearchChange = async event => {
    await this.setState({searchValue: event.target.value, statusMessage : RToolbar.STATUS_MESSAGE_WAIT, page : 1 });
    try {
      // fetch count of all entries
      let totalCount =  await this.props.fetchCountApi(this.state.selectFilterValues, this.state.searchValue);
      
      // fetch content with page no.
      let response = await this.props.fetchDataApi(
        this.state.page, this.state.rowsPerPage, this.state.selectFilterValues,
        this.state.searchValue);
      
      await this.setApiSuccessState(parseInt(totalCount.data), response.data, 1);
    } catch (error) {
      console.log(error);
      await this.setApiFailureState(error);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
  
    if (this.props.updateContent) {
    
      // update filters loaded dynamically, use filters, page and search values 
      // from location props if available
      this.selectFilters = this.props.selectFilters;
      let toolbarState =  this.props.location && this.props.location.state ? this.props.location.state.toolbarState : null;
      if (!toolbarState) {
        let selectFilterValues = {};
        for (let i = 0; i < this.props.selectFilters.length ; ++i) {
          selectFilterValues[this.props.selectFilters[i].name] = this.props.selectFilters[i].value;
        }
        await this.setState({selectFilterValues : selectFilterValues, page : 1});
      }
      try {
        // get total data count
        let totalCount = await this.props.fetchCountApi(this.state.selectFilterValues, this.state.searchValue);
        
        // fetch table data
        let response = await this.props.fetchDataApi(
          this.state.page, this.state.rowsPerPage, this.state.selectFilterValues,
          this.state.searchValue);
        
        await this.setApiSuccessState(parseInt(totalCount.data), response.data, this.state.page);
      
      } catch (error) {
        console.log(error);
        await this.setApiFailureState(error);
      }
    }
    
    if (this.state.printView) {
      this.props.handlePrint();
      setTimeout (() => this.setState({printView : false}), 500);
    }
    
  }
  
  handlePrint = () => {
    this.setState({printView : true});
  }
  
  deleteMultiple = async selectedItems => {
    let deleteData = [];
    this.state.selectedItems.forEach(item => {
      deleteData.push({ type: "DELETE", id: item });
    });
  
    // TODO :: delete api
  };

  
  exportEvent = async () => {
    try {
      await this.props.exportEvent(this.state.selectFilterValues, this.state.searchValue);
    } catch (error) {
      console.log(error);
      await this.setApiFailureState(error);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {
          this.state.printView === false &&
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px:1, py : 1}} component="span">
              <Button
                variant="outlined" 
                color="warning" 
                size="small"
                startIcon={<PrintOutlinedIcon />}
                onClick={this.handlePrint}
              > 
                Print 
              </Button>
            </Box>  
            <NavigateBack />
          </Grid>
        }
        {
          this.state.printView === false &&
          <RToolbar
            selectFilters ={this.selectFilters}
            onSearch={this.handleSearchChange}
            statusMessage = {this.state.statusMessage}
            addUrl = {this.props.addUrl}
            addButtonText = {this.props.addButtonText}
            searchEnable = {this.props.searchEnable}
            searchPlaceholder = {this.props.searchPlaceholder}
            searchValue = {this.state.searchValue}
            handleSelectFilterChange={this.handleSelectFilterChange}
            handleFetchClick={this.handleFetchClick}
            contentType={this.props.title}
            toolbarState={{selectFilterValues : this.state.selectFilterValues, searchValue : this.state.searchValue}}
            export= {{ enable : this.props.exportEnable,  event: this.exportEvent }}
            // selectedItems={this.state.selectedItems}
            // deleteContent={this.deleteMultiple}
           
          />
        }
        <DTable
          handlePageChange={this.handlePageChange}
          handleRowsPerPageChange={this.handleRowsPerPageChange}
          columns = {this.props.columns}
          rowsPerPage={this.state.rowsPerPage}
          dataTotal={this.state.totalCount}
          data={this.state.contents}
          page={this.state.page}
          itemUrlPath={this.state.printView ? null : this.props.itemUrlPath}
          editUrlPath={this.state.printView ? null : this.props.editUrlPath}
          // handleSelectAll={this.handleSelectAll}
          // handleSelectOne={this.handleSelectOne}
          // selectedItems={this.state.selectedItems}
          toolbarState={{selectFilterValues : this.state.selectFilterValues, searchValue : this.state.searchValue}}
          multiDeleteEnabled = {this.props.multiDeleteEnabled}
        />
      </>);
    }
}

export default withPrint(withStyles(styles)(withLocation(RTableView)));