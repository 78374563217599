// all higher order components (hoc) can be defined here !!!
import { Routes, Route, Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

export const ProtectedComponent = ({Component, redirectLink, authCheck}) => {
  if(!authCheck()) {
    return <Navigate to={redirectLink} />;
  }
  return (<Component />);
};

export const HomeWrapperComponent = ({Component, redirectLink, authCheck}) => {
  if(authCheck()) {
    return <Navigate to={redirectLink} />;
  }
  return (<Component />);
};

/*export const ProtectedRoute = ({ component: Component, redirectLink, authCheck, path, ...props }) => {
  if (path === "/") {
    return (
      <Routes>
        <Route key={path}
          path={path}
          element={<HomeContainerRoute redirectLink={redirectLink} authCheck={authCheck} Component={Component} />} 
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route key={path}
          path={path}
          element={<ContainerRoute redirectLink={redirectLink} authCheck={authCheck} Component={Component} />} 
        >
          {props.children }
        </Route>
      </Routes>
    );
  }
};*/

export const withNavigator = (Component: any) => {
  return (props: any) => {
    const navigate  = useNavigate();
    return <Component navigate={navigate} {...props} />;
  };
};

export const withLocation = (Component: any) => {
  return (props: any) => {
    const location  = useLocation();
    return <Component location={location} {...props} />;
  };
};

export const withLocationParams = (Component: any) => {
  return (props: any) => {
    const locationParams  = useParams();
    const location  = useLocation();
    return <Component location = {location} locationParams={locationParams} {...props} />;
  };
}

export const withPrint = (Component: any) => {
  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <Component handlePrint = {props.handlePrint} {...props} />
      </div>
    );
  });

  return (props: any) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });
    return (<ComponentToPrint handlePrint={handlePrint} ref={componentRef} {...props} />);
  };
}
