import React, { Component } from 'react';

import { 
  TextField, 
  Box, 
  Table, 
  TableHead, 
  TableBody,
  TableRow, 
  TableCell,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const headerboxStyle = { width: 64, height: 40,  p: 1, m : 1};
const boxStyle = { width: 64, height: 40,  p: 1, m : 1, borderRadius : '50%', borderColor : 'primary.main', border: '1px dashed', cursor:'pointer' };
const pastdateboxStyle = { bgcolor: '#c9c9c9', color:'#fff', width: 64, height: 40, p: 1, m : 1, borderRadius : '50%', cursor:'pointer', border: '1px dashed' };

export default class Calendar extends Component {
  
  state = {
    currentMonth : this.props.date ? this.props.date : new Date().toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
    selectedDate : null
  };
  
  constructor(props) {
    super(props);
    
    let currentMonth = new Date(this.state.currentMonth);
    this.year = currentMonth.getFullYear();
    this.month =  currentMonth.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    this.totalDays = Math.abs((this.lastDay.getTime() - this.firstDay.getTime()) / (24*60*60*1000)) + 1;
  }
  
  handleMonthChange = async (value) => {
    let currentMonth = new Date(value);
    this.year = currentMonth.getFullYear();
    this.month =  currentMonth.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    this.totalDays = Math.abs((this.lastDay.getTime() - this.firstDay.getTime()) / (24*60*60*1000)) + 1;
    
    this.setState({ currentMonth : currentMonth.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'})});
    
    await this.props.handleMonthChange(this.month, this.firstDay, this.lastDay);
  }
  
  handleDateSelect = async (day) => {
    let value = new Date(`${this.year}-${this.month + 1}-${day}`).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
    this.setState({selectedDate : value});
    return await this.props.handleDateSelect(value);
  }
  
  dayColor = (day) => {
    
    let date = new Date(`${this.year}-${this.month + 1}-${day}`).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
    let style = boxStyle;
    
    if (this.props.disableDate && this.props.disableDate(date)) {
      style.bgcolor = '#c9c9c9';
    } else if (this.state.selectedDate === date) {
      style.bgcolor = 'primary.main';
    } else {
      style.bgcolor = this.props.dayColor(date);
    }
    return style;
  }
  
  async componentDidMount() {
    await this.props.handleMonthChange(this.month, this.firstDay, this.lastDay);
  }
  
  render() {
    let monthName = this.firstDay ? this.firstDay.toLocaleString('default', { month: 'short' }) : '';
    let dayname = this.firstDay ? this.firstDay.toLocaleString('default', { weekday : 'short' }).toUpperCase() : '';
    let startOffset = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].findIndex(day => day === dayname);
    
    let daycount = 0;
    let rows = [];
    if (monthName && dayname) {
      let eachRow = [];
      for (let i = 0; i < startOffset; ++i) {
        eachRow.push(0);
      }
      
      for (let i = 0; i < this.totalDays; ++i) {
        if (eachRow.length === 7) {
          rows.push(eachRow);
          eachRow = [];
        }
        eachRow.push(i+1);
      }
      
      if (eachRow.length) rows.push(eachRow);
    }

    return (
      <Card key="0-card">
        <CardHeader
          action={(
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month']}
                label="Select Month"
                value={this.state.currentMonth}
                onChange={this.handleMonthChange}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
                margin="normal"
                variant="standard"
              />
            </LocalizationProvider>
          )}
          title={this.props.title ? this.props.title : "Pick a Date"}
        />
        <Divider />
        <CardContent>
          <Table sx={{maxWidth : 360}}>
            <TableHead><TableRow>
            {
              ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map (e => {
                return (<TableCell key ={`cell${e}`} sx={{p:1}}>
                  <Box
                    key={e}
                    component="span" 
                    sx={headerboxStyle} 
                  >
                    {e}
                  </Box>
                </TableCell>)
              })
            }
            </TableRow></TableHead>
            <TableBody>
            {
              rows.map((week, idx) => {
                return (<TableRow key={idx}>
                {
                  week.map((day, idx) => {
                    if (day == 0) {
                      return (<TableCell key ={`innercell${idx}`}  sx={{border:'none', p:1}} />)
                    }
                    
                    let currentdate = new Date(this.year + '-' + (this.month+1) + '-' + day).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
    
                    return (<TableCell key ={`innercell${idx}`}  sx={{border:'none', p:1, height:64}}>
                      <Box
                        key={day}
                        component="span" 
                        sx={() => this.dayColor(day)}
                        onClick={ this.props.disableDate && this.props.disableDate(currentdate) ? null : async () => await this.handleDateSelect(day)}
                      >
                      {
                          day >= 10 ? <>{day}</> : <>&nbsp;{day}&nbsp;</>
                      }
                      </Box>
                    </TableCell>)
                  })
                }
                </TableRow>)
              })
            }
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
};