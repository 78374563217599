import { fetchWithTimeout, addPagerParams } from './utils';

import { permissionCheck } from 'permissions';

const ADDN_CHARGES_EP_URL = "/additional-booking-charges";

export const fetchAddnChargesTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : ADDN_CHARGES_EP_URL + '/count?_start=0'
        + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.oldId && params.oldId.length ? '&bookingId.oldId=' + params.oldId : ''),
    method : 'GET',
    operation : permissionCheck('transaction','view-additional-charges-self') 
      ? 'transaction:view-additional-charges-self' 
      : 'transaction:view-additional-charges'
  });
};

export const fetchAddnChargesData = async (params) => {
  return await fetchWithTimeout({
    path : ADDN_CHARGES_EP_URL + addPagerParams(params) 
        + (params.hatcheryId !== 'ALL' ? '&hatcheryId=' + params.hatcheryId : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.bookingId && params.bookingId.length ? '&bookingId=' + params.bookingId : '')
        + (params.hatcheryName && params.hatcheryName.length ? '&bookingId.hatcheryId.name_contains=' + params.hatcheryName : '')
        + (params.oldId && params.oldId.length ? '&bookingId.oldId=' + params.oldId : ''),
    method : 'GET',
    operation : permissionCheck('transaction','view-additional-charges-self') 
      ? 'transaction:view-additional-charges-self' 
      : 'transaction:view-additional-charges'
  });  
};

export const fetchAddnChargesById = async (addnChargesId) => {
  return await fetchWithTimeout({
    path : ADDN_CHARGES_EP_URL + '/' + addnChargesId,
    method : 'GET',
    operation : permissionCheck('transaction','view-additional-charges-self') 
      ? 'transaction:view-additional-charges-self' 
      : 'transaction:view-additional-charges'
  });
};

export const createNewAddnCharges = async (params) => {
  return await fetchWithTimeout({
    path: ADDN_CHARGES_EP_URL,
    method: 'POST',
    body : params,
    operation : 'transaction:create-additional-charges'
  });
};

export const updateAddnCharges = async (addnChargesId, params) => {
   return await fetchWithTimeout({
    path: ADDN_CHARGES_EP_URL + '/' + addnChargesId,
    method: 'PUT',
    body : params,
    operation : 'transaction:update-additional-charges'
  });;
};