/* Rule are part of the dynamic fields listed as properties in JSON format.
 *  Example:
 *   {
 *    name: xxx, label: xxxx, type: xxx,  
 *    properties: [{ required : true }, {email: true}, {string: {min:6, max:10}}.
 *           { fileSize: {min:1, max:5}}, {fileType: ['jpg', 'png', 'gif']},
 *           { gallery: {min:1, max:100}}
 *          ]
 *   }
 */


// custom file validation rules

const isFileSizeMb = (value, args) => {
  var { min, max } = args ? { min: args.min, max:args.max } :  { min:0, max:0 };
  
  // min, max not specified. allow any size.
  if (!min && !max) return true;
  
  min = min * 1024 * 1024;
  max = max * 1024 * 1024;
  
  // console.log (`filesz validation, min: ${min} max: ${max} actual: ${value}`);
  
  let filesize = 0;
  if (typeof value === 'file') {
    // handle file object validation  here
    console.log(value.name +  " " + value.size);
    filesize = value.size;
  } else {
    filesize = value; 
  }
  
  return (filesize >= min && filesize <= max );   
}

const isFileType = (value, args) => {
  
  // no extensions listed, allow any file type.
  if (!args.length) return true;
  
  let parts = [];
  
  if (typeof value === 'object') {
    // handle file object validation  here
    parts = value.name.split('.');
  } else {
    parts = value.split('.');
  }

  return args.indexOf(parts[parts.length - 1]) >= 0;
}

const isMaxFileCount = (files, args ) => {
  
  // check multiple files
  if (Array.isArray(files)) {
    // if no files in gallery
    if (!files.length) return true;
    var { min, max } = args.length > 0 ? { min: args.min, max:args.max } :  { min:0, max:0 };
  
    // min, max not specified. allow any number of files in gallery.
    if (!min && !max) return true;
  
    return (files.length >= min && files.length <= max );   
  }
  
  return true;
}

const isImgExactResol = (crop, args) => {
   
  // no dimensions listed, allow any image sizes.
  if (!args) return true;
   
  var { width, height } = args ? { width: args.width, height:args.height } :  { width:0, height:0 };
  
   try {
    let data = JSON.parse(crop);
    return data.width === width && data.height === height; 
   } catch (error) {
    return false;
   }
}

const isImgMinResol = (crop, args) => {
   
  // no dimensions listed, allow any image sizes.
  if (!args) return true;
  
  var { width, height } = args ? { width: args.width, height:args.height } :  { width:0, height:0 };
  
  try {
    let data = JSON.parse(crop);
    return data.width >= width && data.height >= height; 
  } catch (error) {
    return false;
  }
}

const isImgMaxResol = (crop, args) => {
   
  // no dimensions listed, allow any image sizes.
  if (!args) return true;
  
  var { width, height } = args ? { width: args.width, height:args.height } :  { width:0, height:0 };
  
  try {
    let data = JSON.parse(crop);
    return data.width <= width && data.height <= height; 
  } catch (error) {
    return false;
  }
}

const isImgAspectRatio = (crop, args) => {
   
  // console.log (args);

  // no dimensions listed, allow any image sizes.
  if (!args) return true;
  
  let aspect = args ? args.aspect : null;
  
  if (!aspect) return false;
  
  try {
    let data = JSON.parse(crop);
    return data.aspect === aspect; 
  } catch (error) {
    return false;
  }
}

const isFutureDate = (value) => {
    let today = new Date();
    let currentDate = new Date(value);
    
    // set today time to last millisec of the day.
    today.setHours(23, 59, 59, 998);
    
    return currentDate > today;
}

const isValidAadhar = (value) => {

  let isnum = /^\d+$/.test(value);

  if (isnum && (value.length===12 || value.length===16))
      return true;
   else
     return false;

}
const isValidNumber = (value) => {
  
  let isnum = /^\d+$/.test(value);

  if (isnum )
      return true;
  else
      return false;
}

const isValidMonitordate = (value) => {
  let arrivaldate = new Date(JSON.parse(localStorage.getItem('arrivaldate')));
  let monitordate = new Date(value);

  arrivaldate.setHours(0,0,0,0);
  monitordate.setHours(0,0,0,0);

  if(arrivaldate > monitordate)
  {
    return false;
  }
  else
  {
    return true;
  }
}
const isLessThan = (value,compareField)=>{
  var compareValue = document.getElementById(compareField).value;
   
  if (parseInt(value) > parseInt(compareValue))
     return false;
    else
    return true;

}
const isDateGreaterThan = (value,compareField) => {
  
  let compareDateValue = new Date(document.getElementById(compareField).value);
  let presentDateValue = new Date(value);

  compareDateValue.setHours(0,0,0,0);
  presentDateValue.setHours(0,0,0,0);

  if(compareDateValue < presentDateValue)
  {
    return false;
  }
  else
  {
    return true;
  }
}
export const customValidator = {
  'isFileSizeMb' : isFileSizeMb,
  'isFileType' : isFileType,
  'isMaxFileCount' : isMaxFileCount,
  'isImgExactResol' : isImgExactResol,
  'isImgMinResol' : isImgMinResol,
  'isImgMaxResol' : isImgMaxResol,
  'isImgAspectRatio' : isImgAspectRatio,
  'isFutureDate' : isFutureDate,
  'isValidAadhar' : isValidAadhar,
  'isValidNumber':isValidNumber,
  'isValidMonitordate' : isValidMonitordate,
  'isLessThan' : isLessThan,
  'isDateGreaterThan':isDateGreaterThan
}

export const generateFieldRules = (field, validationRules) => {
  
    // check properties should be an array
    let properties = field.properties;
    if (!Array.isArray(properties)) {
      properties = properties.split(/,| /);
    }

    for(let i = 0; i < properties.length; i++){
      let rule = properties[i];
      if (rule.required === 'true' || rule.required === true) {
        validationRules.push({
          field: field.name,
          method: 'isEmpty',
          validWhen: false,
          message: `${field.label} is required`
        });
      }
     
      if (rule.url) {
        validationRules.push({
          field: field.name,
          method: 'isURL',
          args: [{ require_host: true }, { require_valid_protocol: true }],
          validWhen: true,
          message: `${field.label} is not a valid URL`
        });
      }
     
      if (rule.email) {
        validationRules.push({
          field: field.name,
          method: 'isEmail',
          validWhen: true,
          message: `${field.label} is not a valid Email`
        });
      }
      
      if (rule.mobile) {
        validationRules.push({
          field: field.name,
          method: 'isMobilePhone',
          args: ["any"],
          validWhen: true,
          message: `${field.label} is not a valid mobile number`
        });
      }
      
      if (rule.enum) {
        validationRules.push({
          field: field.field,
          method: 'isIn',
          args: [(rule.enum || [])],
          validWhen: true,
          message: `${field.field} is not a valid value it should be one of ${rule.enum.toString()}`
        });
      }
      
      if (rule.date) {
        validationRules.push({
          field: field.name,
          method: 'isDate',
          validWhen: true,
          message: `${field.label} is not a valid Date, expecting YYYY-MM-DD format`
        });
        
        if (rule.date.noFutureDates) {
          validationRules.push({
            field: field.name,
            method: 'isFutureDate',
            args: [],
            validWhen: false,
            message: 'Date is in future. Cannot allow future dates' 
          });
        }
        if (rule.date.DateGreaterThan) {
          validationRules.push({
            field: field.name,
            method: 'isDateGreaterThan',
            args: [rule.date.DateGreaterThan],
            validWhen: false,
            message: `Date should greater than ${rule.date.DateGreaterThan}` 
          });
        }
      }
      
      if (rule.datetime) {
        validationRules.push({
             field: field.name,
            method: 'isISO8601',
            args : [{
                strict: true,
                strictSeparator: true
            }],
            validWhen: true,
            message: `${field.name} is not a valid date time value, it should be formatted as YYYY-MM-DDTHH:MM:SSZ`
        });
        
        if (rule.datetime.noFutureDates) {
          validationRules.push({
            field: field.name,
            method: 'isFutureDate',
            args: [],
            validWhen: false,
            message: 'Date is in future. Cannot allow future dates' 
          });
        }
        if (rule.datetime.DateGreaterThan) {
          validationRules.push({
            field: field.name,
            method: 'isDateGreaterThan',
            args: [rule.datetime.DateGreaterThan],
            validWhen: false,
            message: `Date should greater than ${field.name}` 
          });
        }
      }

      if (rule.string) {
        let args = [];
        let message = '';
        if (rule.string.min && rule.string.max) {
          args = [{min: rule.string.min, max: rule.string.max}];
          message =  `${field.label} should have min ${rule.string.min} and max ${rule.string.max} characters`;
        } else if (rule.string.min) {
          args = [{min: rule.string.min}];
          message = `${field.label} should have min ${rule.string.min} characters`
        } else if (rule.string.max) {
           args = [{max: rule.string.max}];
           message = `${field.label} should have, max ${rule.string.max} characters`
        }
        
        validationRules.push({
          field: field.name,
          method: 'isLength',
          args: args,
          validWhen: true,
          message: message
        });
      }
      
      if(rule.strongPassword) {
          validationRules.push({
              field: field.name,
              method: 'isStrongPassword',
              args : [{ minLength: 8, minLowercase: 0, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false}],
              validWhen: true,
              message: `${field.label} is not a strong password. ` +
                  `Password should have atleast 8 characters including one uppercase, one symbol, one numeric character`
          });
      }

      if (rule.alphanumeric) {
         validationRules.push({
          field: field.name,
          method: 'isAlpha',
          args : ['en-US'],
          validWhen: false,
          message: `${field.label} should be alphanumeric`
        });
      }
      
      if (rule.json) {
         validationRules.push({
          field: field.name,
          method: 'isJSON',
          args : [],
          validWhen: true,
          message: `${field.label} should be a valid JSON`
        });
      }
      
      if (rule.number) {
        let args = [];
        let message = '';

        if (rule.number.min!==undefined && rule.number.max!==undefined) {

           args = [{min: rule.number.min, max: rule.number.max}];
          message =  `${field.label} should be number, min ${rule.number.min} and max ${rule.number.max}`;
        } else if (rule.number.min!==undefined) {

          args = [{min: rule.number.min}];
          message = `${field.label} should be number, min value ${rule.number.min}`
        } else if (rule.number.max!==undefined) {
           args = [{max: rule.number.max}];
           message = `${field.label} should be number, max value ${rule.number.max}`
        }
        validationRules.push({
          field: field.name,
          method: 'isInt',
          args: args,
          validWhen: true,
          message: message
        });
      }
      
      if (rule.float) {
        let args = [];   
        let message = `${field.label} should be float number`;
        if (rule.float.min!==undefined && rule.float.max!==undefined) {
            args = [{
                min: rule.float.min,
                max: rule.float.max
            }];
            message = `${field.label} should be float, min ${rule.float.min} and max ${rule.float.max}`;
        } else if (rule.float.min!==undefined) {
            args = [{
                min: rule.float.min
            }];
            message = `${field.label} should be float, min ${rule.float.min}`
        } else if (rule.float.max!==undefined) {
            args = [{
                max: rule.float.max
            }];
            message = `${field.label} should be float, max ${rule.float.max}`
        }
        validationRules.push({
            field: field.name,
            method: 'isFloat',
            args: args,
            validWhen: true,
            message: message
        });
      }
      
      if (rule.filesize) {
        validationRules.push({
          field: field.name,
          method: 'isFileSizeMb',
          args: [{min: rule.filesize.min, max: rule.filesize.max}],
          validWhen: true,
          message:`${field.label} file size should not exceed ${rule.filesize.max} Mb`
        });
      }
      
      if (rule.filetype) {
        validationRules.push({
          field: field.name,
          method: 'isFileType',
          args: [rule.filetype],
          validWhen: true,
          message: `${field.label} extension is not supported. Please upload ${rule.filetype.join(',')}`
        });
      }
      if (rule.aadhar) {
        validationRules.push({
          field: field.name,
          method: 'isValidAadhar',
          args: [rule.aadhar],
          validWhen: true,
          message: `${field.label} should be 12 or 16 digits`
        });
      }
      if (rule.numeric) {
        validationRules.push({
          field: field.name,
          method: 'isValidNumber',
          args: [rule.numeric],
          validWhen: true,
          message: `${field.label} accepts only number`
        });
      }
      if (rule.monitordate) {
        validationRules.push({
          field: field.name,
          method: 'isValidMonitordate',
          args: [rule.monitordate],
          validWhen: true,
          message: `${field.label} should greater than arrived date`
        });
      }
      if (rule.filecount) {
        validationRules.push({
          field: field.name,
          method: 'isMaxFileCount',
          args: [{min: rule.filecount.min, max: rule.fileCount.max}],
          validWhen: true,
          message: `${field.label} exceeded max number of files supported, max ${rule.filecount.max}`
        });
      }
      
      if (rule.crop) {
        if (rule.crop.width) {
          validationRules.push({
            field: field.name,
            method: 'isImgExactResol',
            args: [{width: parseInt(rule.crop.width), height: parseInt(rule.crop.height), 
                aspect:rule.crop.aspect}],
            validWhen: true,
            message: `${field.label} Image resolution is not valid. Supported width ${rule.crop.width} and 
                  height ${rule.crop.height} px` 
          });
        } else if (rule.crop.minWidth) {
          validationRules.push({
            field: field.name,
            method: 'isImgMinResol',
            args: [{width: parseInt(rule.crop.minWidth), height: parseInt(rule.crop.minHeight), 
                aspect:rule.crop.aspect}],
            validWhen: true,
            message: `${field.label} Image resolution is not valid. Supports minimum width ${rule.crop.minWidth} and 
                  height ${rule.crop.minHeight} px` 
          });
        }  else if (rule.crop.maxWidth) {
          validationRules.push({
            field: field.name,
            method: 'isImgMaxResol',
            args: [{width: parseInt(rule.crop.maxWidth), height: parseInt(rule.crop.maxHeight), 
                aspect:rule.crop.aspect}],
            validWhen: true,
            message: `${field.label} Image resolution is not valid. Supports maximum width ${rule.crop.maxWidth} and 
                  height ${rule.crop.maxHeight} px` 
          });
        }
        
        // add aspect verification rule
        if (rule.crop.aspect) {
          validationRules.push({
            field: field.name,
              method: 'isImgAspectRatio',
            args: [{'aspect': parseFloat(rule.crop.aspect)}],
            validWhen: true,
            message: `${field.label} Image aspect ratio is not valid. Supported aspect ratio  ${rule.crop.aspect}`
          });
        }
      }
      if (rule.LessThan) {
        validationRules.push({
          field: field.name,
          method: 'isLessThan',
          args: [rule.LessThan],
          validWhen: true,
          message: `${field.label} should less than ${rule.LessThan}`
        });
      }
    };
    return validationRules;
  }
