import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  Icon,
  Typography 
} from '@mui/material';

import theme from 'theme';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchBookingsTotalCountByStatus } from 'services/bookings';

const options = {
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.primary
  }
};

const noDataOptions = {
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: false,
};

const initialData = {
   labels: ['Blocked', 'Confirmed', 'Cancelled'],
   datasets: [{
    backgroundColor: [
      colors.yellow[500],
      colors.green[500],
      colors.red[600]
    ],
    data: [0, 0, 0],
    label: "% of Bookings"
   }],
};

const noData = {
  labels: ['Blocked', 'Confirmed', 'Cancelled'],
  datasets: [{
    backgroundColor:'#d2dee2',
    data : [0.00001,0,0],
    label: "% of Bookings"
  }]
};

export default class BookingStatusGraph extends Component {
  
  state = { 
    selectedMonth : new Date().toISOString().split('T')[0],
    data : noData,
    noData : true
  };
  
  setupGraphData = async () => {
    let currentData = initialData;
    let cancelledCount = 0;
    let blockedCount = 0;
    let confirmedCount = 0;
    let total = 0;
    
    try {
      let response = await fetchBookingsTotalCountByStatus({
        page : 1,
        per_page : 100,
        hatcheryId : 'ALL',
        startDate : this.firstDay.toISOString(),
        endDate : this.lastDay.toISOString()
      });
      
      Object.keys(response.data).map (s => total += response.data[s]);
      cancelledCount = response.data['Cancelled'] + response.data['Rejected'];
      confirmedCount = response.data['Confirmed'] + response.data['Completed'];
      blockedCount = response.data['Blocked'] + response.data['RetryPending'] + response.data['RetryPayment'];
      
      if (total) {
        currentData.datasets[0].data = [
          Math.floor((blockedCount * 100 ) / total),
          Math.floor((confirmedCount * 100) / total),
          Math.floor((cancelledCount * 100) / total)
        ];
        
        this.setState({data : currentData, noData : false});
      } else {
        noData.datasets[0].backgroundColor='#d2dee2';
        this.setState({data : noData, noData : true});
      }
     
    } catch(error) {
      console.log(error);
      noData.datasets[0].backgroundColor='#d2dee2';
      this.setState({data : noData, noData : true});
    }
  }
  
  async componentDidMount() {
    
    let date = new Date(this.state.selectedMonth);
    this.year = date.getFullYear();
    this.month =  date.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    await this.setupGraphData();
  };

  handleMonthChange = async (value) => {
    
    let currentDate = this.state.selectedMonth;
    
    this.setState({selectedMonth : value.toISOString().split('T')[0], data : noData, noData : true });

    this.year = value.getFullYear();
    this.month =  value.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    if (currentDate !== value.toISOString().split('T')[0]) {
      await this.setupGraphData();
    }
  }
  
  render() {
    return (<Card>
      <CardHeader
        action={(
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={this.state.selectedMonth}
              onChange={this.handleMonthChange}
              renderInput={(params) => <TextField {...params}
              fullWidth
              margin="normal"
              variant="standard"
            />}
            />
          </LocalizationProvider>
        )}
        title="Bookings Status"
      />
      <Divider />
      <CardContent>
        <Box sx={{ height: 180,  position: 'relative' }} >
          <Doughnut data={this.state.data} options={this.state.noData ? noDataOptions : options} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
          <Box key="Blocked Bookings" sx={{p: 1, textAlign: 'center'}} >
            <Icon color="action" />
            <Typography color="textPrimary" variant="body1">Blocked</Typography>
            <Typography style={{ color: colors.yellow[500] }} variant="h2"> 
              {this.state.noData ? 0 : this.state.data.datasets[0].data[0]} % 
            </Typography>
          </Box>
          <Box key="Confirmed Bookings" sx={{p: 1, textAlign: 'center'}} >
            <Icon color="action" />
            <Typography color="textPrimary" variant="body1">Confirmed</Typography>
            <Typography style={{ color: colors.green[500] }} variant="h2"> 
              {this.state.noData ? 0 : this.state.data.datasets[0].data[1]} % 
            </Typography>
          </Box>
          <Box key="Cancelled Bookings" sx={{p: 1, textAlign: 'center'}} >
            <Icon color="action" />
            <Typography color="textPrimary" variant="body1">Cancelled</Typography>
            <Typography style={{ color : colors.red[600] }} variant="h2"> 
              {this.state.noData ? 0 : this.state.data.datasets[0].data[2]} % 
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>);
  }
}