import React, { Component } from "react";

import { withLocation } from 'hoc';

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchBookingsTotalCount, 
  fetchBookingsData, 
} from 'services/bookings';

import { bookingFields } from './bookings.fields'

import { fetchAllSuppliers } from 'services/suppliers';
import { fetchAllSpecies } from 'services/species-details';

import { HatcheryStore } from 'store';
import { permissionCheck } from 'permissions';

class BookingsList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    const location = props.location.state;
    const license =  HatcheryStore.state.currentHatchery ?  HatcheryStore.state.currentHatchery.license : null;
    
    // copy list of columns
    this.bookingFields = [];
    bookingFields.map ( f => {
      
      // do not show transaction data if not permitted
      if (!(permissionCheck('transaction', 'view-self') ||  
            permissionCheck('transaction', 'view-any')) ||  
            ['arrival-pending', 'shipment-approval'].includes(this.props.type)) {
        if (['totalPrice', 'paymentId', 'transactionDate'].includes(f.name)) {
          return null;
        }
      }
      
      if (['arrival-pending', 'shipment-approval'].includes(this.props.type) === false) {
        if (['shipmentApproval'].includes(f.name)) {
          return null;
        }
      }
      
      // do not show hatchery name in case of hatchery owner
      if (this.currentUser.user.role.name === 'Hatchery Owner' && f.name==='hatcheryName') {
        return null;
      }

      // Arrival date should shown to Manage Quarantine Cubiles report for RGCA user
      if (this.currentUser.user.role.type !== 'rgca_user' && f.name==='arrivalReport'){
         return null;
      }
      
      let field = {...f};
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
    
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
    
      this.bookingFields.push(field);
      
      // fix warnings
      return null;
    });
    
    this.listingFilters = [{
      name : 'type',
      value : 'ALL',
      label : 'Current Or Past',
      options : [
        {id : "ALL", value : "-- All  Bookings --"},
        {id : "active", value : "Current Bookings"},
        {id : "completed", value : "Past Bookings"},
      ],
    }, {
      name : 'status',
      value : 'ALL',
      label : 'Filter By Booking Status',
      options : [
        {id : "ALL", value : "-- All  --"},
        {id : "Blocked", value : "Blocked"},
        {id : "Cancelled", value : "Cancelled"},
        {id : "Confirmed", value : "Confirmed"},
        {id : "Rejected", value : "Rejected"},
        {id : "Completed", value : "Completed"},
        {id : "RetryPayment", value : "Retry Payment"}
      ]
    }, {
      name : 'supplier',
      value : 'ALL',
      label : 'Filter By Supplier',
      options : [
        {id : "ALL", value : "-- All  Suppliers --"},
      ]
    }, {
        name : 'speciesType',
        value : 'ALL',
        label: 'Filter By Species',
        options : [
          {id : "ALL", value : "-- All  Species --"},
        ]
    }, {
        name : 'shipmentStatus',
        value : 'ALL',
        label: 'Filter By Shipment Status',
        options : [
          {id : "ALL", value : "-- All  --"},
          {id : "ApprovalPending", value : "Approval Pending"},
          {id : "Approved", value : "Approved"},
          {id : "Rejected", value : "Rejected"}
        ]
    }];
    
    this.approvalFilters = [{
      name : 'status',
      value : 'ALL',
      label : 'Filter By Status',
      options : [
        {id : "ALL", value : "-- All  --"},
        {id : "Blocked", value : "Blocked"},
        {id : "Cancelled", value : "Cancelled"},
        {id : "Confirmed", value : "Confirmed"},
        {id : "Rejected", value : "Rejected"},
        {id : "Completed", value : "Completed"},
        {id : "RetryPayment", value : "Retry Payment"}
      ]
    }, {
      name : 'supplier',
      value : 'ALL',
      label : 'Filter By Supplier',
      options : [
        {id : "ALL", value : "-- All  --"},
      ]
    }, {
      name : 'speciesType',
      value : 'ALL',
      label: 'Filter By Species',
      options : [
        {id : "ALL", value : "-- All  Species --"},
      ]
    }];      
    
    this.arrivalFilters = [{
      name : 'supplier',
      value : 'ALL',
      label : 'Filter By Supplier',
      options : [
        {id : "ALL", value : "-- All  --"},
      ]
    }, {
      name : 'speciesType',
      value : 'ALL',
      label: 'Filter By Species',
      options : [
        {id : "ALL", value : "-- All  Species --"},
      ]
    }];
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Bookings' + `${location && location.name ? ' - ' + location.name : ''}`,
      addButtonText : 'New Booking',
      addUrl : this.currentUser.user.role.name === 'Hatchery Owner' && license ? '/aqf/bookings/add' : null,
      searchEnable : true,              
      searchPlaceholder : (this.currentUser.user.role.name === 'Hatchery Owner') 
        ? 'Search By Booking ID ' : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : this.bookingFields,
      itemUrlPath : props.type === 'shipment-approval' ? `/aqf/bookings/shipment-approval/` : `/aqf/bookings/view/`,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingsDataWrapper,
      fetchCountApi : this.fetchBookingsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
    
    if (props.type === 'Listing') {
      this.tableProps.selectFilters = this.listingFilters;
    } else if (props.type === 'shipment-approval') {
      this.tableProps.selectFilters = this.approvalFilters;
    } else if (props.type === 'arrival-pending') {
      this.tableProps.selectFilters = this.arrivalFilters;
    }
  }

  fetchBookingsCountWrapper = async(filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    } else if (this.props.location && this.props.location.state && 
      this.props.location.state.hatcheryId) {
      hatcheryId = this.props.location.state.hatcheryId;
    }
    
    let response = await fetchBookingsTotalCount({
      status : filters['status'],
      supplierId : filters['supplier'],
      hatcheryId: hatcheryId ? hatcheryId : 'ALL',
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      speciesType : filters['speciesType'],
      shipmentStatus : filters['shipmentStatus'],
      type : ['arrival-pending', 'shipment-approval'].includes(this.props.type) ? this.props.type : filters['type']
    });

    return response;
  }    

  fetchBookingsDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    } else if (this.props.location && this.props.location.state && 
      this.props.location.state.hatcheryId) {
      hatcheryId = this.props.location.state.hatcheryId;
    }
    
    let response = await fetchBookingsData({
      page: page,
      per_page: perPage,
      hatcheryId: hatcheryId,
      status : filters['status'],
      supplierId : filters['supplier'],
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      speciesType : filters['speciesType'],
      shipmentStatus : filters['shipmentStatus'],
      type : ['arrival-pending', 'shipment-approval'].includes(this.props.type) ? this.props.type : filters['type']
    });
    
    response.data.forEach(entry => {
      entry.selection = false; //this.currentUser.user.role.name === 'Front Desk';
      entry.url = this.props.type === 'shipment-approval' 
        ? `/aqf/bookings/shipment-approval/${entry.id}` 
        : `/aqf/bookings/view/${entry.id}`;
      entry.hatcheryName = entry.hatcheryId.name;
      entry.numCubicles = entry.cubicleBookingScheduleIds && entry.cubicleBookingScheduleIds.length
        ? entry.cubicleBookingScheduleIds.length 
        : entry.cubicleDetails ? entry.cubicleDetails.length : 0;
      entry.cubicleNumber = entry.cubicleDetails ? entry.cubicleDetails.map( x => x.cubicleName).join(',') : '';
      entry.speciesType = (entry.speciesType || {}).name;
      entry.id = entry.id + (entry.oldId ? ' / ' + entry.oldId : '');
    });
    
    return response;
  }

  async componentDidMount() {
    
    try {
      let response = await fetchAllSpecies({ page : 1, per_page : -1});
      
      if (this.props.type === 'Listing') {
        this.listingFilters[2].options = [{id : "ALL", value : "-- All  Suppliers --"}];
        this.listingFilters[3].options = [{id : "ALL", value : "-- All  Species --"}];
      } else if (this.props.type === 'shipment-approval') {
        this.approvalFilters[1].options = [{id : "ALL", value : "-- All  Suppliers --"}];
        this.approvalFilters[2].options = [{id : "ALL", value : "-- All  Species --"}];
      } else if (this.props.type === 'arrival-pending') {
        this.arrivalFilters[0].options = [{id : "ALL", value : "-- All  Suppliers --"}];
        this.arrivalFilters[1].options = [{id : "ALL", value : "-- All  Species --"}];
      }
      
      response.data.map ( species => {
        if (this.props.type === 'Listing') {
          this.listingFilters[3].options.push({id : species.id, value : species.name});
        } else if (this.props.type === 'shipment-approval') {
          this.approvalFilters[2].options.push({id : species.id, value : species.name});
        } else if (this.props.type === 'arrival-pending') {
          this.arrivalFilters[1].options.push({id : species.id, value : species.name});
        }
      });
        
      response = await fetchAllSuppliers({
        speciesType : HatcheryStore.state.currentHatchery ? HatcheryStore.state.currentHatchery.permittedSpeciesId.id : 'ALL',
        status : true,
        per_page : -1,
        page : 1
      });
      
      response.data.map( supplier => {
        if (this.props.type === 'Listing') {
          this.listingFilters[2].options.push({id : supplier.id, value : supplier.name});
        } else if (this.props.type === 'shipment-approval') {
          this.approvalFilters[1].options.push({id : supplier.id, value : supplier.name});
        } else if (this.props.type === 'arrival-pending') {
          this.arrivalFilters[0].options.push({id : supplier.id, value : supplier.name});
        }
      });
       
      this.tableProps.updateContent = true;
      await this.setState({updateContent : true});
      
    } catch(error) {
      console.log(error);
    }
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
    
    if (prevProps.type !== this.props.type) {
      this.tableProps.updateContent = true;
      this.tableProps.addUrl = (this.props.type === "active" && this.currentUser.user.role.name === 'Hatchery Owner') 
        ? '/aqf/bookings/add' 
        : null;
      
    if (this.props.type === 'Listing') {
      this.tableProps.selectFilters = this.listingFilters;
    } else if (this.props.type === 'shipment-approval') {
      this.tableProps.selectFilters = this.approvalFilters;
    } else if (this.props.type === 'arrival-pending') {
      this.tableProps.selectFilters = this.arrivalFilters;
    }
      
      await this.setState({updateContent : true});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocation(BookingsList);
