import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import bookingTimeFields from './booking-times.fields';

import { createNewBookingTime } from 'services/booking-times';
import { fetchAllSpecies } from 'services/species-details';

export default class AddNewBookingTimes extends Component {
  state = {
    speciesData : false
  };
  constructor(props) {
    super(props);

    bookingTimeFields[0].selectOptions = {};
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new booking time..." };
    
    // todo API calls
    try {
      let response = await createNewBookingTime(params);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data};
    }
    
    return statusMessage;
  }
  async componentDidMount() {
    try {
     
      // fetch all species
      let response = await fetchAllSpecies({page : 1, per_page : -1, sort : 'id:ASC'});
      response.data.map ( species => {
        bookingTimeFields[0].selectOptions[species.id] = species.name;
      });
      
      this.setState({speciesData : true});
      
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Add New Booking Time </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          <DynamicForm
            formFields={bookingTimeFields}
            action="create"
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Booking Time Details"
            buttonWidth= "full"
            columns = {3}
            redirect={'/aqf/admin/booking-times/list'}
            confirmation={"Are you sure you want to save the details ? "}
          />
        </Paper>
      </>
    );
  }
}