import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { waterQualityFields } from '../booking-reports.fields';

import { createNewBookingReport, updateBookingReport } from 'services/booking-reports';

export default class WaterQualityAnalysis extends Component {
  
  constructor ( props ) {
    super (props);
    
     localStorage.setItem('arrivaldate', JSON.stringify(this.props.bookingData.arrivalReport));
    

    this.waterQualityFields = Array.from(waterQualityFields);
    this.waterQualityFields[5].default = 'water_quality_analysis';
    this.waterQualityFields[5].properties = [{required:true}, {readOnly : true}];

    if (props.currentValues && props.currentValues.type=="receiving_water_quality")
    {
      this.waterQualityFields[3].properties = [{required:false}, {readOnly : true}];
    }
    else
    {
      this.waterQualityFields[3].properties = [{required:true}, {readOnly : false}];
    }
    
    // populate cubicle info
    this.waterQualityFields[3].selectOptions = {};
    if (this.props.bookingData.cubicleDetails) {
      props.bookingData.cubicleDetails.map( c => {
        if (c.cubicleId) this.waterQualityFields[3].selectOptions[c.cubicleId] = c.cubicleName;
        else if (c.cubicleNumber) this.waterQualityFields[3].selectOptions[c.cubicleNumber] = `CUB-${c.cubicleNumber}`;
      });
    } else if (this.props.bookingData.cubicleBookingScheduleIds) {
      props.bookingData.cubicleBookingScheduleIds.map(c => {
        this.waterQualityFields[3].selectOptions[c.cubicleId] = `CUB-${c.cubicleId}`;
      });
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleCreateFormSubmit = async (params ,tableData) => {
    let statusMessage = { status: "success", message: "Successfully added new mortality details..." };

    let datelist = [];
    tableData.map(t => { 
      datelist.push(t.monitorDateTime.split('T')[0]+"-"+t.cubicleId);
    })
    const numberOfDuplicates = this.findDuplicateDates(datelist);

    if(numberOfDuplicates.length > 0)
    {
      statusMessage = { status: "error", message: "Monitor date is repeated." };
      return statusMessage;
    }

    let formData = { 
      bookingId : this.props.bookingData.id,
      bulkData : tableData.map(t => { 
        // add booking id into the data
        t.bookingId = this.props.bookingData.id; 
        t.type = 'water_quality_analysis';
        return t;
      })
    };
    
    try {
      let response = await createNewBookingReport('water-quality-analysis', formData);
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message };
      if (error.data && error.data.isValid === false) {
        let errorsOnSubmit = error.data.bulkData[0];
        delete errorsOnSubmit.arrIdx;
        statusMessage['data'] = errorsOnSubmit;
      }
    }
    
    return statusMessage;
  }
  
  findDuplicateDates = (array) => {
    const dateSet = new Set();
    const duplicateDates = [];
    array.forEach((date) => {
      if (dateSet.has(date)) {
        duplicateDates.push(date);
      } else {
        dateSet.add(date);
      }
    });

    return duplicateDates;
  };

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleEditFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated water quality analysis..." };
    try {
      let response = await updateBookingReport('water-quality-analysis', this.props.currentValues.id, params);
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  handleTableDataChange = async (tableData) => {
    // no-op
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Water Quality Analysis ' : 'Water Quality Analysis') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          !this.props.currentValues ?
          <DynamicTableForm
            formFields = {[]}
            tableFormProps = {{
              fields : this.waterQualityFields,
              handleTableDataChange : this.handleTableDataChange,
              serialNoHeader : 'S.No',
              deleteRows: "none",
              uploadFiles : true,
              minRows : 1,
              maxRows: 50,
              currentValues : null,
            }}
            uploadFiles={true}
            action="create"
            buttonText="Save Water Quality Report"
            handleSubmit={this.handleCreateFormSubmit}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/water-quality-analysis"}
          /> :
          <DynamicForm
            formFields={this.waterQualityFields}
            action="update"
            handleSubmit={this.handleEditFormSubmit}
            buttonText="Update Water Quality Report"
            buttonWidth= "full"
            columns = {4}
            currentValues={currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/water-quality-analysis"}
          />
        }     
      </>
    );
  }
}
