import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { Grid, TextField, Button,IconButton, MenuItem, Typography, Paper, Box} from "@mui/material";
import { withStyles } from "@mui/styles";

import CustomSnackbar from "components/CustomSnackbar";
import SearchInput from "components/SearchInput";

// Material icons
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import PublishIcon from '@mui/icons-material/Publish';
import { Delete as DeleteIcon } from "@mui/icons-material/Delete";

import moment from 'moment';

// Component styles
import styles from "./styles";

const SelectFilter = props => (
  <TextField
    id={props.name}
    name = {props.name}
    label={props.label ? props.label : 'Select'}
    fullWidth={true}
    select
    type='text'
    value={props.value ? props.value : 'ALL'}
    onChange={props.onChange}
    margin="normal"
    variant="standard"
  >
  {
    props.options && props.options.map( (option, idx) => {
      return <MenuItem value={option.id} key={idx}>{option.value}</MenuItem>;
    })
  }
  </TextField>
);

const DateFilter = props => (
  <TextField
    id={props.name}
    label={props.label ? props.label : 'Select Date'}
    name={props.name}
    fullWidth
    size="small"
    type="date"
    defaultValue={ props.value ? props.value : moment().format('YYYY-MM-DD')}
    onChange={props.onChange}
    margin="normal"
    variant="standard"
  />);

  const ButtonFilter = props => (
    <Button
      id={props.name}
      sx={{color:"white",backgroundColor:"#006fb6",'&:hover': {
        backgroundColor: '#006fa6',color:"white" }}}
      size="small"
      onClick={props.onClick}  
      name={props.name}>
        {props.label ? props.label : 'Show'}
      </Button>
      
    );
/*
const ToggleGroupFilter = props => (
  <ToggleButtonGroup
  size="small"
  value={props.value}
  exclusive
  onChange={props.onChange}
  >
  {props.options.map(option => {
    return (
    <ToggleButton key={option.id} value={option.id}>
      <Typography>{option.value}</Typography>
    </ToggleButton>
    );
  })}
  </ToggleButtonGroup>
);
*/

class RToolbar extends Component {  

  static STATUS_MESSAGE_NO_CONTENTS = { status : 'info', message : 'No contents to be displayed in the table'};
  static STATUS_MESSAGE_WAIT = {status : 'warning', message : 'Please wait, data is being fetched...'};
  static STATUS_MESSAGE_API_FAILURE =  {status : 'error', message:'Error encountered in fetching data...'};
  
  state = { toggleNocontentDialog : this.props.statusMessage };
  
  render() {
    const { classes, selectedItems, deleteContent } = this.props;
    return (
      <Paper elevation={4} className={classes.root}>
        {
          this.props.statusMessage &&
          this.state.toggleNocontentDialog &&
          (<CustomSnackbar
              variant={this.props.statusMessage.status}
              message={this.props.statusMessage.message}
              open={this.props.statusMessage}
              onClose={() => {
              this.setState({ toggleNocontentDialog: false });
              }}
            />)
        }
        <Typography variant="h5" align="center" style={{padding:'8px 0'}}>{this.props.contentType}</Typography><hr style={{borderColor:"#eef1f6"}} />
        <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.row}>
          <Grid item xs={12} sm={4}>
            {
              this.props.searchEnable &&
              <SearchInput
                className={classes.searchInput}
                onSearch={this.props.onSearch}
                placeholder = {this.props.searchPlaceholder ? this.props.searchPlaceholder  : "Search Keyword"}
                defaultValue={this.props.searchValue ? this.props.searchValue : null}
              />
            }
          </Grid>
          <Grid item xs={12} sm={5} />
          <Grid item xs={12} sm={3}>
            {
              this.props.addUrl &&
              <Button color="primary" size="small" fullWidth variant="contained" component={NavLink} to={this.props.addUrl}>
                {this.props.addButtonText ? this.props.addButtonText : "ADD"}
              </Button>
            }
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between" direction="row" className={classes.row} spacing={3}>
          <Grid item xs={12} sm={12 - (this.props.selectFilters.length ? 3 * this.props.selectFilters.length : 6)}>
          {
            selectedItems && selectedItems.length > 0 &&
            <IconButton className={classes.deleteButton} onClick={deleteContent}><DeleteIcon /></IconButton>
          }
          {
            this.props.export && this.props.export.enable &&
            <Button color="error" size="small" onClick={this.props.export.event} variant="outlined" startIcon ={<PublishIcon/>} sx={{mt:2}}> 
              Export Data 
            </Button>
          }
          </Grid>
          {
            this.props.selectFilters.map ( (filter, idx) => {
              let filterValue = this.props.toolbarState 
                && this.props.toolbarState.selectFilterValues 
                && this.props.toolbarState.selectFilterValues[filter.name] 
                ? this.props.toolbarState.selectFilterValues[filter.name] 
                : filter.value;
                
              return (
              <React.Fragment key={idx}>
                 <Grid item xs={12} sm={2}>
                 {
                   filter.type === 'date' ?
                    <DateFilter
                      name = {filter.name}
                      value= {filterValue}
                      label= {filter.label}
                       onChange={this.props.handleSelectFilterChange}
                    /> : ( filter.type === 'button' ?
                    <ButtonFilter
                      name = {filter.name}
                      label = {filter.label}
                      onClick={this.props.handleFetchClick}
                    /> :
                    <SelectFilter
                      name = {filter.name}
                      value= {filterValue}
                      label = {filter.label}
                      options={filter.options}
                      onChange={this.props.handleSelectFilterChange}
                    />)
                 }
                </Grid>
               </React.Fragment>);
             })
          }
          </Grid>
      </Paper>
    );
  }
  
  async componentDidUpdate (prevProps, prevState) {
    if (prevProps.statusMessage !== this.props.statusMessage &&
      this.props.statusMessage !== undefined) {
      await this.setState({toggleNocontentDialog : true});
    }
  }
}

RToolbar.defaultProps = {
  data: [],
  onSelect: () => {},
  onShowDetails: () => {},
  selectedItems: []
};

export default withStyles(styles)(RToolbar);