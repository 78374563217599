import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import rolePermissionFields from './roles-permissions.fields';

import { createNewRole, fetchAllAppPermissions } from 'services/roles-permissions';

export default class AddNewRolePermissions extends Component {
  
  state = { appPermissions : null };
  
  constructor(props) {
    super(props);
    
    this.formFields = [];
    
    rolePermissionFields.map (f => {
      let field = { ...f};
      
      field.properties = [];
      
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
      
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      this.formFields.push(field);
    });
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new role..." };
    
    let formData = {
      roleName : params.roleName,
      dynamic : true,
      description : params.description ? params.description : params.roleName,
      permissions : []
    };
    
    delete params.roleName;
    delete params.description;
    delete params.dynamic;
    
    formData.permissions = Object.keys(params).filter(p => params[p] === true);
    formData.permissions = formData.permissions.map(p => parseInt(p));
    
    // todo API calls
    try {
      let response = await createNewRole(formData);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    try {
      let modules = [];
      let response = await fetchAllAppPermissions({ page : 1, per_page : -1});
      response.data.map ( permission => {
        
        // skip public and all authenticated permissions
        if (permission.public || permission.authenticated) return;
        
        // skip -self permissions, not applicable to roles other than 
        // hatchery owner
        if (permission.operation.includes('-self')) return;
        
        // push header fields first
        if (modules.includes(permission.module) === false) {
          let label = 'Select ' + permission.module.replace('-', ' ') + ' permissions';
          this.formFields.push({
            'name' : permission.module,
            'label' : label,
            'type' : 'header',
            'properties' : [],
            'action' : 'create'
          });
          modules.push(permission.module);
        }
        
        // push the permission fields
        this.formFields.push({
          'name' : parseInt(permission.id),
          'label' : permission.name ? permission.name : '--',
          'type' : 'boolean',
          'properties' : [{required : false}],
          'action' : 'create',
          'valueStrings' : ['Disabled', 'Enabled'],
          'default' : (permission.public || permission.authenticated) ? true : false
        });        
      });
      
      this.setState({appPermissions : true});
      
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4" align="center"> Add New Role </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.appPermissions &&
            <DynamicForm
              formFields={this.formFields}
              action="create"
              handleSubmit={this.handleFormSubmit}
              buttonText="Save New Role"
              buttonWidth= "full"
              columns = {3}
              redirect={'/aqf/admin/role-permissions/list'}
              confirmation={"Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}