import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";
import { 
  fetchSampleCollectionTotalCount, 
  fetchSampleCollection, 
} from 'services/sample-collection';

import { sampleCollectionFields } from './sample-collection.fields'

export default class SampleCollectionList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Samples Collected',
      addButtonText : 'Add New Sample',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : 'Search By Booking ID',
      
      // table content props
      columns : sampleCollectionFields,
      itemUrlPath : `/aqf/bookings/samples/`,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchSampleCollectionDataWrapper,
      fetchCountApi : this.fetchSampleCollectionCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchSampleCollectionCountWrapper = async(filters, searchValue) => {
    let response = await fetchSampleCollectionTotalCount({
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      speciesType : filters['speciesType']
    });
    return response;
  }    

  fetchSampleCollectionDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchSampleCollection({
      page: page,
      per_page: perPage,
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      speciesType : filters['speciesType'],
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false; //this.currentUser.user.role.name === 'Front Desk';
        entry.url = `/aqf/bookings/samples/${entry.bookingId.id}`;
        entry.hatcheryName = entry.bookingId.hatcheryId.name;
        entry.bookingId = entry.bookingId.id;
        entry.createUser = entry.createUser ? entry.createUser.name : null;
        entry.updateUser = entry.updateUser ? entry.updateUser.name : null;
        if (entry.bulkData) entry.sampleStorage = entry.bulkData.map(x => x.sampleStorage).join(',');
      });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}
