export const hatcheryFields = [
  {  
    name: 'id',
    label: 'Hatchery Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'name',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{required:true}, {string : { min : 5, max : 64}}],
    actions: ['create', 'index', 'view', 'update']
  },
  {
    name: 'address',
    label: 'Hatchery Address',
    type: 'text',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false }, {string : { min : 10, max : 512}}], 
  },
  {
    name: 'address2',
    label: 'Office Address',
    type: 'text',
    actions : ['create', 'update'],
    properties: [{ required:false }, {string : { min : 10, max : 512}}], 
  },
  {
    name: 'state',
    label: 'State',
    type: 'select',
    selectOptions : {},
    actions : ['view', 'create', 'update'],
    properties: [{ required:true }], 
  },
  {
    name: 'district',
    label: 'District',
    type: 'select',
    selectOptions : {},
    actions : ['view', 'create', 'update'],
    properties: [{ required:false }], 
  },
  {
    name: 'zone',
    label: 'Zone',
    type: 'string',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false }], 
  },
  {
    name: 'contactPerson',
    label : 'Contact Person',
    type: 'string',
    actions : ['view', 'create', 'update'],
    properties: [{ required:false }], 
  },
  {
    name: 'contactNumber',
    label : 'Contact Number',
    type: 'string',
    actions : ['create', 'update'],
    properties: [{ required:false }], 
  },
  {
    name: 'phoneNumber',
    label : 'Contact Number',
    type: 'string',
    actions : ['view', 'index'],
    properties: [{ required:false }], 
  },
  {
    name: 'permittedSpeciesId',
    label : 'Species Type',
    type: 'select',
    selectOptions : {},
    default : 1,
    properties: [{required:true}],
    actions: ['create','index', 'view', 'update']
  },
  {
    name : 'email',
    label : 'Email Address',
    type: 'string',
    actions : ['view', 'index'],
    properties: [{ required:false }], 
  },
  {  
    name: 'CAARegistrationNumber',
    label: 'CAA Registration No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'view', 'update']
  },
  {  
    name: 'validFrom',
    label: 'Valid From',
    type: 'date',
    properties: [{required:true}],
    actions: ['create', 'index', 'view', 'update']
  },
  {  
    name: 'validTo',
    label: 'Valid To',
    type: 'date',
    properties: [{required:true}],
    actions: ['create', 'index', 'view', 'update']
  },
  {  
    name: 'issuingDate',
    label: 'Issuing Date',
    type: 'date',
    properties: [{required:true}],
    actions: ['create', 'view', 'update']
  },
  {
    name : 'noOfBroodsStockPerYear',
    label : 'Import Limit (Yr)',
    type: 'number',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true }], 
  },
  {
    name : 'fyBalance',
    label : 'Import Balance (Yr)',
    type: 'number',
    actions : ['view', 'index'],
    properties: [{ required:false }], 
  },
  {
    name : 'approvalStatus',
    label : 'Approval Status',
    type: 'select',
    selectOptions : {
      "ApprovalPending" : "Approval Pending",
      "Approved" : "Approved",
      "Rejected" : "Rejected"
    },
    properties: [{required:true} ,{readOnly:true}],
    default : "ApprovalPending",
    actions: ['index', 'view']
  },
  {
    name : 'updateRequestFlag',
    label : 'License Approval Status',
    type: 'select',
    selectOptions : {
      "ApprovalPending" : "Approval Pending",
      "Approved" : "Approved",
      "Rejected" : "Rejected"
    },
    properties: [{required:true} ,{readOnly:true}],
    default : "ApprovalPending",
    actions: ['view']
  },
  {
    name : 'rejectReason',
    label : 'Reject Reason',
    type: 'string',
    actions : ['view'],
    properties: [{ required:false }], 
  },
];

export const licenseFields = [
  {
    name : 'CAARegistrationNumber',
    label : 'CAA Registration Number',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true }], 
  },
  {
    name : 'issuingDate',
    label : 'Issue Date',
    type: 'date',
    actions : ['view', 'create', 'update', 'index'],
    properties: [{ required:true}, {date : true  }], 
  },
  {
    name : 'validFrom',
    label : 'Valid From',
    type: 'date',
    actions : ['view', 'create', 'update', 'index'],
    properties: [{ required:true}, {date : true }], 
  },
  {
    name : 'validTo',
    label : 'Valid To',
    type: 'date',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true}, {date : true }], 
  },
  {
    name : 'noOfBroodsStockPerYear',
    label : 'Import Limit (Yr)',
    type: 'number',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true }], 
  },
  {
    name: 'document',
    label: 'CAA Document',
    type: 'file',
    actions : ['view', 'create', 'update', 'index'],
    properties: [
      {required:false }, 
      {filesize : {min : 0, max : 10} }, 
      {filetype : ['pdf','PDF']}
    ],
  },
  {
    name : 'expired',
    label : 'Expired',
    type: 'boolean',
    actions : ['index', 'view'],
    valueStrings : ['Valid', 'Expired'],
    properties: [{ required:false }], 
  },
  {
    name : 'updated_at',
    label : 'Updated At',
    type: 'datetime',
    actions : ['view'],
    properties: [], 
  }
];

export const bankFields = [
  {
    name: 'accountName',
    label: 'Account Name',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{required:true}],
  },
  {
    name: 'accountNumber',
    label: 'Account Number',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{required:true}],
  },
  {
    name: 'bankName',
    label: 'Bank Name',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{required:true}, {string : {min : 2, max:128}}],
  },
  {
    name: 'branchName',
    label: 'Branch Name',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{required:true}, {string : {min : 2, max:128}}],
  },
  {
    name: 'accountType',
    label: 'Account Type',
    type: 'select',
    actions : ['index', 'view', 'create', 'update'],
    selectOptions : {'PERSONAL' : 'PERSONAL', 'CORPORATE' : 'CORPORATE'},
    properties: [{required:true}],
  },
  {
    name: 'IFSC',
    label: 'IFSC Code',
    type: 'string',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{required:true}, {string : {'min' : 11, 'max' :11}}],
  },
  {
    name: 'bankAccountDetailProof',
    label: 'Upload Proof (Cancelled Cheque)',
    type: 'file',
    actions : ['view', 'create', 'update'],
    properties: [
      { required:true}, 
      { filesize : {min : 0, max : 10} }, 
      { filetype : ['pdf', 'jpg', 'jpeg', 'png','PDF','JPG','JPEG','PNG']}
    ],
  },
  {
    name : 'updated_at',
    label : 'Updated At',
    type: 'datetime',
    actions : ['view'],
    properties: [], 
  }
];

export const hatcheryChangesFields = [
  {
    name: 'id',
    label: 'Hatchery Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {
    name: 'name',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{required:true}, {string : { min : 5, max : 50}}],
    actions: ['create', 'index', 'view', 'update']
  },
  {
    name : 'email',
    label : 'Email Address',
    type: 'string',
    actions : ['view', 'index'],
    properties: [{ required:false }], 
  },
  {  
    name: 'CAARegistrationNumber',
    label: 'CAA Registration No.',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'index', 'view', 'update']
  },
  {
    name : 'noOfBroodsStockPerYear',
    label : 'Import Limit (Yr)',
    type: 'number',
    actions : ['index', 'view', 'create', 'update'],
    properties: [{ required:true }], 
  },
  {
    name : 'fyBalance',
    label : 'Import Balance (Yr)',
    type: 'number',
    actions : ['view', 'index'],
    properties: [{ required:false }], 
  },
  {
    name: 'bankAccountStatus',
    label: 'Bank Accounts',
    type: 'select',
    selectOptions : {
      "ApprovalPending" : "Approval Pending",
      "Approved" : "Approved",
      "Rejected" : "Rejected"
    },
    actions: ['index'],
    properties: [{ required:false }],
  },  
  {
    name: 'authPersonStatus',
    label: 'Authorized Persons',
    type: 'select',
    selectOptions : {
      "ApprovalPending" : "Approval Pending",
      "Approved" : "Approved",
      "Rejected" : "Rejected"
    },
    actions: ['index'],
    properties: [{ required:false }],
  },
  {
    name: 'licenseRenewalStatus',
    label: 'CAA License',
    type: 'select',
    selectOptions : {
      "ApprovalPending" : "Approval Pending",
      "Approved" : "Approved",
      "Rejected" : "Rejected"
    },
    actions: ['index'],
    properties: [{ required:false }],
  },
  {
    name: 'changed_at',
    label: 'Updated Time',
    type: 'datetime',
    actions: ['index'],
    properties: [{ required:false }],
  }
];
