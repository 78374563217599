import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import addnChargesFields from './additional-charges.fields';

import { createNewAddnCharges } from 'services/additional-charges';

export default class AddNewAddnCharges extends Component {
  constructor(props) {
    super(props);
    
    addnChargesFields[1].default = props.bookingData.id;
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added additional charges for the booking..." };
    
    // todo API calls
    try {
      params.bookingId = this.props.bookingData.id;
      params.hatcheryId = this.props.bookingData.hatcheryId;
      let response = await createNewAddnCharges(params);
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }

  render() {
    return (
      <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
        <Typography variant="h4" align="center"> Add New Additional Charges </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          formFields={addnChargesFields}
          action="create"
          handleSubmit={this.handleFormSubmit}
          buttonText="Save Additional Charges"
          buttonWidth= "full"
          columns = {3}
          redirect={null}
        />
      </Paper>
    );
  }
}