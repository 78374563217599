import {
  Box,
  Container,
  Typography, Button
} from '@mui/material';

const NotFound = () => (
  <>
  <Box
    sx={{
    backgroundColor: 'background.default',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
    }}
  >
    <Container maxWidth="md">
    <Typography
      align="center"
      color="textPrimary"
      variant="h1"
    >
      404 : The page you are looking for isn’t here
    </Typography>
    <Typography
      align="center"
      color="textPrimary"
      variant="subtitle2"
    >
      You either typed an incorrect URL or you may not be authorized to view certain pages.
      Whichever it is, try using the navigation
    </Typography>
    <Box sx={{ textAlign: 'center' }}>
      <img
      alt="Under development"
      src= {process.env.PUBLIC_URL + "/static/images/misc/not_found.png"}
      style={{
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 600
      }}
      />
    </Box>
    <Box sx={{ textAlign: 'center' }}>
      <Button variant="outlined" href={process.env.PUBLIC_URL + "/"} sx={{mt:2}}>Back To Home</Button>
    </Box>
    </Container>
  </Box>
  </>
);

export default NotFound;
