import React, { Component } from "react";
import { withLocationParams } from 'hoc';

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { clearanceBookingFields } from './booking-reports.fields';

// import API services for reports
import {
  fetchBookingReportsCount,
  fetchBookingReports
} from 'services/booking-reports';

import { fetchAllSpecies } from 'services/species-details';

// import permissions for role based ops
import { permissionCheck } from 'permissions';

class ClearanceList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { 
      updateContent : false,
    };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'Booking Clearance Status Report',
      addUrl : null,
      searchEnable : true,              
      searchPlaceholder : (this.currentUser.user.role.name === 'Hatchery Owner') 
        ? 'Search By Booking ID ' : 'Search By Booking ID / Hatchery Name',
      
      // table content props
      columns : clearanceBookingFields,
      itemUrlPath : `/aqf/bookings/view/`,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingReportsDataWrapper,
      fetchCountApi : this.fetchBookingReportsCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [{
        name : 'speciesType',
        value : 'ALL',
        label: 'Filter By Species',
        options : [
          {id : "ALL", value : "-- All  Species --"},
        ]
      }],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }

  fetchBookingReportsCountWrapper = async(filters, searchValue) => {
    
    let hatcheryId = 'ALL';
    let reportType = filters['reportType'];
    let response = {status : 200, data : 0};
    
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }

   
    response = await fetchBookingReportsCount('lab-analysis-report', {
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      speciesType : filters['speciesType']
    });
    
    return response;
  }    

  fetchBookingReportsDataWrapper = async (page, perPage, filters, searchValue) => {
    let hatcheryId = 'ALL';
    let reportType = filters['reportType'];
    let response = {status : 200, data : []};
    
    if (this.currentUser.user.role.name === 'Hatchery Owner') {
      hatcheryId = this.currentUser.user.userProfile.hatcheryId;
    }
    
    response = await fetchBookingReports('lab-analysis-report', {
      hatcheryId : hatcheryId,
      bookingId : parseInt(searchValue) > 0 ? searchValue : null,
      hatcheryName : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && !(/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,
      oldId : (hatcheryId === 'ALL' && isNaN(parseInt(searchValue)) && (/^[Bb]\d{2,}$/.test(searchValue))) ? searchValue : null,

      speciesType : filters['speciesType'],
      per_page : perPage,
      approvalFlag_ne:"Rejected",
      page : this.state.reportType !== reportType ? 1 : page
    });
   
    // don't show report id column in the table
    if (this.tableProps.columns) this.tableProps.columns[0].actions = ['view'];
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = `/aqf/bookings/view/${entry.bookingId.id}`;
        if (entry.bookingId.hatcheryId && typeof entry.bookingId.hatcheryId === 'object') {
          entry.hatcheryId = entry.bookingId.hatcheryId.name;
        }
          
        if (entry.bookingId.supplierId && typeof entry.bookingId.supplierId === 'object') {
          entry.supplierName = entry.bookingId.supplierId.name;
        } else if (entry.bookingId.shipmentDetail && entry.bookingId.shipmentDetail.supplierName) {
          entry.supplierName = entry.bookingId.shipmentDetail.supplierName;
        }
        
        entry.speciesType = entry.bookingId.speciesType.name;
        entry.arrivalDate = entry.bookingId.stockStatus.arrivalReport;
        entry.cubicleId = entry.bookingId.cubicleDetails ? entry.bookingId.cubicleDetails.map (x => x.cubicleName).toString() : "";
        
        if (entry.approvalFlag==="Rejected")
        {
          entry.clearanceStatus = "Rejected";
        }
        else
        {
            // update clearance status
            entry.clearanceStatus = entry.bookingId.stockStatus && entry.bookingId.stockStatus.clearanceCertificate  ? 'Issued' : 'Pending';
        }
  
          
        // entry.bookingId = entry.bookingId.id;
        entry.bookingId = entry.bookingId.oldId ? `${entry.bookingId.oldId} / ${entry.bookingId.id}` : entry.bookingId.id;
        if (entry.cubicleId && typeof entry.cubicleId === 'object') {
          entry.cubicleId = entry.cubicleId.name;
        }

      });
    }
    
    return response;
  }

  async componentDidMount() {
    try {
      let response = await fetchAllSpecies({ page : 1, per_page : -1});
      response.data.map ( species => {
        this.tableProps.selectFilters[0].options.push({id : species.id, value : species.name});
      });
    } catch(error) {
      console.log(error);
    }
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }
  
  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}

export default withLocationParams(ClearanceList);