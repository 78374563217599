import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import NavigateBack from 'components/NavigateBack';

import rolePermissionFields from './roles-permissions.fields';

import { fetchRoleById, updateRole, fetchAllAppPermissions } from 'services/roles-permissions';

class EditRolePermissions extends Component {
  
  state = { 
    roleData : null, 
    appPermissions : null,
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
    
    this.formFields = [];
    
    rolePermissionFields.map (f => {
      let field = { ...f};
      
      field.properties = [];
      
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
      
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      this.formFields.push(field);
    });
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated role permission details..." };
    
    let formData = {
      roleName : params.roleName,
      dynamic : true,
      description : params.description ? params.description : params.roleName,
      permissions : [],
    };
    
    delete params.roleName;
    delete params.description;
    delete params.dynamic;
    
    formData.permissions = Object.keys(params).filter(p => params[p] === true);
    formData.permissions = formData.permissions.map(p => parseInt(p));
    
    // todo API calls
    try {
      let response = await updateRole(this.props.locationParams.id, formData);
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let roleData = null;
    let appPermissions = false;
    let statusMessage = this.state.statusMessage;
    try {
      let response = await fetchRoleById(this.props.locationParams.id);
      roleData = {
        roleName : response.data.role.name,
        description : response.data.role.description,
        permissions : response.data.permissions
      };
      statusMessage = {status: "success", message: "Fetched role details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    
    try {
      let modules = [];
      let response = await fetchAllAppPermissions({ page : 1, per_page : -1});
      response.data.map ( permission => {
        
        // skip public and all authenticated permissions
        if (permission.public || permission.authenticated) return;
        
        // skip -self permissions, not applicable to roles other than 
        // hatchery owner
        if (permission.operation.includes('-self') && 
            roleData.permissions.includes(permission.id) === false) return;
        
        // push header fields first
        if (modules.includes(permission.module) === false) {
          let label = 'Select ' + permission.module.replace('-', ' ') + ' permissions';
          this.formFields.push({
            'name' : permission.module,
            'label' : label,
            'type' : 'header',
            'properties' : [],
            'actions' : ['create', 'update']
          });
          modules.push(permission.module);
        }
        
        // push the permission fields
        this.formFields.push({
          'name' : parseInt(permission.id),
          'label' : permission.name ? permission.name : '--',
          'type' : 'boolean',
          'properties' : [{required : false}],
          'actions' : ['create', 'update'],
          'valueStrings' : ['Disabled', 'Enabled'],
          'default' : (permission.public || permission.authenticated) ? 1 : 0
        });

        roleData[permission.id] = roleData.permissions.includes(permission.id);
      });
      
      appPermissions = true;
      
    } catch(error) {
      console.log(error);
    }
    
      await this.setState({roleData, statusMessage, appPermissions });
  }

  render() {
    return (
      <>
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
          <Typography variant="h4"> Update Role : {this.state.roleData ? this.state.roleData.roleName : ''} </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
          {
            this.state.roleData &&
            <DynamicForm
              formFields={this.formFields}
              action="update"
              handleSubmit={this.handleFormSubmit}
              buttonText="Update Role"
              buttonWidth= "full"
              columns = {3}
              currentValues={this.state.roleData}
              redirect={'/aqf/admin/role-permissions/list'}
              confirmation={"New permissions will be effective from next login. Are you sure you want to save the details ? "}
            />
          }
        </Paper>
      </>
    );
  }
}

export default withLocationParams(EditRolePermissions);