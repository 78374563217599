import React from 'react';

import { Table, TableContainer, TableBody, TableCell, TableRow, TableHead, IconButton } from '@mui/material';

import { FormValidator, FieldCreator, generateFieldRules } from './';

import { withStyles, createStyles } from '@mui/styles';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import theme from 'theme';

// Aws S3 image uploads
import {convertFileToBlob} from 'services/upload';

const styles = createStyles({
  root: {
    marginTop:'10px',
    marginBotton : '40px',
    overflowX:"scroll"
  },
  deleteButton: {
    color: theme.palette.error.main,
    marginLeft: '8px'
  },
  editButton: {
    color: theme.palette.info.main,
    marginLeft: '8px'
  },
  container: {
    maxHeight: 600,
  },
 
  tableHeadCell : {
    padding : theme.spacing(1),
    color : `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    textAlign : "center !important"
  },
  tableCell : {
    padding : theme.spacing(1),
    textAlign : "center !important"
  }
  
});
 
class TableFormHelper extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.inputFields = [];
    this.state = { rows : [], validation : [] };
    this.__mounted = false;
    
    // field meta info
    this.meta = [];
    
    // start with empty validationRules
    this.validator = {};
    this.validationRules = [];
    
    this.maxRows = props.maxRows ?  props.maxRows : 0;

    // add each field into the state and meta
    props.inputFields.forEach((f) => {
          
      let isRequired = f.properties && f.properties.length > 0 &&
        f.properties.findIndex(p => p.required !== undefined && (p.required === true || p.required === 'true')) >= 0;
      
      this.inputFields.push({
        'name': f.name,
        'type': f.type,
        'label': f.label,
        'callback' : f.callback,
        'selectOptions':(f.selectOptions != null) ? f.selectOptions : undefined,
        'properties' : f.properties,
        'actions' : f.actions,
        'default' : f.default
      });
      
      // populate required validation rules for the field
      if (f.properties && f.actions.includes(this.props.action)) {
        generateFieldRules(f, this.validationRules);
      }
      
    });    
    this.validator = new FormValidator(this.validationRules, props.inputFields);
  }
  
  initializeDataRows = async () => {
    let dataRows = [];
    let rowValidations = [];
    
    if (this.props.currentValues && this.props.currentValues.length) {
      this.props.currentValues.map ((row, idx) => {
        
        dataRows[idx] = {};
        
        if (!this.props.validation) {
          rowValidations.push({isValid : true});
        } else {
          rowValidations[idx] = this.props.validation[idx];
        }
        
        this.meta.push({});
        
        this.inputFields.forEach((f) => {
          this.meta[idx][f.name] = { touched : false };
          
          if (['image', 'file'].includes(f.type) && !Array.isArray(row[f.name])) {
            // file and images are arrays
            dataRows[idx][f.name] = [];
            dataRows[idx][f.name].push(row[f.name]);
          } else {
            // update field value from current value props if valid
            dataRows[idx][f.name] = row[f.name];
          }
       
          // if select options have changed, and current field state does not reflect values in the new list
          // reset the field state
          if (dataRows[idx][f.name] && f.selectOptions && f.selectOptions[dataRows[idx][f.name]] === undefined) {
            dataRows[idx][f.name] = '';
          }
          
          //fix warnings
          return null;
        });
        
        // fix warnings
        return null;
      });
    }
    
    // initialize state
    await this.setState({ rows : dataRows, validation : rowValidations});
  }
  
  async componentDidMount() {
    this.initializeDataRows();
    this.__mounted = true;
    
    let currentRows = this.props.currentValues ? this.props.currentValues.length : this.state.rows.length;
    if (this.props.minRows) {
      for(let i = currentRows; i < this.props.minRows; ++i) {
        await this.handleTableRowAdd();
      }
    }
  }
  
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  
  componentWillUnmount() {
    this.__mounted = false;
  }
  
  triggerRowValidation = async (idx) => {
    // pass meta to validate only touched input fields.
    let validation = this.state.validation;
    const rowValidation = this.validator.validate(this.state.rows[idx]);
    validation[idx] = rowValidation;
    await this.setState({ validation : validation});
  };

  
  handleTableRowDelete = async (idx) => {
    let rows = this.state.rows;
    let validation = this.state.validation;
    
    if (rows[idx]['_isNew']) {
      // delete the row, if it was created new
      rows.splice(idx, 1);
      validation.splice(idx, 1);
    } else {
      // mark it is as deleted, parent component has to 
      // clean it up from server in API call
      //
      rows[idx]['_isDeleted'] = true;
      validation[idx] = {isValid : true};
    }
    await this.setState({rows: rows, validation : validation});
    
    // update parent with latest table data
    this.props.handleTableChange(this.state.rows, this.state.validation);
  }
  
  handleTableRowChange = async (idx, event) => {
    let field = this.inputFields.find(f => f.name === event.target.name);
    let rows = this.state.rows;
    
    if (field.type === 'datetime') {
      // convert time UTC timezone always
      rows[idx][event.target.name] = new Date(event.target.value).toISOString();
    } else {
      rows[idx][event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    }

    if (field && field.callback) {
      let {fields, values} = await field.callback(field.name, rows[idx][event.target.name], rows[idx], null);
      if (values) {
        rows[idx] = values;
      }
    }
    
    this.meta[idx][event.target.name].touched = true;
    
    await this.triggerRowValidation(idx);
    
    await this.setState({rows: rows});
    
    // update parent with latest table data
    this.props.handleTableChange(this.state.rows, this.state.validation);
  }
  
  // to validate file size and extensions allowed
  validateFileTypeRules (idx, e) {
    let validation = {isValid: true};
    let formField = this.inputFields.find( f => f.name === e.target.name );
    
    if (!formField) return validation;
    
    for (let i = 0; i < e.target.files.length; ++i) {
      if (formField.properties.find(p => p.filesize !== undefined)) {
        validation = this.validator.validateSingleRule(
                      formField.name,
                      'isFileSizeMb',
                      e.target.files[i].size);
      }
      
      if (validation.isValid &&
        formField.properties.find(p => p.filetype !== undefined)) {
        validation = this.validator.validateSingleRule(
                    formField.name,
                    'isFileType',
                    e.target.files[i].name);
      }

      if (!validation.isValid) return validation;
    }
    
    return validation;
  }
  
  handleFileInputChange = async (idx, event, name) => {
    let fieldname = event.target.name;
    let fieldValidation = this.validateFileTypeRules(idx, event);
    
    this.meta[idx][fieldname].touched = true;
    
    if (fieldValidation.isValid) {
      let fileData = event.target.files;
      let rows = this.state.rows;
      
      for (let i = 0; i < fileData.length ; ++i) {
        fileData[i].blob = await convertFileToBlob(fileData[i]);
        
        if (this.props.currentValues && this.props.currentValues[idx]) {
          if (Array.isArray(this.props.currentValues[idx][fieldname])) {
            fileData[i].oldUrl = this.props.currentValues[idx][fieldname][i];
          } else {
            fileData[0].oldUrl = this.props.currentValues[idx][fieldname];
          }
        }
      }
      
      rows[idx][fieldname] = fileData && fileData.length ? fileData : '';
      
      await this.setState({rows: rows});
      
      await this.triggerRowValidation(idx);
      
      // update parent with latest table data
      this.props.handleTableChange(this.state.rows, this.state.validation);
      
    } else {
      // update form validation status
      let validation = this.state.validation;
      let rowValidation = validation[idx];
      
      validation[idx][event.target.name] = fieldValidation;
      await this.setState({ validation : validation});
    }
  }

  handleTableRowAdd = async () => {
    let rows = this.state.rows;
    let validation = this.state.validation;
    let newRow = {'_isNew': true};
    this.meta.push({});
    this.inputFields.map(f => {
      
      // skip fields not relevant to this action (create/edit)
      if (f.actions) {
        let actions = f.actions;
        if (!Array.isArray(actions)) {
          actions = actions.split(/,| /);
        }
        if (!actions.find(action => action === this.props.action)) {
          return;
        }
      }
      
      this.meta[rows.length][f.name] = { touched : false };
      if (f.properties.find(p => p.rowIndex !== undefined)) {
        newRow[f.name] = rows.length;
      } else {
        newRow[f.name] = f.default ? f.default : "";
      }
      return null;
     });
     
     rows.push(newRow);
     validation.push({isValid : false});
     
     await this.setState({ rows : rows, validation : validation});
     
     // update parent with latest table data
     this.props.handleTableChange(this.state.rows, this.state.validation);
  }
  
  isFieldError = (idx, field, validation) => {
    
    if (validation.hasOwnProperty(idx) === false) {
      return false;
    }
    
    return ((this.props.showValidations || this.meta[idx][field].touched) && validation[idx][field] &&
        validation[idx][field].message !== '');
  }
  
  async componentDidUpdate( prevProps, prevState ) {
    
    // initialize state from props if form current values changes.
    if (prevProps.currentValues === null && this.props.currentValues !== null) {
      await this.initializeDataRows();
      return;
    }
    
    // check for changes in validations due to form submit
    if (this.props.showValidations !== prevProps.showValidations) {
      this.setState({validation : this.props.validation });
    }
  }
    
  
  render() {
    
  const classes = this.props.classes;
  const validation = this.state.validation;
  return (
    <React.Fragment>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.root} aria-label="Editable Table" size="small">
          <TableHead>
            <TableRow>
              <TableCell key={"header1"} className={classes.tableHeadCell}> {this.props.serialNoHeader} </TableCell>
              {
                this.inputFields.map(f => {
                  if (f.actions.includes(this.props.action) === false) return null;
                  return (<TableCell key={`header${f.label}`} className={classes.tableHeadCell}> {f.label} </TableCell>)
                })
              }
              <TableCell key={`actionsheader`} className={classes.tableHeadCell}> Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody> 
             {
              this.state.rows.map((row, idx) => {
              
              if (row._isDeleted) return;
              
              return (
                <TableRow key={idx}>
                  <TableCell key={`cell${idx}`} className={classes.tableCell}> {idx + 1} </TableCell>
                  {
                    this.inputFields.map((f, fidx) => {
                      
                      if (f.actions.includes(this.props.action) === false) return null;
                      
                      // check if a field is readonly from form field properties or 
                      // readonly field data passed
                      //
                      let readonly =  Boolean(f.properties.find(p => p.readOnly)) ? true : false;
                      
                      let isRequired = f.properties && f.properties.length > 0 &&
                        f.properties.findIndex(p => p.required !== undefined && (p.required === true || p.required === 'true')) >= 0;
      
      
                      return (<TableCell key={`fieldcell${fidx}`}>
                        <FieldCreator
                          key={'row_' + idx + '_col_'+ fidx}
                          name={f.name}
                          type={f.type}
                          label={f.label}
                          selectOptions={f.selectOptions}
                          selectMultiple={f.properties.find(p => p.multiple !== undefined)}
                          value={this.state.rows[idx][f.name]}
                          onChange={ event => this.handleTableRowChange(idx, event)}
                          onFileInputChange={(event, name) => this.handleFileInputChange(idx, event, name)}
                          // no support for text editors at this point
                          // onEditorChange={this.handleEditorChange}
                          // 
                          error={this.isFieldError(idx, f.name, validation)}
                          helperText={this.isFieldError(idx, f.name, validation) ? validation[idx][f.name].message : ''}
                          readOnly = {readonly}
                          required = {isRequired}
                          valueStrings={f.valueStrings}
                          numberProps={(f.properties.find(p => p.number !== undefined) || {}).number}
                          floatProps={(f.properties.find(p => p.float !== undefined) || {}).float}
                        />
                       </TableCell>)
                    })
                  }
                  <TableCell key={`action2${idx}`} className={classes.tableCell}>
                  {
                    (this.props.deleteRows === "all" || 
                    (this.props.deleteRows === "none" && row['_isNew']) ||
                    (this.props.deleteRows === "last" && this.state.rows.length -1 === idx)) &&
                    <IconButton
                      aria-label="delete"
                      onClick={() => this.handleTableRowDelete(idx)}
                    >
                       <DeleteIcon className={classes.deleteButton} color="secondary" />
                    </IconButton>
                  }
                  </TableCell>
                </TableRow>
               )
               })
             }
             {
               !(this.maxRows > 0 && (this.state.rows.length >= this.props.maxRows)) &&
               <TableRow rowSpan= {1} >
               <TableCell key = {"addbutton"} colSpan = {this.inputFields.length + 2} align="right">
                 <IconButton color="primary" aria-label="Add New Row" onClick = {this.handleTableRowAdd} >
                  <AddCircleRoundedIcon fontSize="large" />
                </IconButton>
                </TableCell>
               </TableRow>
             }
               
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>);
  }
}

export default withStyles(styles)(TableFormHelper);
