import { fetchWithTimeout, addPagerParams } from './utils';

const SUPPLIERS_EP_URL = "/suppliers";
  
export const fetchSuppliersTotalCount = async (params) => {
  return await fetchWithTimeout({
    path : SUPPLIERS_EP_URL + '/count?_start=0'
        + (params.speciesType !== 'ALL' ? '&speciesIds=' + params.speciesType : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'supplier:view-any'
  });
};

export const fetchAllSuppliers = async (params) => {
  return await fetchWithTimeout({
    path : SUPPLIERS_EP_URL + addPagerParams(params)
        + (params.speciesType !== 'ALL' ? '&speciesIds=' + params.speciesType : '')
        + (params.status !== 'ALL' ? '&status=' + params.status : '')
        + (params.name && params.name.length ? '&name_contains=' + params.name : ''),
    method : 'GET',
    operation : 'supplier:view-any'
  });  
};

export const fetchSupplierById = async (bookingId) => {
  return await fetchWithTimeout({
    path : SUPPLIERS_EP_URL + '/' + bookingId,
    method : 'GET',
    operation : 'supplier:view-any'
  });
};

export const createNewSupplier = async (params) => {
  return await fetchWithTimeout({
    path: SUPPLIERS_EP_URL,
    method: 'POST',
    body : params,
    operation : 'supplier:create'
  });
};

export const updateSupplier = async (supplierId, params) => {
  return await fetchWithTimeout({
    path: SUPPLIERS_EP_URL + '/' + supplierId,
    method: 'PUT',
    body : params,
    operation : 'supplier:update'
  });
};