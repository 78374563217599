import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors
} from '@mui/material';

import theme from 'theme';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { fetchBookingsBySpecies } from 'services/bookings';
import { fetchAllSpecies } from 'services/species-details';

const options = {
  animation: false,
  cornerRadius: 10,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        fontColor: theme.palette.text.secondary
      },
      gridLines: {
        display: false,
        drawBorder: false
      }
    }],
    yAxes: [{
      ticks: {
        fontColor: theme.palette.text.secondary,
        beginAtZero: true,
        min: 0
    },
      gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: theme.palette.divider,
      drawBorder: false,
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2],
      zeroLineColor: theme.palette.divider
      }
    }]
  },
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.primary
  }
};

const noDataOptions = {
  animation: false,
  cornerRadius: 10,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: true,
  tooltips : false,
};

const initialData = {
   labels: [],
   datasets: [{
    backgroundColor: colors.red[500],
    categoryPercentage: 0.5,
    barThickness: 12,
    maxBarThickness: 20,
    barPercentage: 0.5,
    data: [],
    label: "No. of Bookings"
   }],
};

const noData = {
  labels: [],
   datasets: [{
    backgroundColor:'#d2dee2',
    categoryPercentage: 0.5,
    barThickness: 12,
    maxBarThickness: 20,
    barPercentage: 0.5,
    data: [],
    label: "No. of Bookings"
   }],
};

export default class SpeciesBookingsGraph extends Component {
  
  state = { 
    selectedMonth : new Date().toISOString().split('T')[0],
    data : noData,
    noData : true,
    labels : [],
  };
  
  setupGraphData = async () => {
    let currentData = initialData;
    currentData.datasets[0].data = [];
    currentData.labels = this.state.labels;
    
    try {
      let response = await fetchBookingsBySpecies({
        fromDate : this.firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        toDate : this.lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'})
      });
      
      response.data.map( entry => {
        let idx = currentData.labels.findIndex(x => x === entry.speciesType);
        if (idx >= 0) currentData.datasets[0].data[idx] = entry.count;
      });
  
      this.setState({data : currentData, noData : false});
    } catch(error) {
      console.log(error);
      this.setState({data : noData, noData : true});
    }
    
  }
  
  async componentDidMount() {
    
    let date = new Date(this.state.selectedMonth);
    this.year = date.getFullYear();
    this.month =  date.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    try {
      let response = await fetchAllSpecies({ page : 1, per_page : -1, sort : 'id:ASC'});
      this.setState({ labels : response.data.map ( x => x.name )});
    } catch (error) {
      console.log(error);
    }
    
    await this.setupGraphData();
  };

  handleMonthChange = async (value) => {
    
    let currentDate = this.state.selectedMonth;
    
    this.setState({selectedMonth : value.toISOString().split('T')[0], data : noData, noData : true });

    this.year = value.getFullYear();
    this.month =  value.getMonth();
    this.firstDay = new Date(this.year, this.month, 1);
    this.lastDay = new Date(this.year, this.month + 1, 0);
    
    if (currentDate !== value.toISOString().split('T')[0]) {
      await this.setupGraphData();
    }
  }
  
  render() {
    return (<Card>
      <CardHeader
        action={(
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={this.state.selectedMonth}
              onChange={this.handleMonthChange}
              renderInput ={(params) => <TextField {...params}
              fullWidth
              margin="normal"
              variant="standard"
            />}
            />
          </LocalizationProvider>
        )}
        title="Species Wise Bookings"
      />
      <Divider />
      <CardContent>
        <Box sx={{ height: 300,  position: 'relative' }} >
          <Bar data={this.state.data} options={options} />
        </Box>
      </CardContent>
    </Card>);
  }
}
