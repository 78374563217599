import { Avatar, Typography, Box, Tooltip} from '@mui/material';
import { calculateAPISignature } from 'services/utils';

const minViewImage = { width: 48, height: 48, textAlign: "center"};
const minViewDoc = { width: 48, height: 48, textAlign: "center"};
const maxView = { width: 128, height: 128, p:2, border : "1px dashed grey" }

const ImageOrDocument = (props) => {
  if (props.document && props.document.includes("data:image")) {
    return (<Avatar
      variant="circular"
      alt="Image not displayed"
      src={props.document}
      sx={props.minView ? minViewImage : maxView} 
    />);
  }
  
  let parts = [], ext = '';
  if (props.document) {
    parts = props.document.split(".");
    ext = parts[parts.length - 1];
  }

  const ClickHandler = (event) => {
		event.preventDefault();
	};

  const signature = props.document 
    ? calculateAPISignature(encodeURIComponent(props.document.replace(/ /g, '%20')))
    : null;
  
  if(['jpeg', 'png', 'jpg', 'gif','JPEG','PNG','JPG','GIF'].includes(ext)) {
    return (
      <Tooltip title="Click to View Image">
        <a href={
            props.document 
              ? `${process.env.REACT_APP_STRAPI_FILE_URL}${props.document}?s=${signature}` 
              : process.env.PUBLIC_URL + '/not-found'
            }
            target="blank">
          <Avatar
            variant="circular"
            alt="Image not displayed" 
            src={
              props.document
                ? `${process.env.REACT_APP_STRAPI_FILE_URL}${props.document}?s=${signature}`
                : process.env.PUBLIC_URL + "/static/images/misc/noimage.png"
            }              
            sx={props.minView ? minViewImage : maxView} 
          />
        </a>
      </Tooltip>
    );  
  } else {
    return (<Box justifyContent={props.align ? props.align  : "center"} display="flex">
      <Tooltip title="Click to View Document">
        <a 
          href={
            props.document 
              ? `${process.env.REACT_APP_STRAPI_FILE_URL}${props.document}?s=${signature}` 
              : process.env.PUBLIC_URL + '/not-found'
          } 
          target="blank" 
          onClick={props.document ? true : ClickHandler }
          download ={props.document ? true : false}
        >
          <Avatar
            align="center"
            variant="circular"
            alt="Document not displayed" 
            src={
              props.document 
                ? process.env.PUBLIC_URL + "/static/images/misc/document.png"
                : process.env.PUBLIC_URL + "/static/images/misc/noimage.png"
            } 
            sx={props.minView ? minViewDoc : maxView} 
          />
          {
            !props.minView &&
            <Typography variant="overline" color="secondary" sx={{mx:2}}> {props.title ? props.title : "Click to View"} </Typography>
          }
        </a>
      </Tooltip>
    </Box>);
  }
};

export default ImageOrDocument;
