import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import { shipmentPackingFields } from '../booking-reports.fields';

import { 
  createNewBookingReport, updateBookingReport, 
  fetchTotalMortalitiesByBooking, filterFormFieldsBySpeciesType,
} from 'services/booking-reports';

export default class ShipmentPackingReport extends Component {
  
  state = { 
    liveBsData : false,
    statusMessage : { status : 'warning', message : 'Please wait !! fetching current live BS details'} 
  };
  
  constructor ( props ) {
    super (props);

    localStorage.setItem('arrivaldate', JSON.stringify(this.props.bookingData.arrivalReport));
    
    this.reportFields = [];
    shipmentPackingFields.map ( f => {
      let field = {...f};
      field.properties = [];
      // deep copy properties
      f.properties.map ( p => field.properties.push({...p}));
    
      // deep copy select options
      if (f.selectOptions) {
        field.selectOptions = {...f.selectOptions};
      }
      
      if (f.name === 'bookingId') {
        field.default =  props.bookingData.id;
      }

      if (f.name === 'packingDateTime') {
        if (props.bookingData.stockStatus && props.bookingData.stockStatus.clearanceCertificate)
        {
          field.default = props.bookingData.stockStatus.clearanceCertificate;
        }
     
      }
      

      if (f.name === 'maleBsPerBag' || f.name === 'femaleBsPerBag') {
        field.callback = this.calculateTotalBsPerBag;
      }
      this.reportFields.push(field);
      
      // fix warnings
      return null;
    });
    
    // filter fields based on species type
    this.reportFields = filterFormFieldsBySpeciesType('shipment-packing', 
      props.bookingData.speciesType.type, this.reportFields);
  }
  calculateTotalBsPerBag = async (field, value, formData, asyncCb) => {
  
      if (field === 'maleBsPerBag' || field === 'femaleBsPerBag') 
      {
         let maleBS = formData.maleBsPerBag && !isNaN(formData.maleBsPerBag) ? parseInt(formData.maleBsPerBag) : 0;
         let femaleBS = formData.femaleBsPerBag && !isNaN(formData.femaleBsPerBag) ? parseInt(formData.femaleBsPerBag) : 0;
         formData.bsPerBag = maleBS + femaleBS;
      }

    return { fields : null, values : formData};
    
  };
  async componentDidMount() {
    try {
      const opcode = 'booking-reports:' + 
      (
        this.props.currentValues
          ? ('update-shipment-packing:' + this.props.currentValues.id) 
          : 'create-shipment-packing'
      );
        
      let response = await fetchTotalMortalitiesByBooking(this.props.bookingData.id, opcode);
      
      this.reportFields = this.reportFields.map ( f => {
        if (f.name === 'totalMaleBs')
          f.default = response.data.totalMaleBs - response.data.arrivMaleMortalities - response.data.maleMortality;
        else if (f.name === 'totalFemaleBs')
          f.default = response.data.totalFemaleBs - response.data.arrivFemaleMortalities - response.data.femaleMortality;
        else if (f.name === 'totalBsPacked')
          f.default = response.data.totalArrivedBs - response.data.totalArrivMortalities - response.data.totalMortality;
        else if (f.name === 'daysInAQF') f.default = response.data.qrtnDays;
          
        return f;
      });
     
      this.setState({
        liveBsData : true,
        statusMessage : { status : 'success', message : 'Successfully fetched current live BS details'} 
      });
    } catch (error) {
      console.log(error);
      this.setState({
        statusMessage : { status : 'error', message : error.message} 
      });
    }
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new shipment packing report for the booking..." };
 
    if (!params.bsPerBag)
    {
      params.bsPerBag = 0;
    }
    // todo API calls
    try {
      let response;
      if (this.props.currentValues) {  
        response = await updateBookingReport('shipment-packing', this.props.currentValues.id, params);
        statusMessage = { status: "success", message: "Successfully updated shipment packing report for the booking..." };
      } else {
        response = await createNewBookingReport('shipment-packing', params);
      }
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    
    return(
      <>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={() => this.setState({ statusMessage: null })}
          />
        }
              
        <Typography variant="h5" align="center" p={2} > 
        { 
          (this.props.currentValues ? 'Update Shipment Packing Report' : 'Shipment Packing Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.liveBsData &&  
          <DynamicForm
            formFields={this.reportFields}
            action={this.props.currentValues ? "update" : "create"}
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Report"
            buttonWidth= "full"
            columns = {4}
            uploadFiles = {true}
            currentValues={this.props.currentValues}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/shipment-packing"}
          />
        }
      </>
    );
  }
}
