import React, { Component } from 'react';

import { 
  Box, 
  Grow, 
  Grid,  
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography, 
  Paper, 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Button
} from '@mui/material';

// services
import { fetchCubicleStatusByDate } from 'services/bookings';

export default class DailyStockStatus extends Component {
  
  state = { status : [], page : 0, totalPages : 0, fadeIn : true, modalIdx : 0, modalOpen : false};
  
  async componentDidMount () {
    let date = new Date().toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
    try {
      let response = await fetchCubicleStatusByDate({date});
      this.setState({status : response.data, totalPages : Math.floor(response.data.length / 9)});
      
      // setup timer based state change
      this.interval = setInterval(() => {
        this.setState({fadeIn : false});
        if (this.state.page < this.state.totalPages) {
          this.setState({page : this.state.page + 1, fadeIn : true});
        } else {
          this.setState({page : 0, fadeIn : true});
        }
      }, 5000);
      
    } catch (error) {
      console.log(error);
    }
  }
  
  toggle = async () => {
    this.setState({modalOpen : !this.state.modalOpen});
  }
  
  componentWillUnmount() {
    console.log("clearing current interval " + this.interval);
    clearInterval(this.interval);
  }
  
  render() {
    
    let vacant = this.state.status ? this.state.status.filter(x => x.totalAnimalStocked == 0).length : 0;
    let occupied =  this.state.status ?  this.state.status.length - vacant : 0;
    
    return (<Card sx={{ height: '400', p:2 }} {...this.props} >
      <CardHeader title={ `Stock Status : ${new Date().toLocaleString('en-GB', {timezone : 'Asia/Kolkata', dateStyle:'short'})}` }/>
      <Divider />
      <CardContent>
      {
        this.state.status && this.state.status[this.state.modalIdx] &&    
        <Dialog open={this.state.modalOpen} onClose={()=> this.toggle()} aria-labelledby="form-dialog-title" size="medium">
          <DialogContent sx={{ p:2}}> 
            <Typography variant="h4" align="center">{`${this.state.status[this.state.modalIdx].cubicleName}`}</Typography><hr style={{borderColor:"#eef1f6"}} /><br/>
            <Typography variant="body2">{`Total Stock (Qty)   - ${this.state.status[this.state.modalIdx].totalAnimalStocked}`} </Typography><br/>
            <Typography variant="body2">{`Total Weight(Grams) - ${this.state.status[this.state.modalIdx].totalStockGms}`} </Typography><br/>
            {
              this.state.status[this.state.modalIdx].hatchery && 
              <>
                <Typography variant="body2">{`Hatchery   - ${this.state.status[this.state.modalIdx].hatchery}`} </Typography><br/>
                <Typography variant="body2">{`Booking ID - ${this.state.status[this.state.modalIdx].bookingId}`} </Typography><br/>
                <Typography variant="body2">{`Start Date  - ${this.state.status[this.state.modalIdx].fromDate.split('T')[0]}`} </Typography><br/>
                <Typography variant="body2">{`End Date  - ${this.state.status[this.state.modalIdx].toDate.split('T')[0] }`} </Typography><br/>
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggle}> Close </Button>
          </DialogActions>
        </Dialog>
      }
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
      {
        this.state.status.length > 0 &&
        this.state.status.slice(this.state.page * 9, (this.state.page + 1) * 9).map((entry, idx) => {
          return (
            <Grow in = {this.state.fadeIn} key={entry.cubicleId}>
              <Grid item xs={12} sm={4} key={entry.cubicleId} 
                onClick = {() => this.setState({ modalOpen : true, modalIdx : (this.state.page * 9) + idx })}
              >
                <Paper elevation={3}>
                  <Box sx={{p:1, bgcolor: entry.hatchery ? "error.main" : "success.light"}}>
                    <Typography variant="h4" align="center" color="#fff">{entry.cubicleName}</Typography>
                  </Box>
                  <Box sx={{p:1}}>
                    <Typography variant="subtitle2">{`Hatchery : ${entry.hatchery ? entry.hatchery : '--'}`}</Typography><br/>
                    <Grid container justifyContent="space-between"  alignItems="center">
                      <Grid item xs={6} sm={6} >
                        <Box sx={{border : '1px dotted', mr:1}}>
                          <Typography variant="caption" fontWeight="500">
                            {`Total Stock (Qty) : ${entry.totalAnimalStocked}`}
                          </Typography><br/>
                          <Typography variant="caption" fontWeight="500">
                            {`Total Weight (Kgs) : ${(entry.totalStockGms/1000).toFixed(2)}`}
                          </Typography><br/>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Box sx={{border : '1px dotted'}}>
                          <Typography variant="caption" fontWeight="500" >
                            {`From Date : ${entry.fromDate ? entry.fromDate.split('T')[0] : '--'}`}
                          </Typography><br/>
                          <Typography variant="caption" fontWeight="500">
                            {`To Date : ${entry.toDate ? entry.toDate.split('T')[0] : '--'}`}
                          </Typography><br/>
                        </Box>
                      </Grid>
                    </Grid>
                    
                  </Box>
                </Paper>
              </Grid>
            </Grow>
          )
        })
      }
      </Grid>
      <Box sx={{p:1, mt:2, textAlign: "center"}}>
        <Typography variant="overline" color="primary.main" sx={{ml : 4}}> Total Cubicles : {vacant + occupied} </Typography>
        <Typography variant="overline" color="success.light" sx={{ml : 4}}> Vacant Cubicles : {vacant} </Typography>
        <Typography variant="overline" color="error.main" sx={{ml : 4}}> Stocked Cubicles : {occupied} </Typography>
      </Box>
      </CardContent>
    </Card>)
  }
}
