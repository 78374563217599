import { fetchWithTimeout, addPagerParams } from './utils';

const BOOKING_SCHEDULES_EP_URL = "/cubicle-booking-schedules";

 
export const fetchBookingSchedulesByYear = async (params) => {
  return await fetchWithTimeout({
    path : BOOKING_SCHEDULES_EP_URL + '/find-by-year/' + params.year + addPagerParams(params),
    method : 'GET',
    operation : 'booking-schedule:view-any'
  });
}

export const findBookingByDateRange = async (params) => {
  return await fetchWithTimeout({
    path : BOOKING_SCHEDULES_EP_URL + '/find-by-date-range?availableDate_gte=' + params.start + '&availableDate_lte=' + params.end
      + (params.speciesType ? '&speciesId=' + params.speciesType : '')
      + (params.all ? '&_limit=-1' : ''),
    method : 'GET',
    operation : 'booking-schedule:view-any'
  });
}

export const updateBookingSchedules = async (params) => {
  return await fetchWithTimeout({
    path : BOOKING_SCHEDULES_EP_URL + '/bulk-update',
    method : 'POST',
    body : params,
    operation : 'booking-schedule:update'
  });
}

export const createBookingSchedules = async (params) => {
  return await fetchWithTimeout({
    path : BOOKING_SCHEDULES_EP_URL + '/bulk-create',
    method : 'POST',
    body : params,
    operation : 'booking-schedule:create'
  });
}