import React from 'react';

// Material helpers
import { withStyles } from '@mui/styles';

// Material components
import { Input } from '@mui/material';

// Material icons
import { Search as SearchIcon } from '@mui/icons-material';

// Component styles
import styles from './styles';

const SearchInput = props => {
  const { classes, onSearch, style, ...rest } = props;

  return (
  <div
    className={classes.root}
    style={style}
  >
    <SearchIcon className={classes.icon} />
    <Input
    {...rest}
    className={classes.input}
    disableUnderline
    onKeyPress={(ev) => {
      if (ev.key === 'Enter') {
      ev.preventDefault();
      onSearch(ev)
      }
    }}
    onChange={(e) => {
      if (e.target.value.length === 0) {
      onSearch(e)
      }
    }}
    />
  </div>
  );
};

export default withStyles(styles)(SearchInput);
