import React, { Component } from 'react';

import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

// summary widget component
import SummaryWidget from 'components/SummaryWidget';

// services
import {fetchApprovalPendingHatcheriesCount} from 'services/hatcheries';


export default class ApprovalPendingHatcheries extends Component {

  state = { count : '...' }
  
  async componentDidMount () {
    try {
      let response = await fetchApprovalPendingHatcheriesCount({});
      this.setState({count : response.data})
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    return (
      <SummaryWidget 
        label = {'Approval Pending Hatcheries'} 
        count = {this.state.count} 
        icon = {<DomainDisabledIcon />}
        url = { '/aqf/hatcheries/approvals/hatcheries' }
      />
    );
  }
}