import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Typography, Hidden, Box, IconButton, Menu, MenuItem } from '@mui/material';
import Logo from './Logo';

import {
  HelpOutlineOutlined,
  PolicyOutlined,
  FactCheckOutlined,
  HowToRegOutlined,
  HomeOutlined,
} from '@mui/icons-material';

import MenuIcon from '@mui/icons-material/Menu';

import { AuthStore } from 'store';

const MainNavbar = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <AppBar elevation={3} {...props}>
      <Toolbar sx={{ height: 64 }}>
        <RouterLink to="/">
        <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Grid container p={2} justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" color="white" noWrap align="center"  
                sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
              >
                <HomeOutlined sx={{fontSize:28,mx:1}}/>
                <RouterLink to="/" style={{color:"white", textDecoration:"none", align:"center"}}> Home </RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" color="white" noWrap align="center"  
                sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
              >
                <PolicyOutlined sx={{fontSize:28,mx:1}}/>
                <RouterLink to="/main/privacy" style={{color:"white", textDecoration:"none", align:"center"}}> Privacy Policy </RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" noWrap color="white" align="center"
                sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
              >
                <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
                <RouterLink to="/main/terms" style={{color:"white", textDecoration:"none", align:"center"}}> Terms & Conditions </RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" noWrap color="white" align="center"
                sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
              >
                <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
                <RouterLink to="/main/cancellation-refunds" style={{color:"white", textDecoration:"none", align:"center"}}> Cancellation & Refunds </RouterLink>
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="h6" noWrap color="white" align="center"
                 sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap', mr:2}}
              >
                <HelpOutlineOutlined sx={{fontSize:28,mx:1}} />
                <RouterLink to="/main/support" style={{color:"white", textDecoration:"none"}}> Support </RouterLink>
              </Typography>
            </Grid>
            {
              AuthStore.state.currentUser == null &&
              <Grid item xs={12} sm="auto">
                <Typography variant="h6" noWrap color="white" align="center"
                   sx={{display:'flex', alignItems: 'center',flexWrap: 'wrap'}}
                >
                  <HowToRegOutlined sx={{fontSize:28,mx:1}} />
                  <RouterLink to="/aqf/hatcheries/main/register"  style={{color:"yellow", textDecoration:"none"}}>
                    Register Hatchery
                  </RouterLink>
                </Typography>
              </Grid>
            }
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 256,
                maxWidth: 256
              },
            }}
          >
            <MenuItem key={1} onClick={handleClose}>
              <HomeOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/" style={{textDecoration:"none", align:"center"}}> Home </RouterLink>
            </MenuItem>
            <MenuItem key={2} onClick={handleClose}>
              <PolicyOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/main/privacy" style={{textDecoration:"none", align:"center"}}> Privacy Policy </RouterLink>
            </MenuItem>
            <MenuItem key={3} onClick={handleClose}>
              <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/main/terms" style={{textDecoration:"none", align:"center"}}> Terms & Conditions </RouterLink>
            </MenuItem>
            <MenuItem key={4} onClick={handleClose}>
              <FactCheckOutlined sx={{fontSize:28,mx:1}}/>
              <RouterLink to="/main/cancellation-refunds" style={{textDecoration:"none", align:"center"}}> Cancellation & Refunds </RouterLink>
            </MenuItem>
            <MenuItem key={5} onClick={handleClose}>
              <HelpOutlineOutlined sx={{fontSize:28,mx:1}} />
              <RouterLink to="/main/support" style={{textDecoration:"none", align:"center"}}> Support </RouterLink>
            </MenuItem>
            {
              AuthStore.state.currentUser == null &&
              <MenuItem key={6} onClick={handleClose}>
                <HowToRegOutlined sx={{fontSize:28,mx:1}} />
                <RouterLink to="/aqf/hatcheries/main/register" style={{textDecoration:"none", align:"center"}}>
                  Register Hatchery
                </RouterLink>
              </MenuItem>
            }
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default MainNavbar;
