import { useState } from 'react';

import { Paper, Typography, Dialog, DialogContent, Button, Box, Divider } from '@mui/material';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function ConfirmationDialog(props) {
  
  const [modalOpen, setModalOpen] = useState(false);
  
  return (<>
    <Button
      fullWidth = {props.fullWidth}
      color = {props.color}
      disabled={props.disabled}
      onClick={ (e) => setModalOpen(true) }
      size="large"
      variant="contained"
    >
      {props.buttonText}
    </Button>
    {
      modalOpen &&    
      <Dialog open={modalOpen} onClose={()=> setModalOpen(!modalOpen)} aria-labelledby="form-dialog-title">
        <DialogContent sx={{textAlign:"center", p:2}}> 
          <WarningAmberIcon color="warning" sx={{ mr : 2, fontSize : 64, fontWeight : 300 }} />
          <Box>
            
            <Typography variant="subtitle1" sx={{ verticalAlign: 'middle',  display: 'inline-flex'}}> 
              {props.confirmation} 
            </Typography>
          </Box>
          <Divider light={true} sx={{mt :2 }}/>
          <Box sx={{float : "right", m : 1}}>
            <Button color="error" onClick={ () => { setModalOpen(false) }} 
              size="small" variant="outlined"> 
              Cancel 
            </Button>
          </Box>
          <Box sx={{float : "right", m : 1}}>
            <Button color="success" onClick={async (event) => {setModalOpen(false); await props.onClick(event); }} 
              size="small" variant="outlined"> 
              Confirm 
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    }
  </>);
}