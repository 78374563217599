import React, { Component } from 'react';
import { withLocationParams } from 'hoc';
import { Navigate } from 'react-router-dom';

import { 
  Paper, Grid, Box, Button, Typography
} from '@mui/material';

// import single entry view component
import EntryTabularView from 'components/EntryTabularView';

// import TableView Component
import DTable from "components/DataTables/DTable";

import NavigateBack from 'components/NavigateBack';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

// import shared services
import { fetchEventLogById } from 'services/common';

// import form fieldset
import { eventLogFields , changeLogFields } from './eventlog.fields';

class ViewEventLog extends Component {
    
  constructor ( props ) {
    super (props);
     this.state = { eventLogData : null, statusMessage : null, printView : false }
  }
  
  async componentDidMount() {
    try {
      let response = await fetchEventLogById(this.props.locationParams.id);
      response.data.hatcheryId = response.data.hatcheryId ? response.data.hatcheryId.name : null;
      response.data.owner = response.data.owner ? response.data.owner.name : null;
      response.data.eventType = response.data.eventType.replace(/_/g, ' ');
      if (response.data.changeLog &&  response.data.changeLog.length) {
        // index = -1 is equaivalent to null
        response.data.changeLog.map ( x => {
          x.index = (x.index === -1) ? null : x.index;
          if (typeof x.oldValue === 'object') x.oldValue = JSON.stringify(x.oldValue); 
          if (typeof x.newValue === 'object') x.newValue = JSON.stringify(x.newValue); 
          return x;
        });
      }
      this.setState({eventLogData : response.data, statusMessage : null})
    } catch (error) {
      console.log ( error );
      this.setState({ statusMessage : { status : "error", message : error.message}});
    }
  }

  render () {
    
    if (this.state.statusMessage && [404, 403].includes(this.state.statusMessage.code)) {
      return (<Navigate to="/not-found" />);
    }
    
    return (
      <>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        <NavigateBack />
        <Paper elevation={3} sx={{textAlign:"center", p:2, m:1}}>
          <Typography variant="h3" align="center">{'Event Log Details, ID : ' + this.props.locationParams.id}</Typography>
        </Paper>
        
        <Grid container justifyContent="space-between" alignItems="flex-start" direction="row">
          <Grid xs={8} item key={2}>
            <EntryTabularView values={this.state.eventLogData} formFields = {eventLogFields} />
          </Grid>
        </Grid>
        {
          this.state.eventLogData && this.state.eventLogData.changeLog &&
          this.state.eventLogData.changeLog.length &&
          <>
            <Paper elevation={3} sx={{textAlign:"center", p:2, m:1}}>
              <Typography variant="h4" align="center">View Change Log Details</Typography>
            </Paper>
            <DTable
              columns = {changeLogFields}
              rowsPerPage={this.state.eventLogData.changeLog.length}
              dataTotal={this.state.eventLogData.changeLog.length}
              data={this.state.eventLogData.changeLog}
              page={1}
              multiDeleteEnabled={false}
            />
          </>
        }
      </>
    );
  }
}

export default withLocationParams(ViewEventLog);
