export default [
  {  
    name: 'id',
    label: 'Additional Charges Id',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view']
  },
  {  
    name: 'bookingId',
    label: 'Booking ID',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['view', 'index', 'update', 'create']
  },
  {  
    name: 'hatcheryId',
    label: 'Hatchery Name',
    type: 'string',
    properties: [{readOnly:true}],
    actions: ['index','view', 'update']
  },
  {  
    name: 'fromDate',
    label: 'Reserved Date',
    type: 'date',
    properties: [{readOnly:true}],
    actions: ['index','view']
  },
  {  
    name: 'arrivedDate',
    label: 'Arrived Date',
    type: 'date',
    properties: [{readOnly:true}],
    actions: ['index','view', 'update']
  },
  { 
    name: 'type',
    label: 'Charges Type',
    type: 'string',
    properties: [{required:true}],
    actions: ['create','update','index', 'view']
  },
  {  
    name: 'amount',
    label: 'Amount',
    type: 'currency',
    properties: [{required:true}, {number : true},{numeric:true}],
    actions: ['create','update','index', 'view']
  },
  {  
    name: 'paymentId',
    label: 'Order ID',
    type: 'string',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'transactionDate',
    label: 'Payment Date',
    type: 'datetime',
    properties: [{required : true } , {readOnly:true}],
    actions: ['index', 'view']
  },
  {  
    name: 'status',
    label: 'Status',
    type: 'select',
    selectOptions : {
      "PendingPayment" : "Pending Payment",
      "RetryPayment" : "Retry Payment",
      "Paid" : "Paid",
      "Rejected" : "Rejected"
    },
    properties: [{readOnly:true}],
    actions: ['index', 'view', 'update']
  }
];
