import React, { Component } from 'react';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

import userAccountFields from './user-accounts.fields';

import { createNewUserAccout } from 'services/user';
import { fetchAllRoles } from 'services/roles-permissions';
import { checkUniqueAttributes } from 'services/common';

export default class AddNewUserAccount extends Component {
  state = { 
    statusMessage : {status: "warning", message: "Please wait !! populating available roles..."}
  };
  
  constructor(props) {
    super(props);
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'email',
        'value' : formData.email,
        'currentId' : null
      }, {
        'attribute' : 'phoneNumber',
        'value' : formData.phoneNumber,
        'currentId' : null
      }];

      let result = await checkUniqueAttributes(uniqueParams);
      
      if (result.data.email && result.data.email.unique === false) {
        validation.isValid = false;
        validation['email'] = { isInvalid : true, message : result.data.email.message };
      }
      if (result.data.phoneNumber && result.data.phoneNumber.unique === false) {
        validation.isValid = false;
        validation['phoneNumber'] = { isInvalid : true, message : result.data.phoneNumber.message };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new user..." };
    
    // todo API calls
    try {
      let response = await createNewUserAccout(params);
    } catch (error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    try {
      let response = await fetchAllRoles({ page : 1, per_page : 100});
      response.data.map( entry => {
        // cannot add hatchery owner account here !!!
        if (entry.role.name === 'Hatchery Owner') {
          return;
        }
        
        userAccountFields[3].selectOptions[entry.role.name] = entry.role.name
      });
      this.setState({statusMessage : {status: "success", message: "Succesfully populated available roles..."}});
    } catch (error) {
      console.log(error);
      this.setState({statusMessage : {status: "error", message: "Failed to get available roles..."}});
    }
  }

  render() {
    return (
    <>
      <NavigateBack />
      <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
        {
          this.state.statusMessage &&
          <CustomSnackbar variant={this.state.statusMessage.status}
            message={this.state.statusMessage.message}
            open={this.state.statusMessage.status}
            onClose={async () => await this.setState({ statusMessage: null })}
          />
        }
        <Typography variant="h4"> Add New User Profile </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        <DynamicForm
          formFields={userAccountFields}
          action="create"
          preFormSubmit={this.handlePreFormSubmit}
          handleSubmit={this.handleFormSubmit}
          buttonText="Add New AQF User"
          buttonWidth= "full"
          columns = {3}
          uploadFiles={true}
          redirect={'/aqf/admin/user-accounts/list'}
          confirmation={"Are you sure you want to save the details ? "}
        />
      </Paper>
    </>);
  }
}