import React, { Component } from 'react';

import { NavLink } from "react-router-dom";

import { 
  Paper, 
  Grid, 
  TextField, 
  Box, 
  Typography, 
  Button,  
  Fade,
  Divider,
  MenuItem
} from '@mui/material';

import Calendar from 'components/Calendar';

import { HatcheryStore } from 'store';

import { findBookingByDateRange } from 'services/booking-schedules';
import { fetchAllSpecies } from 'services/species-details';

export default class CubicleStatusCard extends Component {
  
  state = {
    selectedDate : null,
    monthSchedule : null,
    speciesData : null,
    selectedSpecies : HatcheryStore.state && HatcheryStore.state.currentHatchery ? 
                        HatcheryStore.state.currentHatchery.permittedSpeciesId.id : null,
    statusCount : {availableDate : '--', availableCount:0, blockedCount : 0, confirmedCount : 0},
    fadeEffect : true,
  };
  
  
  async componentDidMount() {
    let speciesData = null;
    let currentSpecies = this.state.selectedSpecies;
    
    try {
      if (!currentSpecies) {
        speciesData = await fetchAllSpecies({page : 1, per_page: -1});
        if (speciesData.data && speciesData.data.length) {
          currentSpecies = speciesData.data[0].id;
        }
        this.setState({speciesData : speciesData.data, selectedSpecies : currentSpecies});
        this.handleMonthChange(0, this.firstDay, this.lastDay);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  handleMonthChange = async (monthNo, firstDay, lastDay) => {
    
    this.firstDay = firstDay;
    this.lastDay = lastDay;
    
    if (!this.state.selectedSpecies) return;
    
    try {
      let response = await findBookingByDateRange({
        start : firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}), 
        end : lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        speciesType : this.state.selectedSpecies,
        all : true
      });
      this.setState({
        monthSchedule : response.data, 
        statusCount : null,
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  handleSpeciesSelect = async (event) => {
    try {
      let response = await findBookingByDateRange({
        start : this.firstDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        end : this.lastDay.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'}),
        speciesType : event.target.value,
        all : true
      });
      
      this.setState({
        monthSchedule : response.data, 
        statusCount : null,
        selectedSpecies : event.target.value,
        fadeEffect : false
      });
    } catch (error) {
      console.log(error);
      this.setState({selectedSpecies : event.target.value});
    }
    
    setTimeout( () => this.setState({fadeEffect : true}), 500);
  }
  
  handleDateSelect = (date) => {
    
    let statusCount = {availableDate : date, availableCount:0, blockedCount : 0, confirmedCount : 0};
    
    // check if the selected date can be booked
    // check how many cubicles are free vs cubicles required
    if (this.state.monthSchedule && 
      this.state.monthSchedule.statusCount && 
      this.state.monthSchedule.statusCount[date]) {
      statusCount = this.state.monthSchedule.statusCount[date];
        
      this.setState({
        selectedDate : date,
        fadeEffect : false
      });
    } else {
      this.setState({
         selectedDate : date,
         fadeEffect : false
       });
    }
    
    statusCount.availableDate = date;
    
    setTimeout( () => this.setState({fadeEffect : true, statusCount : statusCount}), 500);
    
  }
  disableDate = (date) =>{

    let current_date = new Date();
    let check_date = new Date(date);
    current_date.setDate(current_date.getDate() + 4);

    if (check_date < current_date) {
      return true;
    }
    return false;
  }
  dayColor = (date) => {
   // let date = new Date(this.year + '-' + (this.month+1) + '-' + day).toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
	  if (
      this.state.monthSchedule && 
      this.state.monthSchedule.status === 'Available' &&
      this.state.monthSchedule.statusCount &&
      this.state.monthSchedule.statusCount[date]
    ) {
      let statusCount = this.state.monthSchedule.statusCount[date];
      if (statusCount.availableCount > 0) return 'success.main';
      else if (statusCount.blockedCount > 0) return 'yellow';
      else if (statusCount.confirmedCount > 0) return 'error.main';
      else return null;
    }
    return null;
  }
  
 
  render() {
    const virtualAccount = HatcheryStore.state.currentHatchery ? HatcheryStore.state.currentHatchery.rzrpayVirtualAccount : null;
    const license =  HatcheryStore.state.currentHatchery ?  HatcheryStore.state.currentHatchery.license : null;

    return (
      <Paper elevation={4} sx= {{ p : 1, mt : 1 }}>
        <Grid container spacing={3} justifyContent="space-between" justify="center" sx={{mt:1}} alignItems="flex-start" >
          <Grid key="0-firstgrid" item xs={12} sm={6}>
            <Calendar
              title = "View Cubicle Availability"
              currentMonth = {this.currentMonth}
              handleMonthChange = {this.handleMonthChange}
              handleDateSelect = {this.handleDateSelect}
              dayColor = {this.dayColor}
              disableDate = {this.disableDate}
            />
          </Grid>
          <Grid key="1-firstgrid" item xs={12} sm={6} sx={{ p : 2 }}>
          {
            this.state.speciesData &&
            <TextField
              id="speciesId"
              label="Select Species"
              name="speciesId"
              select
              align="right"
              type='text'
              size="large"
              value={this.state.selectedSpecies}
              onChange={this.handleSpeciesSelect}
              margin="normal"
              variant="outlined"
             >
              {
                this.state.speciesData.map ((opt, i) => {
                  return (<MenuItem key={i} value={opt.id}>{opt.name}</MenuItem>);
                })
              }
            </TextField>
          }
          {
            this.state.statusCount &&
            <Fade in = {this.state.fadeEffect}>
              <Grid container spacing={3} justifyContent="flex-start" sx={{ mx : 1, my : 6}}>
                <Grid item key={'2x'} xs={6} sx={{backgroundColor : '#F4F6F8'}}> 
                  <Box sx={{ display : "flex", component : "span", mb : 2}}>
                    <Box sx={{ width: 40, height: 30, bgcolor: 'success.main', mr : 1}} />
                    <Typography variant="overline"> Available Count : {this.state.statusCount.availableCount}</Typography>
                  </Box>
                  <Box sx={{ display : "flex", component : "span", mb : 2}}>
                    <Box sx={{ width: 40, height: 30, bgcolor: 'yellow', mr : 1 }} />
                    <Typography variant="overline"> Blocked Count : {this.state.statusCount.blockedCount}</Typography>
                  </Box>
                  <Box sx={{ display : "flex", component : "span", mb : 2}}>
                    <Box sx={{ width: 40, height: 30, bgcolor: 'error.main', mr : 1}} />
                    <Typography variant="overline"> Confirmed Count : {this.state.statusCount.confirmedCount}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Fade>
          }
          </Grid>
        </Grid>
        {
          virtualAccount && license &&
          <Grid container justifyContent="flex-end">
            {/* <Button
              variant="contained"
              color = "primary"
              component={NavLink} 
              to={'/aqf/bookings/add'}
            >
              Book Now 
            </Button> */}
          </Grid>
        }
      </Paper>
    );
  }
};