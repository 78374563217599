import React, { Component } from 'react';

import { Typography} from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import { updateBooking } from 'services/bookings';
import { fetchAllSuppliers } from 'services/suppliers';

export default class EditSupplierForm extends React.Component {
 
  state = { suppliers : false };
  
  constructor(props) {
    super(props);
    
    this.formFields = [{
        name: 'supplierId',
        label: 'Select Supplier',
        type: 'select',
        selectOptions : {},
        properties: [{required:true}],
        actions: ['update']
    }];
  }
  
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (formData) => {
    let statusMessage = { status: "success", message: "Successfully cancelled booking..." };
    try {
      let response = await updateBooking(this.props.bookingData.id, formData);
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    return statusMessage;
  }
  
   async componentDidMount () {
    try {
      // fetch and update list of suppliers for the species
      let response = await fetchAllSuppliers({
         speciesType : this.props.bookingData ? this.props.bookingData.supplierDetails.speciesIds : 'ALL',
         status : true,
         per_page : -1,
         page : 1
      });
      
      response.data.map( supplier => {
        this.formFields[0].selectOptions[supplier.id] = supplier.name;
      });
      
      this.setState({suppliers : true });
    } catch(error) {
      console.log(error);
    }
  }
  
  render() {
    return(<>
    
      <Typography variant="h4" align="center" p={1} > 
      { 
        `Change Supplier for Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
      }
      </Typography><hr style={{borderColor:"#eef1f6"}} />
      <DynamicForm
        formFields={this.formFields}
        action="update"
        handleSubmit={this.handleFormSubmit}
        buttonText="Update Supplier"
        buttonWidth= "full"
        columns = {1}
        currentValues={{supplierId : (this.props.bookingData.supplierDetails || {}).id}}
        redirect={null}
      />
    </>);
  }
}