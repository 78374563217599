import React from "react";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from '@mui/styles';

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { ImageCrop } from "./ImageCrop";

const styles = theme => ({
  root: {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper
  },
  ImageList: {
  width: "auto",
  height: 520
  },
  ImageListEmpty: {
  width: "auto",
  height: "auto"
  },
  deleteIcon: {
  color: "red"
  },
  editIcon: {
  color: "white"
  },
  featureIcon: {
  color: "white"
  },
  titleBar: {
  background:
    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  signInButton: {
  marginTop: theme.spacing(2),
  width: "100%"
  },
  fieldError: {
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1)
  },
  submitError: {
  color: theme.palette.error.main,
  alignText: "center",
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2)
  }
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *   img: image,
 *   title: 'Image',
 *   author: 'author',
 *   cols: 2,
 *   },
 *   {
 *   [etc...]
 *   },
 * ];
 */

class ImageGridList extends React.Component {
  constructor(props) {
  super(props);
  this.state = { isLoading: false };
  this.fileInput = React.createRef();
  }

  render() {
  const classes = this.props.classes;
  
  return (
    <div className={classes.root}>
    <ImageList cellHeight={160} 
       className={this.props.images.length > 2 ? classes.ImageList : classes.ImageListEmpty} 
       cols={2}>
      <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}>
      <Typography variant="h6" style={{ align: "center" }}>
        {this.props.formField.label}
      </Typography>
      <hr />
      </ImageListItem>
      {this.props.readOnly === false && (
      <ImageListItem key={0} cols={1}>
        <ImageCrop
        classes={classes}
        formField={this.props.formField}
        isHtmlInput={false}
        action={this.props.action}
        value={null}
        onSave={this.props.addImageHandler}
        readOnly={this.props.readOnly}
        />
      </ImageListItem>
      )}
      {this.props.images.length > 0 &&
      this.props.images.map(
        (tile, idx) =>
        tile.removePending === false && (
          <ImageListItem key={idx} cols={1}>
          <img src={tile.src.image} alt={"Placeholder"} />
          <ImageListItemBar
            actionIcon={
            <React.Fragment>
              <IconButton
              aria-label="Delete Image"
              className={classes.deleteIcon}
              >
              <DeleteIcon
                key={idx}
                onClick={() =>
                this.props.removeImageHandler(
                  this.props.formField.name,
                  idx
                )
                }
              />
              </IconButton>
              {this.props.formField.properties &&
              this.props.formField.properties.find(
                p => p.caption !== undefined
              ).caption && (
                <IconButton
                aria-label="Edit Caption"
                className={classes.editIcon}
                >
                <EditIcon
                  key={idx}
                  onClick={() =>
                  this.props.editCaptionContentHandler(
                    idx,
                    this.props.formField.name,
                    tile
                  )
                  }
                />
                </IconButton>
              )}
              {this.props.isFeaturedImage && (
              <IconButton
                aria-label="Featured Image"
                className={classes.featureIcon}
              >
                {this.props.featuredImageKey === idx ? (
                <FavoriteIcon key={idx} />
                ) : (
                <FavoriteBorderIcon
                  key={idx}
                  onClick={() =>
                  this.props.featureImageHandler(
                    this.props.formField.name,
                    idx
                  )
                  }
                />
                )}
              </IconButton>
              )}
            </React.Fragment>
            }
            actionPosition="right"
            titlePosition="bottom"
            className={classes.titleBar}
          />
          </ImageListItem>
        )
      )}
    </ImageList>
    {this.props.isFeaturedImage &&
     this.props.featuredImageKey == null &&
     this.props.images.length > 0 && (
        <Typography
        className={this.props.classes.fieldError}
        variant="body2"
        >
        {"Please select a Featured Image"}
        </Typography>
      )}
    </div>
  );
  }
}

export default withStyles(styles)(ImageGridList);
