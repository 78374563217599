import React, { Component } from 'react';
import cxor from 'xor-crypto-js';

import { Grid, Box, Button } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

import WebcamImgCapture from 'components/WebcamImgCapture';

import { authorizedPersonFields } from '../owner.fields';

import { faceRecognitionEnrol, faceRecognitionDelete, checkUniqueAttributes } from "services/common";

import { updateUserAccount } from "services/user";

import { calculateAPISignature } from 'services/utils';

export default class SingleAuthPersonForm extends Component {
  
  state = { 
    valid: false, 
    isSubmitting: false, 
    startCapture : false,
    authPerson : this.props.currentValues ? {...this.props.currentValues} : null,
    capturedImage: null
  };

  constructor(props) {
    super(props);
    
    let signature = null;
    if (this.props.currentValues && this.props.currentValues.picture) {
      signature = calculateAPISignature(encodeURIComponent(this.props.currentValues.picture));
      this.state.capturedImage =  
        process.env.REACT_APP_STRAPI_FILE_URL + this.props.currentValues.picture + '?s=' + signature;
    }
    
    // decrypt aadhaar data
    if (this.state.authPerson && this.state.authPerson.aadhaarNumber) {
      this.state.authPerson.aadhaarNumber =
        cxor.decrypt(this.state.authPerson.aadhaarNumber, process.env.REACT_APP_BODY_ENCRYPT_KEY);
    }
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    // some more validations here before save 
    if (!this.props.currentValues && !this.state.capturedImage) {
      statusMessage = { status: "error", message: "Please capture authorized person image" };
      return statusMessage;
    }
    
    let validation = {isValid : true};
    let attr = this.props.profileType === 'auth1' 
      ? ['auth1Email', 'auth1Phone', 'auth1Aadhaar'] 
      : ['auth2Email', 'auth2Phone', 'auth2Aadhaar'];
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : attr[0],
        'value' : formData.email,
        'currentId' : this.props.id
      }, {
        'attribute' : attr[1],
        'value' : formData.phoneNumber,
        'currentId' : this.props.id
      }, {
        'attribute' : attr[2],
        'value' : formData.aadhaarNumber,
        'currentId' : this.props.id
      }];
      
      let result = await checkUniqueAttributes(uniqueParams);
      if (result.data[attr[0]] && result.data[attr[0]].unique === false) {
        validation.isValid = false;
        validation['email'] = { isInvalid : true, message : result.data[attr[0]].message };
      }
      
      if (result.data[attr[1]] && result.data[attr[1]].unique === false) {
        validation.isValid = false;
        validation['phoneNumber'] = { isInvalid : true, message : result.data[attr[1]].message };
      }
      
      if (result.data[attr[2]] && result.data[attr[2]].unique === false) {
        validation.isValid = false;
        validation['aadhaarNumber'] = { isInvalid : true, message : result.data[attr[2]].message };
      }   
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    
    await this.setState({ isSubmitting: true });
    let statusMessage = { status: "success" };
    
    // enrol captured images for face recognition
    // enrol only if image has been captured
    //
    let response;
    let newImageCaptured = this.state.capturedImage && this.state.capturedImage.includes("data:image");
    if (newImageCaptured) {
      response = await faceRecognitionEnrol(
        this.props.hatcheryId,
        this.state.capturedImage,
        this.props.profileType
      );
      
      if (response.status === "success") {
        params.picture = response.fileUpload && response.fileUpload.status === "success" 
          ?  response.fileUpload.url : null;
      } else {
        statusMessage = { status: "error", message : "Person #1 : "  + response.message};
        return statusMessage;
      }
    } else { 
      params.picture = this.props.currentValues ? this.props.currentValues.picture : null;
    }
    
    let formData = {};
    
    if (this.props.profileType === 'auth1') {
      formData = {"authPerson1" : params };
    } else {
      formData = {"authPerson2" : params };
    }
    
    try {
      response = await updateUserAccount(this.props.id, formData);
      
      // clean up old enrollment if exists
      if (newImageCaptured && this.props.currentValues && this.props.currentValues.picture) {
        await faceRecognitionDelete(this.props.currentValues.picture);
      }
      
      if (this.props.refresh) this.props.refresh();
    } catch (error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }

  handleCreateFormSubmit
  handleCapturePhoto = async (name, value) => {
    this.setState({ startCapture : false, capturedImage: value });
  }

  render() {
    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="row">
        <Grid item xs={12} sm={8}>
          <DynamicForm
            formFields={authorizedPersonFields}
            handleSubmit={this.handleFormSubmit}
            preFormSubmit={this.handlePreFormSubmit}
            buttonText="Save Authorized Person Details"
            buttonWidth= "full"
            currentValues = {this.state.authPerson}
            action={this.state.authPerson ? "update" : "create"}
            validateOnLoad={this.props.currentValues ? true : false}
            columns = {1}
            uploadFiles={true}
            redirect={null}
            showSubmitButton={this.state.authPerson ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ borderLeft: '1px 0 solid', p: 2, textAlign: "center" }}>
          <Box sx={{ mt: 2 }}>
          {
            this.state.startCapture ?
              (<WebcamImgCapture name={this.props.profileType} capturePhoto={this.handleCapturePhoto} />) :
              (<Grid container justify="center" direction="column" alignItems="center">
                <Grid item>
                  <img src={this.state.capturedImage ? this.state.capturedImage :
                    process.env.PUBLIC_URL + '/static/images/misc/dummy.png'} width="200" height="auto"/>
                </Grid>
                <Grid item>
                  <Button size="small" variant="outlined" color="primary"
                    onClick={async () => await this.setState({ startCapture: true })}
                  > Capture Photo </Button>
                </Grid>
              </Grid>)
          }
          </Box>
        </Grid>
      </Grid>
    );
  }
}
