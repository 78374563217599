export const sampleCollectionFields = [
  {
    name : 'id',
    label : 'Sample Collection Id',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['update'],
  },
  {
    name : 'bookingId',
    label : 'Booking Id',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },
  {
    name : 'hatcheryName',
    label : 'Hatchery Name',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },
  /*{
    name : 'bioMassPerStock',
    label : 'Bio-mass Per Stock (gms)',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },
  {
    name : 'totalNumberOfStock',
    label : 'Total Stock (No.)',
    type : 'string',
    properties : [{required : true }, {readOnly : true}],
    actions : ['view', 'index'],
  },*/
  {
    name : 'storageDate',
    label : 'Storage Date',
    type : 'datetime',
    properties : [{required:true},  {datetime : {noFutureDates : true}} , { monitordate:true}],
    actions : ['view', 'index', 'create','update'],
  },
  {
    name : 'sampleStorage',
    label : 'Sample Storage No.',
    type : 'string',
    properties : [{required : true }, { numeric:true},{string:{min:1,max:10}}],
    actions : ['view', 'index', 'create','update'],
  },
  {  
    name: 'createUser',
    label: 'Created By',
    type: 'string',
    properties: [],
    actions: ['view', 'index']
  },
  {  
    name: 'created_at',
    label: 'Created At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
  {  
    name: 'updateUser',
    label: 'Updated By',
    type: 'string',
    properties: [],
    actions: ['view', 'index']
  },
  {  
    name: 'updated_at',
    label: 'Updated At',
    type: 'datetime',
    properties: [],
    actions: ['view']
  },
];
