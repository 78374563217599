export const scheduleFormFields = [
  {  
    name: 'scheduleMonth',
    label: 'Schedule Month',
    type: 'date',
    properties: [{required:true, date:true}],
    actions: ['create', 'update']
  },
  {  
    name: 'displayMonth',
    label: 'Schedule Month',
    type: 'string',
    properties: [{required:true}],
    actions: ['view', 'index']
  },
  {  
    name: 'speciesId',
    label: 'Species Name',
    type: 'string',
    properties: [{required:true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name: 'status',
    label: 'Schedule Status',
    type: 'select',
    selectOptions : {
      "Available" : "Available",
      "Blocked" : "Blocked",
      "Confirmed" : "Confirmed",
      "NotAvailable" : "Not Available"
    },
    actions : ['view', 'create', 'update', 'index'],
    properties: [{ required:true }], 
  },
  {  
    name: 'availableDates',
    label: 'Available Dates',
    type: 'string',
    properties: [{required:true, json : true}],
    actions : ['create', 'update'],
  },
];

export const scheduleTableFormFields = [
  {  
    name: 'cubicleId',
    label: 'Cubicle Name',
    type: 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions: ['create', 'update', 'view']
  }
];