export default [
  {  
    name: 'name',
    label: 'Name',
    type: 'string',
    properties: [{required:true}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'phoneNumber',
    label: 'Mobile Number',
    type: 'string',
    properties: [{required:true, mobile:true}, {string : { min : 10, max : 10}}],
    actions: ['create', 'update', 'view', 'index']
  },
  {  
    name: 'email',
    label: 'Email Address',
    type: 'email',
    properties: [{required:true, email : true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'role',
    label : 'Role',
    type : 'select',
    selectOptions : {},
    properties: [{required:true}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {  
    name: 'password',
    label: 'Password',
    type: 'password',
    properties: [{required:true, strongPassword : true}],
    actions : ['create', 'update',],
  },
  {  
    name: 'confirmPassword',
    label: 'Confirm Password',
    type: 'password',
    properties: [{required:true, strongPassword : true}],
    actions : ['create', 'update',],
  },
  {  
    name: 'picture',
    label: 'Picture',
    type: 'image',
    type : 'image',
    properties : [{required : false}, {filesize: { min: 0, max: 5 } }, {filetype: ["jpeg", "png", "jpg", "PNG", "JPEG", "JPG"]}],
    actions : ['view', 'create', 'update', 'index'],
  },
  {
    name : 'status',
    label : 'Status',
    type : 'boolean',
    valueStrings : {
      1 : 'Active',
      0 : 'In Active'
    },
    properties: [{required:true}],
    actions : ['view', 'create', 'update', 'index'],
  },
];