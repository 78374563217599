import React, { Component } from 'react';

import { withLocationParams } from 'hoc';

// Material components/
import { Paper, Typography } from '@mui/material';

// Form Validation and Dynamic Forms
import { DynamicForm } from 'components/Forms';

// Custom snackbar
import CustomSnackbar from 'components/CustomSnackbar';

import NavigateBack from 'components/NavigateBack';

import userAccountFields from './user-accounts.fields';

import { fetchUserAccountById, updateUserAccountAdminOps } from 'services/user';
import { fetchAllRoles } from 'services/roles-permissions';
import { checkUniqueAttributes } from 'services/common';

class EditUserAccount extends Component {
  
  state = { 
    userData : null, 
    statusMessage : {status: "warning", message: "Please wait !! data is being fetched..."}
  };
  
  constructor(props) {
    super(props);
  }
  
  handlePreFormSubmit = async(formData) => {
    
    let statusMessage = { status: "success", message: "" };
    
    let validation = {isValid : true};
      
    // check uniqueness of the attributes
    try {
      let uniqueParams = [{
        'attribute' : 'email',
        'value' : formData.email,
        'currentId' : this.props.locationParams.id
      }, {
        'attribute' : 'phoneNumber',
        'value' : formData.phoneNumber,
        'currentId' : this.props.locationParams.id
      }];

      let result = await checkUniqueAttributes(uniqueParams);
      
      if (result.data.email && result.data.email.unique === false) {
        validation.isValid = false;
        validation['email'] = { isInvalid : true, message : result.data.email.message };
      }
      if (result.data.phoneNumber && result.data.phoneNumber.unique === false) {
        validation.isValid = false;
        validation['phoneNumber'] = { isInvalid : true, message : result.data.phoneNumber.message };
      }
    } catch(error) {
      statusMessage =  { status: "error", message: error.message };
    }
  
    if (validation.isValid === false) {
      statusMessage =  { 
        status: "error", 
        message: 'One or more fields in the form data doesn\'t meet uniqueness criteria', 
        data : validation 
      };
    }
    
    return statusMessage;
  }

  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully updated user account details..." };
    
    if (params.password === "DummyP@123") {
      delete params.password;
      delete params.confirmPassword;
    }
    
    if (params.password && params.password !== params.confirmPassword) {
      return ({ status : "error", message : "Confirm Password does not match with the entered password", 
        data : {
          isValid: false,
          confirmPassword : { isInvalid : true, message : "Confirm Password does not match with the entered Password"},
        }
      });
    }
    
    // todo API calls
    try {
      let response = await updateUserAccountAdminOps(this.props.locationParams.id, params);
    } catch(error) {
      console.log(error);
      statusMessage = { status : "error", message : error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  async componentDidMount() {
    let userData = null;
    let statusMessage = this.state.statusMessage;
    
    console.log(this.props.location.state);
    console.log(this.props.locationParams);
    
    try {
      let response = await fetchUserAccountById(this.props.locationParams.id);
      userData = response.data;
      
      // fill required info for form from nested data
      userData.email = userData.user.email;
      userData.role = userData.user.role;
      userData.status = !userData.user.blocked;
      userData.password = "DummyP@123";
      userData.confirmPassword = "DummyP@123";
      
      // fetch available roles info
      response = await fetchAllRoles({ page : 1, per_page : 100});
      response.data.map( entry => {
        // cannot add hatchery owner account here !!!
        if (entry.role.name === 'Hatchery Owner') {
          return;
        }
        
        userAccountFields[3].selectOptions[entry.role.name] = entry.role.name
      });
      
      statusMessage = {status: "success", message: "Fetched user account details successfully !"}  
    } catch (error) {
      console.log (error);
      statusMessage = { status : "error", message : error.message };
    }
    await this.setState({userData, statusMessage});
  }

  render() {
    return (
    <>
      <NavigateBack />
      <Paper elevation={3} sx={{textAlign:"center", m:2, p:2}}>
        <Typography variant="h4"> Update User Profile </Typography> <br/><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.userData &&
          <DynamicForm
            formFields={userAccountFields}
            action="update"
            preFormSubmit={this.handlePreFormSubmit}
            handleSubmit={this.handleFormSubmit}
            buttonText="Update AQF User Details"
            buttonWidth= "full"
            columns = {3}
            uploadFiles={true}
            currentValues={this.state.userData}
            redirect={null}
            confirmation={"Are you sure you want to save the details ? "}
          />
        }
      </Paper>
    </>);
  }
}

export default withLocationParams(EditUserAccount);