import React, { Component } from "react";

// import TableView Component
import TableView from 'pages/common/TableView';

import { AuthStore } from "store";

import { 
  fetchBookingTimesTotalCount,
  fetchAllBookingTimes, 
} from 'services/booking-times';

import bookingtimesFields from './booking-times.fields';

import { permissionCheck } from 'permissions';

export default class BookingTimesList extends Component {

  constructor(props) {
    super(props);
    
    this.currentUser = AuthStore.state.currentUser;
    this.state = { updateContent : false };
    
    this.tableProps = {
      // table state
      updateContent : false,
      
      // table header props
      title : 'List of All Booking Times',
      addButtonText : 'Add New Booking Time',
      addUrl : permissionCheck('common', 'booking-times-create') ?  '/aqf/admin/booking-times/add' : null,
      searchEnable : false,              
      searchPlaceholder : 'Search By Name',
      
      // table content props
      columns : bookingtimesFields,
      itemUrlPath : null,
      editUrlPath : permissionCheck('common', 'booking-times-update') ?  '/aqf/admin/booking-times/edit/' : null,
      multiDeleteEnabled : false,
      
      // define service APIs.
      fetchDataApi : this.fetchBookingTimesDataWrapper,
      fetchCountApi : this.fetchBookingTimesCountWrapper,
      deleteMultipleApi : null,
      deleteSingleApi : null,
      
      // define filters
      selectFilters : [],
      
      // export to xl, pdf props
      exportEnable : false,
    };
  }
  
  fetchBookingTimesCountWrapper = async(filters, searchValue) => {
    let response = await fetchBookingTimesTotalCount({
      name : searchValue
    });
    return response;
  }

  fetchBookingTimesDataWrapper = async (page, perPage, filters, searchValue) => {
    let response = await fetchAllBookingTimes({
      page: page,
      per_page: perPage,
      name : searchValue
    });
    
    if (response && response.status === 200) {
      response.data.forEach(entry => {
        entry.selection = false;
        entry.url = `/aqf/admin/booking-times/edit/${entry.id}`;
        entry.species_id = (entry.species_id || {}).name;
     });
    }
    
    return response;
  }

  async componentDidMount() {
    this.tableProps.updateContent = true;
    await this.setState({updateContent : true});
  }
  
  async componentDidUpdate(prevProps, prevState) { 
    if (this.tableProps.updateContent) {
      this.tableProps.updateContent = false;
      await this.setState({updateContent : false});
    }
  }


  render() {
    return (
      <TableView {...this.props} {...this.tableProps}/>
    );
  }
}